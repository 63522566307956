import { Cart } from '@ab-core/graphql/dist/index';
import { useSessionCart } from '@ab-core/hooks/user/useSessionCart';
import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import cartMapper, { MappedCart } from '../mappers/cartMapper';
import { CARTS } from './getMyCartsQuery';

type Data = {
    getMyCarts?: Cart[];
};

type UseIdsCartsType = {
    idsCarts?: MappedCart[];
    idsCartsError?: ApolloError;
    idsCartsLoading: boolean;
    idsCartsRefetch: () => Promise<ApolloQueryResult<Data>>;
};
const useIdsCarts = (withActiveCart = false, skip = false): UseIdsCartsType => {
    const { checkForSessionCart } = useSessionCart();
    const { ociSessionId } = checkForSessionCart();
    const { error, data, loading, refetch } = useQuery<Data>(CARTS, {
        variables: { sessionId: ociSessionId },
        fetchPolicy: 'network-only',
        skip
    });
    const [idsCarts, setIdsCarts] = useState<Array<MappedCart>>([]);

    useEffect(() => {
        const mappedCarts = loading || error ? [] : data?.getMyCarts?.map((cart) => cartMapper(cart));
        const idsCartsTemp = mappedCarts
            ?.filter((mappedCart) => (withActiveCart ? true : !mappedCart?.isActiveCart))
            ?.filter((mappedCart) => mappedCart?.isIdsCart)
            ?.sort((a, b) => (b?.lastModifiedAt || new Date()).getTime() - (a?.lastModifiedAt || new Date()).getTime());

        setIdsCarts(idsCartsTemp || []);
    }, [data]);
    return {
        idsCarts,
        idsCartsError: error,
        idsCartsLoading: loading,
        idsCartsRefetch: refetch
    };
};

export default useIdsCarts;
