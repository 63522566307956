import { Colors, Shadows } from '@ab-core/colors';
import { Z_INDEX } from '@ab-core/z-index';
import styled from 'styled-components';

export const HeaderNavigation = styled.div`
    overflow: hidden;
    background-color: ${Colors.white};
    position: fixed;
    top: 0;
    width: 100%;

    z-index: ${Z_INDEX.FIXED};

    box-shadow: ${Shadows.navigationShadow};
`;
