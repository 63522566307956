import { gql } from '@apollo/client';
import CART_PRICE_OBJECT from '../fragments/cartPriceFragment';

const CART_PRICE_QUERY = gql`
    query getCartPrice(
        $products: [CartProduct!]
        $includeShipping: Boolean
        $credits: Float
        $coupon: CouponInput
        $projectId: Float
    ) {
        cartPriceObject(
            products: $products
            includeShipping: $includeShipping
            credits: $credits
            coupon: $coupon
            projectId: $projectId
        ) {
            ...CartPriceFields
        }
    }
    ${CART_PRICE_OBJECT}
`;

export default CART_PRICE_QUERY;
