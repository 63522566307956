import styled from 'styled-components';

export const StyledImage = styled.div`
    position: relative;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
    }
`;

export const ImageLoader = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
`;
