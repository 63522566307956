import type { Mutation, MutationSearchTrackingLoginArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { SEARCH_TRACKING_LOGIN } from './gql/searchTrackingLogin';

type MutationDataType = Pick<Mutation, 'searchTrackingLogin'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('searchTrackingLogin');

export const useSearchTrackingLogin = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [searchTrackingLogin, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationSearchTrackingLoginArgs
    >(SEARCH_TRACKING_LOGIN, {
        onError
    });

    const outgoingSearchTrackingLogin = useCallback(
        (trackingLogin: MutationSearchTrackingLoginArgs['trackingLogin']) => {
            searchTrackingLogin({ variables: { trackingLogin } });
        },
        [searchTrackingLogin]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        searchTrackingLogin: outgoingSearchTrackingLogin,
        loading,
        response
    };
};
