import { ROUTES } from '@ab-core/functions/routes';
import { Link, LINK_VARIANT } from '@ab-core/link';
import Text, { FontTypes } from '@ab-core/text';
import { toast } from '@ab-core/toast';
import React from 'react';

export const cartActiveToast = (id: string, name?: string, notificationText?: string): void => {
    toast.info(
        'Warenkorb aktiv',
        <>
            <Text type={FontTypes.Subtitle} color="gray70">
                {notificationText?.split('$placeholder')[0]}
                <Link variant={LINK_VARIANT.LINK_ONLY} to={`${ROUTES.CART}?${id}`}>
                    {name}
                </Link>
                {notificationText?.split('$placeholder')[1]}
            </Text>{' '}
        </>
    );
};
