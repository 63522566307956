import { CART_OBJECT } from '@ab-core/hooks/fragments/cartFragment';
import { gql } from '@apollo/client';

export const ACTIVECART = gql`
    query getActiveCart($sessionId: String) {
        getActiveCart(sessionId: $sessionId) {
            ...ABCartFields
        }
    }
    ${CART_OBJECT}
`;
