import { useEffect, useState } from 'react';

function getWindowDimensions() {
    if (typeof window === 'undefined') {
        return {
            browserWidth: 0,
            browserHeight: 0
        };
    }

    const { innerWidth: browserWidth, innerHeight: browserHeight } = window;

    return {
        browserWidth,
        browserHeight
    };
}

const useWindowDimensions = () => {
    if (typeof window === 'undefined') {
        return {
            browserWidth: 0,
            browserHeight: 0
        };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
};

export default useWindowDimensions;
