import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const CheckCircled: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.75 10C18.75 14.8325 14.8325 18.75 10 18.75C5.16751 18.75 1.25 14.8325 1.25 10C1.25 5.16751 5.16751 1.25 10 1.25C14.8325 1.25 18.75 5.16751 18.75 10ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM14.4419 7.44194C14.686 7.19786 14.686 6.80214 14.4419 6.55806C14.1979 6.31398 13.8021 6.31398 13.5581 6.55806L7.64706 12.4691L6.44194 11.2639C6.19786 11.0199 5.80214 11.0199 5.55806 11.2639C5.31398 11.508 5.31398 11.9037 5.55806 12.1478L7.20512 13.7949C7.32233 13.9121 7.4813 13.9779 7.64706 13.9779C7.81282 13.9779 7.97179 13.9121 8.089 13.7949L14.4419 7.44194Z"
                fill={color}
            />
        </SVG>
    );
};
