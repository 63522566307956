import { gql } from '@apollo/client';

export const UPDATE_KANBAN_LABEL_SET = gql`
    mutation UpdateKanbanLabelSet($data: KanbanLabelSetInput!) {
        updateKanbanLabelSet(data: $data) {
            id
            createdAt
            updatedAt
            accountId
            name
            kanbanLabelIds
            count
        }
    }
`;
