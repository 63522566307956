import type { InputProps } from '@ab-core/input';
import { Input } from '@ab-core/input';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { useDebounce } from './';

type DebounceProps = { delay?: number };
type InputPropsWithDebounceDelay = InputProps & DebounceProps;

export const DebouncedInput: FC<InputPropsWithDebounceDelay> = (props) => {
    const { onChange, value = '', delay, ...rest } = props;

    const [inputValue, setInputValue] = useState(value);

    const { handleDebounce } = useDebounce(delay);

    const handleOnChange: InputProps['onChange'] = (e) => {
        setInputValue(e.target.value);

        if (!onChange) {
            return;
        }

        handleDebounce(() => {
            onChange(e);
        });
    };

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    return <Input {...rest} onChange={handleOnChange} value={inputValue} />;
};
