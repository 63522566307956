import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const CheckedFilled: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.4419 6.55806C14.686 6.80214 14.686 7.19786 14.4419 7.44194L8.089 13.7949C7.97179 13.9121 7.81282 13.9779 7.64706 13.9779C7.4813 13.9779 7.32233 13.9121 7.20512 13.7949L5.55806 12.1478C5.31398 11.9037 5.31398 11.508 5.55806 11.2639C5.80214 11.0199 6.19786 11.0199 6.44194 11.2639L7.64706 12.4691L13.5581 6.55806C13.8021 6.31398 14.1979 6.31398 14.4419 6.55806Z"
                fill="white"
            />
        </SVG>
    );
};
