import Button, { BUTTON_COLOR, BUTTON_VARIANT } from '@ab-core/button';
import type { BasicDropdownProps } from '@ab-core/dropdown';
import { BasicDropdown } from '@ab-core/dropdown';
import type { FavoriteItemType } from '@ab-core/favorite-list';
import { useFavoriteList } from '@ab-core/favorite-list';
import { Col, Container, Row } from '@ab-core/grid';
import type { IconProps } from '@ab-core/icon';
import { Icon, Icons } from '@ab-core/icon';
import type { InputProps } from '@ab-core/input';
import { Input } from '@ab-core/input';
import Modal from '@ab-core/modal';
import Spacer from '@ab-core/spacer';
import { navigationStore } from '@ab-core/store';
import Text from '@ab-core/text';
import { toast } from '@ab-core/toast';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';

export const FavoriteToggle: FC = () => {
    const { itemIsInList, toggleItem, currentSiteItem } = useFavoriteList();

    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const [item, setItem] = useState(currentSiteItem);

    const iconColor = itemIsInList ? 'warningLightenOne' : 'black';
    const iconName = itemIsInList ? 'StarFilled' : 'Star';

    useEffect(() => {
        setItem(currentSiteItem);
    }, [currentSiteItem]);

    const handleToggleFavorite = () => {
        if (itemIsInList) {
            setIsDeleteModalOpen(true);

            return;
        }

        setIsAddModalOpen(true);
    };

    const onNameChange: InputProps['onChange'] = (e) => {
        const { value } = e.currentTarget;
        setItem({ ...item, name: value });
    };

    const onIconChange: BasicDropdownProps['onItemChange'] = (iconItem) => {
        const newModalItem: FavoriteItemType = { ...item, icon: iconItem?.value as IconProps['name'] };

        setItem(newModalItem);
    };

    const onCancel = () => {
        setItem(currentSiteItem);
        closeModals();
    };

    const onSave = () => {
        toggleItem(item);
        closeModals();
        navigationStore.setters.setUserDropdownNavigation(false);
        navigationStore.setters.setUserNavigation(false);
        toast.success('Favorit angelegt', `Ein neuer Favorit mit dem Namen "${item.name}" wurde angelegt`);
    };

    const onDelete = () => {
        toggleItem(currentSiteItem);
        closeModals();
        navigationStore.setters.setUserDropdownNavigation(false);
        navigationStore.setters.setUserNavigation(false);
        toast.info('Favorit gelöscht', `Der Favorit mit dem Namen "${item.name}" wurde gelöscht`);
    };

    const closeModals = () => {
        setIsDeleteModalOpen(false);
        setIsAddModalOpen(false);
    };

    return (
        <>
            <div className="flex gap-small2 pointer" onClick={handleToggleFavorite}>
                <Icon name={iconName} color={iconColor} />
                <Text>
                    {itemIsInList && 'Seite aus Favoriten entfernen'}
                    {!itemIsInList && 'Seite zu Favoriten hinzufügen'}
                </Text>
            </div>
            <Modal
                headerText="Favorit anlegen"
                onClose={closeModals}
                open={isAddModalOpen}
                footer={
                    <Row justifyContent="between">
                        <Col sm={6} smOrder={2}>
                            <Button onClick={onSave} fluid>
                                Speichern
                            </Button>
                            <Spacer size="xs" />
                        </Col>
                        <Col sm={6} smOrder={1}>
                            <Button variant={BUTTON_VARIANT.OUTLINE} fluid onClick={onCancel} color={BUTTON_COLOR.GRAY}>
                                Abbrechen
                            </Button>
                            <Spacer size="xs" />
                        </Col>
                    </Row>
                }
            >
                <Container>
                    <Spacer size="xs" />
                    <Row>
                        <Col sm={6}>
                            <Input label="Name" value={item.name} onChange={onNameChange} maxLength={20} />
                        </Col>
                        <Col sm={6}>
                            <BasicDropdown
                                label="Icon"
                                onItemChange={onIconChange}
                                items={getIconListForDropdown()}
                                selectedValue={item?.icon}
                            />
                        </Col>
                    </Row>
                    <Spacer size="xxl" />
                    <Text color="info" className="full-width ellipsis">
                        Favorit wird angelegt für: {currentSiteItem.url}
                    </Text>
                    <Spacer size="xs" />
                    <Text>
                        Bitte vergeben Sie einen Namen und klicken anschließend auf “Speichern”, um ihr Änderungen zu
                        übernehmen.
                    </Text>
                </Container>
            </Modal>
            <Modal
                headerText="Favorit löschen"
                onClose={closeModals}
                open={isDeleteModalOpen}
                footer={
                    <Row justifyContent="between">
                        <Col sm={6} smOrder={2}>
                            <Button onClick={onDelete} fluid>
                                Löschen
                            </Button>
                            <Spacer size="xs" />
                        </Col>
                        <Col sm={6} smOrder={1}>
                            <Button variant={BUTTON_VARIANT.OUTLINE} fluid onClick={onCancel} color={BUTTON_COLOR.GRAY}>
                                Abbrechen
                            </Button>
                            <Spacer size="xs" />
                        </Col>
                    </Row>
                }
            >
                <Container>
                    <Spacer size="xs" />
                    <div className="flex-direction-row">
                        <Icon name="Delete" color="primary" />
                        <Text color="primary">{item.name}</Text>
                    </div>
                    <Spacer size="xs" />
                    <Text>
                        Klicken Sie auf “Löschen”, um diesen Favorit endgültig zu entfernen. Dieser kann nach dem
                        Löschvorgang nicht wiederhergestellt werden.
                    </Text>
                    <Spacer size="xs" />
                </Container>
            </Modal>
        </>
    );
};

const getIconListForDropdown = (): BasicDropdownProps['items'] => {
    const dropdownList: BasicDropdownProps['items'] = [];
    Object.keys(Icons).forEach((key) => {
        dropdownList.push({ value: key, label: <Icon name={key as IconProps['name']} /> });
    });

    return dropdownList;
};
