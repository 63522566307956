import type { Mutation, MutationCreateProductNotFoundMailArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { CREATE_PRODUCT_NOT_FOUND_MAIL } from './gql/createProductNotFoundMail';

type MutationDataType = Pick<Mutation, 'createProductNotFoundMail'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('createProductNotFoundMail');

export const useCreateProductNotFoundMail = (
    searchMailInput: MutationCreateProductNotFoundMailArgs['searchMailInput']
) => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [createProductNotFoundMail, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationCreateProductNotFoundMailArgs
    >(CREATE_PRODUCT_NOT_FOUND_MAIL, {
        variables: { searchMailInput },
        onError
    });

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        createProductNotFoundMail,
        loading,
        response
    };
};
