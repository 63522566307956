import type { FC } from 'react';
import React from 'react';
import { SpacerContainer } from './styled';
import type { SpacerProps } from './types';

const Spacer: FC<SpacerProps> = (props) => {
    const { size = 'md', ...rest } = props;

    return <SpacerContainer size={size} {...rest} />;
};

export default Spacer;
