import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Bookmarks: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.75 1.62097C15.75 1.40006 15.5709 1.22097 15.35 1.22097H4.65C4.42909 1.22097 4.25 1.40006 4.25 1.62097V18.4571C4.25 18.6748 4.50897 18.7885 4.66923 18.6411L8.83969 14.8059C9.50201 14.2329 10.498 14.2329 11.1603 14.8059L15.3308 18.6411C15.491 18.7885 15.75 18.6748 15.75 18.4571V1.62097ZM4.25 0C3.55964 0 3 0.546648 3 1.22097V18.7766C3 19.8291 4.27218 20.3885 5.0788 19.6906L9.66848 15.7199C9.85772 15.5562 10.1423 15.5562 10.3315 15.7199L14.9212 19.6906C15.7278 20.3885 17 19.8291 17 18.7766V1.22097C17 0.546648 16.4404 0 15.75 0H4.25Z"
                fill={color}
            />
        </SVG>
    );
};
