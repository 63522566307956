type UseIdsSessionType = {
    isIdsSession: boolean;
    hookUrl?: string;
    idsVersion?: string;
    returnTarget?: string;
};

const useIdsSession = (): UseIdsSessionType => {
    if (typeof window === 'undefined') {
        return { isIdsSession: false };
    }

    const idsSessionString = localStorage.getItem('idsSession');

    if (idsSessionString) {
        const sessionData = JSON.parse(idsSessionString);

        return {
            isIdsSession: true,
            hookUrl: sessionData.hookUrl,
            idsVersion: sessionData.idsVersion,
            returnTarget: sessionData.returnTarget
        };
    }

    return { isIdsSession: false };
};

export default useIdsSession;
