import { Card } from '@ab-core/card';
import { Col, Container, Row } from '@ab-core/grid';
import useText from '@ab-core/localization/src/useText';
import type { FC } from 'react';
import React from 'react';
import { ErrorContent } from './errorContent';
import { ErrorFallbackWrapper } from './styled';

export type ErrorFallbackCardType = React.ComponentPropsWithoutRef<'div'> & {
    code?: string;
    message?: string;
};
const colSix = 6;
const colEight = 8;

export const ErrorFallbackCard: FC<ErrorFallbackCardType> = (props) => {
    const { code, message, ...rest } = props;
    const headline = useText('errorFallbackCard.headline');

    return (
        <ErrorFallbackWrapper {...rest}>
            <Container noPadding>
                <Row justifyContent="center">
                    <Col sm={colEight} md={colSix}>
                        <Card head={headline} content={<ErrorContent message={message} code={code} />} />
                    </Col>
                </Row>
            </Container>
        </ErrorFallbackWrapper>
    );
};
