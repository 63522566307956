import { useRef } from 'react';

const DEBOUNCE_DELAY = 300;

type DebounceCallbackType = () => void;
type Timer = ReturnType<typeof setTimeout>;

export const useDebounce = (delay = DEBOUNCE_DELAY) => {
    const timer = useRef<Timer>();

    const handleDebounce = (callback: DebounceCallbackType) => {
        const newTimer = setTimeout(() => {
            callback();
        }, delay);

        clearTimeout(timer.current);
        timer.current = newTimer;
    };

    const cancelDebounce = () => {
        clearTimeout(timer.current);
    };

    return {
        handleDebounce,
        cancelDebounce
    };
};
