type Id = {
    id: string;
};

type contentType = {
    sys: Id;
};

type Sys = {
    contentType: contentType;
};

export type NavigationElement = {
    id?: string;
    active?: boolean;
    name?: string;
    slug?: string;
    navigationElements?: NavigationElement[] | null;
    externalLink?: string | null;
    identifier?: string | null;
    ancestors?: string[];
    sys?: Sys;
};

const findBySlugOrIdentifier = (
    slug: string,
    navigationElementWithAncestors?: NavigationElement
): NavigationElement | undefined => {
    if (navigationElementWithAncestors?.slug === slug || navigationElementWithAncestors?.identifier === slug) {
        return navigationElementWithAncestors;
    }

    let childElement;

    if (navigationElementWithAncestors?.navigationElements) {
        navigationElementWithAncestors?.navigationElements.forEach((element: NavigationElement) => {
            const tmp = findBySlugOrIdentifier(slug, element);

            if (tmp) {
                childElement = tmp;
            }
        });
    }

    if (childElement) {
        return childElement;
    }

    return undefined;
};

const getSlug = ({ slug, sys }: NavigationElement) => {
    const pathMap: PathMapType = {
        newsTemplate: 'magazin',
        serviceTemplate: 'services',
        promotionTemplate: 'promotion'
    };

    if (slug && sys?.contentType?.sys?.id) {
        return pathMap[sys.contentType.sys.id as keyof typeof pathMap]
            ? `${pathMap[sys.contentType.sys.id as keyof typeof pathMap]}/${slug}`
            : slug;
    }

    return undefined;
};

const setAncestors = (
    navigationElement: NavigationElement,
    ancestorIds: string[] | null
): NavigationElement | undefined => {
    const newAncestorIds = ancestorIds || [];
    const slug = getSlug(navigationElement);
    const newNavigationElements = navigationElement?.navigationElements?.map((elem: NavigationElement) =>
        setAncestors(elem, [...newAncestorIds, navigationElement.id] as string[])
    );

    if (slug) {
        return {
            id: navigationElement.id,
            name: navigationElement.name,
            ancestors: ancestorIds,
            navigationElements: newNavigationElements,
            slug
        } as NavigationElement;
    }

    return {
        ...navigationElement,
        ...{
            ancestors: ancestorIds,
            navigationElements: newNavigationElements
        }
    } as NavigationElement;
};

type PathMapType = {
    newsTemplate: string;
    serviceTemplate: string;
    promotionTemplate: string;
};
const setActivePath = (ancestors: string[], id?: string, navigationElement?: NavigationElement) => {
    let active = false;

    if (ancestors.includes(navigationElement?.id ? navigationElement.id : '')) {
        active = true;
    }

    if (navigationElement?.id === id) {
        active = !navigationElement?.active;
    }

    return {
        ...navigationElement,
        ...{
            active,
            navigationElements: setChildren(ancestors, id, navigationElement?.navigationElements)
        }
    };
};

const setChildren = (
    ancestors: string[],
    id?: string,
    children?: NavigationElement[] | null
): NavigationElement[] | undefined => {
    if (children?.length) {
        return children.map((child: NavigationElement) => setActivePath(ancestors, id, child));
    }

    return undefined;
};

const NavigationElementMapper = (navigationElement: NavigationElement, slug: string): NavigationElement | undefined => {
    const withAncestors = setAncestors(navigationElement, null);
    const activeElement = findBySlugOrIdentifier(slug, withAncestors);

    if (!activeElement || !activeElement.ancestors) {
        return withAncestors;
    }

    return setActivePath(activeElement.ancestors, activeElement.id, withAncestors);
};

export default NavigationElementMapper;
