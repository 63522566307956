import { FontTypesList } from '@ab-core/font-types';
import type { TextAlignmentType } from '@ab-core/functions';
import type { WithTestId } from '@ab-core/testing';
import type { FC, ReactNode } from 'react';
import React from 'react';
import { ABText } from './styled';

export { FontTypesList as FontTypes };

type AsElementTypes = 'div' | 'p' | 'span' | 'a';

export type TextProps = WithTestId &
    TextAlignmentType & {
        uppercase?: boolean;
        content?: ReactNode | string;
        asElement?: AsElementTypes;
        children?: ReactNode;
        color?: string;
        type?: FontTypesList;
        className?: string;
        onClick?: () => void;
        background?: string;
        isLink?: boolean;
        id?: string;
    };

const Text: FC<TextProps> = ({
    uppercase = false,
    asElement = 'div',
    content = '',
    children,
    color = 'black',
    type = FontTypesList.Body,
    isLink = false,
    ...rest
}: TextProps) => (
    <ABText
        uppercase={uppercase}
        as={asElement}
        asElement={asElement}
        color={color}
        type={type}
        isLink={isLink}
        {...rest}
    >
        {content || children}
    </ABText>
);

export default Text;
