import { gql } from '@apollo/client';

export type DeleteCartResponseType = {
    deleteMyCart: {
        id: string;
    };
};

const DELETE_CART = gql`
    mutation DeleteMyCartMutation($cartId: String!) {
        deleteMyCart(cartId: $cartId) {
            id
        }
    }
`;

export default DELETE_CART;
