import { logger } from '@ab-core/logger';
import type { ErrorInfo, ReactNode } from 'react';
import { Component } from 'react';

export type FallbackRendererType = (error: Error) => ReactNode;
type Props = {
    children?: ReactNode;
    fallbackRenderer: FallbackRendererType;
};

type State = {
    hasError: boolean;
    error?: Error;
};

export class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
        error: undefined
    };

    public static getDerivedStateFromError(error: Error): State {
        return { hasError: true, error };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo);
        logger.error({
            title: `Error Boundary at "${location.href}"`,
            error
        });
    }

    public render() {
        if (this.state.hasError && this.state.error) {
            return this.props.fallbackRenderer(this.state.error);
        }

        return this.props.children;
    }
}
