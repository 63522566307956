import type { Query, QueryBestPricesArgs } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { BEST_PRICES } from './gql/bestPrices';

type QueryDataType = Pick<Query, 'bestPrices'>;
type OutgoingDataType = QueryDataType['bestPrices'];

const onError = getErrorLoggerFunction('bestPrices');

export const useBestPrices = (skus: Array<string>, skip = false) => {
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryBestPricesArgs>(BEST_PRICES, {
        variables: {
            productIds: skus
        },
        skip,
        onError
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(data.bestPrices);
        }
    }, [data]);

    return {
        bestPrices: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
