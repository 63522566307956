import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const ScalePrice: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.625 0C0.279822 0 0 0.279822 0 0.625V19.375C0 19.7202 0.279822 20 0.625 20C0.970178 20 1.25 19.7202 1.25 19.375V0.625C1.25 0.279822 0.970178 0 0.625 0ZM6.625 4C6.27982 4 6 4.27982 6 4.625V19.375C6 19.7202 6.27982 20 6.625 20C6.97018 20 7.25 19.7202 7.25 19.375V4.625C7.25 4.27982 6.97018 4 6.625 4ZM12 8.625C12 8.27982 12.2798 8 12.625 8C12.9702 8 13.25 8.27982 13.25 8.625V19.375C13.25 19.7202 12.9702 20 12.625 20C12.2798 20 12 19.7202 12 19.375V8.625ZM18.625 12C18.2798 12 18 12.2798 18 12.625V19.375C18 19.7202 18.2798 20 18.625 20C18.9702 20 19.25 19.7202 19.25 19.375V12.625C19.25 12.2798 18.9702 12 18.625 12Z"
                fill={color}
            />
        </SVG>
    );
};
