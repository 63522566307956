import { gql } from '@apollo/client';

export const GET_FAVORITE_LINKS = gql`
    query GetFavoriteLinks {
        getFavoriteLinks {
            favoriteLinks {
                icon
                name
                url
            }
        }
    }
`;
