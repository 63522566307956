import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Burger: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 5.5C21 5.224 20.776 5 20.5 5H1.5C1.224 5 1 5.224 1 5.5C1 5.776 1.224 6 1.5 6H20.5C20.776 6 21 5.776 21 5.5ZM10.5 15H1.5C1.223 15 1 14.776 1 14.5C1 14.224 1.223 14 1.5 14H10.5C10.776 14 11 14.224 11 14.5C11 14.776 10.776 15 10.5 15Z"
                fill={color}
            />
            <path
                d="M21.125 5.5C21.125 5.15496 20.845 4.875 20.5 4.875V5.125C20.707 5.125 20.875 5.29304 20.875 5.5H21.125ZM20.5 4.875H1.5V5.125H20.5V4.875ZM1.5 4.875C1.15496 4.875 0.875 5.15496 0.875 5.5H1.125C1.125 5.29304 1.29304 5.125 1.5 5.125V4.875ZM0.875 5.5C0.875 5.84504 1.15496 6.125 1.5 6.125V5.875C1.29304 5.875 1.125 5.70696 1.125 5.5H0.875ZM1.5 6.125H20.5V5.875H1.5V6.125ZM20.5 6.125C20.845 6.125 21.125 5.84504 21.125 5.5H20.875C20.875 5.70696 20.707 5.875 20.5 5.875V6.125ZM10.5 14.875H1.5V15.125H10.5V14.875ZM1.5 14.875C1.2922 14.875 1.125 14.7071 1.125 14.5H0.875C0.875 14.8449 1.1538 15.125 1.5 15.125V14.875ZM1.125 14.5C1.125 14.2929 1.2922 14.125 1.5 14.125V13.875C1.1538 13.875 0.875 14.1551 0.875 14.5H1.125ZM1.5 14.125H10.5V13.875H1.5V14.125ZM10.5 14.125C10.707 14.125 10.875 14.293 10.875 14.5H11.125C11.125 14.155 10.845 13.875 10.5 13.875V14.125ZM10.875 14.5C10.875 14.707 10.707 14.875 10.5 14.875V15.125C10.845 15.125 11.125 14.845 11.125 14.5H10.875Z"
                fill={color}
            />
        </SVG>
    );
};
