import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const FastEntry: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.51647 5.85199C5.84501 3.90273 8.08105 2.625 10.6153 2.625C14.6884 2.625 17.9903 5.9269 17.9903 10C17.9903 14.0731 14.6884 17.375 10.6153 17.375C8.08105 17.375 5.84501 16.0973 4.51647 14.148C4.32207 13.8628 3.93325 13.7891 3.64802 13.9835C3.36279 14.1779 3.28916 14.5668 3.48356 14.852C5.03498 17.1283 7.65027 18.625 10.6153 18.625C15.3788 18.625 19.2403 14.7635 19.2403 10C19.2403 5.23654 15.3788 1.375 10.6153 1.375C7.65027 1.375 5.03498 2.87173 3.48356 5.14801C3.28916 5.43324 3.36279 5.82205 3.64802 6.01645C3.93325 6.21086 4.32207 6.13722 4.51647 5.85199ZM10.55 4.375C10.8952 4.375 11.175 4.65482 11.175 5V9.62326L13.1618 11.61C13.4059 11.8541 13.4059 12.2499 13.1618 12.4939C12.9177 12.738 12.522 12.738 12.2779 12.4939L10.1081 10.3241C9.99087 10.2069 9.92502 10.0479 9.92502 9.88215V5C9.92502 4.65482 10.2048 4.375 10.55 4.375ZM0.475342 7.875C0.475342 7.52982 0.755164 7.25 1.10034 7.25H6.00389C6.34907 7.25 6.62889 7.52982 6.62889 7.875C6.62889 8.22018 6.34907 8.5 6.00389 8.5H1.10034C0.755164 8.5 0.475342 8.22018 0.475342 7.875ZM2.15154 9.30121C1.80636 9.30121 1.52654 9.58103 1.52654 9.92621C1.52654 10.2714 1.80636 10.5512 2.15154 10.5512H7.05508C7.40026 10.5512 7.68008 10.2714 7.68008 9.92621C7.68008 9.58103 7.40026 9.30121 7.05508 9.30121H2.15154ZM0.475342 11.9774C0.475342 11.6322 0.755164 11.3524 1.10034 11.3524H6.00389C6.34907 11.3524 6.62889 11.6322 6.62889 11.9774C6.62889 12.3226 6.34907 12.6024 6.00389 12.6024H1.10034C0.755164 12.6024 0.475342 12.3226 0.475342 11.9774Z"
                fill={color}
            />
        </SVG>
    );
};
