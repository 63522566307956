import { gql } from '@apollo/client';
import ACCOUNT_DATA from '../fragments/accountFragment';

const FULL_USER_QUERY = gql`
    query getCustomerDataWithActiveAccount {
        getCustomerDataWithActiveAccount {
            contact {
                id
                salutation
                userName
                firstName
                lastName
                email
                onlineShopAccess
                fax
                title
                createdAt
                academicTitle
                function
                mobilePhone
                featureAccess {
                    feature {
                        id
                        name
                        value
                        amountValue
                        valueText
                    }
                }
            }
            activeAccount {
                ...accountFields
            }
        }
    }
    ${ACCOUNT_DATA}
`;

export default FULL_USER_QUERY;
