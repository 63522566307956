import type { Filter } from '@ab-core/graphql/dist';
import { getParsedFilter } from './parsedFilter';

export const getParam = (param: string, fallback = ''): string => {
    if (typeof window === 'undefined') {
        return fallback;
    }

    const params = new URLSearchParams(location.search);

    return params.get(param) || fallback;
};

export const getParsedFilterFromParam = (param: string): Filter => {
    const paramString = getParam(param);

    return getParsedFilter(paramString.split(';') || []);
};

export const getStringValueFromParam = (param: string): string => {
    return getParam(param);
};
