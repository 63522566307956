import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Filter: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.96 0.875C5.00414 0.875 4.20331 1.50863 3.9361 2.375H1.5C1.15496 2.375 0.875 2.65496 0.875 3C0.875 3.34504 1.15496 3.625 1.5 3.625H3.9361C4.20331 4.49137 5.00414 5.125 5.96 5.125C6.91505 5.125 7.71627 4.4914 7.98345 3.625H20.5C20.845 3.625 21.125 3.34504 21.125 3C21.125 2.65496 20.845 2.375 20.5 2.375H7.98345C7.71627 1.5086 6.91505 0.875 5.96 0.875ZM5.96 2.125C6.44196 2.125 6.835 2.51804 6.835 3C6.835 3.48196 6.44196 3.875 5.96 3.875C5.47804 3.875 5.085 3.48196 5.085 3C5.085 2.51795 5.47712 2.125 5.96 2.125ZM20.5 10.625H18.0242C17.7575 9.75812 16.9562 9.125 16 9.125C15.044 9.125 14.2437 9.75864 13.9765 10.625H1.5C1.15496 10.625 0.875 10.905 0.875 11.25C0.875 11.595 1.15496 11.875 1.5 11.875H13.9765C14.2437 12.7414 15.044 13.375 16 13.375C16.9562 13.375 17.7575 12.7419 18.0242 11.875H20.5C20.845 11.875 21.125 11.595 21.125 11.25C21.125 10.905 20.845 10.625 20.5 10.625ZM16 12.125C15.518 12.125 15.125 11.732 15.125 11.25C15.125 10.768 15.518 10.375 16 10.375C16.482 10.375 16.875 10.768 16.875 11.25C16.875 11.732 16.482 12.125 16 12.125ZM20.5 18.375H13.0242C12.7575 17.5081 11.9562 16.875 11 16.875C10.0438 16.875 9.24253 17.5081 8.97579 18.375H1.5C1.15496 18.375 0.875 18.655 0.875 19C0.875 19.345 1.15496 19.625 1.5 19.625H8.97579C9.24253 20.4919 10.0438 21.125 11 21.125C11.9562 21.125 12.7575 20.4919 13.0242 19.625H20.5C20.845 19.625 21.125 19.345 21.125 19C21.125 18.655 20.845 18.375 20.5 18.375ZM11 19.875C10.518 19.875 10.125 19.482 10.125 19C10.125 18.518 10.518 18.125 11 18.125C11.482 18.125 11.875 18.518 11.875 19C11.875 19.482 11.482 19.875 11 19.875Z"
                fill={color}
                stroke={color}
                strokeWidth="0.25"
            />
        </SVG>
    );
};
