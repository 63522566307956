import { gql } from '@apollo/client';

import PRODUCT_SEARCH_FRAGMENT from '../fragments/productSearchFragment';

const LAST_PURCHASED_QUERY = gql`
    query LastPurchased($query: String!, $page: Float) {
        lastPurchased(query: $query, page: $page) {
            results {
                document {
                    ...productSearchFields
                }
            }
            pageCount
            currentPage
            count
            total
            facets {
                count
                filterName
                id
                facetId
                name
                resultCount
                selected
                values {
                    count
                    filter
                    value
                }
            }
        }
    }
    ${PRODUCT_SEARCH_FRAGMENT}
`;

export default LAST_PURCHASED_QUERY;
