import { Colors } from '@ab-core/colors';
import { FontTypes } from '@ab-core/font-types';
import styled from 'styled-components';

export const StyledTableHeaderItem = styled.th`
    padding: 0.5rem;
    border-bottom: 1px solid ${Colors.gray70};
    position: sticky;
    top: 0;
    background-color: white;
    font-weight: ${FontTypes.button.fontWeight};
    font-size: ${FontTypes.button.fontSize};
    line-height: ${FontTypes.button.lineHeight};
    letter-spacing: ${FontTypes.button.letterSpacing};
    text-transform: ${FontTypes.button.textTransform};
`;
