import { gql } from '@apollo/client';

export const GET_COUPONS = gql`
    query GetCoupons {
        getCoupons {
            couponId
            customerId
            lastModifiedAt
            createdAt
            couponCode
            couponValue
            minValue
            expirationDate
        }
    }
`;
