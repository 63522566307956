import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Orders: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9788_16707)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.91069 1.41069C3.82493 1.49645 3.75 1.64203 3.75 1.875V18.4519L6.01788 17.5447C6.15513 17.4898 6.3074 17.4853 6.44764 17.5321L10 18.7162L13.5524 17.5321C13.6926 17.4853 13.8449 17.4898 13.9821 17.5447L16.25 18.4519V5H14.375C13.858 5 13.3785 4.82493 13.0268 4.47319C12.6751 4.12145 12.5 3.64203 12.5 3.125V1.25H4.375C4.14203 1.25 3.99645 1.32493 3.91069 1.41069ZM13.75 2.13388L15.3661 3.75H14.375C14.142 3.75 13.9965 3.67507 13.9107 3.58931C13.8249 3.50355 13.75 3.35797 13.75 3.125V2.13388ZM3.02681 0.526808C3.37855 0.175071 3.85797 0 4.375 0H13.125C13.2908 0 13.4497 0.0658481 13.5669 0.183058L17.3169 3.93306C17.4342 4.05027 17.5 4.20924 17.5 4.375V19.375C17.5 19.5824 17.3971 19.7763 17.2254 19.8925C17.0537 20.0088 16.8354 20.0323 16.6429 19.9553L13.7304 18.7903L10.1976 19.9679C10.0694 20.0107 9.93065 20.0107 9.80236 19.9679L6.26955 18.7903L3.35712 19.9553C3.16456 20.0323 2.94634 20.0088 2.77461 19.8925C2.60287 19.7763 2.5 19.5824 2.5 19.375V1.875C2.5 1.35797 2.67507 0.878545 3.02681 0.526808ZM5.625 4.375C5.625 4.02982 5.90482 3.75 6.25 3.75H10C10.3452 3.75 10.625 4.02982 10.625 4.375C10.625 4.72018 10.3452 5 10 5H6.25C5.90482 5 5.625 4.72018 5.625 4.375ZM5.625 8.125C5.625 7.77982 5.90482 7.5 6.25 7.5H13.75C14.0952 7.5 14.375 7.77982 14.375 8.125C14.375 8.47018 14.0952 8.75 13.75 8.75H6.25C5.90482 8.75 5.625 8.47018 5.625 8.125ZM5.625 11.875C5.625 11.5298 5.90482 11.25 6.25 11.25H13.75C14.0952 11.25 14.375 11.5298 14.375 11.875C14.375 12.2202 14.0952 12.5 13.75 12.5H6.25C5.90482 12.5 5.625 12.2202 5.625 11.875ZM5.625 15.625C5.625 15.2798 5.90482 15 6.25 15H13.75C14.0952 15 14.375 15.2798 14.375 15.625C14.375 15.9702 14.0952 16.25 13.75 16.25H6.25C5.90482 16.25 5.625 15.9702 5.625 15.625Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_9788_16707">
                    <rect width="20" height="20" fill="transparent" />
                </clipPath>
            </defs>
        </SVG>
    );
};
