import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const LabelActive: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_16487_960)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.4331 0.183058C11.5503 0.065848 11.7092 0 11.875 0H19.375C19.7202 0 20 0.279822 20 0.625V8.125C20 8.29076 19.9342 8.44973 19.8169 8.56694L8.94194 19.4419C8.19786 20.186 7.05214 20.186 6.30806 19.4419L0.558058 13.6919C-0.186019 12.9479 -0.186019 11.8021 0.558058 11.0581L11.4331 0.183058ZM12.1339 1.25L1.44194 11.9419C1.18602 12.1979 1.18602 12.5521 1.44194 12.8081L7.19194 18.5581C7.44786 18.814 7.80214 18.814 8.05806 18.5581L18.75 7.86612V1.25H12.1339ZM15 3.75C14.3096 3.75 13.75 4.30964 13.75 5C13.75 5.69036 14.3096 6.25 15 6.25C15.6904 6.25 16.25 5.69036 16.25 5C16.25 4.30964 15.6904 3.75 15 3.75ZM12.5 5C12.5 3.61929 13.6193 2.5 15 2.5C16.3807 2.5 17.5 3.61929 17.5 5C17.5 6.38071 16.3807 7.5 15 7.5C13.6193 7.5 12.5 6.38071 12.5 5Z"
                    fill={color}
                />
                <path
                    d="M11 15C11 12.7909 12.7909 11 15 11V11C17.2091 11 19 12.7909 19 15V15C19 17.2091 17.2091 19 15 19V19C12.7909 19 11 17.2091 11 15V15Z"
                    fill="#EA002A"
                />
            </g>
            <defs>
                <clipPath id="clip0_16487_960">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </SVG>
    );
};
