import { gql } from '@apollo/client';

export const GET_PROJECTS_BY_CUSTOMER_ID = gql`
    query GetProjectsByCustomerId {
        getProjectsByCustomerId {
            lastModifiedAt
            createdAt
            projectNr
            projectName
            state
            customerId
            costCenter
            priceDeliveryNote
            credits
            hide
            shippingAddress {
                id
                externalId
                primaryAddress
                name
                streetName
                city
                postalCode
                country
                addressExtraLineOne
                addressExtraLineTwo
                addressExtraLineThree
                defaultShippingInstructions
            }
        }
    }
`;
