import type { BUTTON_VARIANT } from '@ab-core/button';
import Button from '@ab-core/button';
import Headline, { HEADLINE_SIZES } from '@ab-core/headline';
import Text from '@ab-core/text';
import type { FC } from 'react';
import React from 'react';

type TextButtonBoxType = {
    headlineText: string;
    text: string;
    buttonText: string;
    handleClick: () => void;
    variant: BUTTON_VARIANT;
};

export const TextButtonBox: FC<TextButtonBoxType> = ({ headlineText, text, buttonText, handleClick, variant }) => (
    <>
        <Headline className="pb-small2" size={HEADLINE_SIZES.THREE}>
            {headlineText}
        </Headline>
        <Text className="pb-small2" content={text} />
        <Button onClick={handleClick} variant={variant}>
            {buttonText}
        </Button>
    </>
);
