import type React from 'react';
import { ToastLayout } from './basic/index';
import { addToast } from './toastState';

export const toast = {
    success: (title: string, description?: React.ReactNode) => {
        addToast({
            title,
            description,
            styleType: ToastLayout.Success,
            appearance: new Date().getTime()
        });
    },
    warning: (title: string, description?: React.ReactNode) => {
        addToast({
            title,
            description,
            styleType: ToastLayout.Warning,
            appearance: new Date().getTime()
        });
    },
    info: (title: string, description?: React.ReactNode) => {
        addToast({
            title,
            description,
            styleType: ToastLayout.Info,
            appearance: new Date().getTime()
        });
    },
    error: (title: string, description?: React.ReactNode) => {
        addToast({
            title,
            description,
            styleType: ToastLayout.Error,
            appearance: new Date().getTime()
        });
    }
};
