export enum Z_INDEX {
    BELOW = -1,
    BACKGROUND = 0,
    BASE = 1,
    DROPDOWN = 1000,
    STICKY = 1100,
    FIXED = 1200,
    MODAL_BACKDROP = 1300,
    MODAL = 1400,
    TOOLTIP = 1500
}
