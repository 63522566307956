import { logger } from '@ab-core/logger';
import type { ApolloError } from '@apollo/client';

export const getErrorLoggerFunction = (hookName: string) => {
    const errorTitle = getHookErrorTitle(hookName);

    return (error: ApolloError) =>
        logger.error({
            title: errorTitle,
            error
        });
};

const getHookErrorTitle = (hookName: string) => {
    return `Hook Error on "${hookName}"`;
};
