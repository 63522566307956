import type { FC } from 'react';
import React from 'react';
import { DESKTOP_ATTRIBUTES, MOBILE_ATTRIBUTES } from './versions';

export enum BRAND_LOGO_VERSION {
    MOBILE = 'mobile',
    DESKTOP = 'desktop'
}

type BrandLogoProps = React.ComponentPropsWithoutRef<'svg'> & {
    version?: BRAND_LOGO_VERSION;
};

export const BrandLogo: FC<BrandLogoProps> = (props) => {
    const { version = BRAND_LOGO_VERSION.DESKTOP, ...rest } = props;

    const attributes = version === BRAND_LOGO_VERSION.DESKTOP ? DESKTOP_ATTRIBUTES : MOBILE_ATTRIBUTES;

    return (
        <svg viewBox={attributes.viewBox} fill="none" width="100%" height="100%" {...rest}>
            <path fillRule="evenodd" clipRule="evenodd" d={attributes.drawPath} fill="currentColor" />
        </svg>
    );
};
