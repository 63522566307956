import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const WindRose: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 1C5.47716 1 1 5.47715 1 11C1 16.5228 5.47716 21 11 21C16.5229 21 21 16.5228 21 11C21 5.47715 16.5229 1 11 1ZM11 1L11 4M1 11.5H4M11 20.5V17.5M18 11.5H21M14.9263 5.73019L9.80507 9.2621L7.94169 15.639L13.5 12L14.9263 5.73019Z"
                stroke={color}
            />
        </SVG>
    );
};
