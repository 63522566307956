import { getSessionId } from '@ab-core-functions/tracking';
import type { Query, QuerySearchV2Args } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { SEARCH_V2 } from './gql/searchV2';

type QueryDataType = Pick<Query, 'searchV2'>;
type OutgoingDataType = QueryDataType['searchV2'];

type IncomingDataType = {
    query: string;
    skip?: boolean;
};

const onError = getErrorLoggerFunction('searchV2');

export const useSearchV2 = (props: IncomingDataType) => {
    const { skip = false, query } = props;
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);
    const [outgoingLoading, setOutgoingLoading] = useState(true);

    const { data, loading, refetch, error } = useQuery<QueryDataType, QuerySearchV2Args>(SEARCH_V2, {
        variables: { query, sid: getSessionId() },
        onError,
        skip
    });

    useEffect(() => {
        if (data?.searchV2) {
            setOutgoingData(data.searchV2);
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            setOutgoingLoading(loading);
        } else if (!loading && outgoingData) {
            setOutgoingLoading(false);
        } else {
            setOutgoingLoading(true);
        }
    }, [loading, outgoingData, error]);

    return {
        searchV2: outgoingData,
        error: !!error,
        success: !!data,
        loading: outgoingLoading,
        refetch
    };
};
