import type { Query } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { GET_KANBAN_LABEL_SETS } from './gql/getKanbanLabelSets';

type QueryDataType = Pick<Query, 'getKanbanLabelSets'>;
type OutgoingDataType = QueryDataType['getKanbanLabelSets'];

const onError = getErrorLoggerFunction('getKanbanLabelSets');

export const useGetKanbanLabelSets = (skip = false) => {
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const { data, loading, refetch, error } = useQuery<QueryDataType>(GET_KANBAN_LABEL_SETS, {
        onError,
        skip
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(data.getKanbanLabelSets);
        }
    }, [data]);

    return {
        kanbanLabelSets: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
