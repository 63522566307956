import { gql } from '@apollo/client';

import { CHANNELS_OBJECT } from '../fragments/channelsFragment';

export const CHANNELS = gql`
    query pickupChannels {
        pickupChannels {
            ...ChannelFields
        }
    }
    ${CHANNELS_OBJECT}
`;
