import { BREAKPOINT } from '@ab-core/breakpoints';
import { useEffect, useState } from 'react';

export const useIsMobile = (breakpoint = BREAKPOINT.MD): boolean => {
    const hasWindow = typeof window !== 'undefined';
    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        setIsMobile(!!(hasWindow && window.innerWidth < breakpoint));
    };

    useEffect(() => {
        if (hasWindow) {
            setIsMobile(!!(hasWindow && window.innerWidth < breakpoint));
            window.addEventListener('resize', handleResize);

            return () => window.removeEventListener('resize', handleResize);
        }

        return undefined;
    }, [hasWindow]);

    return isMobile;
};
