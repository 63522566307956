import { toast } from '@ab-core/toast';
import { makeVar, useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';

export type ComparableProductStyleType = {
    scalableStyle?: {
        isVisible?: boolean;
    };
};

export type ComparableProductType = ComparableProductStyleType & {
    sku?: string;
    title?: string;
    imageSrc?: string;
};

const MAX_COMPARABLE_PRODUCTS = 3;

const comparableProductListState = makeVar<Array<ComparableProductType>>([]);

export const useComparableProductListState = () => {
    return useReactiveVar(comparableProductListState);
};

export const toggleComparableProduct = (comparableProduct: ComparableProductType) => {
    const comparableProductList = comparableProductListState();

    const existingProductIndex = comparableProductList.findIndex((product) => product.sku === comparableProduct.sku);

    if (existingProductIndex !== -1) {
        const newComparableProductList = [...comparableProductList];
        newComparableProductList.splice(existingProductIndex, 1);
        comparableProductListState(newComparableProductList);

        return;
    }

    if (comparableProductList.length >= MAX_COMPARABLE_PRODUCTS) {
        toast.error(
            'Maximale Vergleichprodukte überschritten',
            'Das Produkt konnte nicht hinzugefügt werden. Sie haben bereits die maximale Anzahl von 3 Produkten erreicht.'
        );

        return;
    }

    comparableProductListState([...comparableProductList, comparableProduct]);
};

export const cleanComparableProductList = () => {
    comparableProductListState([]);
};

export const isSkuInComparableList = (sku?: string) => {
    const comparableProductList = useComparableProductListState();
    const [isInList, setIsInList] = useState(false);

    useEffect(() => {
        if (!sku) {
            return;
        }

        const existingProductIndex = comparableProductList.findIndex((product) => product.sku === sku);
        setIsInList(existingProductIndex !== -1);
    }, [comparableProductList, sku]);

    return isInList;
};
