import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const ArrowRight: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5711 4.48685L13.677 4.3792C13.9751 4.17565 14.3811 4.20805 14.6448 4.47641L19.775 9.69607C20.075 10.0013 20.075 10.4937 19.775 10.7989L14.6448 16.0186C14.3449 16.3238 13.8609 16.3238 13.5609 16.0186C13.2609 15.7134 13.2609 15.2209 13.5609 14.9157L17.3744 11.0357L0.769528 11.0357C0.346875 11.0357 4.86486e-07 10.6827 5.2408e-07 10.2527C5.61674e-07 9.82269 0.346875 9.46977 0.769528 9.46977L17.3847 9.46977L13.5711 5.58971C13.2712 5.2845 13.2712 4.79207 13.5711 4.48685Z"
                fill={color}
            />
        </SVG>
    );
};
