import React, { type FC } from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Faq: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.3077 17.1735H12.208L12.116 17.2117L7.2033 19.2511L7.2033 17.6735V17.1735H6.7033H2C1.17157 17.1735 0.5 16.5019 0.5 15.6735V4C0.5 3.17157 1.17157 2.5 2 2.5H18C18.8284 2.5 19.5 3.17157 19.5 4V15.6735C19.5 16.5019 18.8284 17.1735 18 17.1735H12.3077Z"
                stroke={color}
            />
            <path
                d="M7 5.90857C7 5.90857 7.885 5 9.385 5C11.155 5 12.28 6.01143 12.28 7.67429C12.28 10.16 9.91 10.4686 9.805 12.1829L9.775 12.6457H9.04V11.9771C9.04 10.0571 11.2 9.44 11.2 7.79429C11.2 6.81714 10.495 6.13143 9.385 6.13143C8.2 6.13143 7.435 6.90286 7.435 6.90286L7 5.90857ZM8.725 14.7886C8.725 14.3257 9.04 13.9486 9.445 13.9486C9.85 13.9486 10.18 14.3257 10.18 14.7886C10.18 15.2686 9.85 15.6457 9.445 15.6457C9.04 15.6457 8.725 15.2686 8.725 14.7886Z"
                fill={color}
            />
        </SVG>
    );
};
