import { ROUTES } from '@ab-core/functions/routes';
import type { FavoriteItemType } from './types';

type RouteNameType = { route: ROUTES } & Pick<FavoriteItemType, 'name' | 'icon'>;

type RouteNameListType = Array<RouteNameType>;

export const routeNameList: RouteNameListType = [
    { route: ROUTES.LASTBOUGHTPRODUCTS, name: 'Zuletzt gekaufte Produkte', icon: 'History' },
    { route: ROUTES.ORDERS, name: 'Meine Belege', icon: 'Orders' },
    { route: ROUTES.PRODUCTPAGE, name: 'Produkt' },
    { route: ROUTES.CATEGORY, name: 'Kategorie' },
    { route: ROUTES.SEARCH, name: 'Suche', icon: 'Search' },
    { route: ROUTES.OFFERS, name: 'Meine Angebote', icon: 'Label' },
    { route: ROUTES.OFFERDETAILS, name: 'Angebot Details', icon: 'Label' },
    { route: ROUTES.CART, name: 'Warenkorb', icon: 'Cart' },
    { route: ROUTES.CARTS, name: 'Warenkörbe', icon: 'Cart' },
    { route: ROUTES.ACCESSMANAGEMENT, name: 'Zugangsverwaltung', icon: 'Account' },
    { route: ROUTES.SHOPPINGLISTS, name: 'Listen' },
    { route: ROUTES.SHOPPINGLIST, name: 'Liste' },
    { route: ROUTES.CABLEREEL, name: 'Kabeltrommel', icon: 'CableReel' },
    { route: ROUTES.RETURNS, name: 'Retouren' },
    { route: ROUTES.USERLISTOVERVIEW, name: 'Benutzerübersicht', icon: 'Account' },
    { route: ROUTES.USERCONFIGURATOR, name: 'Benutzerverwaltung', icon: 'Account' },
    { route: ROUTES.OXOMICATALOGUES, name: 'Kataloge', icon: 'Grid' },
    { route: ROUTES.OXOMI_NAVIGATOR, name: 'Navigator', icon: 'WindRose' },
    { route: ROUTES.SCANNER, name: 'Scanner', icon: 'Barcode' },
    { route: ROUTES.FASTENTRY, name: 'Schnellerfassung', icon: 'FastEntry' },
    { route: ROUTES.BARCODELIST, name: 'Barcodeliste', icon: 'Barcode' },
    { route: ROUTES.CHANGELOG, name: 'Neuigkeiten', icon: 'Faq' },
    { route: ROUTES.FAQ, name: 'FAQ', icon: 'Faq' },
    { route: ROUTES.PROMOTIONALSHEET, name: 'Aktionsblatt' },
    { route: ROUTES.CONFIGURATORS, name: 'Konfiguratoren', icon: 'Tune' },
    { route: ROUTES.CABLEDEALMARKET, name: 'Kabelschnäppchen', icon: 'Percent' },
    { route: ROUTES.PRODUCT_COMPARE, name: 'Produktvergleich' },
    { route: ROUTES.CREDIT_INFO, name: 'Credit info', icon: 'Credits' },
    { route: ROUTES.HELP, name: 'Hilfe', icon: 'Faq' }
];
