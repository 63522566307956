import type { FavoriteLinks, Query } from '@ab-core/graphql/dist';
import { useAuth } from '@ab-core/hooks';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { GET_FAVORITE_LINKS } from './gql/getFavoriteLinks';

type QueryDataType = Pick<Query, 'getFavoriteLinks'>;
type OutgoingDataType = FavoriteLinks['favoriteLinks'] | undefined;

const onError = getErrorLoggerFunction('getFavoriteLinks');

export const useGetFavoriteLinks = () => {
    const { user } = useAuth();
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const { data, loading, refetch, error } = useQuery<QueryDataType>(GET_FAVORITE_LINKS, {
        onError,
        skip: !user
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(data.getFavoriteLinks?.favoriteLinks || []);
        }
    }, [data]);

    return {
        favoriteLinks: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
