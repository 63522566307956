import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Location: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.75 5.94741C14.75 7.97511 13.7981 10.6855 12.5761 13.2775C11.6921 15.1528 10.7161 16.8655 10 18.052C9.28388 16.8655 8.30794 15.1528 7.42387 13.2775C6.20188 10.6855 5.25 7.97511 5.25 5.94741C5.25 2.97442 7.61099 1.25 10 1.25C12.389 1.25 14.75 2.97442 14.75 5.94741ZM16 5.94741C16 10.5983 11.7501 17.6558 10.4039 19.7748C10.2134 20.0745 9.78655 20.0745 9.59612 19.7748C8.24987 17.6558 4 10.5983 4 5.94741C4 2.15741 7.05448 0 10 0C12.9455 0 16 2.15741 16 5.94741ZM9.42508 6.30161C9.42508 5.98344 9.68252 5.72551 10.0001 5.72551C10.3176 5.72551 10.5751 5.98344 10.5751 6.30161C10.5751 6.61978 10.3176 6.87771 10.0001 6.87771C9.68252 6.87771 9.42508 6.61978 9.42508 6.30161ZM10.0001 4.47313C8.99216 4.47313 8.17508 5.29177 8.17508 6.30161C8.17508 7.31146 8.99216 8.1301 10.0001 8.1301C11.008 8.1301 11.8251 7.31146 11.8251 6.30161C11.8251 5.29177 11.008 4.47313 10.0001 4.47313Z"
                fill={color}
            />
        </SVG>
    );
};
