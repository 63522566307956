import { ROUTES } from '@ab-core/functions';
import { Icon } from '@ab-core/icon';
import Image from '@ab-core/image';
import { Link, LINK_VARIANT } from '@ab-core/link';
import { getTestIdProp } from '@ab-core/testing';
import type { FC } from 'react';
import React from 'react';
import type { ComparableProductType } from '../state';
import { toggleComparableProduct } from '../state';
import { Container, IconContainer, ImageContainer, TitleContainer } from './style';

export const Preview: FC<ComparableProductType> = (props) => {
    const { sku, imageSrc, title } = props;

    return (
        <Container>
            <ImageContainer>
                <Link to={`${ROUTES.PRODUCTPAGE}/${sku}`} variant={LINK_VARIANT.LINK_ONLY}>
                    <Image src={imageSrc} placeHolder srcLoading={false} cloudName="alexander-buerkle-cloud-services" />
                </Link>
            </ImageContainer>
            <TitleContainer>
                <Link to={`${ROUTES.PRODUCTPAGE}/${sku}`} variant={LINK_VARIANT.LINK_ONLY}>
                    {title}
                </Link>
            </TitleContainer>
            <IconContainer
                onClick={() => toggleComparableProduct({ sku })}
                {...getTestIdProp('remove-product-from-compare-button')}
            >
                <Icon name="Close" />
            </IconContainer>
        </Container>
    );
};
