import { gql } from '@apollo/client';

export type RemoveIdsCartResponseType = {
    removeIdsCart: boolean;
};

const REMOVE_IDS_CART = gql`
    mutation removeIdsCart($cartId: String) {
        removeIdsCart(cartId: $cartId)
    }
`;
export default REMOVE_IDS_CART;
