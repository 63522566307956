import { gql } from '@apollo/client';

export const CHANNELS_OBJECT = gql`
    fragment ChannelFields on Channel {
        id
        key
        name
        primaryChannel
        address {
            id
            externalId
            primaryAddress
            name
            streetName
            city
            postalCode
            country
            addressExtraLineOne
            addressExtraLineTwo
            addressExtraLineThree
        }
    }
`;
