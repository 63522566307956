import { getClassNames } from '@ab-core/functions/styles/classNameFormatter';
import { getTestIdProp } from '@ab-core/testing';
import type { WithChildren } from '@ab-core/utils/types';
import type { FC } from 'react';
import React from 'react';
import { ChildrenWrapper, ContentWrapper, SidebarWrapper } from './index.styled';

export enum SIDEBAR_POSITION {
    RIGHT = 'right',
    LEFT = 'left'
}

type SidebarType = WithChildren & {
    sidebarContent: React.ReactNode;
    sidebarPosition?: SIDEBAR_POSITION;
    showMobile?: boolean;
};

export const Sidebar: FC<SidebarType> = (props) => {
    const { children, sidebarContent, sidebarPosition = SIDEBAR_POSITION.LEFT, showMobile = false } = props;
    const positionClass = getClassNames({
        prefix: 'sidebar',
        modifier: { sidebarPosition, showMobile }
    });

    return (
        <ContentWrapper className={positionClass}>
            <SidebarWrapper className={positionClass} {...getTestIdProp('sidebar')}>
                {sidebarContent}
            </SidebarWrapper>
            <ChildrenWrapper className={positionClass}>{children}</ChildrenWrapper>
        </ContentWrapper>
    );
};
