import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Discount: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.20811 9.99951C1.20811 5.18915 5.12308 1.25038 10 1.25038C14.8769 1.25038 18.7919 5.18915 18.7919 9.99951C18.7919 14.8099 14.8769 18.7486 10 18.7486C5.12308 18.7486 1.20811 14.8099 1.20811 9.99951ZM10 -0.000488281C4.49845 -0.000488281 0 4.45502 0 9.99951C0 15.544 4.49845 19.9995 10 19.9995C15.5016 19.9995 20 15.544 20 9.99951C20 4.45502 15.5016 -0.000488281 10 -0.000488281ZM5.59287 6.87466C5.59287 7.54308 6.14247 8.12414 6.86801 8.12414C7.59354 8.12414 8.14314 7.54308 8.14314 6.87466C8.14314 6.20623 7.59354 5.62518 6.86801 5.62518C6.14247 5.62518 5.59287 6.20623 5.59287 6.87466ZM6.86801 9.375C5.51785 9.375 4.38477 8.27721 4.38477 6.87466C4.38477 5.47211 5.51785 4.37431 6.86801 4.37431C8.21817 4.37431 9.35125 5.47211 9.35125 6.87466C9.35125 8.27721 8.21817 9.375 6.86801 9.375ZM13.132 14.3739C12.4064 14.3739 11.8568 13.7928 11.8568 13.1244C11.8568 12.456 12.4064 11.8749 13.132 11.8749C13.8575 11.8749 14.4071 12.456 14.4071 13.1244C14.4071 13.7928 13.8575 14.3739 13.132 14.3739ZM10.6487 13.1244C10.6487 14.5269 11.7818 15.6247 13.132 15.6247C14.4821 15.6247 15.6152 14.5269 15.6152 13.1244C15.6152 11.7218 14.4821 10.6241 13.132 10.6241C11.7818 10.6241 10.6487 11.7218 10.6487 13.1244ZM14.1775 6.70009C14.4177 6.46041 14.4248 6.06448 14.1933 5.81575C13.9618 5.56702 13.5794 5.55968 13.3392 5.79936L5.82245 13.299C5.58223 13.5387 5.57514 13.9346 5.80663 14.1834C6.03811 14.4321 6.42051 14.4394 6.66074 14.1997L14.1775 6.70009Z"
                fill={color}
            />
        </SVG>
    );
};
