import { getSearchFilterParameterFromUrl } from '../params';
import type { FacetElementType } from './facetMapper';
import { FILTER_TYPE } from './facetMapper';

export const toggleFilterByFacetElementType = (element: FacetElementType): Array<string> => {
    const searchParameter = getSearchFilterParameterFromUrl();

    const searchParameterFilter = searchParameter.filter;

    const includingFilterIndex = searchParameterFilter.findIndex((filter) =>
        filter.includes(element.associatedFieldName)
    );

    if (element.filterType === FILTER_TYPE.MULTISELECT) {
        if (element.selected) {
            return removeFilterByFacetElement(element);
        }

        return addFilterByFacetElement(element);
    }

    if (element.filterType === FILTER_TYPE.TREE) {
        if (includingFilterIndex >= 0) {
            if (element.query === searchParameterFilter[includingFilterIndex]) {
                return removeFilterByIndex(includingFilterIndex);
            }

            return updateFilterByIndex(element, includingFilterIndex);
        }

        return addFilterByFacetElement(element);
    }

    if (element.filterType === FILTER_TYPE.SLIDER) {
        if (
            element.absoluteMinValue === element.selectedMinValue &&
            element.absoluteMaxValue === element.selectedMaxValue
        ) {
            return removeFilterByIndex(includingFilterIndex);
        }

        if (includingFilterIndex >= 0) {
            return updateFilterByIndex(element, includingFilterIndex);
        }

        return addFilterByFacetElement(element);
    }

    return searchParameterFilter;
};

const removeFilterByIndex = (index: number): Array<string> => {
    const searchParameter = getSearchFilterParameterFromUrl();

    const newFilter = [...searchParameter.filter];

    if (index >= 0) {
        newFilter.splice(index, 1);
    }

    return newFilter;
};

const removeFilterByFacetElement = (element: FacetElementType): Array<string> => {
    const searchParameter = getSearchFilterParameterFromUrl();

    const newFilter = [...searchParameter.filter];

    const includingFilterIndex = newFilter.findIndex((f) => f === element.query);

    return removeFilterByIndex(includingFilterIndex);
};

const addFilterByFacetElement = (element: FacetElementType): Array<string> => {
    const searchParameter = getSearchFilterParameterFromUrl();

    const newFilter = [...searchParameter.filter];

    return [...newFilter, element.query];
};

const updateFilterByIndex = (element: FacetElementType, index: number): Array<string> => {
    const searchParameter = getSearchFilterParameterFromUrl();

    const newFilter = [...searchParameter.filter];

    if (index >= 0) {
        newFilter[index] = element.query;
    }

    return newFilter;
};
