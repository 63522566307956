import { fallbackImage } from '@ab-core-functions/cloudinary';
import type { SuggestResultV2 } from '@ab-core/graphql/dist';
import Image from '@ab-core/image';
import type { FC } from 'react';
import React from 'react';
import { ImageContainer, ItemContainer, ItemText, QuadratImage } from './styled';

type ItemSuggestionProps = React.ComponentPropsWithoutRef<'div'> & {
    suggestion: SuggestResultV2;
};

export const ItemSuggestion: FC<ItemSuggestionProps> = (props) => {
    const { suggestion, ...rest } = props;

    const srcImg = suggestion.image || fallbackImage;

    return (
        <ItemContainer title={suggestion.name} {...rest}>
            <QuadratImage>
                <ImageContainer>
                    <Image placeHolder src={srcImg} cloudName="alexander-buerkle-cloud-services" />
                </ImageContainer>
            </QuadratImage>
            <ItemText>{suggestion.name}</ItemText>
        </ItemContainer>
    );
};
