import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Leaf: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.931885 3.47C0.931885 3.47 1.97588 3.858 2.04188 5.284C2.06188 5.732 1.86188 6.912 2.96788 7.846C3.69188 8.458 4.58189 8.48 5.07389 8.32C5.07389 8.32 5.16789 7.788 4.97389 7.214C4.72589 6.48 4.45188 5.974 4.02788 5.586C4.02788 5.586 4.87188 5.184 5.54388 6.756C5.54388 6.756 5.73188 6.398 6.08188 5.77C6.08188 5.77 6.10188 4.728 5.02988 3.968C2.96588 2.506 0.933885 3.47 0.933885 3.47H0.931885Z"
                fill={color}
            />
            <path
                d="M6.88406 12.416C6.88406 12.416 6.94406 10.472 7.63806 8.996C8.18406 7.834 9.31006 6.964 10.0281 6.708C10.0281 6.708 7.49406 8.512 7.69206 12.734C7.69206 12.734 11.2241 13.688 12.7281 9.904C13.8321 7.124 13.5821 5.052 15.0681 4.214C15.0681 4.214 12.6401 3.2 9.73606 4.208C8.00406 4.81 6.26806 6.112 5.89206 8.64C5.51606 11.168 6.88406 12.416 6.88406 12.416Z"
                fill={color}
            />
        </SVG>
    );
};
