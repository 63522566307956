import { getClassNames } from '@ab-core/functions/styles/classNameFormatter';
import type { FC } from 'react';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { List } from './styled';

enum LIST_POSITION {
    TOP = 'top',
    BOTTOM = 'bottom'
}

export const DropdownList: FC<React.ComponentPropsWithoutRef<'div'>> = (props) => {
    const { className, ...rest } = props;
    const [listPosition, setListPosition] = useState<LIST_POSITION>(LIST_POSITION.BOTTOM);
    const [triedBottom, setTriedBottom] = useState<boolean>(false);

    const listRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        if (listRef.current) {
            const rect = listRef.current.getBoundingClientRect();
            const windowHeight = Math.min(document.documentElement.clientHeight, window.innerHeight);

            if (rect.bottom > windowHeight) {
                if (triedBottom) {
                    return;
                }

                setTriedBottom(true);
                setListPosition(LIST_POSITION.TOP);

                return;
            }

            if (rect.top < 0) {
                setListPosition(LIST_POSITION.BOTTOM);

                return;
            }
        }
    });

    const listClass = getClassNames({
        className,
        prefix: 'dropdown-list',
        modifier: { position: listPosition }
    });

    return <List ref={listRef} className={listClass} {...rest} />;
};
