import Text, { FontTypes } from '@ab-core/text';
import type { FC } from 'react';
import React from 'react';
import type { BasicDropdownItemProps, BasicDropdownItemsProps, BasicDropdownProps } from './basic';
import { BasicDropdown } from './basic';
import { InlineWrapper } from './styled';

type DropdownProps = Omit<BasicDropdownProps, 'error'> & {
    errorMessage?: string;
};

const Dropdown: FC<DropdownProps> = (props: DropdownProps) => {
    const { errorMessage, ...rest } = props;
    const error = errorMessage !== undefined && errorMessage !== '';

    return (
        <div>
            <BasicDropdown {...rest} error={error} />
            <InlineWrapper>
                {error && (
                    <Text type={FontTypes.Caption} color="error">
                        {errorMessage}
                    </Text>
                )}
            </InlineWrapper>
        </div>
    );
};

export { BasicDropdown, Dropdown };

export type {
    BasicDropdownProps,
    BasicDropdownItemProps as DropdownItemProps,
    BasicDropdownItemsProps as DropdownItemsProps,
    DropdownProps
};
