import { LOCALSTORAGE_KEYS } from '@ab-core/functions';
import { toast } from '@ab-core/toast';
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { navigate } from 'gatsby';
import fetch from 'isomorphic-fetch';

const abCloud = createHttpLink({
    fetch,
    uri: `${process.env.GATSBY_ABCLOUD_GRAPHQL_URL}/graphql`,
    credentials: 'include'
});

const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
        // eslint-disable-next-line no-undef
        const user = localStorage.getItem('isLoggedIn');
        graphQLErrors.map(({ extensions }) => {
            if (extensions && extensions.code && extensions.code === 'UNAUTHENTICATED' && user) {
                // eslint-disable-next-line no-undef
                for (const key of LOCALSTORAGE_KEYS) {
                    localStorage.removeItem(key);
                }

                toast.info('Sie wurden automatisch abgemeldet');
                navigate('/');
            }
        });
    }
});

const client = new ApolloClient({
    link: ApolloLink.from([errorLink, abCloud]),
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: { fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true },
        query: { fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true }
    }
});

export default client;
