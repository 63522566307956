import { BreakpointsMinQuery } from '@ab-core/breakpoints';
import styled from 'styled-components';
import type { SpacerStyleProps } from './types';
import { SpacerMapper } from './types';

export const SpacerContainer = styled.div<SpacerStyleProps>`
    padding-bottom: ${({ size }) => SpacerMapper[size]};

    @media ${BreakpointsMinQuery.xs} {
        ${({ breakpoints }) =>
            breakpoints?.xs &&
            `
            padding-bottom: ${SpacerMapper[breakpoints.xs]};
            `}
    }
    @media ${BreakpointsMinQuery.sm} {
        ${({ breakpoints }) =>
            breakpoints?.sm &&
            `
            padding-bottom: ${SpacerMapper[breakpoints.sm]};
            `}
    }
    @media ${BreakpointsMinQuery.xl} {
        ${({ breakpoints }) =>
            breakpoints?.xl &&
            `
            padding-bottom: ${SpacerMapper[breakpoints.xl]};
            `}
    }
    @media ${BreakpointsMinQuery.md} {
        ${({ breakpoints }) =>
            breakpoints?.md &&
            `
            padding-bottom: ${SpacerMapper[breakpoints.md]};
            `}
    }
    @media ${BreakpointsMinQuery.lg} {
        ${({ breakpoints }) =>
            breakpoints?.lg &&
            `
            padding-bottom: ${SpacerMapper[breakpoints.lg]};
            `}
    }
`;
