import type { Mutation } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { DELETE_GHOST_ACCESS } from './gql/deleteGhostAccess';

type MutationDataType = Pick<Mutation, 'deleteGhostAccess'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('deleteGhostAccess');

export const useDeleteGhostAccess = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [deleteGhostAccess, { loading, error, data }] = useMutation<MutationDataType>(DELETE_GHOST_ACCESS, {
        onError
    });

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        deleteGhostAccess,
        loading,
        response
    };
};
