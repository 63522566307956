import { useStaticQuery, graphql } from 'gatsby';

export function getChangeLog(): Queries.ContentfulChangeLogGroupConnection {
    const { allContentfulChangeLog } = useStaticQuery(graphql`
        {
            allContentfulChangeLog(sort: { createdAt: DESC }, limit: 10) {
                totalCount

                nodes {
                    id
                    updatedAt
                    createdAt

                    title
                    description {
                        description
                    }
                    slug
                }
            }
        }
    `);

    return allContentfulChangeLog;
}
