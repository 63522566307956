import { gql } from '@apollo/client';

import { CART_OBJECT } from '../../fragments/cartFragment';

export type UpdateShareStateResponseType = {
    data: {
        shareState: string;
    };
};

export const UPDATE_CART_SHARE_STATE = gql`
    mutation updateCartShareState($cartId: String!, $shareState: String!) {
        updateCartShareState(cartId: $cartId, shareState: $shareState) {
            ...ABCartFields
        }
    }
    ${CART_OBJECT}
`;
