export const convertName = (name = ''): string => {
    switch (name) {
        case 'available':
            return 'Sofort Lieferbar';
        case 'purchase':
            return 'Bereits gekauft';
        case 'tecselectBonus':
            return 'TECSELECT';
        case 'topProduct':
            return 'Top Produkt';
        case 'abakusBonus':
            return 'Abakus';
        case 'promotion':
            return 'Aktions-Artikel';
        case 'abakusPlusBonus':
            return 'Abakus +';
        case 'true':
            return 'Ja';
        case 'false':
        case '-':
            return 'Nein';
        case 'null':
            return '0';
        default:
            return name.replace(/[[\]]/g, '');
    }
};

export const encodeQueryValue = (text?: string) => {
    return text?.replaceAll('/', encodeURIComponent('/'));
};
