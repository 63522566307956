import { BreakpointsMinQuery } from '@ab-core/breakpoints';
import { Colors } from '@ab-core/colors';
import Text from '@ab-core/text';
import styled from 'styled-components';

type ItemsWrapperType = {
    hasBorder?: boolean;
};

type MobileProps = {
    isMobile?: boolean;
};
type MenuProps = {
    lastMenu: number;
};

export const Menu = styled.div<MenuProps>`
    width: 100%;
    background: ${Colors.white};
    color: ${Colors.black};

    height: 100%;
    @media ${BreakpointsMinQuery.lg} {
        min-height: calc(100vh - 100px);
        max-height: calc(100vh - 100px);
        position: absolute;
        top: 0;
        padding: 0px;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
    }
`;

export const ItemsWrapper = styled.div<ItemsWrapperType>`
    overflow: auto;
    height: calc(100vh);

    @media ${BreakpointsMinQuery.lg} {
        height: inherit;
    }
`;

type MenuItemType = {
    active?: boolean;
};

export const MenuItem = styled.div<MenuItemType>`
    display: flex;

    align-items: center;
    ${({ active }) =>
        active
            ? `
    color: ${Colors.primary};
    && > div {
        color: ${Colors.primary};
    }
    svg > path {
        &:nth-child(even) {
            fill: ${Colors.primary};
        }
        &:nth-child(odd) {
            stroke: ${Colors.primary};
        }
    }
    `
            : `
    color: ${Colors.black};
        && > div {
            color: ${Colors.black};
        }
        svg > path {
            &:nth-child(even) {
                fill: ${Colors.black};
            }
            &:nth-child(odd) {
                stroke: ${Colors.black};
            }
        }`};
    &:hover {
        color: ${Colors.primary};
        && > div {
            color: ${Colors.primary};
        }
        svg > path {
            &:nth-child(even) {
                fill: ${Colors.primary};
            }
            &:nth-child(odd) {
                stroke: ${Colors.primary};
            }
        }
    }
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 0;
    border-bottom: 0;
`;

export const BorderWrapper = styled.div<ItemsWrapperType>`
    padding: 20px 40px;
    margin-top: 20px;
    ${({ hasBorder }) => hasBorder && `border-left: 1px solid ${Colors.gray15}`};
`;

export const TitleHeader = styled.div<MobileProps>`
    display: flex;
    justify-content: start;
    align-items: center;
    padding-bottom: 30px;
    margin-left: ${({ isMobile }) => isMobile && '-8px'};
    width: 100%;
    cursor: ${({ isMobile }) => isMobile && 'pointer'};
`;

type StyledTextType = {
    hideBackButton: boolean;
};

export const StyledText = styled(Text)<StyledTextType>`
    ${({ hideBackButton }) => hideBackButton && 'margin-left: 8px; cursor: default'}
`;
