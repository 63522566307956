import { gql } from '@apollo/client';
import { FRAGMENTS } from '../../_fragments';

export const POSITION_PRICE_OBJECT = gql`
    query PositionPriceObject(
        $productId: String!
        $quantity: Int!
        $hidePrice: Boolean
        $projectId: Int
        $chargeNr: Int
    ) {
        positionPriceObject(
            productId: $productId
            quantity: $quantity
            hidePrice: $hidePrice
            projectId: $projectId
            chargeNr: $chargeNr
        ) {
            ...PositionPriceObjectFields
        }
    }
    ${FRAGMENTS.POSITION_PRICE_OBJECT}
`;
