import Button, { BUTTON_VARIANT } from '@ab-core/button';
import { ROUTES, getClassNames } from '@ab-core/functions';
import { Col, Container, Row } from '@ab-core/grid';
import { Icon } from '@ab-core/icon';
import Modal from '@ab-core/modal';
import { getTestIdProp } from '@ab-core/testing';
import Text from '@ab-core/text';
import { navigate } from 'gatsby';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { Preview } from '../preview';
import { cleanComparableProductList, useComparableProductListState } from '../state';
import { showComparison } from '../utils';
import { ButtonContainer, ComparisonContainer, IconContainer, MinimizeContainer, Separator } from './style';

export const ComparableProductContainer: FC = () => {
    const comparableProductList = useComparableProductListState();
    const productCount = comparableProductList.length;
    const isVisible = showComparison();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(true);

    const containerClass = getClassNames({
        prefix: 'compare-container',
        modifier: { 'is-open': isOpen }
    });

    useEffect(() => {
        setIsOpen(true);
    }, [comparableProductList]);

    const toCompare = () => {
        navigate(ROUTES.PRODUCT_COMPARE);
    };

    const endComparison = () => {
        closeModal();
        cleanComparableProductList();
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const openModal = () => {
        setIsModalOpen(true);
    };

    if (productCount === 0 || !isVisible) {
        return <></>;
    }

    return (
        <>
            <ComparisonContainer className={containerClass} {...getTestIdProp('comparable-products')}>
                {comparableProductList.map((product, index) => {
                    return (
                        <React.Fragment key={product.sku}>
                            {index > 0 && <Separator />}
                            <Preview key={product.sku} {...product} />
                        </React.Fragment>
                    );
                })}
                <ButtonContainer>
                    <Button
                        variant={BUTTON_VARIANT.OUTLINE}
                        fluid
                        onClick={toCompare}
                        {...getTestIdProp('compare-button')}
                    >
                        vergleichen ({productCount})
                    </Button>
                </ButtonContainer>
                <IconContainer onClick={openModal} {...getTestIdProp('close-product-comparison-button')}>
                    <Icon name="Close" />
                </IconContainer>
                <MinimizeContainer onClick={() => setIsOpen(!isOpen)}>
                    <Icon name="Minus" />
                </MinimizeContainer>
            </ComparisonContainer>
            <Modal
                onClose={closeModal}
                open={isModalOpen}
                headerText="Produktvergleich beenden"
                footer={
                    <Container>
                        <Row>
                            <Col sm={6}>
                                <Button variant={BUTTON_VARIANT.OUTLINE} onClick={closeModal} fluid>
                                    Abbrechen
                                </Button>
                            </Col>
                            <Col sm={6}>
                                <Button
                                    onClick={endComparison}
                                    fluid
                                    {...getTestIdProp('confirm-close-product-comparison-button')}
                                >
                                    Beenden
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                }
            >
                <Text>Hiermit entfernen Sie alle Produkte und beenden den Produktvergleich</Text>
            </Modal>
        </>
    );
};
