import type { Query, QueryOfferArgs } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { SERVICE_DIRECTORY } from './gql/serviceDirectory';

type QueryDataType = Pick<Query, 'serviceDirectory'>;
type OutgoingDataType = QueryDataType['serviceDirectory'];

const onError = getErrorLoggerFunction('serviceDirectory');

export const useServiceDirectory = (projectNumber = '') => {
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const skip = projectNumber === '';

    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryOfferArgs>(SERVICE_DIRECTORY, {
        variables: {
            projectNumber
        },
        skip,
        onError
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(data.serviceDirectory);
        }
    }, [data]);

    return {
        offer: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
