import type { Mutation, MutationCreateReturnArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { CREATE_RETURN } from './gql/createReturn';

type MutationDataType = Pick<Mutation, 'createReturn'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('createReturn');

export const useCreateReturn = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [createReturn, { loading, error, data }] = useMutation<MutationDataType, MutationCreateReturnArgs>(
        CREATE_RETURN,
        {
            onError
        }
    );

    const outgoingCreateReturn = useCallback(
        (returnInput: MutationCreateReturnArgs['returnInput']) => {
            createReturn({ variables: { returnInput } });
        },
        [createReturn]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        createReturn: outgoingCreateReturn,
        loading,
        response
    };
};
