import type { Query, QueryGetProductPortfolioRestrictionArgs } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { GET_PRODUCT_PORTFOLIO_RESTRICTION } from './gql/getProductPortfolioRestriction';

type QueryDataType = Pick<Query, 'getProductPortfolioRestriction'>;
type OutgoingDataType = QueryDataType['getProductPortfolioRestriction'];

const onError = getErrorLoggerFunction('getProductPortfolioRestriction');

export const useGetProductPortfolioRestriction = (contactId: string) => {
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const skip = contactId.length === 0;

    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryGetProductPortfolioRestrictionArgs>(
        GET_PRODUCT_PORTFOLIO_RESTRICTION,
        {
            variables: {
                contactId
            },
            skip,
            onError
        }
    );

    useEffect(() => {
        if (data) {
            setOutgoingData(data.getProductPortfolioRestriction || []);
        }
    }, [data]);

    return {
        productPortfolioRestriction: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
