import { BreakpointsMinQuery } from '@ab-core/breakpoints';
import { Colors } from '@ab-core/colors';
import logo from '@ab-core/page-not-found/src/zeroState/logo.svg';
import styled from 'styled-components';

export const ErrorFallbackWrapper = styled.div`
    background-image: url('${logo}');
    background-color: ${Colors.backgroundShade};
    background-position-x: right;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: 165px 165px;
    display: flex;
    align-items: center;
    width: 100%;
    flex: 1;
    padding-left: 40px;
    padding-right: 40px;
    height: 100%;
    @media ${BreakpointsMinQuery.sm} {
        background-size: 276px 276px;
    }
    @media ${BreakpointsMinQuery.md} {
        background-size: 464px 464px;
    }
    @media ${BreakpointsMinQuery.xl} {
        background-size: 595px 595px;
    }
`;

export const ErrorContentWrapper = styled.div`
    word-break: break-word;
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const LinkWrapper = styled.div`
    cursor: pointer;
`;
