import { BREAKPOINT } from '@ab-core/breakpoints';
import useDisableBackgroundScroll from '@ab-core/functions/useDisableBackgroundScroll';
import { useIsMobile } from '@ab-core/functions/useIsMobile';
import useOnClickOutside from '@ab-core/functions/useOnClickOutside';
import useOnPressEscapeKey from '@ab-core/functions/useOnPressEscapeKey';
import { Col, Container, Row } from '@ab-core/grid';
import Headline, { HEADLINE_SIZES } from '@ab-core/headline';
import { getTestIdProp } from '@ab-core/testing';
import type { WithTutorialId } from '@ab-core/tutorials';
import { getTutorialActionIdProp, getTutorialIdProp } from '@ab-core/tutorials';
import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import NewBottomSheet from './mobileBottomSheet';
import { Close, Content, Footer, Header, Inner, Wrapper } from './styled';

export type ModalType = WithTutorialId & {
    children: React.ReactNode;
    open?: boolean;
    onClose: () => void;
    headerText?: string | React.ReactNode;
    footer?: React.ReactNode;
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    height?: number;
    useFullWidth?: boolean;
    paddingLeft?: number;
    paddingRight?: number;
    id?: string;
};

const screenMD = 9;
const screenXS = 12;
const screenLG = 8;
const screenXL = 5;
const halfTransparent = 0.5;
const screenSM = 12;

const ANIMATION_TIMEOUT = 350;

const ModalComponent: FC<ModalType> = ({
    children,
    open = false,
    onClose,
    headerText,
    footer,
    xs = screenXS,
    sm = screenSM,
    md = screenMD,
    lg = screenLG,
    xl = screenXL,
    height = 0,
    useFullWidth = false,
    paddingRight = 0,
    paddingLeft = 0,
    id,
    tutorialId
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [animationRunning, setAnimationRunning] = useState(false);
    const isMobile = useIsMobile(BREAKPOINT.LG);
    useOnClickOutside(ref, () => onClose());
    useOnPressEscapeKey(() => onClose());

    const handleAnimationEnd = () => !open && setAnimationRunning(false);
    const handleAnimationStart = () => open && setAnimationRunning(true);

    const animatedStyles = useSpring({
        opacity: open ? halfTransparent : 0,
        transform: open ? 'translateY(0px)' : 'translateY(1000px)',
        onRest: handleAnimationEnd,
        onStart: handleAnimationStart
    });

    useDisableBackgroundScroll(open);

    useEffect(() => {
        if (isMobile) {
            handleAnimationStart();
            setTimeout(() => {
                handleAnimationEnd();
            }, ANIMATION_TIMEOUT);
        }
    }, [open]);

    return (
        <>
            {isMobile && (open || animationRunning) && (
                <NewBottomSheet
                    id={id}
                    active={isMobile && open}
                    footer={typeof footer === 'object' ? <Footer>{footer}</Footer> : undefined}
                    header={
                        <>
                            <Header>
                                {typeof headerText === 'string' && (
                                    <Headline size={HEADLINE_SIZES.FOUR} asElement="div">
                                        {headerText || ''}
                                    </Headline>
                                )}
                                {headerText && typeof headerText !== 'string' && headerText}
                                <Close name="Close" color="black" onClick={onClose} />
                            </Header>
                        </>
                    }
                >
                    <>{children}</>
                </NewBottomSheet>
            )}

            {!isMobile && (open || animationRunning) && (
                <Wrapper>
                    <animated.div
                        className="content"
                        style={{
                            padding: `0px ${paddingRight}px 0 ${paddingLeft}px  `,
                            transform: animatedStyles.transform
                        }}
                    >
                        <Container fixed={!useFullWidth}>
                            <Row justifyContent="center">
                                <Col col={xs} sm={sm} md={md} lg={lg} xl={xl}>
                                    <Content id={id} height={height} ref={ref} {...getTutorialIdProp(tutorialId)}>
                                        <Header className={'modal_header contactPerson'}>
                                            {typeof headerText === 'string' && (
                                                <Headline size={HEADLINE_SIZES.FOUR} asElement="div">
                                                    {headerText}
                                                </Headline>
                                            )}
                                            {headerText && typeof headerText !== 'string' && headerText}
                                            <div
                                                {...getTestIdProp('close-modal-button')}
                                                {...getTutorialActionIdProp('close-modal-button')}
                                                onClick={onClose}
                                            >
                                                <Close name="Close" color="black" />
                                            </div>
                                        </Header>
                                        <Inner className={'modal_inner'} height={height} footer={!!footer}>
                                            {children}
                                            {footer && <Footer>{footer}</Footer>}
                                        </Inner>
                                    </Content>
                                </Col>
                            </Row>
                        </Container>
                    </animated.div>
                    <animated.div className="backdrop" style={{ opacity: animatedStyles.opacity }} />
                </Wrapper>
            )}
        </>
    );
};

export default ModalComponent;
