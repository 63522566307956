import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Notification: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 2C8.74649 2 8.49665 2.01572 8.2514 2.04625C8.25047 2.03095 8.25 2.01553 8.25 2C8.25 1.58579 8.58579 1.25 9 1.25C9.41421 1.25 9.75 1.58579 9.75 2C9.75 2.01553 9.74953 2.03095 9.7486 2.04625C9.50335 2.01572 9.25351 2 9 2ZM7 2C7 2.11304 7.00938 2.22388 7.0274 2.33181C4.68271 3.14769 3 5.37734 3 8V12C3 12.5523 2.55228 13 2 13C0.895431 13 0 13.8954 0 15C0 16.1046 0.89543 17 2 17L6 17C6 18.6569 7.34315 20 9 20C10.6569 20 12 18.6569 12 17L16 17C17.1046 17 18 16.1046 18 15C18 13.8954 17.1046 13 16 13C15.4477 13 15 12.5523 15 12V8C15 5.37734 13.3173 3.14769 10.9726 2.33181C10.9906 2.22388 11 2.11304 11 2C11 0.895431 10.1046 0 9 0C7.89543 0 7 0.895431 7 2ZM10.75 17H7.25C7.25 17.9665 8.0335 18.75 9 18.75C9.9665 18.75 10.75 17.9665 10.75 17ZM4.25 12.25C4.25 13.3546 3.35457 14.25 2.25 14.25H2C1.58579 14.25 1.25 14.5858 1.25 15C1.25 15.4142 1.58579 15.75 2 15.75H16C16.4142 15.75 16.75 15.4142 16.75 15C16.75 14.5858 16.4142 14.25 16 14.25H15.75C14.6454 14.25 13.75 13.3546 13.75 12.25V8C13.75 5.37665 11.6234 3.25 9 3.25C6.37665 3.25 4.25 5.37665 4.25 8V12.25Z"
                fill={color}
            />
        </SVG>
    );
};
