import { useEffect } from 'react';

const useOnClickOutside = (
    ref: React.RefObject<HTMLDivElement> | Array<React.RefObject<HTMLDivElement>>,
    handler: (e: MouseEvent | TouchEvent) => void
): void => {
    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            if (
                typeof window !== 'undefined' &&
                (localStorage.getItem('isCypressTest') || location.href.includes('/hilfe/'))
            ) {
                return;
            }

            if (Array.isArray(ref)) {
                let shouldReturn = false;
                ref.forEach((refElement) => {
                    if (!refElement.current || refElement.current.contains(event.target as Node)) {
                        shouldReturn = true;
                    }
                });

                if (shouldReturn) {
                    return;
                }

                return handler(event);
            }

            if (!Array.isArray(ref) && ref.current && !ref.current.contains(event.target as Node)) {
                return handler(event);
            }
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
};

export default useOnClickOutside;
