import { gql } from '@apollo/client';

const CREATE_IDS_CART = gql`
    mutation createIdsCart($cartInput: CartInput!) {
        createIdsCart(cartInput: $cartInput) {
            id
        }
    }
`;

export default CREATE_IDS_CART;
