import type { Mutation, MutationCreateOrUpdateFavoriteLinksArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { CREATE_OR_UPDATE_FAVORITE_LINKS } from './gql/createOrUpdateFavoriteLinks';
import { GET_FAVORITE_LINKS } from './gql/getFavoriteLinks';

type MutationDataType = Pick<Mutation, 'createOrUpdateFavoriteLinks'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('createOrUpdateFavoriteLinks');

const refetchQueries = [GET_FAVORITE_LINKS];

export const useCreateOrUpdateFavoriteLinks = (favoriteLinks: MutationCreateOrUpdateFavoriteLinksArgs['data']) => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [createOrUpdateFavoriteLinks, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationCreateOrUpdateFavoriteLinksArgs
    >(CREATE_OR_UPDATE_FAVORITE_LINKS, {
        variables: {
            data: favoriteLinks
        },
        refetchQueries,
        onError
    });

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        createOrUpdateFavoriteLinks,
        loading,
        response
    };
};
