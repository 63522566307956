import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Xing: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.625001 10C0.625001 4.82233 4.82233 0.624999 10 0.624999C15.1777 0.625 19.375 4.82233 19.375 10C19.375 15.1777 15.1777 19.375 10 19.375C4.82233 19.375 0.625 15.1777 0.625001 10Z"
                stroke={color}
                strokeWidth="1.25"
                strokeLinecap="round"
            />
            <g clipPath="url(#clip0_9780_16401)">
                <path
                    d="M6.7748 6.97507C6.68795 6.97507 6.61507 7.00548 6.57808 7.0652C6.54 7.12685 6.54575 7.20603 6.5863 7.2863L7.56137 8.97425V8.98246L6.02932 11.6868C5.98932 11.7666 5.99123 11.8463 6.02932 11.9082C6.06603 11.9677 6.13096 12.0066 6.21781 12.0066H7.66C7.87562 12.0066 7.97973 11.8611 8.05343 11.7279C8.05343 11.7279 9.55206 9.07753 9.61041 8.97452C9.60466 8.96493 8.6189 7.24548 8.6189 7.24548C8.54712 7.11781 8.43863 6.97507 8.21754 6.97507H6.7748Z"
                    fill={color}
                />
                <path
                    d="M12.834 5C12.6186 5 12.5252 5.13562 12.4479 5.27479C12.4479 5.27479 9.34082 10.7849 9.23863 10.9658C9.24384 10.9756 11.2879 14.7252 11.2879 14.7252C11.3595 14.8532 11.4699 15 11.691 15H13.1315C13.2184 15 13.2863 14.9674 13.323 14.9079C13.3616 14.8463 13.3605 14.7652 13.32 14.6849L11.2869 10.9701V10.9608L14.4795 5.31479C14.5197 5.23507 14.5205 5.15397 14.4825 5.09233C14.4458 5.03288 14.3775 5.00027 14.2907 5.00027H12.8337L12.834 5Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_9780_16401">
                    <rect width="8.51014" height="10" fill="white" transform="translate(6 5)" />
                </clipPath>
            </defs>
        </SVG>
    );
};
