import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Factory: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_94_10)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.2782 1.5325C14.3168 0.675511 15.0229 0 15.8815 0H16.957C17.8157 0 18.5218 0.675658 18.5604 1.53278L18.9849 10.8642H19.3827C19.7236 10.8642 20 11.1406 20 11.4815V19.3827C20 19.7236 19.7236 20 19.3827 20H0.617284C0.276367 20 0 19.7236 0 19.3827V11.4815C0 11.2751 0.103149 11.0824 0.274876 10.9679L4.71932 8.00491C4.90874 7.87863 5.15228 7.86686 5.353 7.97428C5.55371 8.08169 5.67901 8.29087 5.67901 8.51852V10.3281L9.16377 8.00491C9.35318 7.87863 9.59673 7.86686 9.79744 7.97428C9.99816 8.08169 10.1235 8.29087 10.1235 8.51852V10.3281L13.6082 8.00491C13.7225 7.9287 13.8566 7.89419 13.989 7.90242L14.2782 1.5325C14.2782 1.53259 14.2782 1.53241 14.2782 1.5325ZM13.3333 9.67192L11.5449 10.8642H13.3333V9.67192ZM15.0904 10.8642H17.7491L17.3271 1.58855C17.3182 1.39085 17.1552 1.23457 16.957 1.23457H15.8815C15.6833 1.23457 15.5204 1.39052 15.5115 1.58821L15.0904 10.8642ZM1.23457 12.0988V18.7654H18.7654V12.0988H1.23457ZM2.65603 10.8642H4.44444V9.67192L2.65603 10.8642ZM7.10048 10.8642H8.88889V9.67192L7.10048 10.8642Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_94_10">
                    <rect width="20" height="20" fill="transparent" transform="matrix(-1 0 0 1 20 0)" />
                </clipPath>
            </defs>
        </SVG>
    );
};
