import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Share: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.4228 13.4529C14.4646 13.4529 13.6012 13.8667 13.002 14.525L7.61247 11.187C7.75636 10.8186 7.83617 10.4185 7.83617 9.99998C7.83617 9.58128 7.75636 9.18119 7.61247 8.813L13.002 5.47484C13.6012 6.13311 14.4646 6.54708 15.4228 6.54708C17.2279 6.54708 18.6964 5.07857 18.6964 3.27346C18.6964 1.46835 17.2279 0 15.4228 0C13.6177 0 12.1492 1.4685 12.1492 3.27361C12.1492 3.69216 12.2292 4.09225 12.3729 4.46059L6.98351 7.7986C6.3843 7.14034 5.52096 6.72637 4.56271 6.72637C2.7576 6.72637 1.28909 8.19502 1.28909 9.99998C1.28909 11.8051 2.7576 13.2736 4.56271 13.2736C5.52096 13.2736 6.3843 12.8598 6.98351 12.2014L12.3729 15.5394C12.2292 15.9077 12.1492 16.3078 12.1492 16.7265C12.1492 18.5315 13.6177 20 15.4228 20C17.2279 20 18.6964 18.5315 18.6964 16.7265C18.6964 14.9214 17.2279 13.4529 15.4228 13.4529ZM13.3429 3.27361C13.3429 2.12677 14.276 1.19369 15.4228 1.19369C16.5697 1.19369 17.5027 2.12677 17.5027 3.27361C17.5027 4.42046 16.5697 5.35354 15.4228 5.35354C14.276 5.35354 13.3429 4.42046 13.3429 3.27361ZM4.56271 12.0799C3.41571 12.0799 2.48263 11.1468 2.48263 9.99998C2.48263 8.85313 3.41571 7.92006 4.56271 7.92006C5.70955 7.92006 6.64248 8.85313 6.64248 9.99998C6.64248 11.1468 5.70955 12.0799 4.56271 12.0799ZM13.3429 16.7263C13.3429 15.5795 14.276 14.6464 15.4228 14.6464C16.5697 14.6464 17.5027 15.5795 17.5027 16.7263C17.5027 17.8732 16.5697 18.8063 15.4228 18.8063C14.276 18.8063 13.3429 17.8732 13.3429 16.7263Z"
                fill={color}
            />
        </SVG>
    );
};
