import type { Mutation, MutationSearchTrackingClickArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { SEARCH_TRACKING_CLICK } from './gql/searchTrackingClick';

type MutationDataType = Pick<Mutation, 'searchTrackingClick'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('searchTrackingClick');

export const useSearchTrackingClick = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [searchTrackingClick, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationSearchTrackingClickArgs
    >(SEARCH_TRACKING_CLICK, {
        onError
    });

    const outgoingSearchTrackingClick = useCallback(
        (trackingClickPos: MutationSearchTrackingClickArgs['trackingClickPos']) => {
            searchTrackingClick({ variables: { trackingClickPos } });
        },
        [searchTrackingClick]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        searchTrackingClick: outgoingSearchTrackingClick,
        loading,
        response
    };
};
