import type { FC } from 'react';
import React from 'react';
import type { HeadlineProps } from './index';

const HeadlineTag: FC<HeadlineProps> = (props) => {
    const { size, children, ...rest } = props;

    const HTMLTag = (): JSX.Element => React.createElement(`h${size}`, rest, children);

    return <HTMLTag />;
};

export default HeadlineTag;
