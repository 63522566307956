import Button, { BUTTON_VARIANT } from '@ab-core/button';
import { cleanString, cleanStringRules } from '@ab-core/functions';
import { getChangeLog } from '@ab-core/gatsby-theme-core/src/scopes/changeLog/components/getChangeLog';
import type { ContentfulChangeLog } from '@ab-core/gatsby-theme-website/contentful-graphql-types';
import Markdown from '@ab-core/markdown';
import type { ToastType } from '@ab-core/toast/basic';
import { Toast, ToastLayout } from '@ab-core/toast/basic';
import { Container as ToastContainer } from '@ab-core/toast/container/styled';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';

const MAX_STORAGE_LIST_LENGTH = 40;
const MAX_AGE_FOR_NOTIFICATION_IN_DAYS = 30;

type ContentfulToastType = ToastType & {
    contentfulId: string;
};

export const FeatureNotifications: FC = () => {
    if (typeof window === 'undefined') {
        return <></>;
    }

    const currentRoute = location.pathname;

    const [toasts, setToasts] = useState<Array<ContentfulToastType>>([]);
    const allContentfulChangeLog = getChangeLog();
    const userIsLoggedIn = Boolean(localStorage.getItem('isLoggedIn'));

    const deleteToastFunction = (index: number) => {
        const tempToasts = [...toasts];
        tempToasts.splice(index, 1);
        setToasts(tempToasts);
    };

    const buttonClicked = (index: number, id: string) => {
        const storage = JSON.parse(localStorage.getItem('seenToasts') || '[]');
        storage.push(id);

        if (storage.length > MAX_STORAGE_LIST_LENGTH) {
            storage.shift();
        }

        localStorage.setItem('seenToasts', JSON.stringify(storage));
        deleteToastFunction(index);
    };

    const daysSince = (date: Date): number => {
        const differenceInMs = new Date().getTime() - date.getTime();

        return differenceInMs / (1000 * 60 * 60 * 24);
    };

    const generateToastsForSite = () => {
        const tempArray: Array<ContentfulToastType> = [];
        const storage = JSON.parse(localStorage.getItem('seenToasts') || '[]');

        if (!localStorage.getItem('isCypressTest')) {
            allContentfulChangeLog.nodes.forEach((element: ContentfulChangeLog) => {
                if (
                    element.slug &&
                    currentRoute.includes(element.slug || '') &&
                    !storage.includes(element.id) &&
                    daysSince(new Date(element.updatedAt)) <= MAX_AGE_FOR_NOTIFICATION_IN_DAYS &&
                    userIsLoggedIn
                ) {
                    const toastObj: ContentfulToastType = {
                        title: element.title || '',
                        description: (
                            <Markdown
                                markDown={cleanString(element?.description?.description || '', [
                                    cleanStringRules.backslashNToBreak
                                ])}
                            />
                        ),
                        appearance: 0,
                        contentfulId: element.id
                    };
                    tempArray.push(toastObj);
                }
            });
        }

        setToasts(tempArray);
    };

    useEffect(() => {
        generateToastsForSite();
    }, [allContentfulChangeLog, currentRoute, userIsLoggedIn]);

    useEffect(() => {
        generateToastsForSite();
    }, []);

    return (
        <ToastContainer>
            {toasts.map((toast, key) => (
                <Toast
                    key={toast.contentfulId}
                    title={toast.title}
                    description={
                        <div>
                            {toast.description}
                            <div className="flex-justify-content-end mt-small4">
                                <Button
                                    variant={BUTTON_VARIANT.OUTLINE}
                                    onClick={() => {
                                        buttonClicked(key, toast.contentfulId);
                                    }}
                                >
                                    Nicht mehr anzeigen
                                </Button>
                            </div>
                        </div>
                    }
                    styleType={ToastLayout.Info}
                    appearance={0}
                    deleteToast={() => deleteToastFunction(key)}
                ></Toast>
            ))}
        </ToastContainer>
    );
};
