export const formatNumber = (amount: string | number | undefined, minimumFractionDigits = 0): string => {
    if (typeof amount === 'undefined' || amount === null) {
        return '';
    }

    if (typeof amount === 'string') {
        return parseInt(amount, 10).toLocaleString('de-DE', { maximumFractionDigits: 10, minimumFractionDigits });
    }

    return amount.toLocaleString('de-DE', { maximumFractionDigits: 10, minimumFractionDigits });
};
