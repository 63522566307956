import type { Filter } from '@ab-core/graphql/dist';
import { defaultFilter } from './defaultFilter';

const convertValueToObject = (currentValue: string, accumulator: Filter) => {
    if (currentValue.includes('categoryId')) {
        return currentValue.includes('=')
            ? { ...accumulator, [currentValue.split('=')[0]]: parseInt(currentValue.split('=')[1]) }
            : {
                  ...accumulator,
                  [currentValue]: true
              };
    }

    return { ...accumulator, [currentValue]: true };
};

export const getParsedFilter = (filter: Array<string>): Filter =>
    filter.reduce(
        (accumulator, currentValue) =>
            currentValue ? convertValueToObject(decodeURIComponent(currentValue), accumulator) : accumulator,
        defaultFilter
    );
