import { formatTestId } from './formatTestIds';

export type WithTestId = { testId?: string };

export const getTestIdProp = (testId?: string): Record<string, string> => {
    if (!testId) {
        return {};
    }

    return { 'data-cy': formatTestId(testId) };
};

export const testDataAttr = 'data-cy';
