import { useMutation } from '@apollo/client';
import { MutationUpdateAccountContactRelationArgs, Customer } from '@ab-core/graphql/dist';
import { UPDATE_ACCOUNT_CONTACT_RELATION } from '@ab-core/hooks';
import { useState, useEffect } from 'react';

type Data = {
    updateAccountContactRelation?: Customer[];
};

const useAccountContactRelation = () => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [response, setResponse] = useState<Data>();
    const [pending, setPending] = useState(false);

    const [updateAccountContactRelation, { client, loading }] = useMutation<
        Data,
        MutationUpdateAccountContactRelationArgs
    >(UPDATE_ACCOUNT_CONTACT_RELATION, {
        update: () => {
            setSuccess(false);
            setErrorMessage('');
            setError(false);
        },
        onCompleted: (res) => {
            setResponse(res);
            setSuccess(true);
            setPending(false);
        },
        onError: (err) => {
            setErrorMessage(err.message);
            setError(true);
            setPending(false);
        }
    });
    useEffect(() => {
        if (loading === true) {
            setPending(true);
        }
    }, [loading]);

    return {
        updateAccountContactRelation,
        client,
        success,
        error,
        response,
        pending,
        errorMessage
    };
};

export default useAccountContactRelation;
