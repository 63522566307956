import { gql } from '@apollo/client';
import { FRAGMENTS } from '../../_fragments';

export const SEARCH = gql`
    query Search($query: String!, $tracking: Tracking, $requestId: String) {
        search(query: $query, tracking: $tracking, requestId: $requestId) {
            didYouMean {
                name
                type
                corrected
                original
            }
            results {
                position
                document {
                    ...ProductSearchFields
                    topProduct
                    isPromotion
                    scoreCardIndex
                }
            }
            pageCount
            currentPage
            count
            total
            facets {
                count
                filterName
                id
                facetId
                name
                resultCount
                selected
                values {
                    count
                    filter
                    value
                    selected
                }
                facets {
                    count
                    filterName
                    id
                    facetId
                    name
                    resultCount
                    selected
                    values {
                        count
                        filter
                        value
                        selected
                    }
                }
            }
        }
    }
    ${FRAGMENTS.PRODUCT_SEARCH}
`;
