import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Credits: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.25 10C1.25 5.16751 5.16751 1.25 10 1.25C14.8325 1.25 18.75 5.16751 18.75 10C18.75 14.8325 14.8325 18.75 10 18.75C5.16751 18.75 1.25 14.8325 1.25 10ZM10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM7.79029 12.2098C6.5699 10.9894 6.5699 9.01073 7.79029 7.79034C9.01068 6.56995 10.9893 6.56995 12.2097 7.79034C12.4538 8.03442 12.8495 8.03442 13.0936 7.79034C13.3377 7.54626 13.3377 7.15054 13.0936 6.90646C11.385 5.19791 8.61495 5.19791 6.90641 6.90646C5.19786 8.615 5.19786 11.3851 6.90641 13.0936C8.61495 14.8022 11.385 14.8022 13.0936 13.0936C13.3377 12.8496 13.3377 12.4538 13.0936 12.2098C12.8495 11.9657 12.4538 11.9657 12.2097 12.2098C10.9893 13.4301 9.01068 13.4301 7.79029 12.2098Z"
                fill={color}
            />
        </SVG>
    );
};
