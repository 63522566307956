import type { ProductDocument } from '@ab-core/graphql/dist';
import type { PositionPriceObject } from '@ab-core/graphql/dist/index';
import { makeVar, useReactiveVar } from '@apollo/client';
import type { AddToListsOrCartsModalProductStatePropType, ProductStoreType, StateType } from './types';

const _state: StateType = {
    cartOrShoppingListModal: makeVar<AddToListsOrCartsModalProductStatePropType | undefined>(undefined),
    showCalculator: makeVar<boolean>(false),
    showCarOrShoppingListModal: makeVar<boolean>(false),
    showContactPersonModal: makeVar<boolean>(false),
    quantity: makeVar<number>(1),
    price: makeVar<PositionPriceObject | undefined>(undefined),
    product: makeVar<ProductDocument | undefined>(undefined),
    showOxomi: makeVar<boolean>(false)
};

export const productStore: ProductStoreType = {
    getters: {
        useCartOrShoppingListModal: () => useReactiveVar(_state.cartOrShoppingListModal),
        useShowCalculator: () => useReactiveVar(_state.showCalculator),
        useShowCartOrShoppingListModal: () => useReactiveVar(_state.showCarOrShoppingListModal),
        useShowContactPersonModal: () => useReactiveVar(_state.showContactPersonModal),
        useQuantity: () => useReactiveVar(_state.quantity),
        usePrice: () => useReactiveVar(_state.price),
        useProduct: () => useReactiveVar(_state.product),
        useShowOxomi: () => useReactiveVar(_state.showOxomi)
    },
    setters: {
        setCartOrShoppingListModal: (product) => _state.cartOrShoppingListModal(product),
        setShowCalculator: (show) => _state.showCalculator(show),
        setShowCartOrShoppingListModal: (show) => _state.showCarOrShoppingListModal(show),
        setShowContactPersonModal: (show) => _state.showContactPersonModal(show),
        setQuantity: (quantity) => _state.quantity(quantity),
        setPrice: (price) => _state.price(price),
        setProduct: (product) => _state.product(product),
        setShowOxomi: (show: boolean) => _state.showOxomi(show)
    }
};
