import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Youtube: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.625001 10C0.625001 4.82233 4.82233 0.624999 10 0.624999C15.1777 0.625 19.375 4.82233 19.375 10C19.375 15.1777 15.1777 19.375 10 19.375C4.82233 19.375 0.625 15.1777 0.625001 10Z"
                stroke={color}
                strokeWidth="1.25"
                strokeLinecap="round"
            />
            <path
                d="M14.7917 7.59318C14.6765 7.16266 14.3382 6.82433 13.9076 6.70909C13.1273 6.5 10 6.5 10 6.5C10 6.5 6.87274 6.5 6.09318 6.70909C5.66266 6.82433 5.32433 7.16266 5.20909 7.59318C5 8.37274 5 10.0002 5 10.0002C5 10.0002 5 11.6276 5.20909 12.4071C5.32433 12.8377 5.66266 13.176 6.09318 13.2912C6.87274 13.5003 10 13.5003 10 13.5003C10 13.5003 13.1273 13.5003 13.9068 13.2912C14.3373 13.176 14.6757 12.8377 14.7909 12.4071C15 11.6276 15 10.0002 15 10.0002C15 10.0002 15.0008 8.37274 14.7917 7.59318ZM8.99984 11.5V8.50033L11.5978 10.0002L8.99984 11.5Z"
                fill={color}
            />
        </SVG>
    );
};
