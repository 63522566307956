import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Calculator: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.5 1.60494C3.5 0.718556 4.21381 0 5.09434 0H14.9057C15.7862 0 16.5 0.718555 16.5 1.60494V18.3951C16.5 19.2814 15.7862 20 14.9057 20H5.09434C4.21381 20 3.5 19.2814 3.5 18.3951V1.60494ZM5.09434 1.23457C4.89114 1.23457 4.72642 1.40039 4.72642 1.60494V6.91358H15.2736V1.60494C15.2736 1.40039 15.1089 1.23457 14.9057 1.23457H5.09434ZM15.2736 8.14815H13.5566V9.87654H15.2736V8.14815ZM15.2736 11.1111H13.5566V12.8395H15.2736V11.1111ZM15.2736 14.0741H13.5566V15.8025H15.2736V14.0741ZM15.2736 17.037H13.5566V18.7654H14.9057C15.1089 18.7654 15.2736 18.5996 15.2736 18.3951V17.037ZM12.3302 18.7654V17.037H10.6132V18.7654H12.3302ZM9.38679 18.7654V17.037H7.66981V18.7654H9.38679ZM6.4434 18.7654V17.037H4.72642V18.3951C4.72642 18.5996 4.89114 18.7654 5.09434 18.7654H6.4434ZM4.72642 15.8025H6.4434V14.0741H4.72642V15.8025ZM4.72642 12.8395H6.4434V11.1111H4.72642V12.8395ZM4.72642 9.87654H6.4434V8.14815H4.72642V9.87654ZM7.66981 8.14815V9.87654H9.38679V8.14815H7.66981ZM10.6132 8.14815V9.87654H12.3302V8.14815H10.6132ZM12.3302 11.1111H10.6132V12.8395H12.3302V11.1111ZM12.3302 14.0741H10.6132V15.8025H12.3302V14.0741ZM9.38679 15.8025V14.0741H7.66981V15.8025H9.38679ZM7.66981 12.8395H9.38679V11.1111H7.66981V12.8395Z"
                fill={color}
            />
        </SVG>
    );
};
