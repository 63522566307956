import { getClassNames } from '@ab-core/functions/styles/classNameFormatter';
import type { TextAlignmentType } from '@ab-core/functions/styles/textAlignment';
import type { WithTestId } from '@ab-core/testing';
import type { WithChildren } from '@ab-core/utils/types';
import type { FC } from 'react';
import React from 'react';
import { StyledHeadline } from './styled';

export enum HEADLINE_SIZES {
    ONE = 1,
    TWO = 2,
    THREE = 3,
    FOUR = 4,
    FIVE = 5,
    SIX = 6
}

export type HeadlineProps = WithTestId &
    TextAlignmentType &
    WithChildren &
    React.ComponentPropsWithoutRef<'h1'> & {
        size?: HEADLINE_SIZES;
        color?: string;
        asElement?: React.ElementType;
        content?: string;
    };

const Headline: FC<HeadlineProps> = (props) => {
    const { size = HEADLINE_SIZES.TWO, children, asElement, content, className, ...rest } = props;
    const newClassNames = getClassNames({ className, prefix: 'headline', modifier: { size } });

    return (
        <StyledHeadline as={asElement} size={size} className={newClassNames} {...rest}>
            {content || children}
        </StyledHeadline>
    );
};

export default Headline;
