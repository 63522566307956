import type { BasicTracking } from './types';

const TRACKING_SESSION_KEY = 'tracking';

export const saveTrackingObject = (trackingObject: BasicTracking) => {
    sessionStorage.setItem(TRACKING_SESSION_KEY, JSON.stringify(trackingObject));
};

export const loadTrackingObject = (): BasicTracking | undefined => {
    const sessionObject = sessionStorage.getItem(TRACKING_SESSION_KEY);

    if (!sessionObject) {
        return undefined;
    }

    const decodeObject = JSON.parse(sessionObject) as BasicTracking;

    return { ...decodeObject };
};
