import type { MoneyObject } from '@ab-core/types/src';

const DEFAULT_FRACTION_DIGITS = 2;
const CENTS_PER_EURO = 100;

export const formatPrice = ({ centAmount, currencyCode, fractionDigits }: MoneyObject): string => {
    if (typeof centAmount === 'undefined') {
        return '';
    }

    const euroAmount = centAmount / CENTS_PER_EURO;
    const options = {
        style: 'currency',
        currency: currencyCode || 'EUR',
        currencyDisplay: 'symbol',
        minimumFractionDigits: fractionDigits || DEFAULT_FRACTION_DIGITS,
        maximumFractionDigits: fractionDigits || DEFAULT_FRACTION_DIGITS
    };

    return euroAmount.toLocaleString('de-DE', options);
};
