import { v4 as uuidV4 } from 'uuid';

const SESSION_ID = 'sessionId';

export const setSessionId = () => {
    if (typeof window !== 'undefined' && !localStorage.getItem(SESSION_ID)) {
        localStorage.setItem(SESSION_ID, uuidV4());
    }
};

export const getSessionId = () => {
    if (typeof window === 'undefined') {
        return undefined;
    }

    return localStorage.getItem(SESSION_ID) || undefined;
};
