import { gql } from '@apollo/client';
import { MONEY_OBJECT } from './moneyObject';

export const PRICE_OBJECT = gql`
    fragment PriceObjectFields on PriceObject {
        productId
        priceReference
        value {
            ...MoneyObjectFields
        }
        retailPriceVatsign
        retailPrice {
            ...MoneyObjectFields
        }
        netRetailPrice {
            ...MoneyObjectFields
        }
        grossRetailPrice {
            ...MoneyObjectFields
        }
        promotionPrices {
            quantity
            value {
                ...MoneyObjectFields
            }
        }
        scalePrices {
            value {
                ...MoneyObjectFields
            }
            quantity
        }
        priceConditionMemory {
            value {
                ...MoneyObjectFields
            }
        }
        materialPriceSurcharges {
            type
            weight
            basePriceReference
            basePrice {
                ...MoneyObjectFields
            }
            value {
                ...MoneyObjectFields
            }
        }
        surchargeCut {
            value {
                ...MoneyObjectFields
            }
        }
    }
    ${MONEY_OBJECT}
`;
