import type { BasicTracking } from '@ab-core/functions/tracking/types';
import type { Query, QuerySearchArgs } from '@ab-core/graphql/dist';
import { useFullUser } from '@ab-core/hooks/user/useFullUser';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { SEARCH } from './gql/search';
import type { SearchDataMapperType } from './productSearchMapper';
import { searchDataMapper } from './productSearchMapper';

type QueryDataType = Pick<Query, 'search'>;
type QueryArgs = QuerySearchArgs;
type OutgoingDataType = SearchDataMapperType;

type IncomingDataType = {
    query: string;
    tracking?: BasicTracking;
    skip?: boolean;
};

const onError = getErrorLoggerFunction('search');

export const useSearch = (props: IncomingDataType) => {
    const { query, tracking, skip = false } = props;
    const { user } = useFullUser();
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);
    const [outgoingLoading, setOutgoingLoading] = useState(true);

    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryArgs>(SEARCH, {
        variables: {
            query,
            tracking
        },
        onError,
        skip
    });

    useEffect(() => {
        if (data?.search) {
            const mappedData = searchDataMapper(data.search, user);
            setOutgoingData(mappedData);
        }
    }, [data, user]);

    useEffect(() => {
        if (error) {
            setOutgoingLoading(loading);
        } else if (!loading && outgoingData) {
            setOutgoingLoading(false);
        } else {
            setOutgoingLoading(true);
        }
    }, [loading, outgoingData, error]);

    return {
        search: outgoingData,
        error: !!error,
        success: !!data,
        loading: outgoingLoading,
        refetch
    };
};
