import { gql } from '@apollo/client';

export const GET_PRODUCT_BY_SKU_V2_WITHOUT_DETAILS = gql`
    query GetProductBySkuV2WithoutDetail($sku: String!) {
        getProductBySkuV2(sku: $sku) {
            bonus {
                isTecselect
                isAbakus
                isAbakusPlus
                isPromotion
                isTopProduct
            }

            customerArticleNumber
            ean
            hasAccessories
            hasAlternatives
            id
            thumbnail
            packagingSize
            procuredProduct
            productCocontractor
            productName
            quantityUnit
            salesUnit
            sku
            supplierId
            supplierProductNumber
            isOnlineAvailable
            reachInfo
            relationType
            weightReference
            scoreCardIndex
            isUnqualifiedContractProduct
            customTariffNumber
            slug
            supplierProductLink
            weight
        }
    }
`;
