import { useGetSearchQueryString } from '@ab-core-functions/search';
import { BREAKPOINT } from '@ab-core/breakpoints';
import { useDebounce } from '@ab-core/debouncer';
import { DropdownOverlay } from '@ab-core/dropdownoverlay';
import { ROUTES, useIsMobile, useOnClickOutside } from '@ab-core/functions';
import type { SuggestResultV2 } from '@ab-core/graphql/dist';
import { isLoggedIn } from '@ab-core/hooks';
import { useSearchSuggestV2 } from '@ab-core/hooks-v2';
import { Icon } from '@ab-core/icon';
import { getTestIdProp } from '@ab-core/testing';
import { getTutorialIdProp } from '@ab-core/tutorials';
import { navigate } from 'gatsby';
import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { SearchForm, SearchIconContainer, SearchInput, SearchWrapper } from './styled';
import type { SearchSuggestionsProps } from './suggestions';
import { SearchSuggestions } from './suggestions';
import { searchInputFocusExceptions } from './utils';

type SearchProps = {
    searchString?: string;
};

export const Search: FC<SearchProps> = (props) => {
    const { searchString } = props;
    const search = searchString;
    const queryString = useGetSearchQueryString();
    const [value, setValue] = useState<string | undefined>(search);
    const [suggest, setSuggest] = useState<string | undefined>();
    const [activeSuggest, setActiveSuggest] = useState<number | undefined>(undefined);
    const [selected, setSelected] = useState(false);
    const [suggestionsShouldDisplay, setSuggestionsShouldDisplay] = useState(false);
    const { suggestionsV2 } = useSearchSuggestV2({ query: suggest });
    const isMobile = useIsMobile(BREAKPOINT.LG);
    const isUserLoggedIn = isLoggedIn();

    const [suggestResult, setSuggestResult] = useState<Array<SuggestResultV2> | undefined>(undefined);
    const [searchSuggestions, setSearchSuggestions] = useState<Array<SuggestResultV2>>([]);
    const [itemSuggestion, setItemSuggestion] = useState<Array<SuggestResultV2>>([]);

    useEffect(() => {
        if (!suggestResult || suggestResult.length === 0) {
            return;
        }

        const newSearchSuggestions = suggestResult?.filter((suggestion) => suggestion.type === 'searchTerm');
        const newItemSuggestion = suggestResult?.filter((suggestion) => suggestion.type === 'productName');
        setSearchSuggestions(newSearchSuggestions);
        setItemSuggestion(newItemSuggestion);
    }, [suggestResult]);

    useEffect(() => {
        if (suggestionsV2) {
            setSuggestResult(suggestionsV2);
        }
    }, [suggestionsV2]);

    const searchRef = useRef<HTMLInputElement>(null);
    const wrapperRef = useRef<HTMLInputElement>(null);
    const suggestRef = useRef<HTMLDivElement>(null);

    useOnClickOutside([suggestRef, searchRef], () => {
        setSuggestionsShouldDisplay(false);
    });

    const { handleDebounce, cancelDebounce } = useDebounce();

    const setSelectedState = () => {
        const isUnfocusRoute = searchInputFocusExceptions.some((route) => {
            return location?.pathname.includes(route.valueOf());
        });

        if (!isMobile && isUserLoggedIn && !isUnfocusRoute) {
            setSelected(true);
        } else {
            setSelected(false);
        }
    };

    useEffect(() => {
        setSelectedState();
    }, [location?.pathname, isMobile]);

    useEffect(() => {
        if (!selected) {
            searchRef?.current?.blur();

            return;
        }

        if (!isMobile) {
            searchRef?.current?.focus();
        }
    }, [selected]);

    useEffect(() => {
        if (typeof window === 'undefined' || !window.location.pathname.includes('suche')) {
            return;
        }

        setValue(search);
    }, [search]);

    window.addEventListener('focus', () => {
        setSelectedState();
    });

    const submitSearch = (val = value || '') => {
        cancelDebounce();

        if (val) {
            setValue(val);
        }

        setSuggestionsShouldDisplay(false);

        setActiveSuggest(undefined);

        const encodedValue = encodeURIComponent(val);
        navigate(`${ROUTES.SEARCH}?${queryString}=${encodedValue}`);
    };

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        const inputValue = e.currentTarget.value;
        setValue(inputValue);
        handleDebounce(() => {
            setSuggest(inputValue);
            setSuggestionsShouldDisplay(true);
        });
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.currentTarget.blur();

            if (searchSuggestions && typeof activeSuggest !== 'undefined') {
                selectSuggestion(searchSuggestions[activeSuggest]);

                return;
            }

            submitSearch(value);
        }

        if (e.key === 'Escape') {
            setActiveSuggest(undefined);
            setSuggestionsShouldDisplay(false);
        }

        if (searchSuggestions.length === 0) {
            return;
        }

        if (e.key === 'ArrowDown') {
            if (typeof activeSuggest === 'undefined') {
                setActiveSuggest(0);
            } else {
                setActiveSuggest(Math.min(activeSuggest + 1, (searchSuggestions?.length || 1) - 1));
            }
        }

        if (e.key === 'ArrowUp') {
            if (typeof activeSuggest === 'undefined') {
                setActiveSuggest(0);
            } else {
                setActiveSuggest(Math.max(activeSuggest - 1, 0));
            }
        }
    };

    useEffect(() => {
        if (typeof activeSuggest === 'number' && searchSuggestions) {
            setValue(searchSuggestions[activeSuggest].name);
        }
    }, [activeSuggest]);

    const handleClear = () => {
        setSuggestResult(undefined);
        setValue('');
        setSuggestionsShouldDisplay(false);
        searchRef?.current?.focus();
    };

    const onSearchClick = () => {
        setActiveSuggest(undefined);
        submitSearch(value);
    };

    const selectSuggestion: SearchSuggestionsProps['selectSuggestion'] = (suggestion) => {
        if (suggestion.type === 'productName' && suggestion.searchParams?.query) {
            setSuggestionsShouldDisplay(false);
            navigate(`${ROUTES.PRODUCTPAGE}/${suggestion.searchParams.query}`);

            return;
        }

        if (suggestion?.name) {
            submitSearch(suggestion?.name);
        }
    };

    return (
        <SearchForm ref={wrapperRef}>
            <SearchWrapper className={selected ? 'selected' : 'not-selected'} {...getTutorialIdProp('search-input')}>
                <SearchInput
                    {...getTestIdProp('search-field')}
                    ref={searchRef}
                    enterKeyHint="enter"
                    onKeyDown={handleKeyPress}
                    onChange={handleChange}
                    onClick={() => setSuggestionsShouldDisplay(true)}
                    value={value}
                    autoFocus={false}
                    type="text"
                    placeholder="Schlagwort / EAN / Artikelnummer"
                    onFocus={() => setSelected(true)}
                    onBlur={() => setSelected(false)}
                />
                {value && value?.length > 0 && (
                    <SearchIconContainer onClick={handleClear}>
                        <Icon name="Close" color="primary" />
                    </SearchIconContainer>
                )}
                <SearchIconContainer onClick={onSearchClick} {...getTestIdProp('submit-search-button')}>
                    <Icon name="Search" />
                </SearchIconContainer>
            </SearchWrapper>
            <DropdownOverlay
                parentRef={wrapperRef}
                open={Boolean(suggestionsShouldDisplay && suggestResult)}
                onClose={() => {}}
            >
                <SearchSuggestions
                    itemSuggestion={itemSuggestion}
                    searchSuggestions={searchSuggestions}
                    ref={suggestRef}
                    activeSuggest={activeSuggest}
                    selectSuggestion={selectSuggestion}
                />
            </DropdownOverlay>
        </SearchForm>
    );
};
