import { gql } from '@apollo/client';

export const GET_CATEGORIES = gql`
    query GetCategories {
        getCategories {
            name
            id
            count
            children {
                name
                id
                count
                children {
                    name
                    id
                    count
                    children {
                        count
                        id
                        name
                    }
                }
            }
        }
    }
`;
