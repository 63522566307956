import { fallbackImage } from '@ab-core-functions/cloudinary';
import SkeletonCard from '@ab-core/skeletonloader';
import { Image as CloudinaryImage, Placeholder } from 'cloudinary-react';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { ImageLoader, StyledImage } from './image.styled';

const PLACEHOLDER = fallbackImage;

export type ImageTypes = {
    /** Cloudinary id or any image url */
    src?: string;
    /** image alt */
    srcLoading?: boolean;
    /** image alt */
    alt?: string;
    /** cover|contain default:contain */
    objectFit?: string;
    /** show skeleton loader until loaded */
    skeleton?: boolean;
    /** show placeholder if no img url is passed or error while loading img occurred */
    placeHolder?: boolean;
    /** disable lazyLoading */
    disableLazyLoading?: boolean;
    /** fixed width */
    width?: string;
    height?: string;
    className?: string;
    cloudName?: string;
    onClick?: () => void;
    id?: string | number;
};

const Image: FC<ImageTypes> = ({
    id,
    src,
    srcLoading = true,
    alt = '',
    objectFit = 'contain',
    skeleton,
    placeHolder,
    className,
    disableLazyLoading = false,
    width,
    height,
    cloudName = 'alexander-buerkle',
    onClick
}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [usePlaceHolder, setUsePlaceholder] = useState(false);
    const handleLoad = () => setLoading(false);
    const handleError = () => {
        setError(true);
        setLoading(false);
    };
    const exp1 = !srcLoading && !loading && error;
    const exp2 = !srcLoading && !src;

    useEffect(() => {
        if (!placeHolder) {
            return;
        }

        if (!error && src) {
            setUsePlaceholder(false);

            return;
        }

        if (exp1 || exp2) {
            setUsePlaceholder(true);
        }
    }, [loading, error, srcLoading, src]);

    return (
        <StyledImage className={className} onClick={onClick}>
            {skeleton && loading && (
                <ImageLoader>
                    <SkeletonCard extraLarge />
                </ImageLoader>
            )}
            <CloudinaryImage
                id={id}
                cloudName={cloudName}
                publicId={usePlaceHolder ? PLACEHOLDER : src}
                dpr="auto"
                crop="scale"
                quality="auto"
                width={width || 'auto'}
                height={height || null}
                responsive={!width}
                alt={alt}
                onLoad={handleLoad}
                style={{ visibility: !width && loading ? 'hidden' : 'visible', objectFit }}
                onError={handleError}
                secure
                loading={disableLazyLoading ? undefined : 'lazy'}
                fetchFormat="auto"
            >
                {!disableLazyLoading && <Placeholder type="blur" />}
            </CloudinaryImage>
        </StyledImage>
    );
};

export default Image;
