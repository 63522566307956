import { useAuth } from '@ab-core/hooks';
import { v4 as uuidV4 } from 'uuid';
import { getTrackingPaginationNext, getTrackingPaginationPrevious, getTrackingSelectPage } from './dataClass';
import { loadTrackingObject, saveTrackingObject } from './saving';
import { type BasicTracking } from './types';

const useBasicTrackingValues = (): BasicTracking => {
    const { user } = useAuth();
    let sessionId = '';
    let requestId = '';

    if (typeof window !== 'undefined') {
        sessionId = localStorage.getItem('sessionId') || '';
        requestId = localStorage.getItem('requestId') || '';
    }

    return {
        userId: user?.contact?.id || '',
        sessionId,
        requestId
    };
};

export const setAndGetRequestId = (): string => {
    const requestId = uuidV4();

    if (typeof window !== 'undefined') {
        localStorage.setItem('requestId', requestId);
    }

    return requestId;
};

export const setSessionId = () => {
    if (typeof window !== 'undefined' && !localStorage.getItem('sessionId')) {
        localStorage.setItem('sessionId', uuidV4());
    }
};

export const setAdditionalTrackingObject = (trackingObject: BasicTracking) => {
    saveTrackingObject(trackingObject);
};

type SetAdditionalTrackingObjectByPageType = { q: string; page: number; selectedPage: number };

export const setAdditionalTrackingObjectByPage = (props: SetAdditionalTrackingObjectByPageType) => {
    const { q, page, selectedPage } = props;
    const getPageDif = page - selectedPage;

    let paginationTrackingObject: BasicTracking | undefined = undefined;

    if (getPageDif === 1) {
        paginationTrackingObject = getTrackingPaginationPrevious({ q, page });
    } else if (getPageDif === -1) {
        paginationTrackingObject = getTrackingPaginationNext({ q, page });
    } else {
        paginationTrackingObject = getTrackingSelectPage({ q, page, selectedPage });
    }

    if (!paginationTrackingObject) {
        return;
    }

    setAdditionalTrackingObject(paginationTrackingObject);
};

export const useTracking = (trackingObject?: BasicTracking): BasicTracking | undefined => {
    const session = loadTrackingObject();
    const basicTracking = useBasicTrackingValues();

    if (!session && !trackingObject) {
        return undefined;
    }

    if (trackingObject) {
        return { ...basicTracking, ...trackingObject };
    }

    return { ...basicTracking, ...session };
};
