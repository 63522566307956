import type { Query } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { GET_CATEGORIES_V2 } from './gql/getCategoriesV2';

type QueryDataType = Pick<Query, 'getCategoriesV2'>;
type OutgoingDataType = QueryDataType['getCategoriesV2'];

const onError = getErrorLoggerFunction('getCategoriesV2');

export const useGetCategoriesV2 = () => {
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const { data, loading, refetch, error } = useQuery<QueryDataType>(GET_CATEGORIES_V2, {
        onError
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(data.getCategoriesV2);
        }
    }, [data]);

    return {
        categories: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
