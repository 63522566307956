import type { Coupon } from '@ab-core/graphql/dist';
import { makeVar, useReactiveVar } from '@apollo/client';
import type { PriceStoreType, StateType } from './types';

const FALLBACK = 0;

const _state: StateType = {
    coupon: makeVar<Coupon | undefined>(undefined),
    credits: makeVar<number>(FALLBACK),
    maxCredits: makeVar<number>(FALLBACK)
};

export const priceStore: PriceStoreType = {
    getters: {
        useCoupon: () => useReactiveVar(_state.coupon),
        useCredits: () => useReactiveVar(_state.credits),
        useMaxCredits: () => useReactiveVar(_state.maxCredits)
    },
    setters: {
        setCoupon: (coupon) => _state.coupon(coupon),
        setCredits: (credits) => _state.credits(credits),
        setMaxCredits: (maxCredits) => _state.maxCredits(maxCredits)
    }
};
