import { gql } from '@apollo/client';
import { MONEY_OBJECT } from './moneyObject';
import { PRICE_OBJECT } from './priceObjects';

export const POSITION_PRICE_OBJECT = gql`
    fragment PositionPriceObjectFields on PositionPriceObject {
        productId
        quantity
        basePrice {
            ...MoneyObjectFields
        }
        priceObject {
            priceConditionMemory {
                value {
                    ...MoneyObjectFields
                }
            }
            priceReference
            value {
                ...MoneyObjectFields
            }
            scalePrices {
                quantity
                value {
                    ...MoneyObjectFields
                }
            }
        }
        quantity
        productPriceSum {
            ...MoneyObjectFields
        }
        value {
            ...MoneyObjectFields
        }
        materialPriceSurcharges {
            type
            weight
            weightReference
            basePriceReference
            basePrice {
                ...MoneyObjectFields
            }
            value {
                ...MoneyObjectFields
            }
        }
        value {
            ...MoneyObjectFields
        }
        priceObject {
            ...PriceObjectFields
        }
        promotionPrices {
            quantity
            value {
                ...MoneyObjectFields
            }
        }
    }
    ${MONEY_OBJECT}
    ${PRICE_OBJECT}
`;
