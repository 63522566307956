import type { IconProps } from '@ab-core/icon';
import { Icon } from '@ab-core/icon';
import Text from '@ab-core/text';
import type { WithChildren } from '@ab-core/utils/types';
import type { FC } from 'react';
import React from 'react';
import { PointElementWrapper } from './styled';

type PointElementProps = WithChildren & {
    iconName: IconProps['name'];
};

export const PointElement: FC<PointElementProps> = (props) => {
    const { children, iconName } = props;

    return (
        <PointElementWrapper>
            <Icon name={iconName} />
            <Text color="inherit" className="text-nowrap">
                {children}
            </Text>
        </PointElementWrapper>
    );
};
