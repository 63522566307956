import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Barcode: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 0C0.447715 0 0 0.447715 0 1V3.5C0 3.77614 0.223858 4 0.5 4C0.776142 4 1 3.77614 1 3.5V1H3.5C3.77614 1 4 0.776142 4 0.5C4 0.223858 3.77614 0 3.5 0H1ZM19 20C19.5523 20 20 19.5523 20 19V16.5C20 16.2239 19.7761 16 19.5 16C19.2239 16 19 16.2239 19 16.5V19H16.5C16.2239 19 16 19.2239 16 19.5C16 19.7761 16.2239 20 16.5 20H19ZM1 20C0.447715 20 0 19.5523 0 19V16.5C0 16.2239 0.223858 16 0.5 16C0.776142 16 1 16.2239 1 16.5V19H3.5C3.77614 19 4 19.2239 4 19.5C4 19.7761 3.77614 20 3.5 20H1ZM20 1C20 0.447715 19.5523 0 19 0H16.5C16.2239 0 16 0.223858 16 0.5C16 0.776142 16.2239 1 16.5 1H19V3.5C19 3.77614 19.2239 4 19.5 4C19.7761 4 20 3.77614 20 3.5V1ZM4 6.5C4 6.22386 4.22386 6 4.5 6C4.77614 6 5 6.22386 5 6.5V13.5C5 13.7761 4.77614 14 4.5 14C4.22386 14 4 13.7761 4 13.5V6.5ZM6.5 6C6.22386 6 6 6.22386 6 6.5V13.5C6 13.7761 6.22386 14 6.5 14C6.77614 14 7 13.7761 7 13.5V6.5C7 6.22386 6.77614 6 6.5 6ZM8 6.5C8 6.22386 8.22386 6 8.5 6C8.77614 6 9 6.22386 9 6.5V13.5C9 13.7761 8.77614 14 8.5 14C8.22386 14 8 13.7761 8 13.5V6.5ZM10.5 6C10.2239 6 10 6.22386 10 6.5V13.5C10 13.7761 10.2239 14 10.5 14C10.7761 14 11 13.7761 11 13.5V6.5C11 6.22386 10.7761 6 10.5 6ZM12 7C12 6.44772 12.4477 6 13 6C13.5523 6 14 6.44772 14 7V13C14 13.5523 13.5523 14 13 14C12.4477 14 12 13.5523 12 13V7ZM15.5 6C15.2239 6 15 6.22386 15 6.5V13.5C15 13.7761 15.2239 14 15.5 14C15.7761 14 16 13.7761 16 13.5V6.5C16 6.22386 15.7761 6 15.5 6Z"
                fill={color}
            />
        </SVG>
    );
};
