import { Colors } from '@ab-core/colors';
import styled from 'styled-components';

export const CartCounter = styled.div`
    padding-top: 3px;
    position: relative;
    right: 0.8px;
    height: 13px;
    min-width: 13px;
    margin-top: 1px;
    background: ${Colors.primary};
    border-radius: 50%;
    color: ${Colors.white};
    font-size: 8px;
    line-height: 9px;
    text-align: center;
`;

export const CartCounterWhite = styled.div`
    position: absolute;
    height: 15px;
    width: 15px;
    margin-top: 5px;
    margin-left: 10px;
    background: ${Colors.white};
    border-radius: 50%;
    padding: 0 2px;
`;
