import type { Query, QueryBestPriceArgs } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { BEST_PRICE } from './gql/bestPrice';

type QueryDataType = Pick<Query, 'bestPrice'>;
type OutgoingDataType = QueryDataType['bestPrice'];
type IncomingDataType = { skip?: boolean } & QueryBestPriceArgs;

const onError = getErrorLoggerFunction('bestPrice');

export const useBestPrice = (props: IncomingDataType) => {
    const { productId, quantity, priceReference, skip = false } = props;
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryBestPriceArgs>(BEST_PRICE, {
        variables: {
            productId,
            quantity,
            priceReference
        },
        skip,
        onError
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(data.bestPrice);
        }
    }, [data]);

    return {
        bestPrice: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
