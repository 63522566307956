import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Checkmark: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.9419 3.29335C19.186 3.53743 19.186 3.93315 18.9419 4.17723L5.67724 17.4419C5.55741 17.5618 5.39405 17.6278 5.22461 17.6249C5.05516 17.622 4.89416 17.5504 4.7785 17.4266L1.0432 13.4266C0.807617 13.1743 0.821151 12.7788 1.07343 12.5432C1.32572 12.3076 1.72121 12.3211 1.9568 12.5734L5.25068 16.1007L18.0581 3.29335C18.3021 3.04927 18.6979 3.04927 18.9419 3.29335Z"
                fill={color}
            />
        </SVG>
    );
};
