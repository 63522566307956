type HandleInputKeyDownEnterType = {
    event: React.KeyboardEvent<HTMLInputElement>;
    callback: () => void;
};

export const handleInputKeyDownEnter = ({ event, callback }: HandleInputKeyDownEnterType) => {
    if (event.key === 'Enter') {
        callback();
    }
};
