export type CleanStringRuleType = (value: string) => string;

export const noWhitespace: CleanStringRuleType = (value) => {
    return value.replace(/\s+/g, ' ');
};

export const noHtmlTags: CleanStringRuleType = (value) => {
    return value.replaceAll('<br />', '\r\n').replaceAll('</b>', '').replaceAll('<b>', '');
};

export const backslashNToBreak: CleanStringRuleType = (value) => {
    return value.replaceAll(/\n/g, '<br />');
};
