import type { Query } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { GET_CATEGORIES } from './gql/getCategories';

type QueryDataType = Pick<Query, 'getCategories'>;
type OutgoingDataType = QueryDataType['getCategories'];

const onError = getErrorLoggerFunction('getCategories');

export const useGetCategories = () => {
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const { data, loading, refetch, error } = useQuery<QueryDataType>(GET_CATEGORIES, {
        onError
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(data.getCategories);
        }
    }, [data]);

    return {
        categories: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
