import { gql } from '@apollo/client';

export const FAST_CAPTURE_SEARCH = gql`
    query FastCaptureSearch($query: String!) {
        fastCaptureSearch(query: $query) {
            total
            results {
                unionDocument {
                    ... on FastCaptureProductDocument {
                        sku
                        productName
                        salesUnit
                        quantityUnit
                        ean
                    }
                }
            }
        }
    }
`;
