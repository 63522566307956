import { gql } from '@apollo/client';

export const SEARCH_SUGGEST = gql`
    query SearchSuggest($query: String!, $tracking: Tracking) {
        suggest(query: $query, tracking: $tracking) {
            suggest
            suggestHighlighted
        }
    }
`;
