import { gql } from '@apollo/client';
import { FRAGMENTS } from '../../_fragments';

export const SERVICE_DIRECTORY = gql`
    query ServiceDirectory($offerNumber: String!) {
        serviceDirectory(offerNumber: $offerNumber) {
            ...OfferDetailFields
        }
    }
    ${FRAGMENTS.OFFER_DETAIL_FIELDS}
`;
