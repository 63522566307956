import { graphql, useStaticQuery } from 'gatsby';

type ValueType = {
    value: string;
};
type Resourcetype = {
    value: ValueType;
    key: string;
};
type ResourcesType = {
    name: string;
    resources: Resourcetype[];
};
const useText = (key: string): string => {
    const { allContentfulResourceSet } = useStaticQuery(
        graphql`
            query resourceQuery {
                allContentfulResourceSet {
                    nodes {
                        name
                        resources {
                            value {
                                value
                            }
                            key
                        }
                    }
                }
            }
        `
    );
    const { nodes } = allContentfulResourceSet;
    const keyArray = key.split('.');
    const resourceSetKey = keyArray[0];

    const set = nodes?.find(
        (resource: ResourcesType) => resource?.name?.toLowerCase() === resourceSetKey.toLowerCase()
    );
    const str = set?.resources?.find((resource: Resourcetype) => resource?.key === key)?.value?.value;
    if (!str) {
        return key;
    }
    return str;
};

export default useText;
