import type { NavigationElement } from './categoryToNavigationElementMapper';

const findCategoryIndetifier = (
    categoryNavigationElement: NavigationElement[],
    navigationElements?: NavigationElement[] | null
): NavigationElement[] | null => {
    if (!navigationElements?.length) {
        return null;
    }

    return navigationElements.map((navigationElement: NavigationElement) => {
        if (navigationElement.identifier === 'category') {
            return {
                ...navigationElement,
                ...{
                    navigationElements: categoryNavigationElement,
                    slug: 'kategorie'
                }
            } as NavigationElement;
        }

        if (navigationElement.identifier === 'becomeACustomer') {
            return {
                ...navigationElement,
                ...{
                    slug: 'kunde-werden'
                }
            } as NavigationElement;
        }

        return {
            ...navigationElement,
            ...{
                navigationElements: findCategoryIndetifier(
                    categoryNavigationElement,
                    navigationElement.navigationElements
                )
            }
        } as NavigationElement;
    });
};
const combinedNavigationElementMapper = (
    navigationElement: NavigationElement,
    categoryNavigationElement: NavigationElement[]
): NavigationElement => ({
    ...navigationElement,
    ...{
        navigationElements: findCategoryIndetifier(categoryNavigationElement, navigationElement?.navigationElements)
    }
});

export default combinedNavigationElementMapper;
