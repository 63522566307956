import type { BasicTracking } from '@ab-core/functions/tracking/types';
import type { Query, QuerySuggestArgs } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { SEARCH_SUGGEST } from './gql/searchSuggest';

type QueryDataType = Pick<Query, 'suggest'>;
type OutgoingDataType = QueryDataType['suggest'];

type IncomingDataType = {
    query?: string;
    minimumQueryLength?: number;
    tracking?: BasicTracking;
};

const onError = getErrorLoggerFunction('suggest');

const MINIMUM_QUERY_LENGTH = 2;

export const useSearchSuggest = (props: IncomingDataType) => {
    const { query = '', minimumQueryLength = MINIMUM_QUERY_LENGTH, tracking } = props;
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const skip = !query || query?.length < minimumQueryLength;

    const { data, loading, refetch, error } = useQuery<QueryDataType, QuerySuggestArgs>(SEARCH_SUGGEST, {
        variables: {
            query,
            tracking
        },
        skip,
        onError
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(data.suggest);
        }
    }, [data]);

    return {
        suggestions: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
