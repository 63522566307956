import type { Cart } from '@ab-core/graphql/dist';
import { gql } from '@apollo/client';
import { CART_OBJECT } from '../../../fragments/cartFragment';

export type UpdateCartResponseType = {
    upsertLineItemToCarts: Array<Cart>;
};

export const UPDATE_CART = gql`
    mutation upsertLineItem(
        $cartIds: [String!]!
        $lineItemInput: [LineItemInput!]!
        $tracking: Tracking
        $sessionId: String
    ) {
        upsertLineItemToCarts(
            cartIds: $cartIds
            lineItemInput: $lineItemInput
            tracking: $tracking
            sessionId: $sessionId
        ) {
            ...ABCartFields
        }
    }
    ${CART_OBJECT}
`;
