import type { Query, QueryFastCaptureSearchListArgs } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { FAST_CAPTURE_SEARCH_LIST } from './gql/fastCaptureSearchList';

type QueryDataType = Pick<Query, 'fastCaptureSearchList'>;
type OutgoingDataType = QueryDataType['fastCaptureSearchList'];

const onError = getErrorLoggerFunction('fastCaptureSearchList');

export const useFastCaptureSearchList = (input: Array<string>) => {
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const skip = input.length === 0;

    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryFastCaptureSearchListArgs>(
        FAST_CAPTURE_SEARCH_LIST,
        {
            variables: {
                input
            },
            onError,
            skip
        }
    );

    useEffect(() => {
        if (data) {
            setOutgoingData(data.fastCaptureSearchList);
        }
    }, [data]);

    return {
        fastCaptureSearchList: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
