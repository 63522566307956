import { useEffect } from 'react';

const useDisableBackgroundScroll = (activator: boolean) => {
    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        const body = document.querySelector('body');

        if (body) {
            if (activator) {
                body.style.overflow = 'hidden';
            } else {
                body.style.overflow = 'auto';
            }
        }

        return () => {
            if (body) {
                body.style.overflow = 'auto';
            }
        };
    }, [activator]);
};

export default useDisableBackgroundScroll;
