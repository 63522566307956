import { Link } from '@ab-core/link';
import type { FC } from 'react';
import React from 'react';

type MarkdownLinkProps = {
    children?: string[];
    href?: string;
    title?: string;
    className?: string;
};

const Markdownlink: FC<MarkdownLinkProps> = ({ title, href, children }: MarkdownLinkProps) => (
    <Link to={href || ''} title={title}>
        {children}
    </Link>
);

export default Markdownlink;
