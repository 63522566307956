import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Teach: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.5233 13.9362L16.7321 13.833V13.6V8.75178L18.1828 8.15851V13V14.5H18.9328V13V7.85015L20.144 7.34623L21.0453 6.97127L20.1248 6.64638L11.6248 3.64638L11.4877 3.59798L11.3538 3.65469L2.85375 7.25469L1.97443 7.62711L2.8722 7.95255L10.8722 10.8526L11.0019 10.8996L11.1312 10.8513L15.9821 9.04028V13.367L11.4928 15.5853L6.5625 13.3579V9.4H5.8125V13.6V13.8421L6.03311 13.9417L11.3456 16.3417L11.5072 16.4147L11.6661 16.3362L16.5233 13.9362Z"
                stroke={color}
                strokeWidth="0.75"
            />
        </SVG>
    );
};
