import { useIsSearchV2 } from '@ab-core-functions/search';
import { getSessionId } from '@ab-core-functions/tracking';
import type { Mutation, MutationCreateMyOrderArgs } from '@ab-core/graphql/dist';
import { USER_QUERY } from '@ab-core/hooks/user/getUserQuery';
import { priceStore } from '@ab-core/store';
import { useMutation } from '@apollo/client';
import orderMapper from './mappers/orderMapper';
import { CREATE_ORDER } from './postCreateOrder';

type MutationType = Pick<Mutation, 'createMyOrder'>;

const useOrder = (activeCartId: string) => {
    const myCredits = priceStore.getters.useCredits();
    const maxCredits = priceStore.getters.useMaxCredits();
    const coupon = priceStore.getters.useCoupon();
    const credits = myCredits > maxCredits ? maxCredits : myCredits;
    const { isSearchV2 } = useIsSearchV2();
    const isV2 = isSearchV2();
    const sessionId = isV2 ? getSessionId() : undefined;
    const [createOrder, { client, loading, error, data }] = useMutation<MutationType, MutationCreateMyOrderArgs>(
        CREATE_ORDER,
        {
            variables: {
                cartId: activeCartId,
                sessionId,
                credits,
                coupon
            },
            fetchPolicy: 'network-only',
            refetchQueries: [
                {
                    query: USER_QUERY,
                    fetchPolicy: 'no-cache',
                    variables: {}
                },
                'GetCoupons'
            ],
            update(cache) {
                cache.modify({
                    id: cache.identify({ __typename: 'Cart', id: activeCartId }),
                    fields: {
                        cart(_existingCommentRefs, { DELETE }) {
                            return DELETE;
                        }
                    }
                });
            }
        }
    );

    return { createOrder, client, order: orderMapper(data?.createMyOrder), orderError: error, orderLoading: loading };
};

export default useOrder;
