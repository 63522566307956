import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Print: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.69863 1.60494C4.69863 1.40067 4.8645 1.23457 5.06849 1.23457H14.9315C15.1355 1.23457 15.3014 1.40067 15.3014 1.60494V3.95062H4.69863V1.60494ZM16.5342 1.60494V3.95062H17.3973C18.2821 3.95062 19 4.66945 19 5.55556V15.4321C19 16.3182 18.2821 17.037 17.3973 17.037H16.5342V18.3951C16.5342 19.2812 15.8164 20 14.9315 20H5.06849C4.18361 20 3.46575 19.2812 3.46575 18.3951V17.037H2.60274C1.71785 17.037 1 16.3182 1 15.4321V5.55556C1 4.66945 1.71785 3.95062 2.60274 3.95062H3.46575V1.60494C3.46575 0.718837 4.18361 0 5.06849 0H14.9315C15.8164 0 16.5342 0.718837 16.5342 1.60494ZM17.3973 15.8025H16.5342V14.0617C16.8156 14.0045 17.0274 13.7554 17.0274 13.4568C17.0274 13.1159 16.7514 12.8395 16.411 12.8395H3.58904C3.24859 12.8395 2.9726 13.1159 2.9726 13.4568C2.9726 13.7554 3.18438 14.0045 3.46575 14.0617V15.8025H2.60274C2.39875 15.8025 2.23288 15.6364 2.23288 15.4321V5.55556C2.23288 5.35129 2.39875 5.18519 2.60274 5.18519H17.3973C17.6012 5.18519 17.7671 5.35129 17.7671 5.55556V15.4321C17.7671 15.6364 17.6012 15.8025 17.3973 15.8025ZM4.69863 14.0741H15.3014V18.3951C15.3014 18.5993 15.1355 18.7654 14.9315 18.7654H5.06849C4.8645 18.7654 4.69863 18.5993 4.69863 18.3951V14.0741ZM14.9315 7.03705C14.3868 7.03705 13.9452 7.47924 13.9452 8.0247C13.9452 8.57017 14.3868 9.01236 14.9315 9.01236C15.4762 9.01236 15.9178 8.57017 15.9178 8.0247C15.9178 7.47924 15.4762 7.03705 14.9315 7.03705Z"
                fill={color}
            />
        </SVG>
    );
};
