import { gql } from '@apollo/client';

export const BEST_PRICE = gql`
    query BestPrice($productId: String!, $quantity: Int!, $priceReference: Int!) {
        bestPrice(productId: $productId, quantity: $quantity, priceReference: $priceReference) {
            value {
                centAmount
            }
            bestPriceBonus {
                name
                percent
                value {
                    centAmount
                }
            }
        }
    }
`;
