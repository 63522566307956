import { gql } from '@apollo/client';

const ORDERS_SEARCH_QUERY = gql`
    query orders($query: String, $page: Float, $filter: OrderFilter) {
        orders(query: $query, page: $page, filter: $filter) {
            total
            pageCount
            currentPage
            count
            total
            facets {
                values {
                    value
                    filter
                    count
                }
                id
                name
                filterName
            }
            results {
                unionDocument {
                    ... on OrderDocument {
                        orderNumber
                        orderText
                        id
                        purchaser
                        lastModifiedAt
                        project
                        costCenter
                        orderState
                        orderDate
                        orderNumber
                        orderType
                        accountId
                        orderDeliveryType
                        totalPrice {
                            centAmount
                        }
                        lineItemsTotal
                        qscOrderLineItems {
                            sku
                            lineItemName
                            positionPrice
                            position
                            positionText
                            lineItemQuantity
                            deliveryQuantity
                            state
                            deliveryDate
                            estimatedDeliveryDate
                            invoiceNumber
                            deliveryNoteNumber
                            diverseArticle
                        }
                    }
                }
            }
        }
    }
`;

export default ORDERS_SEARCH_QUERY;
