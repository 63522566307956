import type { Mutation, MutationCreateAccountContactRelationArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { CREATE_ACCOUNT_CONTACT_RELATION } from './gql/createAccountContactRelation';

type MutationDataType = Pick<Mutation, 'createAccountContactRelation'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('createAccountContactRelation');

export const useCreateAccountContactRelation = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [createAccountContactRelation, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationCreateAccountContactRelationArgs
    >(CREATE_ACCOUNT_CONTACT_RELATION, {
        onError
    });

    const outgoingCreateReturn = useCallback(
        (accountId: MutationCreateAccountContactRelationArgs['accountId']) => {
            createAccountContactRelation({ variables: { accountId } });
        },
        [createAccountContactRelation]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        createAccountContactRelation: outgoingCreateReturn,
        loading,
        response
    };
};
