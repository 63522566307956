const baseUrlService = (): string => {
    if (typeof window === 'undefined') {
        return '';
    }

    return `${window.location.protocol}//${window.location.hostname}${
        window.location.port && `:${window.location.port}`
    }`;
};

export default baseUrlService;
