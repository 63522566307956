import { Colors } from '@ab-core/colors';
import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';

const Line = styled.div`
    border-top: 1px solid ${Colors.gray30};
`;

type HorizontalLineProps = React.ComponentPropsWithoutRef<'div'>;

export const HorizontalLine: FC<HorizontalLineProps> = (props) => {
    const { ...rest } = props;

    return <Line {...rest} />;
};
