import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Home: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99995 0C10.1657 -2.98022e-07 10.3247 0.0658474 10.4419 0.183057L19.8169 9.55806C19.9957 9.73681 20.0492 10.0056 19.9524 10.2392C19.8557 10.4727 19.6278 10.625 19.375 10.625H17.5V19.375C17.5 19.7202 17.2202 20 16.875 20H12.5C12.1548 20 11.875 19.7202 11.875 19.375V13.125H8.12499V19.375C8.12499 19.7202 7.84517 20 7.49999 20H3.12499C2.77981 20 2.49999 19.7202 2.49999 19.375V10.625H0.624988C0.372199 10.625 0.144302 10.4727 0.0475633 10.2392C-0.049175 10.0056 0.00429693 9.73681 0.183045 9.55806L9.55801 0.183059C9.67522 0.0658486 9.83419 2.98024e-07 9.99995 0ZM9.99995 1.50888L2.13387 9.375H3.12499C3.47017 9.375 3.74999 9.65482 3.74999 10V18.75H6.87499V12.5C6.87499 12.1548 7.15481 11.875 7.49999 11.875H12.5C12.8452 11.875 13.125 12.1548 13.125 12.5V18.75H16.25V10C16.25 9.65482 16.5298 9.375 16.875 9.375H17.8661L9.99995 1.50888Z"
                fill={color}
            />
        </SVG>
    );
};
