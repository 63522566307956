import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Info: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 1.23457C5.15899 1.23457 1.23457 5.15899 1.23457 10C1.23457 14.841 5.15899 18.7654 10 18.7654C14.841 18.7654 18.7654 14.841 18.7654 10C18.7654 5.15899 14.841 1.23457 10 1.23457ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM9.99506 6.79012C10.336 6.79012 10.6123 6.51376 10.6123 6.17284C10.6123 5.83192 10.336 5.55556 9.99506 5.55556C9.65414 5.55556 9.37777 5.83192 9.37777 6.17284C9.37777 6.51376 9.65414 6.79012 9.99506 6.79012ZM10.6173 9.01235C10.6173 8.67143 10.3409 8.39506 10 8.39506C9.65908 8.39506 9.38272 8.67143 9.38272 9.01235V13.9506C9.38272 14.2915 9.65908 14.5679 10 14.5679C10.3409 14.5679 10.6173 14.2915 10.6173 13.9506V9.01235Z"
                fill={color}
            />
        </SVG>
    );
};
