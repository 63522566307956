import { BREAKPOINT } from '@ab-core/breakpoints';
import { useIsMobile } from '@ab-core/functions';
import type { CategoryResult } from '@ab-core/graphql/dist';
import type { ColProps } from '@ab-core/grid';
import { Col, Row } from '@ab-core/grid';
import { Icon } from '@ab-core/icon';
import Skeletonloader from '@ab-core/skeletonloader';
import type { WithTestId } from '@ab-core/testing';
import { getTestIdProp } from '@ab-core/testing';
import { FontTypes } from '@ab-core/text';
import type { FC } from 'react';
import React from 'react';
import { BorderWrapper, ItemsWrapper, Menu, MenuItem, StyledText, TitleHeader } from './collectionItems.styled';

type HeaderType = {
    title?: string;
    onClick: () => void;
    isMobile: boolean;
    hideBackButton?: boolean;
};
const Header: FC<HeaderType> = ({ title = '', onClick, isMobile, hideBackButton = false }) => (
    <TitleHeader isMobile={isMobile} onClick={!hideBackButton ? () => onClick() : undefined}>
        {isMobile && !hideBackButton && <Icon name="ChevronLeft" color="black" />}
        <StyledText hideBackButton={hideBackButton && isMobile} type={FontTypes.Title} color="black" content={title} />
    </TitleHeader>
);

type CollectionItemProps = WithTestId &
    React.ComponentPropsWithoutRef<'div'> & {
        header: Pick<HeaderType, 'onClick' | 'title' | 'hideBackButton'>;
        lastMenu: number;
        items?: Array<CategoryResult>;
        activeItem?: CategoryResult;
        itemOnClick: (item: CategoryResult) => void;
        contentItem: (item: CategoryResult) => React.ReactNode;
        colProps?: ColProps;
        hasBorder?: boolean;
    };

const CollectionItem: FC<CollectionItemProps> = (props) => {
    const { header, items, activeItem, colProps, hasBorder = true, itemOnClick, contentItem, ...rest } = props;
    const isMobile = useIsMobile(BREAKPOINT.LG);

    return (
        <Menu {...rest}>
            <ItemsWrapper>
                <BorderWrapper hasBorder={hasBorder}>
                    <Header isMobile={isMobile} {...header} />
                    <Row>
                        {items?.map((item, key) => (
                            <Col key={key} {...colProps}>
                                <MenuItem
                                    onClick={() => itemOnClick(item)}
                                    active={item.name === activeItem?.name}
                                    {...getTestIdProp(`collection-item-${item.name}`)}
                                >
                                    {contentItem && contentItem(item)}
                                </MenuItem>
                            </Col>
                        ))}
                    </Row>
                </BorderWrapper>
            </ItemsWrapper>
        </Menu>
    );
};

export default CollectionItem;

export const LoadingCollectionItem: FC<CollectionItemProps> = (props) => {
    const { header, colProps, hasBorder = true, ...rest } = props;
    const isMobile = useIsMobile(BREAKPOINT.LG);

    return (
        <Menu {...rest}>
            <ItemsWrapper>
                <BorderWrapper hasBorder={hasBorder}>
                    <Header isMobile={isMobile} {...header} />
                    <Row>
                        <Col {...colProps}>
                            <Skeletonloader />
                            <Skeletonloader />
                            <Skeletonloader />
                            <Skeletonloader />
                        </Col>
                    </Row>
                </BorderWrapper>
            </ItemsWrapper>
        </Menu>
    );
};
