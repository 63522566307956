import { getClassNames } from '@ab-core/functions/styles/classNameFormatter';
import type { SuggestResultV2 } from '@ab-core/graphql/dist';
import type { FC } from 'react';
import React from 'react';
import { Suggestion, SuggestionText } from './styled';

type SearchSuggestionProps = React.ComponentPropsWithoutRef<'div'> & {
    suggestion: SuggestResultV2;
    active?: boolean;
};

export const SearchSuggestion: FC<SearchSuggestionProps> = (props) => {
    const { suggestion, active = false, className, ...rest } = props;

    const suggestionClass = getClassNames({
        className,
        prefix: 'suggestion',
        modifier: { active }
    });

    return (
        <Suggestion className={suggestionClass} {...rest}>
            <SuggestionText>{suggestion.name}</SuggestionText>
        </Suggestion>
    );
};
