import { getClassNames } from '@ab-core/functions/styles/classNameFormatter';
import type { WithTestId } from '@ab-core/testing';
import type { FC } from 'react';
import React from 'react';
import { SheetContent, StyledSheet } from './index.styled';

export enum SHEET_STYLE_TYPE {
    FLAT = 'flat',
    CARD = 'card'
}

type SheetType = WithTestId & {
    children: React.ReactNode;
    active?: boolean;
    styleType?: SHEET_STYLE_TYPE;
};

export const Sheet: FC<SheetType> = (props) => {
    const { children, active = false, styleType = SHEET_STYLE_TYPE.CARD, ...rest } = props;
    const newClassName = getClassNames({ prefix: 'sheet', modifier: { styleType, active } });

    return (
        <StyledSheet className={newClassName}>
            <SheetContent {...rest}>{children}</SheetContent>
        </StyledSheet>
    );
};
