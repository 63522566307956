import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Procured: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.7568 3.13521C13.7568 2.78439 13.4724 2.5 13.1216 2.5H0.63521C0.284393 2.5 1.90735e-06 2.78439 1.90735e-06 3.13521V14.373C1.90735e-06 14.7238 0.284393 15.0082 0.63521 15.0082H3.20273C3.484 16.0858 4.464 16.8811 5.62977 16.8811C6.79553 16.8811 7.77553 16.0858 8.0568 15.0082H11.9432C12.2245 16.0858 13.2045 16.8811 14.3702 16.8811C15.5373 16.8811 16.5183 16.084 16.7982 15.0044C16.821 15.0069 16.8441 15.0082 16.8675 15.0082H19.3648C19.7156 15.0082 20 14.7238 20 14.373V9.3784C20 9.29238 19.9825 9.20725 19.9486 9.12818L18.0757 4.75795C17.9756 4.52439 17.7459 4.37296 17.4918 4.37296H13.7568V3.13521ZM13.7568 5.64337H17.073L18.4015 8.74319H13.7568V5.64337ZM18.7296 10.0136H13.1216C12.7708 10.0136 12.4864 9.72922 12.4864 9.3784V3.77042H1.27042V13.7377H3.20273C3.484 12.6601 4.464 11.8648 5.62977 11.8648C6.79553 11.8648 7.77553 12.6601 8.0568 13.7377H11.9432C12.2245 12.6601 13.2045 11.8648 14.3702 11.8648C15.5373 11.8648 16.5183 12.6619 16.7982 13.7415C16.821 13.739 16.8441 13.7377 16.8675 13.7377H18.7296V10.0136ZM14.3702 13.1352C15.0538 13.1352 15.608 13.6894 15.608 14.373C15.608 15.0565 15.0538 15.6107 14.3702 15.6107C13.6866 15.6107 13.1325 15.0565 13.1325 14.373C13.1325 13.6894 13.6866 13.1352 14.3702 13.1352ZM5.62977 13.1352C6.31336 13.1352 6.86752 13.6894 6.86752 14.373C6.86752 15.0565 6.31336 15.6107 5.62977 15.6107C4.94618 15.6107 4.39202 15.0565 4.39202 14.373C4.39202 13.6894 4.94618 13.1352 5.62977 13.1352Z"
                fill={color}
            />
        </SVG>
    );
};
