import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Edit: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.7354 0.180798C15.9764 -0.060266 16.3673 -0.060266 16.6083 0.180798L20.5589 4.13141C20.8 4.37248 20.8 4.76332 20.5589 5.00438L7.71945 17.8439C7.65169 17.9116 7.56908 17.9627 7.47817 17.993L4.52788 18.9764C4.51978 18.9793 4.51163 18.982 4.50344 18.9846L1.55225 19.9683C1.33044 20.0422 1.08589 19.9845 0.920561 19.8192C0.755233 19.6539 0.697503 19.4093 0.77144 19.1875L1.75517 16.2363C1.75771 16.2281 1.76043 16.22 1.76332 16.2119L2.74675 13.2616C2.77705 13.1707 2.82811 13.0881 2.89587 13.0203L15.7354 0.180798ZM14.1965 3.46556L4.20532 13.4568L7.28297 16.5344L17.2742 6.5432L14.1965 3.46556ZM18.1472 5.67023L15.0695 2.59259L16.1718 1.49025L19.2495 4.5679L18.1472 5.67023ZM6.14023 17.1376L3.60212 14.5995L3.05095 16.253L4.48673 17.6888L6.14023 17.1376ZM3.17727 18.1253L2.61446 17.5625L2.33306 18.4067L3.17727 18.1253Z"
                fill={color}
            />
        </SVG>
    );
};
