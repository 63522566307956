import type { Mutation, MutationCreateKanbanLabelArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { CREATE_KANBAN_LABEL } from './gql/createKanbanLabel';
import { GET_KANBAN_LABEL_SET } from './gql/getKanbanLabelSet';

type MutationDataType = Pick<Mutation, 'createKanbanLabel'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('createKanbanLabel');

const refetchQueries = [GET_KANBAN_LABEL_SET];

export const useCreateKanbanLabel = (labelSetId: string) => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [createKanbanLabel, { loading, error, data }] = useMutation<MutationDataType, MutationCreateKanbanLabelArgs>(
        CREATE_KANBAN_LABEL,
        {
            refetchQueries,
            onError
        }
    );

    const outgoingCreateKanbanLabel = useCallback(
        (labelData: MutationCreateKanbanLabelArgs['labelData']) => {
            createKanbanLabel({ variables: { labelData, labelSetId } });
        },
        [createKanbanLabel]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        createKanbanLabel: outgoingCreateKanbanLabel,
        loading,
        response
    };
};
