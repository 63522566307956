import useText from '@ab-core/localization/src/useText';
import type { TabsType } from '@ab-core/tabs';
import { Tabs } from '@ab-core/tabs';
import Text from '@ab-core/text';
import type { FC } from 'react';
import React from 'react';
import { Login } from './login';
import { Register } from './register';
import type { LoginBoxProps } from './types';

export const LoginBox: FC<LoginBoxProps> = ({ redirectAfterLogin, forceRedirect = false, id }) => {
    const loginText = useText('loginBox.tabLoginLabel');
    const signUpText = useText('loginBox.tabSignUpLabel');
    const tabs: TabsType = [
        {
            head: <Text color="inherit">{loginText} </Text>,
            content: <Login redirectAfterLogin={redirectAfterLogin} forceRedirect={forceRedirect} id={id} />
        },
        {
            head: <Text color="inherit">{signUpText}</Text>,
            content: <Register />
        }
    ];

    return <Tabs tabs={tabs} />;
};

export type { LoginBoxProps };
