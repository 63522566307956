import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const QrCode: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.5 3.5H9V2H2V9H3.5V3.5Z" fill={color} />
            <path d="M3.5 15H2V22H9V20.5H3.5V15Z" fill={color} />
            <path d="M15 2V3.5H20.5V9H22V2H15Z" fill={color} />
            <path d="M20.5 20.5H15V22H22V15H20.5V20.5Z" fill={color} />
            <path d="M6 11H11V6H6V11ZM7 7H10V10H7V7Z" fill={color} />
            <path d="M18 6H13V11H18V6ZM17 10H14V7H17V10Z" fill={color} />
            <path d="M18 13H13V18H18V13ZM17 17H14V14H17V17Z" fill={color} />
            <path d="M6 18H11V13H6V18ZM7 14H10V17H7V14Z" fill={color} />
        </SVG>
    );
};
