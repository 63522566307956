import type { TextAlignmentType } from '@ab-core/functions';
import { TextAlignment } from '@ab-core/functions/styles/textAlignment';
import type { FC } from 'react';
import React from 'react';
import { StyledList, StyledListItem } from './styled';

export enum LIST_ITEM_STYLE {
    BULLET = 'bullet',
    CHECK = 'check'
}

type ListItemType = {
    children: React.ReactNode;
    listItemStyle?: LIST_ITEM_STYLE | null;
};

export type ListProps = TextAlignmentType & {
    children?: React.ReactNode;
    listItemStyle?: LIST_ITEM_STYLE | null;
};

export const ListItem: FC<ListItemType> = ({ children, listItemStyle }) => (
    <StyledListItem listItemStyle={listItemStyle}>{children}</StyledListItem>
);

const List: FC<ListProps> = ({ children, textAlignment = TextAlignment.LEFT }) => (
    <StyledList textAlignment={textAlignment}>{children}</StyledList>
);

export default List;
