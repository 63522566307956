import { getSessionId } from '@ab-core-functions/tracking';
import type { Query, QuerySuggestV2Args } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { SEARCH_SUGGEST_V2 } from './gql/searchSuggestV2';

type QueryDataType = Pick<Query, 'suggestV2'>;
type OutgoingDataType = QueryDataType['suggestV2'];

type IncomingDataType = {
    query?: string;
    minimumQueryLength?: number;
};

const onError = getErrorLoggerFunction('suggestV2');

const MINIMUM_QUERY_LENGTH = 2;

export const useSearchSuggestV2 = (props: IncomingDataType) => {
    const { query = '', minimumQueryLength = MINIMUM_QUERY_LENGTH } = props;
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const skip = !query || query?.length < minimumQueryLength;

    const { data, loading, refetch, error } = useQuery<QueryDataType, QuerySuggestV2Args>(SEARCH_SUGGEST_V2, {
        variables: {
            query,
            sid: getSessionId()
        },
        skip,
        onError
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(data.suggestV2);
        }
    }, [data]);

    return {
        suggestionsV2: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
