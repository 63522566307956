import { Colors } from '@ab-core/colors';
import { FontTypes } from '@ab-core/font-types';
import { textAlignmentStyle } from '@ab-core/functions/styles/textAlignment';
import styled from 'styled-components';
import type { TextProps } from './index';

export const ABText = styled.div<TextProps>`
    display: ${({ asElement }) => (asElement === 'p' ? 'block' : 'inline-block')};
    font-weight: ${({ type }) => FontTypes[type as keyof typeof FontTypes].fontWeight};
    font-size: ${({ type }) => FontTypes[type as keyof typeof FontTypes].fontSize};
    line-height: ${({ type }) => FontTypes[type as keyof typeof FontTypes].lineHeight};
    ${({ type }) =>
        FontTypes[type as keyof typeof FontTypes].letterSpacing &&
        `letter-spacing: ${FontTypes[type as keyof typeof FontTypes].letterSpacing}`};
    ${({ type, uppercase }) =>
        uppercase
            ? `text-transform: uppercase`
            : FontTypes[type as keyof typeof FontTypes].textTransform &&
              `text-transform: ${FontTypes[type as keyof typeof FontTypes].textTransform}`};
    color: ${({ color }) =>
        color && Colors[color as keyof typeof Colors]
            ? Colors[color as keyof typeof Colors]
            : color === 'inherit'
            ? color
            : Colors.black};
    ${({ background }) =>
        background &&
        Colors[background as keyof typeof Colors] &&
        `background: ${Colors[background as keyof typeof Colors]};`};
    text-decoration: ${({ isLink }) => isLink && 'underline'};
    ${textAlignmentStyle}
`;
