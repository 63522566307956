import { gql } from '@apollo/client';

export const DELIVERY_ADDRESSES = gql`
    query getShippingAddresses {
        getCustomerData {
            activeAccount {
                shippingAddresses {
                    id
                    externalId
                    primaryAddress
                    name
                    streetName
                    city
                    postalCode
                    country
                    addressExtraLineOne
                    addressExtraLineTwo
                    addressExtraLineThree
                    defaultShippingInstructions
                }
            }
        }
    }
`;
