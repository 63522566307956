import type { Category, CategoryCustomFieldsRaw } from '@ab-core/graphql/dist';

const SECOND_LEVEL = 2;
type CategoryCustomField = {
    id: string;
};
type NavigationOrderElement = {
    name: string;
    id: string;
    slug: string;
    ancestors: string[];
    orderHint: number;
};

export type NavigationElement = {
    identifier?: string;
    name: string;
    id: string;
    slug: string;
    ancestors: string[];
    navigationElements: NavigationElement[] | null;
};

const categoryToNavigationElementMapper = (categories: Category[]): NavigationElement[] => {
    const categoriesWithParents = categories
        .map((category: Category) => {
            let categoryParents = [];

            if (category.parent && category.parent.id) {
                categoryParents.push(category.parent.id);
            }

            const filteredParents =
                category.custom &&
                category.custom.customFieldsRaw &&
                category.custom.customFieldsRaw.find(
                    (customField: CategoryCustomFieldsRaw) => customField.name === 'additionalParents'
                );

            if (filteredParents) {
                const parsedFilteredParents = JSON.parse(filteredParents.value || '');
                const additionalParents = parsedFilteredParents.map((item: CategoryCustomField) => item.id);

                if (additionalParents && additionalParents.length) {
                    categoryParents = [...categoryParents, ...additionalParents];
                }
            }

            return {
                name: category.name,
                id: category.id,
                slug: category.slug,
                ancestors: categoryParents,
                orderHint: category.orderHint
            } as NavigationOrderElement;
        })
        .sort((a: NavigationOrderElement, b: NavigationOrderElement) => a.orderHint - b.orderHint);

    const findChildren = (category: NavigationOrderElement, level: number): NavigationElement[] | null => {
        if (level === SECOND_LEVEL) {
            return null;
        }

        const children = categoriesWithParents.filter((item: NavigationOrderElement) =>
            item.ancestors.includes(category.id)
        );

        if (!children.length) {
            return null;
        }

        const sortedChildren = [...children].sort(
            (a: NavigationOrderElement, b: NavigationOrderElement) => a.orderHint - b.orderHint
        );

        return sortedChildren.map((child: NavigationOrderElement) => ({
            id: child.id,
            name: child.name,
            slug: `kategorie/${child.slug}`,
            navigationElements: findChildren(child, level + 1),
            ancestors: child.ancestors
        }));
    };

    return categoriesWithParents
        .filter((category: NavigationOrderElement) => !category.ancestors || !category.ancestors.length)
        .sort((a: NavigationOrderElement, b: NavigationOrderElement) => a.orderHint - b.orderHint)
        .map(
            (category: NavigationOrderElement) =>
                ({
                    name: category.name,
                    id: category.id,
                    slug: `kategorie/${category.slug}`,
                    navigationElements: findChildren(category, 0),
                    ancestors: category.ancestors
                } as NavigationElement)
        );
};

export default categoryToNavigationElementMapper;
