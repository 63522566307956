import type { Cart, CartCustomFieldRaw } from '@ab-core/graphql/dist/index';
import { CartShippingMethodInput, DeliveryMethod } from '@ab-core/shippingaddress/types';

export type MappedCart = {
    shippingMethod: CartShippingMethodInput;
    deliveryDate?: Date;
    lastModifiedAt?: Date;
    isIdsCart?: boolean;
    sessionId?: string;
} & Omit<Cart, 'deliveryDate' | 'lastModifiedAt'>;

type AttributesType = {
    [key: string]: string;
};

const cartMapper = (data: Cart): MappedCart => {
    const cartCustomFields: AttributesType | undefined = data.custom?.customFieldsRaw?.reduce(
        (acc: CartCustomFieldRaw, cur: CartCustomFieldRaw) => ({
            ...acc,
            [cur.name]: cur.value
        }),
        {} as CartCustomFieldRaw
    );

    return {
        ...data,
        displayShippingMethod: data.displayShippingMethod || DeliveryMethod.DELIVERY,
        sessionId: cartCustomFields ? cartCustomFields['cart-sessionId'] : '',
        shippingMethod:
            data?.shippingInfo?.shippingMethod?.key === 'pickup'
                ? CartShippingMethodInput.Pickup
                : CartShippingMethodInput.Delivery,
        deliveryDate: data.deliveryDate ? new Date(Date.parse(data.deliveryDate)) : undefined,
        lastModifiedAt: data?.lastModifiedAt ? new Date(Date.parse(data.lastModifiedAt)) : undefined,
        isIdsCart: !!(cartCustomFields && cartCustomFields['cart-isIdsCart'] === 'true')
    };
};

export default cartMapper;
