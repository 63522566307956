import { BREAKPOINT } from '@ab-core/breakpoints';
import { getClassNames } from '@ab-core/functions/styles/classNameFormatter';
import { useIsMobile } from '@ab-core/functions/useIsMobile';
import Headline, { HEADLINE_SIZES } from '@ab-core/headline';
import { Loader } from '@ab-core/loader';
import { getTestIdProp } from '@ab-core/testing';
import type { FC } from 'react';
import React from 'react';
import { CardActions, CardContent, CardHeader, Card as StyledCard } from './index.styled';
import type { CardType } from './types';
import { CARD_STYLE_TYPE } from './types';

export const Card: FC<CardType> = (props) => {
    const {
        head,
        content,
        footer,
        active = false,
        loading = false,
        wordbreak = true,
        styleType = CARD_STYLE_TYPE.CARD,
        className,
        ...rest
    } = props;
    const newClassNames = getClassNames({ className, prefix: 'card', modifier: { styleType, active, wordbreak } });
    const isMobile = useIsMobile(BREAKPOINT.SM);

    return (
        <StyledCard className={newClassNames} {...rest}>
            {loading && <Loader />}
            <CardHeader>
                <Headline size={isMobile ? HEADLINE_SIZES.SIX : HEADLINE_SIZES.FOUR} {...getTestIdProp('card-head')}>
                    {head}
                </Headline>
            </CardHeader>
            {content && <CardContent>{content}</CardContent>}
            {footer && <CardActions>{footer}</CardActions>}
        </StyledCard>
    );
};
