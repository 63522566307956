import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Collection: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.625" y="0.625" width="18.75" height="17.75" rx="1.375" stroke={color} strokeWidth="1.25" />
            <rect x="15" y="4.625" width="1.25" height="10" rx="0.625" transform="rotate(90 15 4.625)" fill={color} />
            <rect x="15" y="8.875" width="1.25" height="10" rx="0.625" transform="rotate(90 15 8.875)" fill={color} />
            <rect x="15" y="13.125" width="1.25" height="10" rx="0.625" transform="rotate(90 15 13.125)" fill={color} />
        </SVG>
    );
};
