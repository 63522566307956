import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const ExternalLink: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9780_16426)">
                <path
                    d="M19.375 10C19.375 15.1777 15.1777 19.375 10 19.375C4.82233 19.375 0.625 15.1777 0.625 10C0.625 4.82233 4.82233 0.625 10 0.625C15.1777 0.625 19.375 4.82233 19.375 10Z"
                    stroke={color}
                    strokeWidth="1.25"
                />
                <path
                    d="M13.0786 10.1193C13.1167 10.4623 13.4257 10.7095 13.7688 10.6714C14.1118 10.6333 14.359 10.3243 14.3209 9.98123L13.0786 10.1193ZM13.3462 6.86827L13.9674 6.79925C13.9351 6.50872 13.7057 6.27938 13.4152 6.2471L13.3462 6.86827ZM10.2332 5.89354C9.89017 5.85542 9.58115 6.10263 9.54304 6.4457C9.50492 6.78876 9.75213 7.09778 10.0952 7.1359L10.2332 5.89354ZM6.54029 12.7903C6.29621 13.0344 6.29621 13.4301 6.54029 13.6742C6.78437 13.9183 7.1801 13.9183 7.42417 13.6742L6.54029 12.7903ZM13.4346 7.66377C13.6787 7.41969 13.6787 7.02396 13.4346 6.77988C13.1905 6.53581 12.7948 6.53581 12.5507 6.77988L13.4346 7.66377ZM14.3209 9.98123L13.9674 6.79925L12.725 6.93729L13.0786 10.1193L14.3209 9.98123ZM13.4152 6.2471L10.2332 5.89354L10.0952 7.1359L13.2772 7.48945L13.4152 6.2471ZM7.42417 13.6742L13.4346 7.66377L12.5507 6.77988L6.54029 12.7903L7.42417 13.6742Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_9780_16426">
                    <rect width="20" height="20" fill="transparent" />
                </clipPath>
            </defs>
        </SVG>
    );
};
