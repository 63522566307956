import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const AccountLoggedIn: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0765 0C7.2854 0 5.01481 2.27058 5.01481 5.06173C5.01481 6.63666 5.74544 8.03634 6.87628 8.96403C4.68714 9.29865 3 11.1737 3 13.4568V19.3827C3 19.7235 3.27651 20 3.61728 20C3.95806 20 4.23457 19.7235 4.23457 19.3827V13.4568C4.23457 11.6188 5.72991 10.1235 7.5679 10.1235H12.5062C14.3442 10.1235 15.8395 11.6188 15.8395 13.4568V19.3827C15.8395 19.7235 16.116 20 16.4568 20C16.7976 20 17.0741 19.7235 17.0741 19.3827V13.4568C17.0741 11.1974 15.4206 9.33805 13.264 8.97515C14.403 8.04779 15.1383 6.64259 15.1383 5.06173C15.1383 2.27058 12.8677 0 10.0765 0ZM6.24938 5.06173C6.24938 2.95115 7.96596 1.23457 10.0765 1.23457C12.1871 1.23457 13.9037 2.95115 13.9037 5.06173C13.9037 7.17231 12.1871 8.88889 10.0765 8.88889C7.96596 8.88889 6.24938 7.17231 6.24938 5.06173Z"
                fill={color}
            />
            <circle cx="17" cy="17" r="3.5" fill="#279989" />
        </SVG>
    );
};
