export const fixHookUrl = (url?: string): string => {
    if (url) {
        const wrongProtocolTest = new RegExp(/https?:\/\w/, 'gm');
        let fixedUrl = url;

        if (wrongProtocolTest.test(url)) {
            const protocolIndex = wrongProtocolTest.lastIndex - 1;
            const protocolSubstring = url.substring(0, protocolIndex);
            const restUrl = url.substring(protocolIndex, url.length);
            const fixedProtocolString = `${protocolSubstring}/`;
            fixedUrl = fixedProtocolString + restUrl;
        }

        return fixedUrl;
    }

    return '';
};
