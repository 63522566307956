import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Ghost: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5333 9V19" stroke={color} strokeLinecap="round" />
            <path d="M3.46667 9V19" stroke={color} strokeLinecap="round" />
            <path d="M3.46667 19L5.80001 16" stroke={color} strokeLinecap="round" />
            <path d="M7.66663 19L9.99996 16" stroke={color} strokeLinecap="round" />
            <path d="M12.3333 19L10 16" stroke={color} strokeLinecap="round" />
            <path d="M12.3334 19L14.2 16" stroke={color} strokeLinecap="round" />
            <path d="M5.80005 16L7.66672 19" stroke={color} strokeLinecap="round" />
            <path d="M14.2 16L16.5333 19" stroke={color} strokeLinecap="round" />
            <path
                d="M3 9C3 6.87827 3.32 4.84344 4.72027 3.34315C6.12054 1.84286 8.01971 1 9.99999 1C11.9803 0.999998 13.8794 1.84285 15.2797 3.34314C16.68 4.84342 17 6.87827 17 9H16.0667C16.0667 7.16944 15.7633 5.41383 14.5552 4.11943C13.3471 2.82503 11.7085 2.09784 9.99999 2.09784C8.29147 2.09784 6.65292 2.82503 5.44481 4.11944C4.2367 5.41384 3.93333 7.16944 3.93333 9H3Z"
                fill={color}
            />
            <path d="M7.66663 8V10" stroke={color} strokeWidth="2" strokeLinecap="round" />
            <path d="M12.3334 8V10" stroke={color} strokeWidth="2" strokeLinecap="round" />
        </SVG>
    );
};
