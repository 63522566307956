import { BREAKPOINT } from '@ab-core/breakpoints';
import type { FC } from 'react';
import React from 'react';
import type { ThemeProps } from 'styled-bootstrap-grid';
import { GridThemeProvider } from 'styled-bootstrap-grid';

export type ProvidersProps = Omit<ThemeProps, 'gridTheme'>;

const gridTheme: ThemeProps['gridTheme'] = {
    breakpoints: {
        xxl: BREAKPOINT.XXL,
        xl: BREAKPOINT.XL,
        lg: BREAKPOINT.LG,
        md: BREAKPOINT.MD,
        sm: BREAKPOINT.SM,
        xs: BREAKPOINT.XS
    },
    row: {
        padding: 0
    },
    col: {
        padding: 10
    },
    container: {
        padding: 0,
        maxWidth: {
            xxl: BREAKPOINT.XXL,
            xl: BREAKPOINT.XL,
            lg: BREAKPOINT.LG,
            md: BREAKPOINT.MD,
            sm: BREAKPOINT.SM,
            xs: BREAKPOINT.XS
        }
    }
};

export const Providers: FC<ProvidersProps> = (props) => <GridThemeProvider {...props} gridTheme={gridTheme} />;
