import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Close: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.4418 1.55806C18.1977 1.31398 17.802 1.31398 17.5579 1.55806L9.99987 9.11612L2.44194 1.55819C2.19786 1.31411 1.80214 1.31411 1.55806 1.55819C1.31398 1.80227 1.31398 2.19799 1.55806 2.44207L9.11599 10L1.55806 17.5579C1.31398 17.802 1.31398 18.1977 1.55806 18.4418C1.80214 18.6859 2.19786 18.6859 2.44194 18.4418L9.99987 10.8839L17.5579 18.4419C17.802 18.686 18.1977 18.686 18.4418 18.4419C18.6859 18.1979 18.6859 17.8021 18.4418 17.5581L10.8838 10L18.4418 2.44194C18.6859 2.19786 18.6859 1.80214 18.4418 1.55806Z"
                fill={color}
            />
        </SVG>
    );
};
