import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Facebook: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.625001 10C0.625001 4.82233 4.82233 0.624999 10 0.624999C15.1777 0.625 19.375 4.82233 19.375 10C19.375 15.1777 15.1777 19.375 10 19.375C4.82233 19.375 0.625 15.1777 0.625001 10Z"
                stroke={color}
                strokeWidth="1.25"
                strokeLinecap="round"
            />
            <path
                d="M15.0611 10.0306C15.0611 7.25225 12.8089 5 10.0306 5C7.25225 5 5 7.25225 5 10.0306C5 12.5414 6.83959 14.6226 9.24453 15V11.4847H7.96724V10.0306H9.24453V8.92226C9.24453 7.66148 9.99558 6.96506 11.1446 6.96506C11.6951 6.96506 12.2707 7.06331 12.2707 7.06331V8.3013H11.6364C11.0115 8.3013 10.8166 8.68911 10.8166 9.08693V10.0306H12.2118L11.9887 11.4847H10.8166V15C13.2215 14.6226 15.0611 12.5415 15.0611 10.0306Z"
                fill={color}
            />
        </SVG>
    );
};
