import { gql } from '@apollo/client';

export const BEST_PRICES = gql`
    query BestPrices($productIds: [String!]) {
        bestPrices(productIds: $productIds) {
            sku
            bestPriceBonus {
                name
                percent
                value {
                    fractionDigits
                    currencyCode
                    centAmount
                }
            }
            value {
                centAmount
                currencyCode
                fractionDigits
            }
        }
    }
`;
