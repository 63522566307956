import type { IconProps } from '@ab-core/icon';
import { Icon } from '@ab-core/icon';
import Text, { FontTypes } from '@ab-core/text';
import type { FC } from 'react';
import React from 'react';
import { FooterElementWrapper } from './index.styled';

type FooterElementType = {
    iconName: IconProps['name'];
    onClick: () => void;
    content: string;
};

export const FooterElement: FC<FooterElementType> = ({ iconName, onClick, content }) => (
    <FooterElementWrapper onClick={onClick}>
        <Icon name={iconName} />
        <Text type={FontTypes.Caption} content={content} />
    </FooterElementWrapper>
);
