import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Warning: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5215 2.28612C10.4083 2.10797 10.2115 2 10 2C9.78848 2 9.59168 2.10797 9.47851 2.28612L0.0957911 17.0554C-0.0246268 17.2449 -0.0320224 17.4848 0.0764907 17.6813C0.185004 17.8779 0.392226 18 0.617284 18H19.3827C19.6078 18 19.815 17.8779 19.9235 17.6813C20.032 17.4848 20.0246 17.2449 19.9042 17.0554L10.5215 2.28612ZM10 3.76552L18.2611 16.7692H1.7389L10 3.76552ZM9.99997 7.9077C10.3409 7.9077 10.6173 8.18322 10.6173 8.52308V12.4615C10.6173 12.8014 10.3409 13.0769 9.99997 13.0769C9.65906 13.0769 9.38269 12.8014 9.38269 12.4615V8.52308C9.38269 8.18322 9.65906 7.9077 9.99997 7.9077ZM10.7415 14.4308C10.7415 14.8386 10.4098 15.1692 10.0007 15.1692C9.59164 15.1692 9.26 14.8386 9.26 14.4308C9.26 14.0229 9.59164 13.6923 10.0007 13.6923C10.4098 13.6923 10.7415 14.0229 10.7415 14.4308Z"
                fill={color}
            />
        </SVG>
    );
};
