import type { Filter } from '@ab-core/graphql/dist';

export const defaultFilter: Filter = {
    available: false,
    tecselectBonus: false,
    abakusPlusBonus: false,
    abakusBonus: false,
    promotion: false,
    lastPurchased: false
};
