import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const ErrorIcon: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM13.1817 6.55803C13.4258 6.80211 13.4258 7.19784 13.1817 7.44191L10.6236 9.99997L13.1817 12.5581C13.4258 12.8021 13.4258 13.1979 13.1817 13.4419C12.9376 13.686 12.5419 13.686 12.2978 13.4419L9.73975 10.8839L7.18169 13.4419C6.93761 13.686 6.54188 13.686 6.2978 13.4419C6.05373 13.1978 6.05373 12.8021 6.2978 12.558L8.85586 9.99997L6.29783 7.44194C6.05375 7.19786 6.05375 6.80214 6.29783 6.55806C6.54191 6.31398 6.93764 6.31398 7.18172 6.55806L9.73975 9.11609L12.2978 6.55803C12.5419 6.31395 12.9376 6.31395 13.1817 6.55803Z"
                fill={color}
            />
        </SVG>
    );
};
