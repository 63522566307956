import type { Filter } from '@ab-core/graphql/dist';
import { navigate } from 'gatsby';
import { ROUTES } from '../routes';
import { defaultFilter } from './defaultFilter';
import { getParam, getParsedFilterFromParam, getStringValueFromParam } from './getParamFilter';

type UpdateUrlParameters = {
    updatedFilter: string;
    updatedSupplierFilter: string;
    updatedCategoryFilter: string;
    updatedProductAttributesFilter: string;
    updatedSort?: string;
    updatedEtim?: string;
    updatedPage?: number;
    searchValue?: string;
    forceSetUrl?: boolean;
};

const setUrl = ({
    updatedFilter,
    updatedSupplierFilter,
    updatedCategoryFilter,
    updatedProductAttributesFilter,
    updatedSort,
    updatedEtim,
    searchValue,
    forceSetUrl = false
}: UpdateUrlParameters) => {
    const isSearchPage = window?.location.toString().includes(ROUTES.SEARCH);
    const updatedSearch = typeof searchValue === 'string' ? searchValue : getParam('search');
    const encodedValue = encodeURIComponent(updatedSearch);
    const encodedFilter = encodeURIComponent(updatedFilter.replaceAll('=true', ''));
    const encodedSupplierName = encodeURIComponent(updatedSupplierFilter.replaceAll('=true', ''));
    const encodedProductAttributes = encodeURIComponent(updatedProductAttributesFilter.replaceAll('=true', ''));
    const encodedCategory = encodeURIComponent(updatedCategoryFilter.replaceAll('=true', ''));
    const encodedEtim = encodeURIComponent(updatedEtim || '') || '';

    let parameters = `?q=${encodedValue || ''}`;

    if (updatedFilter && (isSearchPage || forceSetUrl)) {
        parameters += `&filter=${encodedFilter}`;
    }

    if (updatedSupplierFilter) {
        parameters += `&supplierName=${encodedSupplierName}`;
    }

    if (updatedCategoryFilter) {
        parameters += `&categories=${encodedCategory}`;
    }

    if (updatedSort) {
        parameters += `&sort=${updatedSort}`;
    }

    if (updatedEtim) {
        parameters += `&etim=${encodedEtim}`;
    }

    if (encodedProductAttributes) {
        parameters += `&productAttributes=${encodedProductAttributes}`;
    }

    navigate(`${ROUTES.SEARCH}${parameters}`);
};

const filterObjectToString = (object: Filter): string =>
    Object.entries(object)
        .filter((item) => item[1])
        .map((element) => `${element[0]}=${element[1]}`)
        .join(';');

export const resetFilter = (resetSearchValue = false) => {
    if (resetSearchValue) {
        setUrl({
            updatedFilter: filterObjectToString(defaultFilter),
            updatedSupplierFilter: '',
            updatedCategoryFilter: '',
            updatedProductAttributesFilter: '',
            updatedSort: '',
            searchValue: ''
        });
    } else {
        setUrl({
            updatedFilter: filterObjectToString(defaultFilter),
            updatedSupplierFilter: '',
            updatedCategoryFilter: '',
            updatedProductAttributesFilter: '',
            updatedSort: ''
        });
    }
};

type FilterObject = {
    updatedFilter: string;
    updatedSupplierFilter: string;
    updatedCategoryFilter: string;
    updatedProductAttributesFilter: string;
    updatedSort: string;
    updatedEtim: string;
};
const getFilterObjectFromParam = (): FilterObject => {
    const parsedFilter = getParsedFilterFromParam('filter');
    const parsedSupplierFilter = getParsedFilterFromParam('supplierName');
    const parsedCategoriesFilter = getParsedFilterFromParam('categories');
    const parsedProductAttributesFilter = getParsedFilterFromParam('productAttributes');
    const parsedSortFilter = getParam('sort');
    const parsedEtimFilter = getStringValueFromParam('etim');

    return {
        updatedFilter: filterObjectToString(parsedFilter),
        updatedSupplierFilter: filterObjectToString(parsedSupplierFilter),
        updatedCategoryFilter: filterObjectToString(parsedCategoriesFilter),
        updatedSort: parsedSortFilter,
        updatedEtim: parsedEtimFilter,
        updatedProductAttributesFilter: filterObjectToString(parsedProductAttributesFilter)
    };
};

export const handlePage = (lastPage: number, pageNumber = 1) => {
    setUrl({
        ...getFilterObjectFromParam(),
        updatedPage: pageNumber < lastPage ? pageNumber : lastPage
    });
};

export const handleSearchValue = (value: string) => {
    if (!value) {
        setUrl({
            ...getFilterObjectFromParam(),
            searchValue: value
        });
    } else {
        setUrl({
            updatedFilter: '',
            updatedSupplierFilter: '',
            updatedCategoryFilter: '',
            updatedProductAttributesFilter: '',
            searchValue: value
        });
    }
};

export const handleFilter = (name: string, value: boolean | string, forceSetUrl = false, clearValue = false) => {
    const parsedFilter = getParsedFilterFromParam('filter');

    if (clearValue) {
        setUrl({
            ...getFilterObjectFromParam(),
            forceSetUrl,
            searchValue: '',
            updatedFilter: filterObjectToString({ ...parsedFilter, ...{ [name]: value } })
        });
    } else {
        setUrl({
            ...getFilterObjectFromParam(),
            forceSetUrl,
            updatedFilter: filterObjectToString({ ...parsedFilter, ...{ [name]: value } })
        });
    }
};
