import { BREAKPOINT } from '@ab-core/breakpoints';
import { getClassNames } from '@ab-core/functions/styles/classNameFormatter';
import { useIsMobile } from '@ab-core/functions/useIsMobile';
import Headline, { HEADLINE_SIZES } from '@ab-core/headline';
import { Loader } from '@ab-core/loader';
import { getTestIdProp } from '@ab-core/testing';
import type { FC } from 'react';
import React from 'react';
import { Card, CardContent, CardHeader, CardListWrapper, List } from './index.styled';
import { CARD_STYLE_TYPE, type CardType } from './types';

export const CardList: FC<CardType> = (props) => {
    const {
        head,
        content,
        active = false,
        loading = false,
        pointer = false,
        className,
        styleType = CARD_STYLE_TYPE.CARD,
        ...rest
    } = props;
    const newClassNames = getClassNames({ className, prefix: 'card', modifier: { styleType, active, pointer } });
    const isMobile = useIsMobile(BREAKPOINT.SM);

    return (
        <Card className={newClassNames} {...rest}>
            {loading && <Loader />}
            <CardListWrapper>
                <CardHeader>
                    <Headline
                        size={isMobile ? HEADLINE_SIZES.SIX : HEADLINE_SIZES.FOUR}
                        {...getTestIdProp('card-head')}
                    >
                        {head}
                    </Headline>
                </CardHeader>
                {content && (
                    <CardContent className="ab-card-list">
                        <List>{content}</List>
                    </CardContent>
                )}
            </CardListWrapper>
        </Card>
    );
};
