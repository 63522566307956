import { StyledTable, TableContainer } from '@ab-core/table/styled';
import type { WithChildren } from '@ab-core/utils/types';
import type { FC } from 'react';
import React from 'react';

type MarkdownTableProps = WithChildren;

export const MarkdownTable: FC<MarkdownTableProps> = ({ children }) => (
    <TableContainer>
        <StyledTable>{children}</StyledTable>
    </TableContainer>
);
