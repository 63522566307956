import { useGetSearchQueryString } from '@ab-core-functions/search';
import { BREAKPOINT } from '@ab-core/breakpoints';
import { useIsMobile } from '@ab-core/functions';
import type { PageRendererProps } from 'gatsby';
import type { FC } from 'react';
import React from 'react';
import { Desktop } from './desktop/desktop';
import { HeaderNavigation } from './index.styled';
import { Mobile } from './mobile/mobile';

export const Header: FC<PageRendererProps> = (props) => {
    const { location } = props;
    const isMobile = useIsMobile(BREAKPOINT.LG);
    const params = new URLSearchParams(location.search);
    const queryString = useGetSearchQueryString();
    const searchString = params.get(queryString) || '';

    return (
        <HeaderNavigation>
            {isMobile ? <Mobile searchString={searchString} /> : <Desktop searchString={searchString} />}
        </HeaderNavigation>
    );
};
