import useText from '@ab-core//localization/src/useText';
import Button, { ICON_POSITION } from '@ab-core/button';
import Spacer from '@ab-core/spacer';
import Text from '@ab-core/text';
import { navigate } from 'gatsby';
import type { FC } from 'react';
import React from 'react';
import { ErrorContentWrapper, LinkWrapper, TextWrapper } from './styled';

type errorContentType = {
    message?: string;
    code?: string;
};

export const ErrorContent: FC<errorContentType> = (props) => {
    const { message, code } = props;

    const hotlineEmail = useText('cart.hotlineEmail');
    const hotlineNumber = useText('cart.hotlineNumber');
    const body1 = useText('errorFallbackCard.body1');
    const body2 = useText('errorFallbackCard.body2');
    const contactCall = useText('errorFallbackCard.contactCall');

    const goBackToLastPage = () => {
        navigate(-1);
        setTimeout(() => {
            location.reload();
        }, 300);
    };

    return (
        <ErrorContentWrapper>
            <TextWrapper>
                <Text content={body1} />
                <Text content={body2} />
            </TextWrapper>
            <Spacer size={'xs'} />
            <TextWrapper>
                <Text content={`Code: ${code}`} />
                <Text content={`Fehlermeldung: ${message}`} />
            </TextWrapper>
            <Spacer size={'xs'} />
            <Text content={contactCall} />
            <TextWrapper>
                <Text content={hotlineNumber} />
                <LinkWrapper>
                    <Text
                        content={hotlineEmail}
                        isLink
                        onClick={() => {
                            window.location.href = `mailto:${hotlineEmail}`;
                        }}
                    />
                </LinkWrapper>
            </TextWrapper>
            <Spacer size="xs" />
            <div className="flex-justify-content-end">
                <Button iconName="ArrowLeft" iconPosition={ICON_POSITION.LEFT} onClick={goBackToLastPage}>
                    Zurück zur letzten Seite
                </Button>
            </div>
        </ErrorContentWrapper>
    );
};
