import { PermissionWrapper, ROUTES, formatNumber } from '@ab-core/functions';
import { useAuth } from '@ab-core/hooks';
import { navigate } from 'gatsby';
import type { FC } from 'react';
import React from 'react';
import { PointElement } from './pointElement';
import { PointsOverviewWrapper } from './styled';

type PointsOverviewProps = React.ComponentPropsWithoutRef<'div'>;

export const PointsOverview: FC<PointsOverviewProps> = (props) => {
    const { ...rest } = props;
    const { user } = useAuth(false);

    const onCreditClick = () => {
        navigate(ROUTES.CREDIT_INFO);
    };

    return (
        <PointsOverviewWrapper {...rest}>
            <PermissionWrapper permission="creditsSee">
                <div className="pointer" onClick={onCreditClick}>
                    <PointElement iconName="Credits">{formatNumber(user?.availableCredits)}</PointElement>
                </div>
            </PermissionWrapper>
            <PermissionWrapper permission="canSeeTecselect">
                <PointElement iconName="Tecselect">{formatNumber(user?.tecselectPoints)}</PointElement>
            </PermissionWrapper>
        </PointsOverviewWrapper>
    );
};
