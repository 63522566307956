import { useCreateOrUpdateFavoriteLinks, useGetFavoriteLinks } from '@ab-core/hooks-v2';
import type { IconProps } from '@ab-core/icon';
import { useEffect, useState } from 'react';
import { routeNameList } from './routeNames';
import type { FavoriteItemType } from './types';

export const useGetFavoriteList = (): Array<FavoriteItemType> => {
    const { favoriteLinks, loading } = useGetFavoriteLinks();
    const [list, setList] = useState<Array<FavoriteItemType>>([]);

    const iconNameMapper = (name?: string): IconProps['name'] => {
        if (!name) {
            return 'Bookmarks';
        }

        return `${name[0].toUpperCase()}${name.substring(1)}` as IconProps['name'];
    };

    useEffect(() => {
        if (loading) {
            return;
        }

        if (!favoriteLinks) {
            return;
        }

        if (favoriteLinks.length === 0) {
            return;
        }

        const mappedList: Array<FavoriteItemType> = favoriteLinks.map((link) => ({
            name: link.name,
            url: link.url,
            icon: iconNameMapper(link.icon)
        }));

        setList(mappedList);
    }, [favoriteLinks, loading]);

    return list;
};

export const useSaveFavoriteList = (list: Array<FavoriteItemType>) => {
    const { createOrUpdateFavoriteLinks } = useCreateOrUpdateFavoriteLinks({ favoriteLinks: list });

    return { saveFavoriteList: createOrUpdateFavoriteLinks };
};

const isUrlInFavoriteList = (list: Array<FavoriteItemType>, url: FavoriteItemType['url']): boolean => {
    return !!getFavoriteItemByUrl(list, url);
};

const getFavoriteItemByUrl = (list: Array<FavoriteItemType>, url: FavoriteItemType['url']) => {
    return list.find((item) => item.url === url);
};

const addFavoriteToList = (list: Array<FavoriteItemType>, item: FavoriteItemType): Array<FavoriteItemType> => {
    list.push(item);

    return list;
};

export const removeFavoriteFromListByUrl = (
    list: Array<FavoriteItemType>,
    url: FavoriteItemType['url']
): Array<FavoriteItemType> => {
    const index = list.findIndex((item) => item.url === url);

    if (index > -1) {
        list.splice(index, 1);
    }

    return list;
};

const toggleItemInList = (list: Array<FavoriteItemType>, item: FavoriteItemType) => {
    if (isUrlInFavoriteList(list, item.url)) {
        return removeFavoriteFromListByUrl(list, item.url);
    }

    return addFavoriteToList(list, item);
};

export const useFavoriteList = () => {
    const favoriteList = useGetFavoriteList();
    const [list, setList] = useState(favoriteList);
    const [currentSiteItem, setCurrentSiteItem] = useState(getFavoriteItemForCurrentSite());
    const [itemIsInList, setItemIsInList] = useState(isUrlInFavoriteList(list, currentSiteItem.url));
    const { saveFavoriteList } = useSaveFavoriteList(list);

    useEffect(() => {
        setList(favoriteList);
    }, [favoriteList]);

    const toggleItem = (toggledItem?: FavoriteItemType) => {
        const favItem = toggledItem || currentSiteItem;
        const newList = toggleItemInList([...list], favItem);
        saveFavoriteList({ variables: { data: { favoriteLinks: newList } } });
        setList(newList);
    };

    useEffect(() => {
        if (!itemIsInList) {
            setCurrentSiteItem(getFavoriteItemForCurrentSite());

            return;
        }

        const newItem = getFavoriteItemByUrl(list, currentSiteItem.url);

        if (!newItem) {
            return;
        }

        setCurrentSiteItem(newItem);
    }, [itemIsInList]);

    useEffect(() => {
        setItemIsInList(isUrlInFavoriteList(list, currentSiteItem.url));
    }, [list]);

    return { toggleItem, itemIsInList, currentSiteItem };
};

const getFavoriteItemForCurrentSite = (): FavoriteItemType => {
    if (typeof window === 'undefined') {
        return {
            name: 'Neuer Favorit',
            url: '/'
        };
    }

    const path = window.location.pathname.replace('de-de/', '');

    const name = path;
    const url = path + window.location.search;

    const routeNameItem = routeNameList.find((routeName) => url.includes(routeName.route));

    if (!routeNameItem) {
        return { name, url };
    }

    return { name: routeNameItem.name, icon: routeNameItem.icon, url };
};
