import { useEffect, useState } from 'react';

const useMediaQuery = (query: string): boolean | undefined => {
    const [matches, setMatches] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        const media = window.matchMedia(query);

        if (media.matches !== matches) {
            setMatches(media.matches);
        }

        const listener = () => {
            setMatches(media.matches);
        };
        try {
            media.addEventListener('change', listener);
        } catch (e) {
            // Fallback for older iOS Safari verions
            media.addListener(listener); // NOSONAR
        }

        return () => {
            try {
                media.removeEventListener('change', listener);
            } catch (e) {
                // Fallback for older iOS Safari verions
                media.removeListener(listener); // NOSONAR
            }
        };
    }, [matches, query]);

    return matches;
};

export default useMediaQuery;
