import { Colors } from '@ab-core/colors';
import type { IconProps } from '@ab-core/icon';
import { Icon } from '@ab-core/icon';
import Text from '@ab-core/text';
import React from 'react';
import { ElementWrapper } from './index.styled';

type ElementContainerType = {
    iconName?: IconProps['name'];
    content?: string;
    backgroundColor?: string;
    suffixIconName?: IconProps['name'];
    onClick: () => void;
    fontColor?: string;
};

export const ElementContainer = React.forwardRef<HTMLDivElement, ElementContainerType>((props, ref) => {
    const { iconName, content, backgroundColor, onClick, suffixIconName, fontColor = 'black', ...rest } = props;
    const background = {
        backgroundColor: backgroundColor ? `${Colors[backgroundColor as keyof typeof Colors]}` : `${Colors.white}`
    };

    return (
        <ElementWrapper style={background} onClick={onClick} ref={ref} {...rest}>
            {iconName && <Icon color={fontColor as keyof typeof Colors} name={iconName} />}
            {content && (
                <Text color={fontColor} className="text-nowrap">
                    {content}
                </Text>
            )}
            {suffixIconName && <Icon color={fontColor as keyof typeof Colors} name={suffixIconName} />}
        </ElementWrapper>
    );
});
