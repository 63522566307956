import { BreakpointsMinQuery } from '@ab-core/breakpoints';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import type { ContainerProps } from './index';

type ABContainerProps = {
    order?: string;
    noPadding?: boolean;
} & ContainerProps;

export const ABContainer = styled(Container)<ABContainerProps>`
    width: 100%;
    max-width: 9999px;
    ${({ noPadding }) => !noPadding && `padding-left: 20px;`};
    ${({ noPadding }) => !noPadding && `padding-right: 20px;`};
    @media ${BreakpointsMinQuery.sm} {
        ${({ noPadding }) => !noPadding && `padding-left: 40px;`};
        ${({ noPadding }) => !noPadding && `padding-right: 40px;`};
    }
    [data-grid='container'] {
        padding-left: 0;
        padding-right: 0;
    }
`;

export const ABRow = styled(Row)`
    margin: 0 -10px;
`;

export const ABCol = styled(Col)`
    ${ABContainer}, [data-grid="container"] {
        padding-left: 0;
        padding-right: 0;
    }
`;
