import { gql } from '@apollo/client';
import { FRAGMENTS } from '../../_fragments';

export const UPDATE_OFFER_POSITIONS = gql`
    mutation UpdateOfferPositions($positionsInput: [OfferPositionUpdateInput!]!, $offerId: String!) {
        updateOfferPositions(positionsInput: $positionsInput, offerId: $offerId) {
            ...OfferDetailFields
        }
    }
    ${FRAGMENTS.OFFER_DETAIL_FIELDS}
`;
