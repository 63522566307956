import { Colors } from '@ab-core/colors';
import { RADIUS } from '@ab-core/radius';
import styled from 'styled-components';

export const SearchContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Barrier = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 145px);
    margin: 0;
    width: 100%;
    ::-webkit-scrollbar {
        width: 2px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px white;
        border-radius: ${RADIUS.MINIMAL};
    }

    ::-webkit-scrollbar-thumb {
        background: ${Colors.primary};
        border-radius: ${RADIUS.MINIMAL};
    }
`;
