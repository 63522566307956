import type { StorageType } from '@ab-core/availabilities';
import type { ShippingAddress } from '@ab-core/graphql/dist';
import { makeVar, useReactiveVar } from '@apollo/client';
import type { CartStoreType, StateType } from './types';

const _state: StateType = {
    activeCartInProgress: makeVar<boolean>(false),
    selectedProjectNumber: makeVar<string | undefined>(undefined),
    projectChangePending: makeVar<boolean>(false),
    selectedProjectAddress: makeVar<ShippingAddress | undefined>(undefined),
    showCreditsOrCoupons: makeVar<boolean>(true),
    email: makeVar<string>(''),
    cartButtonsDisabled: makeVar<boolean>(false),
    lineItemDeleted: makeVar<boolean>(false),
    storage: makeVar<Array<StorageType> | undefined>(undefined)
};

export const cartStore: CartStoreType = {
    getters: {
        useActiveCartInProgress: () => useReactiveVar(_state.activeCartInProgress),
        useSelectedProjectNumber: () => useReactiveVar(_state.selectedProjectNumber),
        useProjectChangePending: () => useReactiveVar(_state.projectChangePending),
        useSelectedProjectAddress: () => useReactiveVar(_state.selectedProjectAddress),
        useShowCreditsOrCoupons: () => useReactiveVar(_state.showCreditsOrCoupons),
        useEmail: () => useReactiveVar(_state.email),
        useCartButtonDisabled: () => useReactiveVar(_state.cartButtonsDisabled),
        useLineItemDeleted: () => useReactiveVar(_state.lineItemDeleted),
        useStorage: () => useReactiveVar(_state.storage)
    },
    setters: {
        setActiveCartInProgress: (inProgress) => _state.activeCartInProgress(inProgress),
        setSelectedProjectNumber: (number) => _state.selectedProjectNumber(number),
        setProjectChangePending: (pending) => _state.projectChangePending(pending),
        setSelectedProjectAddress: (address) => _state.selectedProjectAddress(address),
        setShowCreditsOrCoupons: (show) => _state.showCreditsOrCoupons(show),
        setEmail: (email) => _state.email(email),
        setCartButtonDisabled: (disabled) => _state.cartButtonsDisabled(disabled),
        setLineItemDeleted: (lineItemDeleted) => _state.lineItemDeleted(lineItemDeleted),
        setStorage: (storage) => _state.storage(storage)
    }
};
