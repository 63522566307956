import { gql } from '@apollo/client';

export const GET_ALL_SHIPPING_INFOS = gql`
    query getAllShippingInfos($offerNumber: String!, $isNotOffer: Boolean!) {
        pickupChannels {
            name
            primaryChannel
            id
            key
            address {
                id
                externalId
                primaryAddress
                name
                streetName
                city
                postalCode
                country
                addressExtraLineOne
                addressExtraLineTwo
                addressExtraLineThree
                defaultShippingInstructions
            }
        }
        getCustomerData {
            activeAccount {
                shippingAddresses {
                    id
                    externalId
                    primaryAddress
                    name
                    streetName
                    city
                    postalCode
                    country
                    addressExtraLineOne
                    addressExtraLineTwo
                    addressExtraLineThree
                    defaultShippingInstructions
                }
            }
        }
        offer(offerNumber: $offerNumber) @skip(if: $isNotOffer) {
            id
            offerNumber
            orderType
            offerDate
            offerText
            shippingAddress {
                id
                externalId
                primaryAddress
                name
                streetName
                city
                postalCode
                country
                addressExtraLineOne
                addressExtraLineTwo
                addressExtraLineThree
                defaultShippingInstructions
            }
            shippingInfo {
                shippingMethod {
                    id
                    key
                }
            }
            shippingInstructions
            displayShippingMethod
            offerReferenceShippingAddress {
                id
                externalId
                primaryAddress
                name
                streetName
                city
                postalCode
                country
                addressExtraLineOne
                addressExtraLineTwo
                addressExtraLineThree
                defaultShippingInstructions
            }
        }
    }
`;
