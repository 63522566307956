import { BREAKPOINT } from '@ab-core/breakpoints';
import { useEffect, useState } from 'react';

type Query = {
    breakPoint: BREAKPOINT;
    query: string;
};
type MediaQuery = {
    breakPoint: BREAKPOINT;
    query: string;
    media: MediaQueryList;
};
const queries: Query[] = [
    {
        breakPoint: BREAKPOINT.XS,
        query: `(max-width: ${BREAKPOINT.SM - 1}px)`
    },
    {
        breakPoint: BREAKPOINT.SM,
        query: `(min-width: ${BREAKPOINT.SM}px) and (max-width: ${BREAKPOINT.MD - 1}px)`
    },
    {
        breakPoint: BREAKPOINT.MD,
        query: `(min-width: ${BREAKPOINT.MD}px) and (max-width: ${BREAKPOINT.LG - 1}px)`
    },
    {
        breakPoint: BREAKPOINT.LG,
        query: `(min-width: ${BREAKPOINT.LG}px) and (max-width: ${BREAKPOINT.XL - 1}px)`
    },
    {
        breakPoint: BREAKPOINT.XL,
        query: `(min-width: ${BREAKPOINT.XL}px) and (max-width: ${BREAKPOINT.XXL - 1}px)`
    },
    {
        breakPoint: BREAKPOINT.XXL,
        query: `(min-width: ${BREAKPOINT.XXL}px)`
    }
];

const useBreakpoint = (): BREAKPOINT | undefined => {
    const [matches, setMatches] = useState<MediaQuery | undefined>(undefined);
    const listener = (event?: MediaQueryListEvent, mediaQuery?: MediaQuery) => {
        if (event?.matches) {
            setMatches(mediaQuery);
        }
    };
    const addListeners = (mediaQueryLists: MediaQuery[]) => {
        mediaQueryLists.forEach((mediaQueryList) => {
            if (mediaQueryList.media.matches) {
                setMatches(mediaQueryList);
            }

            try {
                mediaQueryList.media.addEventListener('change', (event) => listener(event, mediaQueryList));
            } catch {
                // Fallback for older iOS Safari verions
                mediaQueryList.media.addListener((event) => listener(event, mediaQueryList)); // NOSONAR
            }
        });
    };
    useEffect(() => {
        const mediaQueryLists = queries.map((mediaQuery: Query) => ({
            ...mediaQuery,
            media: matchMedia(mediaQuery.query)
        }));
        addListeners(mediaQueryLists);

        return () => {
            mediaQueryLists.forEach((mediaQueryList) => {
                try {
                    mediaQueryList.media.removeEventListener('change', listener);
                } catch (e) {
                    // Fallback for older iOS Safari verions
                    mediaQueryList.media.removeListener(listener); // NOSONAR
                }
            });
        };
    }, []);

    return matches?.breakPoint;
};

export default useBreakpoint;
