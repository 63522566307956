import { useIsFeatureVisible } from '@ab-core/functions';
import { Link, LINK_VARIANT } from '@ab-core/link';
import { getTestIdProp } from '@ab-core/testing';
import { getTutorialIdProp } from '@ab-core/tutorials';
import type { FC } from 'react';
import React from 'react';
import { SingleNavigationElement } from '../navigationElements';
import type { ContentItem } from '../types';

type NavElementProps = {
    content: Array<ContentItem>;
    pathName: string;
    onClose?: () => void;
};

export const NavigationElement: FC<NavElementProps> = (props) => {
    const { content, pathName, onClose } = props;

    const { isFeatureVisible } = useIsFeatureVisible();

    return (
        <>
            {content
                .filter((item) => (item.featureName ? isFeatureVisible(item.featureName) : true))
                .map((item, index) => (
                    <div key={index} {...getTutorialIdProp(`menu-${item.title}`)}>
                        <Link
                            variant={LINK_VARIANT.LINK_ONLY}
                            to={item.link}
                            target={item.target}
                            onClick={onClose ? onClose : undefined}
                            {...getTestIdProp(item.cypressId)}
                        >
                            <SingleNavigationElement {...item} pathName={pathName} />
                        </Link>
                    </div>
                ))}
        </>
    );
};
