import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const DateIcon: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.69136 0.617284C4.69136 0.276367 4.41499 0 4.07407 0C3.73316 0 3.45679 0.276367 3.45679 0.617284V1.97531H2.59259C1.16074 1.97531 0 3.13605 0 4.5679V17.4074C0 18.8393 1.16074 20 2.59259 20H17.4074C18.8393 20 20 18.8393 20 17.4074V4.5679C20 3.13605 18.8393 1.97531 17.4074 1.97531H16.5432V0.617284C16.5432 0.276367 16.2668 0 15.9259 0C15.585 0 15.3086 0.276367 15.3086 0.617284V1.97531H4.69136V0.617284ZM15.3086 5.55556V3.20988H4.69136V5.55556C4.69136 5.89647 4.41499 6.17284 4.07407 6.17284C3.73316 6.17284 3.45679 5.89647 3.45679 5.55556V3.20988H2.59259C1.84258 3.20988 1.23457 3.81788 1.23457 4.5679V8.39506H18.7654V4.5679C18.7654 3.81788 18.1574 3.20988 17.4074 3.20988H16.5432V5.55556C16.5432 5.89647 16.2668 6.17284 15.9259 6.17284C15.585 6.17284 15.3086 5.89647 15.3086 5.55556ZM18.7654 9.62963H1.23457V17.4074C1.23457 18.1574 1.84258 18.7654 2.59259 18.7654H17.4074C18.1574 18.7654 18.7654 18.1574 18.7654 17.4074V9.62963Z"
                fill={color}
            />
        </SVG>
    );
};
