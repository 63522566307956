import type { SuggestResultV2 } from '@ab-core/graphql/dist';
import { Col, Row } from '@ab-core/grid';
import { HorizontalLine } from '@ab-core/horizontalline';
import Text, { FontTypes } from '@ab-core/text';
import React from 'react';
import { ItemSuggestion } from './itemSuggestion';
import { SearchSuggestion } from './searchSuggestion';
import { Barrier, SearchContainer } from './styled';

export type SearchSuggestionsProps = {
    searchSuggestions?: Array<SuggestResultV2>;
    itemSuggestion?: Array<SuggestResultV2>;
    activeSuggest?: number;
    selectSuggestion: (suggestion: SuggestResultV2) => void;
};

export const SearchSuggestions = React.forwardRef<HTMLDivElement, SearchSuggestionsProps>((props, ref) => {
    const { searchSuggestions, itemSuggestion, activeSuggest, selectSuggestion } = props;
    const hasSearchSuggestions = searchSuggestions?.length !== 0;
    const hasItemSuggestion = itemSuggestion?.length !== 0;

    const searchSuggestionsCol = hasItemSuggestion ? 4 : 12;
    const itemSuggestionCol = hasSearchSuggestions ? 8 : 12;

    return (
        <SearchContainer ref={ref}>
            <Barrier>
                <Row className="mx-small">
                    {hasSearchSuggestions && (
                        <Col md={searchSuggestionsCol}>
                            <Text type={FontTypes.Overline}>Suchbegriffe</Text>
                            <HorizontalLine className="pb-small" />
                            {searchSuggestions?.map((suggestion, i) => (
                                <SearchSuggestion
                                    key={i}
                                    active={i === activeSuggest}
                                    tabIndex={i}
                                    onClick={() => selectSuggestion(suggestion)}
                                    suggestion={suggestion}
                                />
                            ))}
                        </Col>
                    )}
                    {hasItemSuggestion && (
                        <Col md={itemSuggestionCol}>
                            <Text type={FontTypes.Overline}>Vorschläge</Text>
                            <HorizontalLine className="pb-small" />
                            <Row>
                                {itemSuggestion?.map((suggestion) => (
                                    <Col xs={6} col={4} noGutter key={suggestion.name}>
                                        <ItemSuggestion
                                            onClick={() => selectSuggestion(suggestion)}
                                            suggestion={suggestion}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    )}
                </Row>
            </Barrier>
        </SearchContainer>
    );
});
