import styled from 'styled-components';

export const StyledTableDataItem = styled.td`
    padding: 0.5rem;
    vertical-align: top;
`;

export const MobileLabel = styled.div`
    display: none;
`;

export const TableDataContent = styled.div`
    height: 100%;
    grid-column-start: 2;
`;
