import type { FC } from 'react';
import React from 'react';
import type { IconTagProps } from '../types';
import { SVG } from '../styled';

export const Abakus: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM10 3.74998C10.2367 3.74998 10.4531 3.88373 10.559 4.09547L15.559 14.0955C15.7134 14.4042 15.5882 14.7796 15.2795 14.934C14.9708 15.0884 14.5954 14.9632 14.441 14.6545L13.0512 11.875H6.94876L5.55902 14.6545C5.40465 14.9632 5.02923 15.0884 4.72049 14.934C4.41175 14.7796 4.28661 14.4042 4.44098 14.0955L9.44098 4.09547C9.54685 3.88373 9.76327 3.74998 10 3.74998ZM7.57376 10.625H12.4262L10 5.77252L7.57376 10.625Z"
                fill={color}
            />
        </SVG>
    );
};
