import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Grid: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 12.4691C0 11.5828 0.718555 10.8642 1.60494 10.8642H7.53086C8.41725 10.8642 9.1358 11.5828 9.1358 12.4691V18.3951C9.1358 19.2814 8.41725 20 7.53086 20H1.60494C0.718555 20 0 19.2814 0 18.3951V12.4691ZM1.60494 12.0988C1.40039 12.0988 1.23457 12.2646 1.23457 12.4691V18.3951C1.23457 18.5996 1.40039 18.7654 1.60494 18.7654H7.53086C7.73541 18.7654 7.90123 18.5996 7.90123 18.3951V12.4691C7.90123 12.2646 7.73541 12.0988 7.53086 12.0988H1.60494Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8642 12.4691C10.8642 11.5828 11.5828 10.8642 12.4691 10.8642H18.3951C19.2814 10.8642 20 11.5828 20 12.4691V18.3951C20 19.2814 19.2814 20 18.3951 20H12.4691C11.5828 20 10.8642 19.2814 10.8642 18.3951V12.4691ZM12.4691 12.0988C12.2646 12.0988 12.0988 12.2646 12.0988 12.4691V18.3951C12.0988 18.5996 12.2646 18.7654 12.4691 18.7654H18.3951C18.5996 18.7654 18.7654 18.5996 18.7654 18.3951V12.4691C18.7654 12.2646 18.5996 12.0988 18.3951 12.0988H12.4691Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8642 1.60494C10.8642 0.718555 11.5828 0 12.4691 0H18.3951C19.2814 0 20 0.718555 20 1.60494V7.53086C20 8.41725 19.2814 9.1358 18.3951 9.1358H12.4691C11.5828 9.1358 10.8642 8.41725 10.8642 7.53086V1.60494ZM12.4691 1.23457C12.2646 1.23457 12.0988 1.40039 12.0988 1.60494V7.53086C12.0988 7.73541 12.2646 7.90123 12.4691 7.90123H18.3951C18.5996 7.90123 18.7654 7.73541 18.7654 7.53086V1.60494C18.7654 1.40039 18.5996 1.23457 18.3951 1.23457H12.4691Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 1.60494C0 0.718555 0.718555 0 1.60494 0H7.53086C8.41725 0 9.1358 0.718555 9.1358 1.60494V7.53086C9.1358 8.41725 8.41725 9.1358 7.53086 9.1358H1.60494C0.718555 9.1358 0 8.41725 0 7.53086V1.60494ZM1.60494 1.23457C1.40039 1.23457 1.23457 1.40039 1.23457 1.60494V7.53086C1.23457 7.73541 1.40039 7.90123 1.60494 7.90123H7.53086C7.73541 7.90123 7.90123 7.73541 7.90123 7.53086V1.60494C7.90123 1.40039 7.73541 1.23457 7.53086 1.23457H1.60494Z"
                fill={color}
            />
        </SVG>
    );
};
