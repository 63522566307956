import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const DiscountFilled: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.155029" width="20" height="20" rx="10" fill={color} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.00003 6.7588C4.00003 5.32077 5.16579 4.15503 6.60382 4.15503C8.04185 4.15503 9.20761 5.32077 9.20761 6.7588C9.20761 8.19683 8.04185 9.36258 6.60382 9.36258C5.16579 9.36258 4.00003 8.19683 4.00003 6.7588ZM6.60382 5.2871C5.79101 5.2871 5.13211 5.94601 5.13211 6.7588C5.13211 7.5716 5.79101 8.2305 6.60382 8.2305C7.41663 8.2305 8.07553 7.5716 8.07553 6.7588C8.07553 5.94601 7.41663 5.2871 6.60382 5.2871ZM14.4758 5.67931C14.6968 5.90036 14.6968 6.25876 14.4758 6.47981L6.3248 14.6308C6.10375 14.8518 5.74535 14.8518 5.5243 14.6307C5.30325 14.4097 5.30325 14.0513 5.5243 13.8303L13.6753 5.67931C13.8963 5.45826 14.2547 5.45826 14.4758 5.67931ZM10.7924 13.5513C10.7924 12.1132 11.9582 10.9475 13.3962 10.9475C14.8342 10.9475 16 12.1132 16 13.5513C16 14.9893 14.8342 16.155 13.3962 16.155C11.9582 16.155 10.7924 14.9893 10.7924 13.5513ZM13.3962 12.0796C12.5834 12.0796 11.9245 12.7385 11.9245 13.5513C11.9245 14.3641 12.5834 15.023 13.3962 15.023C14.209 15.023 14.8679 14.3641 14.8679 13.5513C14.8679 12.7385 14.209 12.0796 13.3962 12.0796Z"
                fill="white"
            />
        </SVG>
    );
};
