import { gql } from '@apollo/client';
import { FRAGMENTS } from '../../_fragments';

export const GET_CABLE_SNAPS = gql`
    query getCableSnaps($query: String!) {
        search(query: $query) {
            results {
                document {
                    ...ProductSearchFields
                    topProduct
                    isPromotion
                    cableSnaps {
                        chargeNr
                        length
                    }
                }
            }
            pageCount
            currentPage
            count
            total
        }
    }
    ${FRAGMENTS.PRODUCT_SEARCH}
`;
