import Text, { FontTypes } from '@ab-core/text';
import type { FC } from 'react';
import React from 'react';

type TitleElementType = {
    headline: string;
};

export const TitleElement: FC<TitleElementType> = ({ headline }) => (
    <Text className="px-medium2 py-small" type={FontTypes.Overline} color="gray50">
        {headline}
    </Text>
);
