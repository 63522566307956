import type { Query, QueryProductsAvailabilitiesWithChannelArgs } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_PRODUCTS_AVAILABILITIES_WITH_CHANNEL } from './getProductsAvailabilitiesWithChannel';

type QueryDataType = Pick<Query, 'productsAvailabilitiesWithChannel'>;
type QueryReturnDataType = QueryDataType['productsAvailabilitiesWithChannel'];

export const useGetProductsAvailabilitiesWithChannel = (
    variables: QueryProductsAvailabilitiesWithChannelArgs,
    skip = false
) => {
    const [success, setSuccess] = useState(false);
    const [productsAvailabilitiesWithChannel, setProductsAvailabilitiesWithChannel] = useState<QueryReturnDataType>([]);

    const { data, loading, refetch } = useQuery<QueryDataType, QueryProductsAvailabilitiesWithChannelArgs>(
        GET_PRODUCTS_AVAILABILITIES_WITH_CHANNEL,
        {
            variables,
            fetchPolicy: 'network-only',
            onCompleted: () => setSuccess(true),
            skip: variables.skus.length === 0 || skip
        }
    );

    useEffect(() => {
        if (data?.productsAvailabilitiesWithChannel && !loading) {
            setProductsAvailabilitiesWithChannel(data.productsAvailabilitiesWithChannel);
        }
    }, [data, loading]);

    return {
        productsAvailabilitiesWithChannel,
        loading,
        refetch,
        success
    };
};
