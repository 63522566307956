import type { Query, QueryGetKanbanLabelSetArgs } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { GET_KANBAN_LABEL_SET } from './gql/getKanbanLabelSet';

type QueryDataType = Pick<Query, 'getKanbanLabelSet'>;
type OutgoingDataType = QueryDataType['getKanbanLabelSet'];

const onError = getErrorLoggerFunction('getKanbanLabelSet');

export const useGetKanbanLabelSet = (id: string) => {
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryGetKanbanLabelSetArgs>(
        GET_KANBAN_LABEL_SET,
        {
            variables: { id },
            onError
        }
    );

    useEffect(() => {
        if (data) {
            setOutgoingData(data.getKanbanLabelSet);
        }
    }, [data]);

    return {
        kanbanLabelSet: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
