import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const ArrowLeft: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.42885 16.0081L6.32305 16.1158C6.02494 16.3193 5.61892 16.2869 5.35516 16.0186L0.224984 10.7989C-0.0749975 10.4937 -0.0749975 10.0013 0.224984 9.69606L5.35516 4.4764C5.65515 4.17119 6.13913 4.17119 6.43912 4.4764C6.7391 4.78162 6.7391 5.27405 6.43912 5.57926L2.62557 9.45932L19.2305 9.45932C19.6531 9.45932 20 9.81225 20 10.2423C20 10.6723 19.6531 11.0252 19.2305 11.0252L2.61531 11.0252L6.42885 14.9053C6.72884 15.2105 6.72884 15.7029 6.42885 16.0081Z"
                fill={color}
            />
        </SVG>
    );
};
