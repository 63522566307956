import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Link: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill={color}
                d="M9.6275 7.90452C9.4915 7.90452 9.3555 7.85272 9.2519 7.74886C8.42342 6.92038 7.07537 6.92011 6.24662 7.74886C6.03943 7.95631 5.70262 7.95631 5.49543 7.74886C5.28798 7.54141 5.28798 7.20513 5.49543 6.99767C6.73829 5.75455 8.7605 5.75481 10.0031 6.99767C10.2105 7.20513 10.2105 7.54141 10.0031 7.74886C9.8995 7.85272 9.76323 7.90452 9.6275 7.90452Z"
            />
            <path
                fill={color}
                d="M9.25121 10.935C8.43495 10.935 7.61868 10.6243 6.99739 10.003C6.78993 9.79552 6.78993 9.45923 6.99739 9.25178C7.20457 9.04433 7.54139 9.04433 7.74857 9.25178C8.57706 10.0803 9.92511 10.0805 10.7539 9.25178C10.961 9.04433 11.2979 9.04433 11.505 9.25178C11.7125 9.45923 11.7125 9.79552 11.505 10.003C10.8835 10.6243 10.0672 10.935 9.25121 10.935Z"
            />
            <path
                fill={color}
                d="M11.1288 10.1584C10.9928 10.1584 10.8568 10.1066 10.7532 10.0028C10.5458 9.79531 10.5458 9.45902 10.7532 9.25157L13.7208 6.28401C13.928 6.07655 14.2648 6.07655 14.472 6.28401C14.6794 6.49146 14.6794 6.82774 14.472 7.0352L11.5044 10.0028C11.4008 10.1066 11.2648 10.1584 11.1288 10.1584Z"
            />
            <path
                fill={color}
                d="M6.66009 14.6282C6.52409 14.6282 6.38809 14.5764 6.2845 14.4726C6.07704 14.2651 6.07704 13.9289 6.2845 13.7214L7.94332 12.0626C8.15051 11.8551 8.48732 11.8551 8.69451 12.0626C8.90196 12.27 8.90196 12.6063 8.69451 12.8138L7.03568 14.4726C6.93209 14.5764 6.79609 14.6282 6.66009 14.6282Z"
            />
            <path
                fill={color}
                d="M2.90423 10.8718C2.76823 10.8718 2.63223 10.82 2.52864 10.7161C2.32118 10.5087 2.32118 10.1724 2.52864 9.96493L5.4962 6.99763C5.70365 6.79018 6.0402 6.79018 6.24739 6.99763C6.45484 7.20508 6.45484 7.54137 6.24739 7.74882L3.27982 10.7161C3.17596 10.8197 3.03996 10.8718 2.90423 10.8718Z"
            />
            <path
                fill={color}
                d="M8.68157 5.09335C8.54557 5.09335 8.40984 5.04156 8.30598 4.93796C8.09853 4.73051 8.09853 4.39423 8.30598 4.18651L9.96481 2.52742C10.1723 2.31996 10.5085 2.31996 10.7163 2.52742C10.9237 2.73487 10.9237 3.07115 10.7163 3.27887L9.05743 4.93796C8.95357 5.04129 8.81731 5.09335 8.68157 5.09335Z"
            />
            <path
                fill={color}
                d="M4.78156 15.389C3.95998 15.389 3.13841 15.0835 2.52773 14.4726C1.30586 13.2507 1.30586 11.1865 2.52773 9.96467C2.73492 9.75722 3.07173 9.75722 3.27892 9.96467C3.48637 10.1721 3.48637 10.5084 3.27892 10.7159C2.46452 11.5303 2.46452 12.9067 3.27892 13.7211C4.09333 14.5355 5.4698 14.5355 6.2842 13.7211C6.49139 13.5137 6.8282 13.5137 7.03539 13.7211C7.24284 13.9286 7.24284 14.2649 7.03539 14.4723C6.42472 15.0835 5.60314 15.389 4.78156 15.389Z"
            />
            <path
                fill={color}
                d="M14.0982 7.1908C13.9622 7.1908 13.8262 7.13901 13.7226 7.03515C13.5152 6.82769 13.5152 6.49141 13.7226 6.28396C14.537 5.46955 14.537 4.09308 13.7226 3.27868C12.9082 2.46427 11.5317 2.46427 10.7173 3.27868C10.5101 3.48613 10.1733 3.48613 9.96614 3.27868C9.75868 3.07122 9.75868 2.73494 9.96614 2.52749C11.188 1.30562 13.2522 1.30562 14.4741 2.52749C15.6959 3.74937 15.6959 5.81354 14.4741 7.03541C14.3702 7.13901 14.2342 7.1908 14.0982 7.1908Z"
            />
        </SVG>
    );
};
