import { getClassNames } from '@ab-core/functions/styles/classNameFormatter';
import type { WithTestId } from '@ab-core/testing';
import type { FC, ReactNode } from 'react';
import React from 'react';
import { MobileLabel, StyledTableDataItem, TableDataContent } from './styled';

type TableDataItemProps = WithTestId &
    React.ComponentPropsWithoutRef<'td'> & {
        mobileLabel?: ReactNode;
    };

export const TableDataItem: FC<TableDataItemProps> = (props) => {
    const { mobileLabel, children, className, ...rest } = props;
    const tableDataItemClass = getClassNames({
        className,
        prefix: 'table-data-item'
    });

    const tableMobileLabelClass = getClassNames({
        prefix: 'table-data-item-mobile-label'
    });

    return (
        <StyledTableDataItem {...rest} className={tableDataItemClass}>
            {mobileLabel && <MobileLabel className={tableMobileLabelClass}>{mobileLabel}</MobileLabel>}
            <TableDataContent>{children}</TableDataContent>
        </StyledTableDataItem>
    );
};
