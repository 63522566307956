import type { NavigationElement } from './types';

const isElementActive = (id: string, navigationElement: NavigationElement, ancestors?: string[]): boolean => {
    if (ancestors?.includes(navigationElement.id ? navigationElement.id : '')) {
        return true;
    }

    if (navigationElement.id === id) {
        return !navigationElement.active;
    }

    return false;
};
const setChildren = (
    id: string,
    children: NavigationElement[] | undefined | null,
    ancestors?: string[]
): NavigationElement[] | undefined => {
    if (Array.isArray(children) && children?.length) {
        const filteredChildren: NavigationElement[] | undefined = children?.filter(
            (filterChild: NavigationElement | undefined) => filterChild
        );

        if (Array.isArray(filteredChildren) && filteredChildren?.length) {
            return filteredChildren.map((child: NavigationElement) => setActivePath(id, child, ancestors));
        }
    }

    return undefined;
};

export const setActivePath = (
    id: string,
    navigationElement: NavigationElement,
    ancestors?: string[]
): NavigationElement => ({
    ...navigationElement,
    ...{
        active: isElementActive(id, navigationElement, ancestors),
        navigationElements: setChildren(id, navigationElement.navigationElements, ancestors)
    }
});
