import { gql } from '@apollo/client';
import { MONEY_OBJECT } from './moneyObject';

export const OFFER_DETAIL_FRAGMENT = gql`
    fragment OfferDetailFragment on Offer {
        id
        offerNumber
        offerDate
        project
        bindingPeriod
        orderType
        offerText
        purchaser
        modificationRule
        customerId
        contactPerson
        priceDeliveryNote
        singleInvoice
        costCenter
        shippingInstructions
        desiredDeliveryDate
        displayShippingMethod
        completeDelivery
        shippingAddress {
            id
            externalId
            primaryAddress
            name
            streetName
            city
            postalCode
            country
            addressExtraLineOne
            addressExtraLineTwo
            addressExtraLineThree
        }
        offerReferenceShippingAddress {
            id
            externalId
            primaryAddress
            name
            streetName
            city
            postalCode
            country
            addressExtraLineOne
            addressExtraLineTwo
            addressExtraLineThree
        }
        priceObject {
            value {
                ...MoneyObject
            }
            valueDiscountSum {
                ...MoneyObject
            }
            valueMaterialPriceSurchargesSum {
                ...MoneyObject
            }
            valueSurchargeCutSum {
                ...MoneyObject
            }
            valueSurchargesSum {
                ...MoneyObject
            }
            valueWithoutAdditional {
                ...MoneyObject
            }
        }
        shippingInfo {
            shippingMethod {
                id
                key
            }
        }
        offerPositionsGroups {
            name
            groupType
            groupTotalPrice {
                ...MoneyObject
            }
            groupBasePrice {
                ...MoneyObject
            }
            groupTotalSurcharges {
                ...MoneyObject
            }
            groupTotalDiscount {
                ...MoneyObject
            }
            offerPositions {
                id
                isSelected
                fixPosNumber
                posNumber
                sku
                ean
                name
                dispo
                positionText
                quantity
                priceObject {
                    surchargeCut {
                        value {
                            ...MoneyObject
                        }
                    }
                    totalSurchargeValue {
                        ...MoneyObject
                    }
                    vats {
                        value {
                            ...MoneyObject
                        }
                    }
                    productPriceSum {
                        ...MoneyObject
                    }
                    value {
                        ...MoneyObject
                    }
                    basePrice {
                        ...MoneyObject
                    }
                    priceObject {
                        priceReference
                    }
                    materialPriceSurcharges {
                        value {
                            ...MoneyObject
                        }
                    }
                    discountSum {
                        ...MoneyObject
                    }
                }
                salesUnit
                quantityUnit
                imageThumbnailUrl
                isSelected
                isAlternativePosition
                isOptionalPosition
                positionType
                isPackageItem
                isDiversarticle
            }
        }
    }
    ${MONEY_OBJECT}
`;
