import { gql } from '@apollo/client';

const PRODUCT_SEARCH_FRAGMENT = gql`
    fragment productSearchFields on ProductDocument {
        id
        sku
        productName
        ean
        packagingSize
        supplierId
        supplierName
        productCocontractorValue
        image
        procuredProduct
        productCocontractor
        salesUnit
        quantityUnit
        category
        hasAccessories
        hasAlternatives
        bonus
        availability
        customerArticleNumber
        scoreCardIndex
    }
`;

export default PRODUCT_SEARCH_FRAGMENT;
