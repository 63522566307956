import type { CategoryResult } from '@ab-core/graphql/dist';

const THREE = 3;
const TWO = 2;

export const calculateCells = (item: CategoryResult) => {
    const arr = item?.children || [];

    const arrayLength = item?.children?.length || 0;

    let size1;
    let size2;
    let size3;

    if (arrayLength === 0) {
        size1 = size2 = size3 = 0;
    } else if (arrayLength === 1) {
        size1 = 1;
        size2 = size3 = 0;
    } else if (arrayLength === TWO) {
        size1 = 1;
        size2 = 1;
        size3 = 0;
    } else {
        const mod = arrayLength % THREE;

        if (mod === 0) {
            size1 = size2 = arrayLength / THREE;
            size3 = arrayLength / THREE - 1;
        } else if (mod === 1) {
            size1 = size2 = Math.floor(arrayLength / THREE);
            size3 = Math.floor(arrayLength / THREE);
        } else {
            size1 = size2 = Math.floor(arrayLength / THREE) + 1;
            size3 = Math.floor(arrayLength / THREE);
        }
    }

    const firstArray = arr.slice(0, size1);
    const secondArray = arr.slice(size1, size1 + size2);
    const thirdArray = arr.slice(size1 + size2, arrayLength);

    const pseudoObject: CategoryResult = {
        __typename: 'CategoryResult',
        id: '',
        name: ''
    };
    const result = [];
    let index = 0;
    while (index < Math.max(size1, size2, size3)) {
        if (index < size1) {
            result.push(firstArray[index]);
        } else {
            result.push({ ...pseudoObject, id: String(index) });
        }

        if (index < size2) {
            result.push(secondArray[index]);
        } else {
            result.push({ ...pseudoObject, id: String(index) });
        }

        if (index < size3) {
            result.push(thirdArray[index]);
        }

        index++;
    }

    return result;
};
