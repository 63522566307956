import type { ShippingAddress } from '@ab-core/graphql/dist';
import { DeliveryMethod } from '@ab-core/shippingaddress/types';
import { makeVar, useReactiveVar } from '@apollo/client';
import type { AddressStoreType, StateType } from './types';

const _state: StateType = {
    returnCheckoutAddress: makeVar<ShippingAddress | undefined>(undefined),
    shippingMethod: makeVar<DeliveryMethod>(DeliveryMethod.RETURN),
    driverNote: makeVar<string>('')
};

export const addressStore: AddressStoreType = {
    getters: {
        useReturnCheckoutAddress: () => useReactiveVar(_state.returnCheckoutAddress),
        useShippingMethod: () => useReactiveVar(_state.shippingMethod),
        useDriverNote: () => useReactiveVar(_state.driverNote)
    },
    setters: {
        setReturnCheckoutAddress: (item) => _state.returnCheckoutAddress(item),
        setShippingMethode: (item) => _state.shippingMethod(item),
        setDriverNote: (note) => _state.driverNote(note)
    }
};
