import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const CableReel: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10 11.75C10.9665 11.75 11.75 10.9665 11.75 10C11.75 9.0335 10.9665 8.25 10 8.25C9.0335 8.25 8.25 9.0335 8.25 10C8.25 10.9665 9.0335 11.75 10 11.75ZM10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13ZM5.65685 4.34315C5.26633 3.95262 4.63316 3.95262 4.24264 4.34315C3.85212 4.73367 3.85212 5.36684 4.24264 5.75736L5.65685 7.17157C6.04738 7.5621 6.68054 7.5621 7.07107 7.17157C7.46159 6.78105 7.46159 6.14788 7.07107 5.75736L5.65685 4.34315ZM4.24264 14.2426C3.85212 14.6332 3.85212 15.2663 4.24264 15.6569C4.63316 16.0474 5.26633 16.0474 5.65685 15.6569L7.07107 14.2426C7.46159 13.8521 7.46159 13.219 7.07107 12.8284C6.68054 12.4379 6.04738 12.4379 5.65685 12.8284L4.24264 14.2426ZM12.7279 5.75736C12.3374 6.14788 12.3374 6.78105 12.7279 7.17157C13.1184 7.5621 13.7516 7.5621 14.1421 7.17157L15.5563 5.75736C15.9469 5.36683 15.9469 4.73367 15.5563 4.34315C15.1658 3.95262 14.5327 3.95262 14.1421 4.34315L12.7279 5.75736ZM14.1421 12.8284C13.7516 12.4379 13.1184 12.4379 12.7279 12.8284C12.3374 13.219 12.3374 13.8521 12.7279 14.2426L14.1421 15.6569C14.5327 16.0474 15.1658 16.0474 15.5563 15.6569C15.9469 15.2663 15.9469 14.6332 15.5563 14.2426L14.1421 12.8284Z"
                fill={color}
            />
        </SVG>
    );
};
