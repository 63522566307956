import type { Mutation, MutationUpdateKanbanLabelSetArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { GET_KANBAN_LABEL_SETS } from './gql/getKanbanLabelSets';
import { UPDATE_KANBAN_LABEL_SET } from './gql/updateKanbanLabelSet';

type MutationDataType = Pick<Mutation, 'updateKanbanLabelSet'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('updateKanbanLabelSet');

const refetchQueries = [GET_KANBAN_LABEL_SETS];

export const useUpdateKanbanLabelSet = (id: string, name: string) => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [updateKanbanLabelSet, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationUpdateKanbanLabelSetArgs
    >(UPDATE_KANBAN_LABEL_SET, {
        variables: {
            data: {
                id,
                name: name?.trim()
            }
        },
        refetchQueries,
        onError
    });

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        updateKanbanLabelSet,
        loading,
        response
    };
};
