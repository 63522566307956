import { getClassNames } from '@ab-core/functions/styles/classNameFormatter';
import type { IconProps } from '@ab-core/icon';
import { Icon } from '@ab-core/icon';
import { getTestIdProp } from '@ab-core/testing';
import Text, { FontTypes } from '@ab-core/text';
import type { FC } from 'react';
import React from 'react';
import { CloseWrapper, Content, DescriptionWrapper, IconWrapper, Notification } from './styled';

export enum ToastLayout {
    Warning = 'warning',
    Success = 'success',
    Info = 'info',
    Error = 'error'
}

export type ToastType = Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> & {
    title: React.ReactNode;
    description: React.ReactNode;
    styleType?: ToastLayout;
    appearance: number;
    deleteToast?: React.ComponentPropsWithoutRef<'div'>['onClick'];
};

export const Toast: FC<ToastType> = (props) => {
    const { styleType = ToastLayout.Warning, deleteToast, title, description, ...rest } = props;
    const newClassNames = getClassNames({ prefix: 'toast', modifier: { styleType } });

    const getIcon = (): IconProps['name'] => {
        switch (styleType) {
            case ToastLayout.Warning:
                return 'Warning';
            case ToastLayout.Success:
                return 'Checkmark';
            case ToastLayout.Info:
                return 'Info';
            case ToastLayout.Error:
                return 'ErrorIcon';
            default:
                return 'Checkmark';
        }
    };

    return (
        <div {...rest}>
            <Notification {...getTestIdProp(`toast-${styleType}-${title}-message`)} className={newClassNames}>
                <IconWrapper>
                    <Icon name={getIcon()} color={styleType}></Icon>
                </IconWrapper>
                <Content>
                    <Text type={FontTypes.Title}>{title}</Text>
                    {description && <DescriptionWrapper>{description}</DescriptionWrapper>}
                </Content>
                {deleteToast && (
                    <CloseWrapper onClick={deleteToast} {...getTestIdProp('delete-toast-button')}>
                        <Icon name="Close" color="gray50"></Icon>
                    </CloseWrapper>
                )}
            </Notification>
        </div>
    );
};
