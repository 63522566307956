import { BreakpointsMinQuery } from '@ab-core/breakpoints';
import { Z_INDEX } from '@ab-core/z-index';
import styled from 'styled-components';

enum TOP_BAR_CONTAINER_HEIGHT {
    DEFAULT = '60px',
    SM = '80px',
    LG = '100px'
}

export const Container = styled.div`
    position: fixed;
    z-index: ${Z_INDEX.TOOLTIP};
    top: calc(${TOP_BAR_CONTAINER_HEIGHT.DEFAULT} + 1rem);
    @media ${BreakpointsMinQuery.sm} {
        top: calc(${TOP_BAR_CONTAINER_HEIGHT.SM} + 1rem);
    }
    @media ${BreakpointsMinQuery.lg} {
        top: calc(${TOP_BAR_CONTAINER_HEIGHT.SM} + 1rem);
    }
    right: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    width: calc(100% - 2rem);
    max-width: 375px;

    @media ${BreakpointsMinQuery.md} {
        min-width: 375px;
        max-width: 600px;
        width: auto;
    }
`;
