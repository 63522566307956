import { ErrorFallbackCard } from '@ab-core/error-fallback-card';
import { FeatureNotifications } from '@ab-core/featurenotifications';
import ABFonts from '@ab-core/fonts';
import type { FallbackRendererType } from '@ab-core/functions/errorBoundary/';
import { ErrorBoundary } from '@ab-core/functions/errorBoundary/';
import { ComparableProductContainer } from '@ab-core/product-comparison/container';
import { ToastContainer } from '@ab-core/toast/container';
import type { WithChildren } from '@ab-core/utils/types';
import type { FC } from 'react';
import React from 'react';
import { BaseCSS } from 'styled-bootstrap-grid';
import { Reset } from 'styled-reset';
import GlobalStyle from './globalstyles';

export type WrapperProps = WithChildren & {
    hasProductComparison: boolean;
};

const createErrorCode = (): string => {
    if (typeof window === 'undefined') {
        return '';
    }
    const date = new Date();
    return `${date.getTime()}${location.pathname !== '/' ? location.pathname + location.search : '/999'}`;
};

const fallbackRenderer: FallbackRendererType = (error) => (
    <ErrorFallbackCard code={createErrorCode()} message={error.message} />
);

export const GlobalCss: FC<WrapperProps> = ({ children, hasProductComparison }) => (
    <>
        <Reset />
        <ABFonts />
        <GlobalStyle />
        <BaseCSS />
        <div id="ab-root">
            <ErrorBoundary fallbackRenderer={fallbackRenderer}>{children}</ErrorBoundary>
        </div>
        <FeatureNotifications />
        <ToastContainer />
        {hasProductComparison && <ComparableProductContainer />}
    </>
);
