import { useAuth } from '@ab-core/hooks';

const USER_LOCKED_STATE_1 = 1;
const USER_LOCKED_STATE_4 = 4;
const USER_LOCKED_STATE_999 = 999;

export const userIsLocked = () => {
    const { user } = useAuth();
    const currentLockState = user?.activeAccount?.lock;

    return (
        currentLockState === USER_LOCKED_STATE_1 ||
        currentLockState === USER_LOCKED_STATE_4 ||
        currentLockState === USER_LOCKED_STATE_999 ||
        (user && !user.activeAccount)
    );
};
