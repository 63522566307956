import { getClassNames } from '@ab-core/functions/styles/classNameFormatter';
import { getTestIdProp } from '@ab-core/testing';
import type { FC } from 'react';
import React from 'react';
import { Item } from './styled';

export type DropdownItemProps = React.ComponentPropsWithoutRef<'div'> & {
    label: React.ReactNode;
    value: string;
    disabled?: boolean;
    isSelected?: boolean;
};

export const DropdownItem: FC<DropdownItemProps> = (props) => {
    const { disabled, label, value, className, isSelected, ...rest } = props;

    const itemClass = getClassNames({
        className,
        prefix: 'dropdown-item',
        modifier: { disabled, selected: isSelected }
    });

    return (
        <Item {...rest} {...getTestIdProp(`dropdown-item-${value}`)} className={itemClass}>
            {label}
        </Item>
    );
};
