import Button, { BUTTON_COLOR, BUTTON_VARIANT } from '@ab-core/button';
import type { BasicDropdownProps } from '@ab-core/dropdown';
import { BasicDropdown } from '@ab-core/dropdown';
import { Col, Row } from '@ab-core/grid';
import type { IconProps } from '@ab-core/icon';
import { Icon, Icons } from '@ab-core/icon';
import type { InputProps } from '@ab-core/input';
import { Input } from '@ab-core/input';
import type { ModalType } from '@ab-core/modal';
import Modal from '@ab-core/modal';
import Spacer from '@ab-core/spacer';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { Container } from 'styled-bootstrap-grid';
import type { FavoriteItemType } from '../list/types';

export enum CHANGE_TYPE {
    DELETE = 'delete',
    UPDATE = 'update'
}

export type EditModalProps = Pick<ModalType, 'onClose'> & {
    item?: FavoriteItemType;
    handleItemChange: (item: FavoriteItemType, changeType: CHANGE_TYPE) => void;
};

export const EditModal: FC<EditModalProps> = (props) => {
    const { item, handleItemChange, onClose } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [modalItem, setModalItem] = useState(item);

    useEffect(() => {
        setModalItem(item);
    }, [item]);

    useEffect(() => {
        if (!modalItem) {
            setIsOpen(false);

            return;
        }

        setIsOpen(true);
    }, [modalItem]);

    const onSave = () => {
        if (!modalItem) {
            return;
        }

        handleItemChange(modalItem, CHANGE_TYPE.UPDATE);
        handleCloseModal();
    };

    const onDelete = () => {
        if (!modalItem) {
            return;
        }

        handleItemChange(modalItem, CHANGE_TYPE.DELETE);
        handleCloseModal();
    };

    const handleCloseModal = () => {
        onClose();
        setModalItem(undefined);
    };

    const onNameChange: InputProps['onChange'] = (e) => {
        const { value } = e.currentTarget;

        if (!modalItem) {
            return;
        }

        const newModalItem: FavoriteItemType = { ...modalItem, name: value };

        setModalItem(newModalItem);
    };

    const onIconChange: BasicDropdownProps['onItemChange'] = (dropdownItem) => {
        if (!modalItem) {
            return;
        }

        const newModalItem: FavoriteItemType = { ...modalItem, icon: dropdownItem?.value as IconProps['name'] };

        setModalItem(newModalItem);
    };

    const itemName = modalItem?.name || '';

    return (
        <Modal
            open={isOpen}
            onClose={handleCloseModal}
            headerText={`${itemName} bearbeiten`}
            footer={
                <Row justifyContent="between">
                    <Col sm={6} smOrder={2}>
                        <Button onClick={onSave} fluid iconName="Checkmark">
                            Fertig
                        </Button>
                        <Spacer size="xs" />
                    </Col>
                    <Col sm={6} smOrder={1}>
                        <Button
                            iconName="Delete"
                            variant={BUTTON_VARIANT.OUTLINE}
                            fluid
                            onClick={onDelete}
                            color={BUTTON_COLOR.GRAY}
                        >
                            Favorit löschen
                        </Button>
                        <Spacer size="xs" />
                    </Col>
                </Row>
            }
        >
            <Container>
                <Spacer size="xs" />
                <Row>
                    <Col sm={6}>
                        <Input label="Name" value={itemName} onChange={onNameChange} maxLength={20} />
                    </Col>
                    <Col sm={6}>
                        <BasicDropdown
                            label="Icon"
                            onItemChange={onIconChange}
                            items={getIconListForDropdown()}
                            selectedValue={modalItem?.icon}
                        />
                    </Col>
                </Row>
                <Spacer size="xs" />
                <Row>
                    <Col>
                        <Input label="Pfad" value={modalItem?.url} disabled />
                    </Col>
                </Row>
                <Spacer size="xxl" />
            </Container>
        </Modal>
    );
};

const getIconListForDropdown = (): BasicDropdownProps['items'] => {
    const dropdownList: BasicDropdownProps['items'] = [];
    Object.keys(Icons).forEach((key) => {
        dropdownList.push({ value: key, label: <Icon name={key as IconProps['name']} /> });
    });

    return dropdownList;
};
