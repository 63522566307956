import type { ProjectGql } from '@ab-core/graphql/dist';
import type { ApolloQueryResult } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { GET_PROJECTS_BY_CUSTOMER_ID } from '../cartNew/getProjectsByCustomerIdQuery';
import useAuth from './useAuth';

type Data = {
    getProjectsByCustomerId: Array<ProjectGql>;
};

type UseProjectsType = {
    projects?: Array<ProjectGql>;
    projectsLoading?: boolean;
    userHasProjects?: boolean;
    projectsRefetch: () => Promise<ApolloQueryResult<Data>>;
};

const useProjects = (): UseProjectsType => {
    const { userLoading } = useAuth();
    const { data, loading, refetch } = useQuery<Data>(GET_PROJECTS_BY_CUSTOMER_ID, {
        skip: Boolean(userLoading)
    });
    const userHasProjects = Boolean(data?.getProjectsByCustomerId.filter((project) => !project.hide)?.length);

    return {
        projects: data?.getProjectsByCustomerId,
        projectsLoading: loading,
        projectsRefetch: refetch,
        userHasProjects
    };
};

export default useProjects;
