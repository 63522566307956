import { useAuth } from '@ab-core/hooks';
import type { FC } from 'react';
import React from 'react';

export const FreshChatDataContainer: FC = () => {
    const { user } = useAuth();

    return (
        <span
            id="freshchat_data"
            data-firstname={user?.firstName}
            data-lastname={user?.lastName}
            data-email={user?.email}
            data-customer-id={user?.activeAccount?.accountNumber || '0'}
            data-phone={user?.phone || '0'}
            data-sector={user?.activeAccount?.businessUnit || '1'}
            data-customer-classification={user?.activeAccount?.customerClassification || '0'}
        />
    );
};
