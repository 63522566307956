import { gql } from '@apollo/client';

export const GET_CAN_RECEIVE_CART_CONTACTS = gql`
    query getContactsWithCanReceiveCartRight {
        getContactsWithCanReceiveCartRight {
            id
            email
            salutation
            firstName
            lastName
        }
    }
`;
