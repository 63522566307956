import { gql } from '@apollo/client';

export const AVAILABILITY_FRAGMENT = gql`
    fragment availabilityFields on ProductVariantAvailabilityWithChannel {
        availability {
            availableQuantity
        }
        channel {
            name
            key
        }
    }
`;
