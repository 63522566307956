import { ApolloClient, useQuery } from '@apollo/client';
import { useAuth } from '@ab-core/hooks';
import type { QuerySearchByCategoryArgs, SearchResult } from '@ab-core/graphql/dist/index';

import productSearchMapper, { MappedSearchResultDocument } from '@ab-core/hooks/search/mappers/productSearchMapper';
import PRODUCT_SEARCH_BY_CATEGORY_QUERY from './getProductSearchByCategoryQuery';

type Data = {
    searchByCategory: SearchResult | undefined;
};

type UseProductsByCategory = {
    results?: MappedSearchResultDocument[];
    lastPage?: number;
    currentPage?: number;
    loading: boolean;
    searchResultCount?: number;
    client: ApolloClient<unknown>;
};

const useProductsByCategory = (category: string, page = 1): UseProductsByCategory => {
    const { user } = useAuth();
    const { loading, data, client } = useQuery<Data, QuerySearchByCategoryArgs>(PRODUCT_SEARCH_BY_CATEGORY_QUERY, {
        variables: { category, page }
    });

    return {
        client,
        loading,
        results: productSearchMapper(data?.searchByCategory?.results, user),
        lastPage: data?.searchByCategory?.pageCount,
        currentPage: data?.searchByCategory?.currentPage,
        searchResultCount: data?.searchByCategory?.total
    };
};

export default useProductsByCategory;
