export const uniteOfMeasure: Map<string, string> = new Map();

uniteOfMeasure.set('Meter', 'm');
uniteOfMeasure.set('Blister', 'Bli.');
uniteOfMeasure.set('Kilogramm', 'kg');
uniteOfMeasure.set('Karton', 'Kart.');
uniteOfMeasure.set('Paar', 'Paar');
uniteOfMeasure.set('Stück', 'Stk.');
uniteOfMeasure.set('Rolle', 'Roll.');
uniteOfMeasure.set('Satz', 'Satz');
uniteOfMeasure.set('VE', 'VE');
