import type { Query, QueryGetProductBySkuV2Args } from '@ab-core/graphql/dist';
import type { BasicTracking } from '@ab-core/src/tracking/types';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { GET_PRODUCT_BY_SKU_V2_WITHOUT_DETAILS } from './gql/getProductBySkuV2WithoutDetail';

type QueryDataType = Pick<Query, 'getProductBySkuV2'>;
type OutgoingDataType = QueryDataType['getProductBySkuV2'];
type IncomingDataType = {
    sku?: string;
    skip?: boolean;
    tracking?: BasicTracking;
};

const onError = getErrorLoggerFunction('getProductBySkuV2');

export const useGetProductBySkuSmartV2 = (props: IncomingDataType) => {
    const { sku = '', skip = false, tracking } = props;
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const skipValue = skip || sku === '';

    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryGetProductBySkuV2Args>(
        GET_PRODUCT_BY_SKU_V2_WITHOUT_DETAILS,
        {
            variables: {
                sku,
                tracking
            },
            skip: skipValue,
            onError
        }
    );

    useEffect(() => {
        if (data) {
            setOutgoingData(data.getProductBySkuV2);
        }
    }, [data]);

    return {
        product: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
