import { gql } from '@apollo/client';

export const GET_SF_FIELD_DATA = gql`
    query GetSFFieldData {
        getSFFieldData {
            functions
            salutations
            academicTitles
        }
    }
`;
