import { gql } from '@apollo/client';
import { FRAGMENTS } from '../../_fragments';

export const CART_PRICE_OBJECT = gql`
    query CartPriceObject(
        $products: [CartProduct!]
        $includeShipping: Boolean
        $credits: Float
        $coupon: CouponInput
        $projectId: Float
    ) {
        cartPriceObject(
            products: $products
            includeShipping: $includeShipping
            credits: $credits
            coupon: $coupon
            projectId: $projectId
        ) {
            ...CartPriceObjectFields
        }
    }
    ${FRAGMENTS.CART_PRICE_OBJECT}
`;
