import type { FunctionProp } from './types';

type AdditionalSearchProps = 'q';

export const getTrackingSearch: FunctionProp<AdditionalSearchProps> = (props) => ({
    ...props,
    type: 'search'
});

type AdditionalSuggestProps = 'q' | 'selectedQuery' | 'position';

export const getTrackingSuggest: FunctionProp<AdditionalSuggestProps> = (props) => ({
    ...props,
    type: 'suggest'
});

type AdditionalPaginationNextProps = 'q' | 'page';

export const getTrackingPaginationNext: FunctionProp<AdditionalPaginationNextProps> = (props) => ({
    ...props,
    type: 'paginationNext'
});

type AdditionalPaginationPreviousProps = 'q' | 'page';

export const getTrackingPaginationPrevious: FunctionProp<AdditionalPaginationPreviousProps> = (props) => ({
    ...props,
    type: 'paginationPrevious'
});

type AdditionalSelectPageProps = 'q' | 'page' | 'selectedPage';

export const getTrackingSelectPage: FunctionProp<AdditionalSelectPageProps> = (props) => ({
    ...props,
    type: 'selectPage'
});

type AdditionalClickFacetProps = 'q' | 'facetId' | 'facetValue' | 'position' | 'page';

export const getTrackingClickFacet: FunctionProp<AdditionalClickFacetProps> = (props) => ({
    ...props,
    type: 'clickFacet'
});

type AdditionalClickDocumentProps = 'q' | 'docId' | 'position' | 'page';

export const getTrackingClickDocument: FunctionProp<AdditionalClickDocumentProps> = (props) => ({
    ...props,
    type: 'clickDocument'
});

type AdditionalAddToCartProps = 'q' | 'docId' | 'position' | 'page';

export const getTrackingAddToCart: FunctionProp<AdditionalAddToCartProps> = (props) => ({
    ...props,
    type: 'addToCart'
});

type AdditionalFavoritesProps = 'q' | 'docId' | 'position' | 'page';

export const getTrackingFavorites: FunctionProp<AdditionalFavoritesProps> = (props) => ({
    ...props,
    type: 'favorites'
});

type AdditionalAccessoriesProps = 'q' | 'docId' | 'position' | 'page';

export const getTrackingAccessories: FunctionProp<AdditionalAccessoriesProps> = (props) => ({
    ...props,
    type: 'accessories'
});

type AdditionalAlternativesProps = 'q' | 'docId' | 'position' | 'page';

export const getTrackingAlternatives: FunctionProp<AdditionalAlternativesProps> = (props) => ({
    ...props,
    type: 'alternatives'
});
