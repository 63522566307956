import { gql } from '@apollo/client';

export const GET_OUTSTANDING_RECEIVABLES = gql`
    query GetOutstandingReceivables {
        getOutstandingReceivables {
            customerNumber
            documentNumber
            revenue
            netDue
            skontoDue1
            skontoDue2
            type
        }
    }
`;
