import type { Mutation, MutationCreateKanbanLabelSetArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { CREATE_KANBAN_LABEL_SET } from './gql/createKanbanLabelSet';
import { GET_KANBAN_LABEL_SETS } from './gql/getKanbanLabelSets';

type MutationDataType = Pick<Mutation, 'createKanbanLabelSet'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('createKanbanLabelSet');

const refetchQueries = [GET_KANBAN_LABEL_SETS];

export const useCreateKanbanLabelSet = (name: string) => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [createKanbanLabelSet, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationCreateKanbanLabelSetArgs
    >(CREATE_KANBAN_LABEL_SET, {
        variables: {
            name: name.trim()
        },
        refetchQueries,
        onError
    });

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        createKanbanLabelSet,
        loading,
        response
    };
};
