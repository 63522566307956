const mainPrefix = 'ab';

type ModifierRecordTypes = boolean | string | number | undefined | null;
type Modifier = Record<string, ModifierRecordTypes>;
type CreateModifierClassNames = (prefix: string, modifier?: Modifier) => Array<string>;

type GetClassNamesProps = {
    prefix: string;
    className?: string;
    styleNames?: Array<string>;
    modifier?: Modifier;
};
const createModifierClassNames: CreateModifierClassNames = (prefix, modifier = {}) =>
    Object.keys(modifier)
        .filter((key) => Boolean(modifier[key]) || modifier[key] === 0)
        .map((key) =>
            typeof modifier[key] === 'boolean'
                ? `${mainPrefix}-${prefix}-${key}`
                : `${mainPrefix}-${prefix}-${key}-${modifier[key]}`
        );

export const getClassNames = (props: GetClassNamesProps): string => {
    const { className, styleNames, prefix, modifier } = props;

    const classArray = [`${mainPrefix}-${prefix}`];

    if (className) {
        classArray.push(className);
    }

    if (styleNames) {
        styleNames.forEach((styleName) => {
            classArray.push(`${mainPrefix}-${prefix}-${styleName}`);
        });
    }

    const modifierClassNames = createModifierClassNames(prefix, modifier);

    return [...classArray, ...modifierClassNames].join(' ');
};
