import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Star: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 0C10.2491 0 10.4737 0.14985 10.5694 0.379831L13.0344 6.30642L19.4326 6.81936C19.6809 6.83927 19.8928 7.00659 19.9698 7.24348C20.0468 7.48037 19.9737 7.7403 19.7845 7.90234L14.9097 12.0781L16.399 18.3217C16.4568 18.564 16.3632 18.8173 16.1617 18.9637C15.9602 19.1101 15.6904 19.1209 15.4778 18.991L10 15.6452L4.52221 18.991C4.30964 19.1209 4.03984 19.1101 3.83833 18.9637C3.63682 18.8173 3.54318 18.564 3.60097 18.3217L5.0903 12.0781L0.215507 7.90234C0.0263417 7.7403 -0.0467626 7.48037 0.0302073 7.24348C0.107177 7.00659 0.319105 6.83927 0.567388 6.81936L6.96564 6.30642L9.43064 0.379831C9.5263 0.14985 9.75092 0 10 0ZM10 2.22234L7.95971 7.1278C7.87091 7.3413 7.67012 7.48717 7.43963 7.50565L2.14378 7.93022L6.17866 11.3865C6.35427 11.537 6.43096 11.773 6.37731 11.9979L5.14459 17.1658L9.67857 14.3964C9.87591 14.2759 10.1241 14.2759 10.3214 14.3964L14.8554 17.1658L13.6227 11.9979C13.569 11.773 13.6457 11.537 13.8213 11.3865L17.8562 7.93022L12.5604 7.50565C12.3299 7.48717 12.1291 7.3413 12.0403 7.1278L10 2.22234Z"
                fill={color}
            />
        </SVG>
    );
};
