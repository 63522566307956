import { gql } from '@apollo/client';

import { CART_OBJECT } from '../../fragments/cartFragment';

export const UPDATE_CART_INFO = gql`
    mutation setCartInformation(
        $cartInformationInput: CartInformationInput
        $shippingInput: CartShippingInput
        $cartId: String!
        $attributes: [String!]
    ) {
        setCartInformation(
            cartInformationInput: $cartInformationInput
            shippingInput: $shippingInput
            cartId: $cartId
            attributes: $attributes
        ) {
            ...ABCartFields
        }
    }
    ${CART_OBJECT}
`;
