import { encodeQueryValue } from '@ab-core-functions/search';
import { BREAKPOINT } from '@ab-core/breakpoints';
import { ROUTES, getClassNames, useDisableBackgroundScroll, useIsMobile, useOnClickOutside } from '@ab-core/functions';
import type { CategoryResult } from '@ab-core/graphql/dist';
import { useGetCategoriesV2 } from '@ab-core/hooks-v2';
import { Icon } from '@ab-core/icon';
import { navigationStore } from '@ab-core/store';
import Text, { FontTypes } from '@ab-core/text';
import { navigate } from 'gatsby';
import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { Backdrop } from './backdrop';
import { MenuWrapper, StyledCollectionItems, StyledContainer, Wrapper } from './collection.styled';
import { calculateCells } from './function';

const FIRST_MENU = 0;
const SECOND_MENU = 1;
const LAST_MENU = 2;

export const CollectionV2: FC = () => {
    const collectionItemsClassNamePrefix = 'collectionItems';
    const ref = useRef<HTMLDivElement>(null);

    const isSmartphone: boolean = useIsMobile(BREAKPOINT.SM);
    const [secondMenuOpen, setSecondMenuOpen] = useState(false);
    const [thirdMenuOpen, setThirdMenuOpen] = useState(false);
    const [firstItem, setFirstItem] = useState<CategoryResult>();
    const [secondItem, setSecondItem] = useState<CategoryResult>();
    const collectionOpen = navigationStore.getters.useCollectionNavigation();
    const isMobile: boolean = useIsMobile(BREAKPOINT.LG);
    const { categories } = useGetCategoriesV2();
    const items = categories;

    const firstItemClicked = (item: CategoryResult) => {
        setFirstItem(item);
        setSecondMenuOpen(true);
        setThirdMenuOpen(false);
        setSecondItem(undefined);
    };

    useEffect(() => {
        setSecondMenuOpen(false);
        setThirdMenuOpen(false);
        setFirstItem(undefined);
        setSecondItem(undefined);
    }, [collectionOpen]);

    const secondItemClicked = (item: CategoryResult) => {
        let itemValue;

        if (isSmartphone) {
            itemValue = item;
        } else {
            const result = calculateCells({
                ...item
            });
            const obj = { count: item.count, id: item.id, name: item.name, children: result };
            itemValue = obj;
        }

        setSecondItem(itemValue);
        setThirdMenuOpen(true);
    };

    const closeFirstMenu = () => navigationStore.setters.setCollectionNavigation(false);

    const closeSecondMenu = () => {
        setFirstItem(undefined);
        setSecondMenuOpen(false);
        setThirdMenuOpen(false);
    };
    const closeThirdMenu = () => {
        setSecondItem(undefined);
        setThirdMenuOpen(false);
    };

    const search = (item: CategoryResult) => {
        let categoryString = '';
        categoryString += encodeQueryValue(firstItem?.name);
        categoryString += '/';
        categoryString += encodeQueryValue(secondItem?.name);
        categoryString += '/';
        categoryString += encodeQueryValue(item.name);
        const filterString = encodeURIComponent(`categoryPath:${categoryString}`);

        navigate(`${ROUTES.SEARCH}?query=*&filter=${filterString}`);

        navigationStore.setters.setCollectionNavigation(false);
    };

    const getLastMenu = (): number => {
        if (thirdMenuOpen) {
            return LAST_MENU;
        }

        if (secondMenuOpen) {
            return SECOND_MENU;
        }

        return FIRST_MENU;
    };

    const collectionButtonRefObject = navigationStore.getters.useCollectionButtonRef();

    if (collectionButtonRefObject) {
        useOnClickOutside([ref, collectionButtonRefObject], closeFirstMenu);
    }

    useDisableBackgroundScroll(!!collectionOpen);

    return (
        <>
            {collectionOpen && items && (
                <Wrapper>
                    {!isMobile && <Backdrop />}
                    <StyledContainer className="content">
                        <MenuWrapper ref={ref} className="flex-direction-row">
                            <>
                                <StyledCollectionItems
                                    header={{
                                        title: 'Sortiment',
                                        hideBackButton: true,
                                        onClick: isMobile ? () => closeFirstMenu() : () => undefined
                                    }}
                                    className={getClassNames({
                                        prefix: collectionItemsClassNamePrefix,
                                        styleNames: ['1']
                                    })}
                                    lastMenu={getLastMenu()}
                                    items={items}
                                    activeItem={firstItem}
                                    itemOnClick={firstItemClicked}
                                    contentItem={(item) => (
                                        <>
                                            {item.name}
                                            <Icon name="ChevronRight" color="black" />
                                        </>
                                    )}
                                />
                                {secondMenuOpen && firstItem && (
                                    <StyledCollectionItems
                                        header={{
                                            title: firstItem.name || '',
                                            onClick: isMobile ? () => closeSecondMenu() : () => undefined
                                        }}
                                        className={getClassNames({
                                            prefix: collectionItemsClassNamePrefix,
                                            styleNames: ['2']
                                        })}
                                        lastMenu={getLastMenu()}
                                        items={firstItem?.children}
                                        activeItem={secondItem}
                                        itemOnClick={secondItemClicked}
                                        contentItem={(item) => (
                                            <>
                                                {item.name}
                                                <Icon name="ChevronRight" color="black" />
                                            </>
                                        )}
                                    />
                                )}
                                {thirdMenuOpen && secondItem && (
                                    <StyledCollectionItems
                                        header={{
                                            title: secondItem.name || '',
                                            onClick: isMobile ? () => closeThirdMenu() : () => undefined
                                        }}
                                        className={getClassNames({
                                            prefix: collectionItemsClassNamePrefix,
                                            styleNames: ['3']
                                        })}
                                        colProps={{ sm: 4 }}
                                        lastMenu={getLastMenu()}
                                        items={secondItem?.children}
                                        activeItem={secondItem}
                                        itemOnClick={(item) => search(item)}
                                        hasBorder={false}
                                        contentItem={(item) => (
                                            <>
                                                {item.name && item.count && (
                                                    <Text
                                                        type={FontTypes.Subtitle}
                                                        content={`${item.name} (${item.count})`}
                                                    />
                                                )}
                                            </>
                                        )}
                                    />
                                )}
                            </>
                        </MenuWrapper>
                    </StyledContainer>
                </Wrapper>
            )}
        </>
    );
};
