type UseCxmlSessionType = {
    isCxmlSession: boolean;
    hookUrl?: string;
    punchoutDataId?: string;
};

export const useCxmlSession = (): UseCxmlSessionType => {
    if (typeof window === 'undefined') {
        return { isCxmlSession: false };
    }

    const cxmlSessionString = localStorage.getItem('cxmlSession');

    if (cxmlSessionString) {
        const sessionData = JSON.parse(cxmlSessionString);

        return {
            isCxmlSession: true,
            hookUrl: sessionData.hookUrl,
            punchoutDataId: sessionData.punchoutDataId
        };
    }

    return { isCxmlSession: false };
};
