import type { OrderDetail, QueryOrderArgs, ShippingAddress } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import ORDER_DETAILS_QUERY from './getOrderDetailsQuery';
import type { OrderLineItem } from './mappers/ordersSearchMapper';
import type { orderHeadObject } from './types';
import { ORDER_STATE, POSITION_STATE, orderStateMap } from './types';

type Data = {
    order: OrderDetail | undefined;
};

type UserOrderDetails = {
    shippingAddress?: ShippingAddress;
    billingAddress?: ShippingAddress;
    orderHead: orderHeadObject;
    orderType?: string;
    loading: boolean;
    lineItems?: OrderLineItem[];
};
const getDesiredDeliveryDate = (order?: OrderDetail): Date | undefined => {
    let desiredDeliveryDate: Date | undefined;

    if (order?.orderHead?.desiredDeliveryDate) {
        desiredDeliveryDate = new Date(order?.orderHead?.desiredDeliveryDate);
    } else {
        let earliestLineItemDate: Date | undefined;
        order?.orderDetailLineItems?.forEach((lineItem) => {
            if (
                lineItem.desiredDeliveryDate &&
                (!earliestLineItemDate || new Date(lineItem.desiredDeliveryDate) < earliestLineItemDate)
            ) {
                earliestLineItemDate = new Date(lineItem.desiredDeliveryDate);
            }
        });

        desiredDeliveryDate = earliestLineItemDate;
    }

    return desiredDeliveryDate;
};

const useOrderDetails = (orderNumber: string): UserOrderDetails => {
    const { loading, data } = useQuery<Data, QueryOrderArgs>(ORDER_DETAILS_QUERY, {
        variables: {
            orderNumber
        }
    });
    const mapOrderState = (state = ''): ORDER_STATE => {
        const key = parseInt(state, 10) as keyof typeof orderStateMap;

        return orderStateMap[key] || ORDER_STATE.UNKNOWN;
    };

    const orderHead: orderHeadObject = {
        ...(data?.order?.orderHead || {}),
        ...{ orderDate: data?.order?.orderHead?.orderDate ? new Date(data?.order?.orderHead?.orderDate) : undefined },
        ...{
            deliveryDate: data?.order?.orderHead?.deliveryDate
                ? new Date(data?.order?.orderHead?.deliveryDate)
                : undefined
        },
        ...{ desiredDeliveryDate: getDesiredDeliveryDate(data?.order) },
        ...{
            orderState: data?.order?.orderHead?.orderState
                ? mapOrderState(data?.order?.orderHead?.orderState)
                : undefined
        }
    };

    const lineItems: OrderLineItem[] | undefined = data?.order?.orderDetailLineItems?.map((item) => {
        let state;

        switch (item.state) {
            case '1':
                state = POSITION_STATE.OPEN;
                break;
            case '3':
                state = POSITION_STATE.DELIVERED;
                break;
            case '4':
                state = POSITION_STATE.CANCELED;
                break;
            case '9':
                state = POSITION_STATE.RESIDUES;
                break;
            default:
                state = POSITION_STATE.OPEN;
                break;
        }

        const lineItemQuantity = item.lineItemQuantity ? item.lineItemQuantity.toString() : undefined;
        const deliveryQuantity = item.deliveryQuantity ? item.deliveryQuantity.toString() : undefined;

        const estimatedDeliveryDate =
            (item.estimatedDeliveryDate && new Date(item?.estimatedDeliveryDate)) || undefined;
        const deliveryDate = (item.deliveryDate && new Date(item?.deliveryDate)) || undefined;

        return {
            ...item,
            ...{
                state,
                lineItemQuantity,
                deliveryQuantity,
                estimatedDeliveryDate,
                deliveryDate,
                __typename: 'QscOrderLineItem'
            }
        };
    });

    return {
        loading,
        orderHead,
        lineItems,
        orderType: data?.order?.orderHead?.orderType || ORDER_STATE.UNKNOWN,
        shippingAddress: data?.order?.shippingAddress,
        billingAddress: data?.order?.billingAddress
    };
};

export default useOrderDetails;
