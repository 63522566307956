import { gql } from '@apollo/client';

export const SEARCH_SUGGEST_V2 = gql`
    query SearchSuggest_V2($query: String!, $sid: String) {
        suggestV2(query: $query, sid: $sid) {
            image
            name
            searchParams {
                query
            }
            type
        }
    }
`;
