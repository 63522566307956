import type { Cart } from '@ab-core/graphql/dist';
import { gql } from '@apollo/client';

export type CreateMyCartResponseType = {
    createMyCart: Cart;
};

const CREATE_MY_CART = gql`
    mutation createMyCart($cartInput: CartInput!) {
        createMyCart(cartInput: $cartInput) {
            id
            custom {
                customFieldsRaw {
                    name
                    value
                }
            }
        }
    }
`;

export default CREATE_MY_CART;
