import type { Query, QueryProductsV2Args } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { PRODUCTS_V2 } from './gql/productsV2';

type QueryDataType = Pick<Query, 'productsV2'>;
type OutgoingDataType = QueryDataType['productsV2'];
type IncomingDataType = {
    skus: Array<string>;
    offset?: number;
    limit?: number;
};

const onError = getErrorLoggerFunction('productsV2');

export const useProductsV2 = (props: IncomingDataType) => {
    const { skus, offset = 0, limit = skus.length } = props;
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const skip = skus.length === 0;

    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryProductsV2Args>(PRODUCTS_V2, {
        variables: {
            skus,
            offset,
            limit
        },
        skip,
        onError
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(data.productsV2);
        }
    }, [data]);

    return {
        products: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
