import { BRAND_LOGO_VERSION, BrandLogo } from '@ab-core/brand-logo';
import { BREAKPOINT } from '@ab-core/breakpoints';
import { ROUTES, useIsMobile } from '@ab-core/functions';
import { useAuth } from '@ab-core/hooks';
import { LINK_VARIANT, Link } from '@ab-core/link';
import type { FC } from 'react';
import React from 'react';
import { BrandLogoContainer, BrandWrapper } from './styled';

export const Brand: FC = () => {
    const handleClick = () => sessionStorage.setItem('@@scroll|/de-de|initial', '0');
    const { user } = useAuth();
    const homeLink = user ? ROUTES.SHOP : ROUTES.START;

    const isMobile = useIsMobile(BREAKPOINT.SM);

    return (
        <Link variant={LINK_VARIANT.LINK_ONLY} to={homeLink} onClick={handleClick} className="brand-button-link">
            <BrandWrapper>
                <BrandLogoContainer>
                    <BrandLogo version={isMobile ? BRAND_LOGO_VERSION.MOBILE : BRAND_LOGO_VERSION.DESKTOP} />
                </BrandLogoContainer>
            </BrandWrapper>
        </Link>
    );
};
