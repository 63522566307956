import type { OrderFilter, QueryOrdersArgs, SearchResult } from '@ab-core/graphql/dist';
import type { ApolloClient } from '@apollo/client';
import { useQuery } from '@apollo/client';
import ORDERS_SEARCH_QUERY from './getOrdersSearchQuery';
import ordersSearchMapper from './mappers/ordersSearchMapper';
import type { MappedSearchOrder } from './types';

type Data = {
    orders: SearchResult | undefined;
};

export type UserOrders = {
    results?: MappedSearchOrder[];
    lastPage?: number;
    currentPage?: number;
    loading: boolean;
    searchResultCount?: number;
    client: ApolloClient<unknown>;
};

const useSearchOrders = (filter?: OrderFilter, query = '*', page = 1): UserOrders => {
    const { loading, data, client } = useQuery<Data, QueryOrdersArgs>(ORDERS_SEARCH_QUERY, {
        fetchPolicy: 'no-cache',
        variables: {
            query,
            filter,
            page
        },
        skip: !filter
    });

    return {
        client,
        loading,
        results: ordersSearchMapper(data?.orders?.results) || [],
        lastPage: data?.orders?.pageCount || 1,
        currentPage: data?.orders?.currentPage || 1,
        searchResultCount: data?.orders?.total || 0
    };
};

export default useSearchOrders;
