import type { AbcloudCategoryEdge } from '@ab-core/gatsby-theme-website/contentful-graphql-types';
import { graphql, useStaticQuery } from 'gatsby';
import categoryToNavigationElementMapper from '../mappers/categoryToNavigationElementMapper';
import combinedNavigationElementMapper from '../mappers/combinedNavigationElementMapper';
import type { NavigationElement } from '../mappers/navigationElementMapper';
import navigationElementMapper from '../mappers/navigationElementMapper';

type ContentfulNode = {
    identifier: string;
};

type ContentfulNav = {
    node: ContentfulNode;
};

const useNavigation = (slug: string): NavigationElement | undefined => {
    const { allAbcloudCategory, allContentfulNavigationElement } = useStaticQuery(
        graphql`
            query navigationQuery {
                allAbcloudCategory {
                    edges {
                        node {
                            id
                            slug
                            name
                            orderHint
                            remoteParent {
                                id
                            }
                            custom {
                                customFieldsRaw {
                                    name
                                    value
                                }
                            }
                        }
                    }
                }
                allContentfulNavigationElement {
                    ...ContentfulNavigation
                }
            }
        `
    );

    const categories = allAbcloudCategory.edges.map((item: AbcloudCategoryEdge) => ({
        parent: item.node.remoteParent,
        id: item.node.id,
        externalId: item.node.externalId,
        slug: item.node.slug,
        name: item.node.name,
        description: item.node.description,
        stagedProductCount: item.node.stagedProductCount,
        orderHint: item.node.orderHint,
        item: item.node.item,
        metaTitle: item.node.metaTitle,
        metaDescription: item.node.metaDescription,
        custom: item.node.custom
    }));
    const categoryNavigationElement = categoryToNavigationElementMapper(categories);
    const rootNavigation = allContentfulNavigationElement.edges.find(
        (elem: ContentfulNav) => elem.node.identifier === 'root'
    );
    const combinedNavigationElement = combinedNavigationElementMapper(rootNavigation.node, categoryNavigationElement);

    return navigationElementMapper(combinedNavigationElement, slug);
};

export default useNavigation;
