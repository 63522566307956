import type { Availability, QueryProductAvailabilityWithChannelArgs } from '@ab-core/graphql/dist';
import { useAuth } from '@ab-core/hooks';
import getActiveCart from '@ab-core/hooks/cartNew/activeCart/useActiveCart';
import { DeliveryMethod } from '@ab-core/shippingaddress/types';
import type { ApolloError, ApolloQueryResult } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { AVAILABILITY } from './getAvailabilityQuery';

type Data = {
    productAvailabilityWithChannel: Availability | undefined;
};

export type UseAvailability = {
    availabilityLoading: boolean;
    availabilityError?: ApolloError;
    availability?: Availability;
    refetch: (_: QueryProductAvailabilityWithChannelArgs) => Promise<ApolloQueryResult<Data>>;
};

const useAvailability = (sku: string, isOnlineAvailable = false): UseAvailability => {
    const { user } = useAuth();
    const skip = user ? !user : true;
    const { activeCart } = getActiveCart(skip);

    const getExternalId = () => {
        if (activeCart?.displayShippingMethod !== DeliveryMethod.PROJECT) {
            return activeCart?.shippingAddress?.externalId || undefined;
        }

        if (user && user.deliveryAddresses && user.deliveryAddresses.length > 0) {
            return user.deliveryAddresses[0]?.externalId;
        }

        return undefined;
    };

    const { loading, error, data, refetch } = useQuery<Data, QueryProductAvailabilityWithChannelArgs>(AVAILABILITY, {
        variables: {
            sku,
            cartId: activeCart?.id,
            externalId: getExternalId()
        },
        skip: !sku || !activeCart?.id || !user || !isOnlineAvailable
    });

    return {
        availabilityLoading: loading,
        availabilityError: error,
        availability: data?.productAvailabilityWithChannel,
        refetch
    };
};

export default useAvailability;
