import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Percent: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
            <path
                fill={color}
                d="M289.899-540Q236-540 198-578.101t-38-92Q160-724 198.101-762t92-38Q344-800 382-761.899t38 92Q420-616 381.899-578t-92 38Zm-.017-60Q319-600 339.5-620.382q20.5-20.383 20.5-49.5Q360-699 339.618-719.5q-20.383-20.5-49.5-20.5Q261-740 240.5-719.618q-20.5 20.383-20.5 49.5Q220-641 240.382-620.5q20.383 20.5 49.5 20.5Zm380.017 440Q616-160 578-198.101t-38-92Q540-344 578.101-382t92-38Q724-420 762-381.899t38 92Q800-236 761.899-198t-92 38Zm-.017-60Q699-220 719.5-240.382q20.5-20.383 20.5-49.5Q740-319 719.618-339.5q-20.383-20.5-49.5-20.5Q641-360 620.5-339.618q-20.5 20.383-20.5 49.5Q600-261 620.382-240.5q20.383 20.5 49.5 20.5ZM202-160l-42-42 598-598 42 42-598 598Z"
            />
        </SVG>
    );
};
