type checkForSessionCart = {
    ociSessionId?: string;
};

type UseIdsSessionType = {
    checkForSessionCart: () => checkForSessionCart;
};

export const useSessionCart = (): UseIdsSessionType => {
    const checkForSessionCart = () => {
        if (typeof window === 'undefined') {
            return { ociSessionId: undefined };
        }

        const isOciSessionString = localStorage.getItem('ociSessionId');
        if (isOciSessionString) {
            return {
                ociSessionId: isOciSessionString
            };
        }
        return { ociSessionId: undefined };
    };

    return { checkForSessionCart };
};
