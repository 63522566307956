import { Colors } from '@ab-core/colors';
import styled from 'styled-components';

export const DesktopBarWrapper = styled.div`
    height: 100px;
    display: flex;
`;

export const VerticalDivider = styled.div`
    height: 100px;
    width: 1px;
    border-left: 1px solid ${Colors.gray15};
`;
