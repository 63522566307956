import type { WithTestId } from '@ab-core/testing';
import type { WithTutorialId } from '@ab-core/tutorials';
import { getTutorialIdProp } from '@ab-core/tutorials';
import type { FC } from 'react';
import React from 'react';
import { TABS_STATE } from '../tabState';
import { TabListContainer } from './styled';
import type { TabListItemProps } from './tabListItem';
import { TabListItem } from './tabListItem';

type HeaderListProps = Omit<TabListItemProps, 'onClick' | 'styleType'> & { disabled?: boolean };

export type TabListProps = WithTestId &
    WithTutorialId &
    React.ComponentPropsWithoutRef<'div'> & {
        currentTabIndex: number;
        handleTabChange: (e: number) => void;
        tabListItems: Array<HeaderListProps>;
    };

export const TabList: FC<TabListProps> = (props) => {
    const { tabListItems, handleTabChange, currentTabIndex = 0, tutorialId, ...rest } = props;

    const getStyle = (index: number, tabListItem: HeaderListProps): TABS_STATE => {
        if (tabListItem.disabled) {
            return TABS_STATE.DISABLED;
        }

        if (currentTabIndex === index) {
            return TABS_STATE.ACTIVE;
        }

        return TABS_STATE.INACTIVE;
    };

    return (
        <TabListContainer {...rest} {...getTutorialIdProp(tutorialId)}>
            {tabListItems.map((tabListItem, index) => (
                <TabListItem
                    {...tabListItem}
                    styleType={getStyle(index, tabListItem)}
                    onClick={() => handleTabChange(index)}
                    key={index}
                />
            ))}
        </TabListContainer>
    );
};
