import { useAuth } from '@ab-core/hooks';
import { graphql, useStaticQuery } from 'gatsby';

type ResourceType = {
    value: { value: string };
    key: string;
};

type NodesType = {
    name: string;
    resources: Array<ResourceType>;
};

type ContentfulResourceSetType = {
    allContentfulResourceSet: {
        nodes: Array<NodesType>;
    };
};

export const useIsFeatureVisible = () => {
    const { allContentfulResourceSet } = useStaticQuery<ContentfulResourceSetType>(
        graphql`
            query resourceQuery {
                allContentfulResourceSet {
                    nodes {
                        name
                        resources {
                            value {
                                value
                            }
                            key
                        }
                    }
                }
            }
        `
    );
    const { nodes } = allContentfulResourceSet;

    const featureAccessListNode = nodes.filter((resource) => resource?.name === 'featureAccessList');

    const featureAccessList: { [key: string]: Array<string> } = {};

    for (const feature of featureAccessListNode[0].resources) {
        featureAccessList[feature.key.replace('featureAccessList.', '')] = feature?.value?.value
            ?.replaceAll(' ', '')
            .split(',');
    }

    const isFeatureVisible = (featureName: string, isSite = false): boolean => {
        const { user, userLoading } = useAuth();
        const accountNumber = user?.activeAccount?.accountNumber;

        if (!featureName) {
            return true;
        }

        if (userLoading) {
            return !!isSite;
        }

        if (!accountNumber) {
            return false;
        }

        if (featureName) {
            return featureAccessList[featureName]?.some((access) => access === accountNumber);
        }

        return true;
    };

    return { isFeatureVisible };
};
