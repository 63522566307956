import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Logout: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_6_135)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 2.4C0 1.07452 1.07452 0 2.4 0H11.6C12.9255 0 14 1.07452 14 2.4V5C14 5.27614 13.7761 5.5 13.5 5.5C13.2239 5.5 13 5.27614 13 5V2.4C13 1.6268 12.3732 1 11.6 1H2.4C1.6268 1 1 1.6268 1 2.4V17.6C1 18.3732 1.6268 19 2.4 19H11.6C12.3732 19 13 18.3732 13 17.6V15C13 14.7239 13.2239 14.5 13.5 14.5C13.7761 14.5 14 14.7239 14 15V17.6C14 18.9255 12.9255 20 11.6 20H2.4C1.07452 20 0 18.9255 0 17.6V2.4ZM17.1464 7.64645C17.3417 7.45118 17.6583 7.45118 17.8536 7.64645L19.8536 9.64645C20.0488 9.84171 20.0488 10.1583 19.8536 10.3536L17.8536 12.3536C17.6583 12.5488 17.3417 12.5488 17.1464 12.3536C16.9512 12.1583 16.9512 11.8417 17.1464 11.6464L18.2929 10.5L8.5 10.5C8.22386 10.5 8 10.2761 8 10C8 9.72386 8.22386 9.5 8.5 9.5L18.2929 9.5L17.1464 8.35355C16.9512 8.15829 16.9512 7.84171 17.1464 7.64645Z"
                    fill={color}
                    stroke={color}
                    strokeWidth="0.25"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_6_135">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </SVG>
    );
};
