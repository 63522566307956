import { makeVar } from '@apollo/client';
import type { ToastType } from './basic';

export const toastListState = makeVar<Array<ToastType>>([]);

export const addToast = (toast: ToastType) => {
    const toastList = toastListState();
    const existingToastIndex = toastList.findIndex(
        (element) => element.title === toast.title && element.description === toast.description
    );

    if (existingToastIndex === -1) {
        toastListState([toast, ...toastList]);
    } else {
        toastList[existingToastIndex].appearance = new Date().getTime();
        toastListState(toastList);
    }
};

export const deleteToastByIndex = (index: number) => {
    const toastList = toastListState();

    if (typeof toastList[index] !== 'undefined') {
        const newToastList = [...toastList];
        newToastList.splice(index, 1);
        toastListState(newToastList);
    }
};
