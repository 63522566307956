import type { WithTestId } from '@ab-core/testing';
import type { FC, ReactNode } from 'react';
import React from 'react';
import type { ColProps as BSColProps, ContainerProps as BSContainerProps, RowProps } from 'styled-bootstrap-grid';
import { ABCol, ABContainer, ABRow } from './styled';

export type ColProps = {
    onClick?: () => void;
} & BSColProps;

export type ContainerProps = {
    noPadding?: boolean;
    fixed?: boolean;
    children?: ReactNode;
    className?: string;
} & BSContainerProps &
    WithTestId;

export const Container: FC<ContainerProps> = ({ fixed, noPadding, children, className, ...rest }) => (
    <ABContainer className={className} fixed={fixed} noPadding={noPadding} data-grid="container" {...rest}>
        {children}
    </ABContainer>
);

export { ABCol as Col, ABRow as Row };

export type { RowProps };
