type ParagraphType = {
    title: FontType;
    price: FontType;
    priceBig: FontType;
    subTitle: FontType;
    body: FontType;
    button: FontType;
    caption: FontType;
    overline: FontType;
    overlineBlack: FontType;
};

type FontType = {
    fontSize: string;
    lineHeight: string;
    fontWeight?: number;
    letterSpacing?: string;
    textTransform?: string;
};

export const FontTypes: ParagraphType = {
    title: {
        fontWeight: 700,
        fontSize: '1.25rem',
        lineHeight: '1.25rem',
        letterSpacing: '0.5px'
    },

    price: {
        fontWeight: 900,
        fontSize: '1.5rem',
        lineHeight: '1.5rem'
    },
    priceBig: {
        fontSize: ' 2.25rem',
        lineHeight: '2.25rem',
        letterSpacing: '1px',
        fontWeight: 900
    },

    subTitle: {
        fontSize: '0.875rem',
        lineHeight: '1.5rem',
        letterSpacing: '0.1px',
        fontWeight: 500
    },
    body: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        letterSpacing: '0.5px',
        fontWeight: 400
    },
    button: {
        fontSize: '0.875rem',
        lineHeight: '1rem',
        letterSpacing: '1.25px',
        fontWeight: 700,
        textTransform: 'uppercase'
    },
    caption: {
        fontSize: '0.75rem',
        lineHeight: '1rem',
        letterSpacing: '.4px',
        fontWeight: 400
    },
    overline: {
        fontSize: '0.75rem',
        lineHeight: '1rem',
        letterSpacing: '1.5px',
        fontWeight: 500,
        textTransform: 'uppercase'
    },
    overlineBlack: {
        fontWeight: 950,
        fontSize: '0.875rem',
        lineHeight: '0.875rem',
        letterSpacing: '4px',
        textTransform: 'uppercase'
    }
};

export enum FontTypesList {
    Subtitle = 'subTitle',
    Body = 'body',
    Button = 'button',
    Caption = 'caption',
    Overline = 'overline',
    OverLineBlack = 'overlineBlack',
    Title = 'title',

    Price = 'price',
    PriceBig = 'priceBig'
}
