import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Circle: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} version="1.1" x="0px" y="0px" viewBox="0 0 20 20">
            <path
                d="M10,20C4.5,20,0,15.5,0,10S4.5,0,10,0s10,4.5,10,10S15.5,20,10,20z M10,1.2c-4.8,0-8.8,3.9-8.8,8.8s3.9,8.8,8.8,8.8s8.8-3.9,8.8-8.8S14.8,1.2,10,1.2z"
                fill={color}
            />
        </SVG>
    );
};
