import { gql } from '@apollo/client';
import { CART_OBJECT_WITHOUT_LINE_ITEMS } from '../../fragments/cartFragmentWithOutLineItems';

export const SHARED_CARTS = gql`
    query getSharedCarts {
        getSharedCarts {
            ...ABCartFields
        }
    }
    ${CART_OBJECT_WITHOUT_LINE_ITEMS}
`;
