import type { Mutation, MutationSetProductPortfolioRestrictionArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { SET_PRODUCT_PORTFOLIO_RESTRICTION } from './gql/setProductPortfolioRestriction';

type MutationDataType = Pick<Mutation, 'setProductPortfolioRestriction'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('setProductPortfolioRestriction');

export const useSetProductPortfolioRestriction = (contactId = '', hiddenCategoryIds: Array<string> = []) => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [setProductPortfolioRestriction, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationSetProductPortfolioRestrictionArgs
    >(SET_PRODUCT_PORTFOLIO_RESTRICTION, {
        variables: {
            contactId,
            hiddenCategoryIds
        },
        onError
    });

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        setProductPortfolioRestriction,
        loading,
        response
    };
};
