import { gql } from '@apollo/client';
import { AVAILABILITY_FRAGMENT } from '../fragments/availabilityFragment';

export const AVAILABILITY = gql`
    query productAvailabilityWithChannel($sku: String!, $cartId: String!, $externalId: String) {
        productAvailabilityWithChannel(sku: $sku, cartId: $cartId, externalId: $externalId) {
            aggregatedMainChannelQuantity
            procuredProduct
            deliveryFromFactory
            deliveryTimeInfoIdentifier
            mainChannelAvailability {
                ...availabilityFields
            }
            cartChannelAvailability {
                ...availabilityFields
            }
            pickupChannelsAvailability {
                ...availabilityFields
            }
            zajadaczChannelAvailability {
                ...availabilityFields
            }
            phillipsburgChannelAvailability {
                ...availabilityFields
            }
        }
    }
    ${AVAILABILITY_FRAGMENT}
`;
