import { Icon } from '@ab-core/icon';
import Text from '@ab-core/text';
import styled from 'styled-components';

type ListElementProps = {
    selected?: boolean;
};

export const Wrapper = styled.div`
    width: 100%;
    padding-top: 20px;
`;

export const Label = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ListElement = styled.div`
    cursor: pointer;
    &:not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const SelectedCustomerLabel = styled.div<ListElementProps>`
    display: flex;
    justify-content: space-between;
    height: ${({ selected }) => (selected ? '20px' : '0px')};
    overflow: ${({ selected }) => (selected ? 'visible' : 'hidden')};
    opacity: ${({ selected }) => (selected ? 1 : 0)};
    transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
`;

export const UserLockedInfo = styled.div`
    display: flex;
`;

export const IconStyled = styled(Icon)`
    margin-top: 5px;
    position: relative;
    left: -5px;
`;

export const LockedTextStyled = styled(Text)`
    margin-top: 5px;
`;
