import type { DocumentWrapper, OrderDocument, QscOrderLineItem } from '@ab-core/graphql/dist';
import type { MoneyObject } from '@ab-core/types/src';
import type { MappedSearchOrder } from '../types';
import { ORDER_STATE, POSITION_STATE, allowedOrderTypes, orderStateMap } from '../types';

const filterAllowedOrderTypes = (type = ''): string => {
    if (allowedOrderTypes?.includes(type)) {
        return `order.orderType${type}`;
    }

    return 'order.unsupportedType';
};

const mapOrderState = (state = ''): ORDER_STATE => {
    const key = parseInt(state, 10) as keyof typeof orderStateMap;

    return orderStateMap[key] || ORDER_STATE.UNKNOWN;
};

export type OrderLineItem = {
    positionPrice?: MoneyObject;
    estimatedDeliveryDate?: Date;
    deliveryDate?: Date;
} & Omit<QscOrderLineItem, 'positionPrice' | 'estimatedDeliveryDate' | 'deliveryDate'>;

const ordersSearchMapper = (data: DocumentWrapper[] | undefined | null): MappedSearchOrder[] | undefined => {
    if (!data || !Array.isArray(data)) {
        return undefined;
    }

    return data.map((result: DocumentWrapper) => {
        const orderDocument: OrderDocument = result.unionDocument as OrderDocument;

        const returnLineItems: OrderLineItem[] | undefined = orderDocument?.qscOrderLineItems?.map((item) => {
            let state;

            switch (item.state) {
                case '1':
                    state = POSITION_STATE.OPEN;
                    break;
                case '3':
                    state = POSITION_STATE.DELIVERED;
                    break;
                case '4':
                    state = POSITION_STATE.CANCELED;
                    break;
                case '9':
                    state = POSITION_STATE.RESIDUES;
                    break;
                default:
                    state = POSITION_STATE.OPEN;
                    break;
            }

            const estimatedDeliveryDate =
                (item.estimatedDeliveryDate && new Date(item?.estimatedDeliveryDate)) || undefined;
            const deliveryDate = (item.deliveryDate && new Date(item?.deliveryDate)) || undefined;
            const positionPrice = item.positionPrice
                ? {
                      centAmount: parseInt(item.positionPrice),
                      currencyCode: 'EUR',
                      fractionDigits: 2
                  }
                : undefined;

            return {
                ...item,
                ...{
                    state,
                    positionPrice,
                    estimatedDeliveryDate,
                    deliveryDate
                }
            };
        });

        return {
            orderNumber: (orderDocument?.orderNumber as string) || '',
            date: (orderDocument?.orderDate && new Date(orderDocument?.orderDate)) || undefined,
            bindingPeriod: (orderDocument?.bindingPeriod && new Date(orderDocument?.bindingPeriod)) || '',
            type: filterAllowedOrderTypes(orderDocument?.orderDeliveryType),
            project: orderDocument?.project || '',
            costCenter: orderDocument?.costCenter || '',
            orderer: orderDocument?.purchaser || '',
            state: mapOrderState(orderDocument?.orderState),
            orderText: orderDocument?.orderText || '',
            value: orderDocument?.totalPrice,
            lineItems: returnLineItems || [],
            lineItemsTotal: orderDocument?.lineItemsTotal || ''
        } as MappedSearchOrder;
    });
};

export default ordersSearchMapper;
