import { useEffect } from 'react';

const useOnPressEscapeKey = (handler: (e: KeyboardEvent) => void): void => {
    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key !== 'Escape') {
                return;
            }

            handler(event);
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);
};

export default useOnPressEscapeKey;
