import { useEffect } from 'react';

export const useHandleSalesViewChange = (handler: () => void) => {
    useEffect(() => {
        window.addEventListener('storage_ab_salesView', handler);

        return () => {
            window.removeEventListener('storage_ab_salesView', handler);
        };
    }, []);
};
