import type { WithTestId } from '@ab-core/testing';
import { getTestIdProp } from '@ab-core/testing';
import Text, { FontTypes } from '@ab-core/text';
import React, { useEffect, useState } from 'react';
import type { BasicInputProps } from './basic';
import { BasicInput } from './basic';
import { CounterWrapper, InlineWrapper } from './index.styled';

export type { BasicInputProps };

export { BasicInput };

export type InputProps = WithTestId &
    Omit<BasicInputProps, 'error'> & {
        errorMessage?: string;
    };

export const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const { maxLength, errorMessage, value, onInput, ...rest } = props;
    const [countedCharacters, setCountedCharacters] = useState(value?.toString().length || 0);

    useEffect(() => {
        setCountedCharacters(value?.toString().length || 0);
    }, [value]);

    const customOnInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onInput) {
            onInput(event);
        }
    };

    const error = errorMessage !== undefined && errorMessage !== '';

    return (
        <div>
            <BasicInput
                {...rest}
                ref={ref}
                error={error}
                value={value}
                maxLength={maxLength}
                onInput={(event: React.ChangeEvent<HTMLInputElement>) => customOnInput(event)}
            />
            <InlineWrapper>
                {error && (
                    <Text
                        type={FontTypes.Caption}
                        color="error"
                        {...getTestIdProp(rest['data-cy' as keyof typeof rest] + '-error-message')}
                    >
                        {errorMessage}
                    </Text>
                )}
                {maxLength && (
                    <CounterWrapper>
                        <Text type={FontTypes.Caption} color="gray50">{`${countedCharacters} / ${maxLength}`}</Text>
                    </CounterWrapper>
                )}
            </InlineWrapper>
        </div>
    );
});
