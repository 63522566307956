import type { OutstandingReceivable } from '@ab-core/graphql/dist/index';
import { useQuery } from '@apollo/client';
import { GET_OUTSTANDING_RECEIVABLES } from './getInvoices';

type ReturnType = {
    invoices?: OutstandingReceivable[];
    pending: boolean;
};

export const useOutstandingReceivables = (): ReturnType => {
    const { loading, data } = useQuery(GET_OUTSTANDING_RECEIVABLES, {
        fetchPolicy: 'no-cache'
    });

    return {
        pending: loading,
        invoices: data?.getOutstandingReceivables
    };
};
