import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Visible: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 16C4.47715 16 0 9.5 0 9.5C0 9.5 4.47715 3 10 3C15.5228 3 20 9.5 20 9.5C20 9.5 15.5228 16 10 16ZM1.74751 9.71706C1.68442 9.64108 1.62516 9.56857 1.56986 9.5C1.62516 9.43143 1.68442 9.35892 1.74751 9.28294C2.22557 8.70724 2.91576 7.94183 3.76409 7.18006C5.50303 5.61855 7.70862 4.25 10 4.25C12.2914 4.25 14.497 5.61855 16.2359 7.18006C17.0842 7.94183 17.7744 8.70724 18.2525 9.28294C18.3156 9.35892 18.3748 9.43143 18.4301 9.5C18.3748 9.56857 18.3156 9.64108 18.2525 9.71706C17.7744 10.2928 17.0842 11.0582 16.2359 11.8199C14.497 13.3815 12.2914 14.75 10 14.75C7.70862 14.75 5.50303 13.3815 3.76409 11.8199C2.91576 11.0582 2.22557 10.2928 1.74751 9.71706ZM12.25 9.5C12.25 10.7426 11.2426 11.75 10 11.75C8.75736 11.75 7.75 10.7426 7.75 9.5C7.75 8.25736 8.75736 7.25 10 7.25C11.2426 7.25 12.25 8.25736 12.25 9.5ZM13.5 9.5C13.5 11.433 11.933 13 10 13C8.067 13 6.5 11.433 6.5 9.5C6.5 7.567 8.067 6 10 6C11.933 6 13.5 7.567 13.5 9.5Z"
                fill={color}
            />
        </SVG>
    );
};
