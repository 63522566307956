import { countryCodes } from './countryList';

export { countryCodes };

export const getCountryNameByCountryCode = (countryCode?: string) => {
    const country = countryCodes?.find((element) => element.id === countryCode);

    if (!country) {
        return countryCode;
    }

    return country.name;
};
