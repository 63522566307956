import { makeVar, useReactiveVar } from '@apollo/client';
import type { GlobalStoreType, StateType } from './types';

const _state: StateType = {
    triggerRemoveFile: makeVar<boolean>(true),
    ghostAccess: makeVar<boolean>(false)
};

export const globalStore: GlobalStoreType = {
    getters: {
        useTriggerRemoveFile: () => useReactiveVar(_state.triggerRemoveFile),
        useGhostAccess: () => useReactiveVar(_state.ghostAccess)
    },
    setters: {
        setTriggerRemoveFile: (value) => _state.triggerRemoveFile(value),
        setGhostAccess: (value) => _state.ghostAccess(value)
    }
};
