import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Search: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.9627 0C9.08223 0 5.92576 3.15651 5.92576 7.03704C5.92576 8.75532 6.54911 10.3287 7.57602 11.5509L0.180043 18.946C-0.0604618 19.1878 -0.05979 19.5783 0.180715 19.8201C0.301335 19.9395 0.459269 20 0.617189 20C0.77511 20 0.933498 19.939 1.05412 19.8196L8.44889 12.4238C9.67107 13.4507 11.2445 14.0741 12.9627 14.0741C16.8432 14.0741 19.9997 10.9176 19.9997 7.03704C19.9997 3.15651 16.8432 0 12.9627 0ZM7.16031 7.03704C7.16031 3.83707 9.76279 1.23457 12.9627 1.23457C16.1627 1.23457 18.7652 3.83707 18.7652 7.03704C18.7652 10.237 16.1627 12.8395 12.9627 12.8395C9.76279 12.8395 7.16031 10.237 7.16031 7.03704Z"
                fill={color}
            />
        </SVG>
    );
};
