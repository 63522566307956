import { Colors } from '@ab-core/colors';
import { RADIUS } from '@ab-core/radius';
import { SPACING } from '@ab-core/spacing';
import Text from '@ab-core/text';
import styled from 'styled-components';

export const Suggestion = styled.div`
    display: flex;
    padding: ${SPACING.SMALL};
    width: 100%;
    align-items: center;
    cursor: pointer;
    outline: none;
    border: 0;
    border-radius: ${RADIUS.ROUNDED};
    background-color: ${Colors.white};

    &.ab-suggestion-active {
        background-color: ${Colors.backgroundShade};
    }
    &:hover {
        background-color: ${Colors.backgroundShade};
    }
`;

export const SuggestionText = styled(Text)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
