import styled from 'styled-components';
import type { IconTagProps } from './types';

export const SVG = styled.svg<IconTagProps>`
    ${({ size }) => `
        width: ${size};
        height: ${size};
        min-width: ${size};
        min-height: ${size}
    `};
    transform: ${({ rotation }) => `rotate(${rotation}deg)`};
    transition: 0.5s ease-in-out;
`;
