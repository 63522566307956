import { Cart } from '@ab-core/graphql/dist/index';
import { useAuth } from '@ab-core/hooks';
import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import cartMapper, { MappedCart } from '../mappers/cartMapper';
import { SHARED_CARTS } from './getSharedCartsQuery';

type Data = {
    getSharedCarts?: Cart[];
};

type UseSharedCartsType = {
    sharedCarts?: MappedCart[];
    sharedCartsError?: ApolloError;
    sharedCartsLoading: boolean;
    sharedCartsRefetch: () => Promise<ApolloQueryResult<Data>>;
};
const useSharedCarts = (withActiveCart = false, skip = false): UseSharedCartsType => {
    const { error, data, loading, refetch } = useQuery<Data>(SHARED_CARTS, {
        fetchPolicy: 'network-only',
        skip
    });
    const { user } = useAuth();
    const [sharedCarts, setSharedCarts] = useState<Array<MappedCart>>([]);

    useEffect(() => {
        const mappedCarts = loading || error ? [] : data?.getSharedCarts?.map((cart) => cartMapper(cart));
        const filteredCarts = mappedCarts?.filter((cart) => cart?.createdBy?.contactId !== (user?.contact?.id || ''));
        const sharedCartsTemp = filteredCarts
            ?.filter((mappedCart) => (withActiveCart ? true : !mappedCart?.isActiveCart))
            ?.sort((a, b) => (b?.lastModifiedAt || new Date()).getTime() - (a?.lastModifiedAt || new Date()).getTime());
        setSharedCarts(sharedCartsTemp || []);
    }, [data]);

    return {
        sharedCarts,
        sharedCartsError: error,
        sharedCartsLoading: loading,
        sharedCartsRefetch: refetch
    };
};

export default useSharedCarts;
