import type { SearchParameter } from './searchFilterParameter';

export const getSearchQueryBySearchParameter = (searchParameter: SearchParameter) => {
    let returnString = getBasicArg(searchParameter);

    returnString += getFilterArg(searchParameter);
    returnString += getSortArg(searchParameter);
    returnString += getRowsArg(searchParameter);
    returnString += getPageArg(searchParameter);

    return returnString;
};

const getBasicArg = (searchParameter: SearchParameter) => {
    return `?query=${encodeParameter(searchParameter.productQueryString)}`;
};

const getSortArg = (searchParameter: SearchParameter) => {
    if (!searchParameter.sort) {
        return '';
    }

    return `&sort=${encodeParameter(searchParameter.sort)}`;
};

const getRowsArg = (searchParameter: SearchParameter) => {
    if (!searchParameter.hitsPerPage) {
        return '';
    }

    return `&hitsPerPage=${encodeParameter(searchParameter.hitsPerPage)}`;
};

const getPageArg = (searchParameter: SearchParameter) => {
    if (!searchParameter.page) {
        return '';
    }

    return `&page=${encodeParameter(searchParameter.page)}`;
};

const getFilterArg = (searchParameter: SearchParameter) => {
    if (searchParameter.filter.length === 0) {
        return '';
    }

    let filterArg = '';
    searchParameter.filter.forEach((filter) => {
        filterArg += `&filter=${encodeParameter(filter)}`;
    });

    return filterArg;
};

const encodeParameter = (value: string) => encodeURIComponent(value);
