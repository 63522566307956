import { BREAKPOINT } from '@ab-core/breakpoints';
import { useIsMobile } from '@ab-core/functions';
import { navigationStore } from '@ab-core/store';
import useNavigation from '@ab-core/wrappageelement/src/hooks/useNavigation';
import type { FC } from 'react';
import React, { useState } from 'react';
import { Menu } from './items';
import { setActivePath } from './pathMapper';
import { MenuWrapper } from './styled';
import type { NavigationElement } from './types';

type SiteMenuProps = {
    slug?: string;
};

export const SiteMenu: FC<SiteMenuProps> = ({ slug = '' }) => {
    const navigationElement = useNavigation(slug);
    const [stateElement, setState] = useState<NavigationElement | undefined>(navigationElement);
    const isMobile = useIsMobile(BREAKPOINT.LG);
    const menuOpen = navigationStore.getters.useShopNavigation();
    const openMenu = () => navigationStore.setters.setShopNavigation(true);
    const closeMenu = () => {
        if (isMobile) {
            return;
        } else {
            navigationStore.setters.setShopNavigation(false);
        }
    };

    const handleClick = (ancestors?: string[], id?: string) => {
        if (navigationElement && id) {
            const newState = setActivePath(id, navigationElement, ancestors);
            setState(newState);
        }
    };

    return (
        <>
            {menuOpen && (
                <MenuWrapper>
                    <Menu
                        {...stateElement}
                        first={true}
                        handleClick={handleClick}
                        onMouseEnter={openMenu}
                        onMouseLeave={closeMenu}
                        onLeave={closeMenu}
                    />
                </MenuWrapper>
            )}
        </>
    );
};
