import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Deactivated: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.75 10C18.75 14.8325 14.8325 18.75 10 18.75C7.80869 18.75 5.80553 17.9445 4.27045 16.6134L16.6134 4.27045C17.9445 5.80553 18.75 7.80869 18.75 10ZM3.38657 15.7295L15.7295 3.38657C14.1944 2.0555 12.1913 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 12.1913 2.0555 14.1944 3.38657 15.7295ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z"
                fill={color}
            />
        </SVG>
    );
};
