import { ApolloClient, useQuery } from '@apollo/client';
import { useAuth } from '@ab-core/hooks';
import type { QuerySearchArgs, SearchResult } from '@ab-core/graphql/dist/index';

import productSearchMapper, { MappedSearchResultDocument } from '@ab-core/hooks/search/mappers/productSearchMapper';
import LAST_PURCHASED_QUERY from '../order/getLastPurchasedQuery';

type Data = {
    lastPurchased: SearchResult | undefined;
};

type UseSearch = {
    results?: MappedSearchResultDocument[];
    lastPage?: number;
    currentPage?: number;
    loading: boolean;
    searchResultCount?: number;
    client: ApolloClient<unknown>;
};

const useLastPurchased = (query: string, page = 1): UseSearch => {
    const { loading, data, client } = useQuery<Data, QuerySearchArgs>(LAST_PURCHASED_QUERY, {
        variables: {
            query: query === '' ? '*' : query,
            page
        }
    });
    const { user } = useAuth();

    return {
        client,
        loading,
        results: productSearchMapper(data?.lastPurchased?.results, user),
        lastPage: data?.lastPurchased?.pageCount,
        currentPage: data?.lastPurchased?.currentPage,
        searchResultCount: data?.lastPurchased?.total
    };
};

export default useLastPurchased;
