import { BUTTON_VARIANT } from '@ab-core/button';
import { logIn } from '@ab-core/functions';
import { HorizontalLine } from '@ab-core/horizontalline';
import useText from '@ab-core/localization/src/useText';
import Spacer from '@ab-core/spacer';
import type { FC } from 'react';
import React from 'react';
import { TextButtonBox } from '../TextButtonBox';

type LoginType = {
    forceRedirect?: boolean;
    redirectAfterLogin?: boolean;
    id?: string;
};

export const Login: FC<LoginType> = ({ forceRedirect, redirectAfterLogin, id }) => {
    const loginNewShop = () => {
        if (forceRedirect) {
            logIn(forceRedirect);
        } else {
            logIn(redirectAfterLogin);
        }
    };

    const loginOldShop = () => {
        if (typeof window !== 'undefined') {
            window.open('https://shop.alexander-buerkle.de/login');
        }
    };

    const loginOldShopArticle = () => {
        if (typeof window !== 'undefined') {
            window.open(`https://shop.alexander-buerkle.de/article/articleinfo/${id}`);
        }
    };

    const headlineText = useText('loginBox.loginNewShopHeadline');
    const text = useText('loginBox.loginNewShopText');
    const buttonText = useText('loginBox.loginNewShopButtonText');

    const secondaryHeadlineText = useText('loginBox.loginOldShopHeadline');
    const secondaryText = useText('loginBox.loginOldShopText');
    const secondaryButtonText = useText('loginBox.loginOldShopButtonText');

    return (
        <>
            <TextButtonBox
                headlineText={headlineText}
                text={text}
                buttonText={buttonText}
                handleClick={loginNewShop}
                variant={BUTTON_VARIANT.FILL}
            />

            <Spacer size="sm" />
            <HorizontalLine />
            <Spacer size="sm" />

            <TextButtonBox
                headlineText={secondaryHeadlineText}
                text={secondaryText}
                buttonText={secondaryButtonText}
                handleClick={!id ? loginOldShop : loginOldShopArticle}
                variant={BUTTON_VARIANT.OUTLINE}
            />

            <Spacer size="md" />
        </>
    );
};
