import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Save: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 3.42307V14.3846C16 14.8449 15.8458 15.2292 15.5375 15.5375C15.2292 15.8458 14.8449 16 14.3846 16H1.61538C1.15513 16 0.770833 15.8458 0.4625 15.5375C0.154167 15.2292 0 14.8449 0 14.3846V1.61537C0 1.15512 0.154167 0.770833 0.4625 0.4625C0.770833 0.154167 1.15513 0 1.61538 0H12.5769L16 3.42307ZM15 3.85L12.15 1H1.61538C1.43589 1 1.28846 1.05769 1.17308 1.17307C1.05769 1.28846 1 1.43589 1 1.61537V14.3846C1 14.5641 1.05769 14.7115 1.17308 14.8269C1.28846 14.9423 1.43589 15 1.61538 15H14.3846C14.5641 15 14.7115 14.9423 14.8269 14.8269C14.9423 14.7115 15 14.5641 15 14.3846V3.85ZM8 12.5385C8.55128 12.5385 9.02244 12.343 9.41348 11.9519C9.80449 11.5609 10 11.0898 10 10.5385C10 9.98719 9.80449 9.51603 9.41348 9.125C9.02244 8.73397 8.55128 8.53845 8 8.53845C7.44872 8.53845 6.97756 8.73397 6.58653 9.125C6.19551 9.51603 6 9.98719 6 10.5385C6 11.0898 6.19551 11.5609 6.58653 11.9519C6.97756 12.343 7.44872 12.5385 8 12.5385ZM2.76923 5.76922H10.1923V2.76922H2.76923V5.76922ZM1 3.85V15V1V3.85Z"
                fill={color}
            />
        </SVG>
    );
};
