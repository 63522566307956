import type { HTMLAttributeAnchorTarget } from 'react';
import { LINK_STATE } from '.';

export const isUrlExternal = (url: string) => {
    const regex = new RegExp('^(http://|https://|www.)', 'i');

    return regex.test(url);
};

const isTargetBlank = (target: HTMLAttributeAnchorTarget) => target === '_blank';
const isToMail = (to: string) => to?.startsWith('mailto:');

export const getLinkState = (to: string, target?: HTMLAttributeAnchorTarget): LINK_STATE => {
    if (isToMail(to)) {
        return LINK_STATE.INTERNAL;
    }

    if (isUrlExternal(to)) {
        return LINK_STATE.EXTERNAL;
    }

    if (target && isTargetBlank(target)) {
        return LINK_STATE.INTERNAL_BLANK;
    }

    return LINK_STATE.INTERNAL;
};
