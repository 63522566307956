import { gql } from '@apollo/client';

export const GET_CONTACTS_WITHOUT_RIGHTS = gql`
    query GetContactsWithoutRights {
        getContactsWithoutRights {
            id
            salutation
            userName
            firstName
            lastName
            email
            onlineShopAccess
            fax
            title
            createdAt
            academicTitle
            function
            mobilePhone
            phone
        }
    }
`;
