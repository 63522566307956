import type { WithTutorialId } from '@ab-core/tutorials';
import type { FC, ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import { TabList, TabPanel } from './components';
import { getTabListItemsByTabs, getTabPanelItemsByTabs } from './utils';

export type TabProps = {
    head: ReactNode;
    content?: ReactNode;
};

export type TabsType = Array<TabProps>;

export type TabsProps = WithTutorialId &
    React.ComponentPropsWithoutRef<'div'> & {
        tabs: TabsType;
        activeTab?: number;
        tabClicked?: (n: number) => void;
    };

export const Tabs: FC<TabsProps> = (props) => {
    const { tabs, activeTab = 0, tabClicked, ...rest } = props;
    const [currentTabIndex, setCurrentTabIndex] = useState(activeTab);
    const handleTabChange = (tabIndex: number) => {
        if (tabIndex === currentTabIndex) {
            return;
        }

        setCurrentTabIndex(tabIndex);

        if (tabClicked) {
            tabClicked(tabIndex);
        }
    };

    useEffect(() => {
        setCurrentTabIndex(activeTab);
    }, [activeTab]);

    useEffect(() => {
        if (tabs.length <= currentTabIndex) {
            handleTabChange(0);
        }
    }, [tabs]);

    return (
        <div>
            <TabList
                {...rest}
                currentTabIndex={currentTabIndex}
                handleTabChange={handleTabChange}
                tabListItems={getTabListItemsByTabs(tabs)}
            />
            <TabPanel tabPanelItems={getTabPanelItemsByTabs(tabs)} currentTabIndex={currentTabIndex} />
        </div>
    );
};
