import { getTestIdProp } from '@ab-core/testing';
import { useReactiveVar } from '@apollo/client';
import type { FC } from 'react';
import React, { useEffect } from 'react';
import { Toast } from '../basic';
import { deleteToastByIndex, toastListState } from '../toastState';
import { Container } from './styled';

const INTERVAL = 5000;
const CHECK_INTERVAL = 1000;

export const ToastContainer: FC = () => {
    const toastList = useReactiveVar(toastListState);

    useEffect(() => {
        const interval = setInterval(() => {
            toastList.forEach((toast, key) => {
                if (new Date().getTime() - toast.appearance >= INTERVAL) {
                    deleteToast(key);
                }
            });
        }, CHECK_INTERVAL);

        return () => {
            clearInterval(interval);
        };
    }, [toastList]);

    const deleteToast = (index: number) => {
        deleteToastByIndex(index);
    };

    return (
        <Container {...getTestIdProp('toasts')}>
            {toastList.map((toast, index) => {
                return <Toast key={index} {...toast} deleteToast={() => deleteToast(index)} />;
            })}
        </Container>
    );
};
