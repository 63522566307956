import type { Query, QueryProductsByIdsV2Args } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { PRODUCT_BY_IDS_V2 } from './gql/productsByIdsV2';

type QueryDataType = Pick<Query, 'productsByIdsV2'>;
type OutgoingDataType = QueryDataType['productsByIdsV2'];

const onError = getErrorLoggerFunction('productsByIdsV2');

export const useProductsByIdsV2 = (ids: Array<string>) => {
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const skip = ids.length === 0;

    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryProductsByIdsV2Args>(PRODUCT_BY_IDS_V2, {
        variables: {
            ids
        },
        skip,
        onError
    });

    useEffect(() => {
        if (data) {
            setOutgoingData(data.productsByIdsV2);
        }
    }, [data]);

    return {
        products: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
