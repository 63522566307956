import ABHeadline, { HEADLINE_SIZES } from '@ab-core/headline';
import { Icon } from '@ab-core/icon';
import type { FC } from 'react';
import React from 'react';
import { HeadlineWrapper, IconButton } from './styled';

type HeadlineProps = {
    isEditMode: boolean;
    hasChanges: boolean;
    onSave: () => void;
    onCancel: () => void;
    onEdit: () => void;
};

export const Headline: FC<HeadlineProps> = (props) => {
    const { isEditMode, hasChanges, onCancel, onEdit, onSave } = props;

    const headlineText = isEditMode ? 'Favoriten (Bearbeitungsmodus)' : 'Favoriten';

    return (
        <HeadlineWrapper>
            <ABHeadline size={HEADLINE_SIZES.TWO}>{headlineText}</ABHeadline>
            {!isEditMode && (
                <IconButton onClick={onEdit}>
                    <Icon name="Edit" />
                </IconButton>
            )}
            {isEditMode && (
                <>
                    {!hasChanges && (
                        <IconButton onClick={onSave}>
                            <Icon name="Save" />
                        </IconButton>
                    )}
                    <IconButton onClick={onCancel}>
                        <Icon name="Close" />
                    </IconButton>
                </>
            )}
        </HeadlineWrapper>
    );
};
