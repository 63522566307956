import { useEffect, useState } from 'react';

const useIsIntersecting = (ref: { current: Element }): boolean => {
    const [hasIntersected, setHasIntersected] = useState(false);

    const observer =
        typeof window !== 'undefined' && new IntersectionObserver(([entry]) => setHasIntersected(entry.isIntersecting));

    useEffect(() => {
        if (!observer) {
            return;
        }
        observer.observe(ref.current);
        return () => observer.disconnect();
    }, [ref]);

    return hasIntersected;
};

export default useIsIntersecting;
