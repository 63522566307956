import type { SuggestResult } from '@ab-core/graphql/dist';
import { HorizontalLine } from '@ab-core/horizontalline';
import { Icon } from '@ab-core/icon';
import Text, { FontTypes } from '@ab-core/text';
import React from 'react';
import { Suggestion, SuggestionText, Suggestions } from './styled';

type SearchSuggestionsProps = {
    suggestResult?: SuggestResult[];
    activeSuggest?: number;
    submitSearch: (value?: string) => void;
};

export const SearchSuggestions = React.forwardRef<HTMLDivElement, SearchSuggestionsProps>((props, ref) => {
    const { suggestResult, activeSuggest, submitSearch } = props;
    const handleClick = (suggestion: SuggestResult) => {
        if (suggestion?.suggest) {
            submitSearch(suggestion?.suggest);
        }
    };

    return (
        <div className="px-small5" ref={ref}>
            <Text className="pb-small2" type={FontTypes.Overline}>
                Suchvorschläge
            </Text>
            <HorizontalLine />
            <Suggestions>
                {Array.isArray(suggestResult) &&
                    suggestResult?.map((suggestion: SuggestResult, i: number) => (
                        <Suggestion
                            active={i === activeSuggest}
                            key={i}
                            tabIndex={i}
                            onClick={() => handleClick(suggestion)}
                        >
                            <Icon name="Search" color="black" />
                            <SuggestionText>
                                <span dangerouslySetInnerHTML={{ __html: suggestion?.suggestHighlighted || '' }} />
                            </SuggestionText>
                        </Suggestion>
                    ))}
            </Suggestions>
        </div>
    );
});
