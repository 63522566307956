import type { Query } from '@ab-core/graphql/dist/index';
import { useFullUser } from '@ab-core/hooks/user/useFullUser';
import { useQuery } from '@apollo/client';
import { GET_CAN_RECEIVE_CART_CONTACTS } from './getContactsWithCanReceiveCartRight';

type Data = Pick<Query, 'RestrictedContactForCanReceiveCart'>;

export const useContactsWithCanReceiveRights = () => {
    const { user } = useFullUser(false);
    const canOrder = user?.featureAccess?.find((u) => u.feature.name === 'canOrder').feature.value;
    let skip = true;

    if (user && !canOrder) {
        skip = false;
    }

    const { data, loading } = useQuery<Data>(GET_CAN_RECEIVE_CART_CONTACTS, {
        fetchPolicy: 'network-only',
        skip
    });

    return {
        data,
        loading
    };
};
