import { useEffect, useState } from 'react';

const useIsIntersecting = (ref: { current: Element }): boolean => {
    const [hasIntersected, setHasIntersected] = useState(false);

    const observer =
        typeof window !== 'undefined' && new IntersectionObserver(([entry]) => setHasIntersected(entry.isIntersecting));

    useEffect(() => {
        if (observer) {
            observer?.observe(ref.current);
        }

        return () => {
            if (observer) {
                return observer.disconnect();
            }
        };
    }, [ref]);

    return hasIntersected;
};

export default useIsIntersecting;
