import { gql } from '@apollo/client';

export const GET_CATEGORIES_V2 = gql`
    query GetCategoriesV2 {
        getCategoriesV2 {
            name
            count
            children {
                name
                count
                children {
                    name
                    count
                    children {
                        name
                        count
                    }
                }
            }
        }
    }
`;
