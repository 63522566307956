import type { CleanStringRuleType } from './rules';
import { backslashNToBreak, noHtmlTags, noWhitespace } from './rules';

type CleanStringType = (value: string, rules?: Array<CleanStringRuleType>) => string;
export const cleanString: CleanStringType = (value, rules) => {
    let newValue = value;
    rules?.forEach((rule) => {
        newValue = rule(newValue);
    });
    return newValue;
};

export const cleanStringRules = { noWhitespace, noHtmlTags, backslashNToBreak };
