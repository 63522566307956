import { Icon } from '@ab-core/icon';
import type { FC } from 'react';
import React from 'react';
import { FavoriteEdit, FavoriteEditClickArea } from './styled';

type FavoriteEditButtonProps = React.ComponentPropsWithoutRef<'div'>;

export const FavoriteEditButton: FC<FavoriteEditButtonProps> = (props) => {
    const { ...rest } = props;

    return (
        <FavoriteEditClickArea {...rest}>
            <FavoriteEdit>
                <Icon name="Edit" />
            </FavoriteEdit>
        </FavoriteEditClickArea>
    );
};
