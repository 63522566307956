import type { FontTypes } from '@ab-core/text';
import type { FC } from 'react';
import React from 'react';
import { StyledBold } from './styled';

export type BoldProps = {
    children?: string;
    type: FontTypes;
};

const Bold: FC<BoldProps> = ({ children, type }: BoldProps) => (
    <StyledBold content={children} type={type} asElement="span" />
);

export default Bold;
