import { getTestIdProp } from '@ab-core/testing';
import type { FC, ReactNode } from 'react';
import React from 'react';
import { TABS_STATE } from '../tabState';
import { TabPanelItem } from './tabPaneltem';

export type TabPanelProps = React.ComponentPropsWithoutRef<'div'> & {
    tabPanelItems: Array<ReactNode>;
    currentTabIndex: number;
};

export const TabPanel: FC<TabPanelProps> = (props) => {
    const { tabPanelItems, currentTabIndex, ...rest } = props;

    const getStyle = (index: number): TABS_STATE => {
        if (index === currentTabIndex) {
            return TABS_STATE.ACTIVE;
        }

        return TABS_STATE.INACTIVE;
    };

    return (
        <div {...rest}>
            {tabPanelItems?.map((panelItem, index) => (
                <TabPanelItem
                    styleType={getStyle(index)}
                    key={index}
                    {...getTestIdProp(`tab-content-${panelItem?.props?.resourceKey}`)}
                >
                    {panelItem}
                </TabPanelItem>
            ))}
        </div>
    );
};
