import { getClassNames } from '@ab-core/functions/styles/classNameFormatter';
import type { WithChildren } from '@ab-core/utils/types';
import type { FC } from 'react';
import React from 'react';
import { TABS_STATE } from '../../tabState';
import { TabListItemContainer } from './styled';

export type TabListItemProps = WithChildren &
    React.ComponentPropsWithoutRef<'div'> & {
        styleType: TABS_STATE;
    };

export const TabListItem: FC<TabListItemProps> = (props) => {
    const { children, styleType, onClick, ...rest } = props;
    const newClassNames = getClassNames({ prefix: 'tab-list-item', modifier: { 'style-type': styleType } });

    return (
        <TabListItemContainer
            {...rest}
            className={newClassNames}
            onClick={styleType === TABS_STATE.DISABLED ? undefined : onClick}
        >
            {children}
        </TabListItemContainer>
    );
};
