import type { FC } from 'react';
import React from 'react';
import type { FooterProps } from './ui';
import FooterUi from './ui';

const socialmedia: FooterProps['socialmedia'] = [
    {
        iconname: 'Facebook',
        link: 'https://www.facebook.com/alexander.buerkle.de'
    },
    {
        iconname: 'Youtube',
        link: 'https://www.youtube.com/user/AlexanderBuerkle'
    },
    {
        iconname: 'Xing',
        link: 'https://www.xing.com/pages/alexanderburklegmbh-co-kg'
    },
    {
        iconname: 'Linkedin',
        link: 'https://de.linkedin.com/company/alexander-b%C3%BCrkle-gmbh-&-co-kg'
    },
    {
        iconname: 'Instagram',
        link: 'https://www.instagram.com/alexander.buerkle/'
    }
];

const links: FooterProps['links'] = [
    {
        name: 'Impressum',
        link: '/impressum'
    },
    {
        name: 'Datenschutz',
        link: '/datenschutz'
    },
    {
        name: 'AGB',
        link: '/agb'
    },
    {
        name: 'Zertifikate',
        link: '/zertifikate'
    },
    {
        name: 'Newsletter anmelden',
        link: 'https://go.alexander-buerkle.com/newsletteranmeldung-start/'
    }
];

const Footer: FC = () => <FooterUi homelink="/" socialmediaColor="primary" socialmedia={socialmedia} links={links} />;

export default Footer;
