import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Delete: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.4201 1.97531L8.05934 1.97531C8.2832 1.5356 8.73876 1.23457 9.26433 1.23457H12.2152C12.7407 1.23457 13.1963 1.5356 13.4201 1.97531ZM17.625 1.97531L14.7235 1.97531C14.4477 0.841479 13.4293 0 12.2152 0H9.26433C8.0502 0 7.03179 0.841479 6.756 1.97531L4.71516 1.97531L3.8545 1.97531C3.51498 1.97531 3.23975 2.25168 3.23975 2.59259C3.23975 2.93351 3.51498 3.20988 3.8545 3.20988H4.71516V18.3951C4.71516 19.2814 5.43077 20 6.31352 20H15.166C16.0487 20 16.7643 19.2814 16.7643 18.3951V3.20988H17.625C17.9645 3.20988 18.2397 2.93351 18.2397 2.59259C18.2397 2.25168 17.9645 1.97531 17.625 1.97531ZM5.94466 3.20988V18.3951C5.94466 18.5996 6.10981 18.7654 6.31352 18.7654H15.166C15.3697 18.7654 15.5348 18.5996 15.5348 18.3951V3.20988L5.94466 3.20988ZM8.40369 6.54319C8.40369 6.20227 8.12845 5.9259 7.78893 5.9259C7.44941 5.9259 7.17418 6.20227 7.17418 6.54319V15.4321C7.17418 15.773 7.44941 16.0494 7.78893 16.0494C8.12845 16.0494 8.40369 15.773 8.40369 15.4321V6.54319ZM11.3545 6.54319C11.3545 6.20227 11.0793 5.9259 10.7398 5.9259C10.4002 5.9259 10.125 6.20227 10.125 6.54319V15.4321C10.125 15.773 10.4002 16.0494 10.7398 16.0494C11.0793 16.0494 11.3545 15.773 11.3545 15.4321V6.54319ZM14.3053 6.54319C14.3053 6.20227 14.0301 5.9259 13.6906 5.9259C13.3511 5.9259 13.0758 6.20227 13.0758 6.54319V15.4321C13.0758 15.773 13.3511 16.0494 13.6906 16.0494C14.0301 16.0494 14.3053 15.773 14.3053 15.4321V6.54319Z"
                fill={color}
            />
        </SVG>
    );
};
