import { Accordion } from '@ab-core/accordion';
import { BREAKPOINT } from '@ab-core/breakpoints';
import Collection from '@ab-core/collection';
import CustomerSelectModal from '@ab-core/customerselect';
import { useIsMobile } from '@ab-core/functions';
import { GhostAccessModal } from '@ab-core/ghostaccess/modals/ghostAccessModal';
import { Col, Row } from '@ab-core/grid';
import { isLoggedIn } from '@ab-core/hooks';
import { LoginBox } from '@ab-core/loginbox';
import { SiteMenu } from '@ab-core/menu';
import Modal from '@ab-core/modal';
import { ShopElements } from '@ab-core/shop-navigation';
import Spacer from '@ab-core/spacer';
import { globalStore, navigationStore } from '@ab-core/store';
import { UserSettings } from '@ab-core/usersettings';
import type { FC } from 'react';
import React from 'react';
import { FreshChatDataContainer } from './freshchat';
import { Block, SiteMenuContainer } from './styled';

type GatsbyPageContainerProps = {
    pathName: string;
};

export const GatsbyPageContainer: FC<GatsbyPageContainerProps> = (props) => {
    const { pathName } = props;
    const isMobile = useIsMobile(BREAKPOINT.LG);
    const isUserLoggedIn = isLoggedIn();
    const shopNavigationOpen = navigationStore.getters.useShopNavigation();
    const userModal = navigationStore.getters.useUserNavigation();
    const collectionOpen = navigationStore.getters.useCollectionNavigation();
    const ghostModalOpen = globalStore.getters.useGhostAccess();

    const closeMenu = () => navigationStore.setters.setShopNavigation(false);

    return (
        <>
            <CustomerSelectModal />
            <GhostAccessModal open={ghostModalOpen} />
            {isMobile ? (
                <>
                    <Modal
                        headerText={isUserLoggedIn ? 'Profil' : 'Anmelden / Registrieren '}
                        open={userModal}
                        onClose={() => navigationStore.setters.setUserNavigation(false)}
                    >
                        {isUserLoggedIn ? (
                            <Block>
                                <UserSettings />
                            </Block>
                        ) : (
                            <LoginBox />
                        )}
                    </Modal>
                    <Modal headerText="Menu" open={shopNavigationOpen} onClose={closeMenu}>
                        <Row>
                            {isUserLoggedIn && (
                                <Col>
                                    <ShopElements pathName={pathName} onClose={closeMenu} />
                                </Col>
                            )}
                            <Col>
                                <Accordion title="Webseite" open={!isUserLoggedIn}>
                                    <SiteMenuContainer>
                                        <SiteMenu />
                                    </SiteMenuContainer>
                                </Accordion>

                                <Spacer size="xxl" />
                            </Col>
                        </Row>
                    </Modal>
                </>
            ) : (
                <SiteMenu />
            )}

            {isMobile ? (
                <Modal
                    headerText="Sortiment"
                    open={collectionOpen}
                    onClose={() => navigationStore.setters.setCollectionNavigation(false)}
                >
                    {collectionOpen && <Collection />}
                </Modal>
            ) : (
                <> {collectionOpen && <Collection />}</>
            )}
            <FreshChatDataContainer />
        </>
    );
};
