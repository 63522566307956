import { gql } from '@apollo/client';

const RENAME_CART = gql`
    mutation setCartInformationMutation($cartId: String!, $cartInformationInput: CartInformationInput) {
        setCartInformation(cartId: $cartId, cartInformationInput: $cartInformationInput) {
            isActiveCart
            id
            customLineItems {
                id
            }
            lineItems {
                id
            }
        }
    }
`;
export default RENAME_CART;
