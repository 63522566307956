import { getClassNames } from '@ab-core/functions/styles/classNameFormatter';
import { getTestIdProp } from '@ab-core/testing';
import type { ReactNode } from 'react';
import React from 'react';
import { TABLE_STYLE_TYPE, TableDataItem, TableHeaderItem, TableRow } from './components';
import { StyledTable, StyledTableBody, StyledTableHeader, TableContainer } from './styled';

type MobileBreakpointType = 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export enum TABLE_STYLE {
    DEFAULT = 'default',
    FULL_TABLE = 'full-table'
}

export type TableHeaderType = Array<ReactNode>;

type TableProps<T> = React.ComponentPropsWithoutRef<'table'> & {
    header?: TableHeaderType;
    items: Array<T>;
    mobileBreakpoint?: MobileBreakpointType;
    tableBodyRenderer: (item: T, index: number) => ReactNode;
    tableStyle?: TABLE_STYLE;
    bodyId?: string;
};

export const Table = <T,>(props: TableProps<T>): JSX.Element => {
    const {
        items,
        tableBodyRenderer,
        header,
        mobileBreakpoint = 'sm',
        className,
        tableStyle = TABLE_STYLE.DEFAULT,
        bodyId,
        ...rest
    } = props;

    const tableData = items.map((item, index) => {
        return <React.Fragment key={index}>{tableBodyRenderer(item, index)}</React.Fragment>;
    });

    const tableHead = header?.map((head, index) => {
        return <TableHeaderItem key={index}>{head}</TableHeaderItem>;
    });

    const tableContainerClass = getClassNames({
        prefix: 'table-container',
        modifier: { 'table-style': tableStyle }
    });

    const tableClass = getClassNames({
        className,
        prefix: 'table',
        modifier: { mobileBreakpoint }
    });

    const tableHeaderRowClass = getClassNames({
        prefix: 'table-header-row'
    });

    const tableHeaderClass = getClassNames({
        prefix: 'table-header',
        modifier: { mobileBreakpoint }
    });

    return (
        <TableContainer className={tableContainerClass}>
            <StyledTable {...rest} className={tableClass}>
                {tableHead && (
                    <StyledTableHeader className={tableHeaderClass}>
                        <TableRow className={tableHeaderRowClass}>{tableHead}</TableRow>
                    </StyledTableHeader>
                )}
                <StyledTableBody {...getTestIdProp(bodyId)}>{tableData}</StyledTableBody>
            </StyledTable>
        </TableContainer>
    );
};

export { TABLE_STYLE_TYPE, TableDataItem, TableRow };
