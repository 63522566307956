import { gql } from '@apollo/client';

export type SendIdsCartResponseType = {
    submitCartToIds: {
        xml: string;
        hookurl: string;
        customerId: string;
    };
};
const SEND_IDS_CART = gql`
    mutation SubmitCartToIds($withOrder: Boolean, $orderId: String, $cartId: String) {
        submitCartToIds(withOrder: $withOrder, orderId: $orderId, cartId: $cartId) {
            xml
            hookurl
            customerId
        }
    }
`;

export default SEND_IDS_CART;
