import { BreakpointsMaxQuery } from '@ab-core/breakpoints';
import { Colors, Shadows } from '@ab-core/colors';
import { FontTypes } from '@ab-core/font-types';
import { Z_INDEX } from '@ab-core/z-index';
import styled, { css } from 'styled-components';

export const TableContainer = styled.div`
    overflow-x: auto;
    &.ab-table-container-table-style-default {
        max-height: 700px;
    }
`;

const mobileTableStyle = css`
    .ab-table-data-item {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0.5rem;

        &:nth-child(odd) {
            background-color: ${Colors.backgroundShade};
        }

        &:last-child {
            border-bottom: 0;
        }
    }
    .ab-table-row {
        display: block;
        margin-bottom: 0.5em;
        border: 1px solid ${Colors.gray30};

        &.ab-table-row-styleType-card {
            box-shadow: ${Shadows.componentShadow};
        }
        &.ab-table-row-styleType-flat {
            box-shadow: none;
        }
        &:nth-child(odd) {
            background-color: ${Colors.white};
        }
    }
    .ab-table-data-item-mobile-label {
        display: block;
    }
`;

export const StyledTable = styled.table`
    height: 1px;
    margin: 0;
    padding: 0;
    position: relative;
    border-collapse: separate;
    border-spacing: 0;

    width: 100%;
    font-weight: ${FontTypes.body.fontWeight};
    font-size: ${FontTypes.body.fontSize};
    line-height: ${FontTypes.body.lineHeight};
    letter-spacing: ${FontTypes.body.letterSpacing};
    text-transform: ${FontTypes.body.textTransform};
    @media ${BreakpointsMaxQuery.xs} {
        &.ab-table-mobileBreakpoint-xs {
            ${mobileTableStyle}
        }
    }
    @media ${BreakpointsMaxQuery.sm} {
        &.ab-table-mobileBreakpoint-sm {
            ${mobileTableStyle}
        }
    }
    @media ${BreakpointsMaxQuery.md} {
        &.ab-table-mobileBreakpoint-md {
            ${mobileTableStyle}
        }
    }
    @media ${BreakpointsMaxQuery.lg} {
        &.ab-table-mobileBreakpoint-lg {
            ${mobileTableStyle}
        }
    }
    @media ${BreakpointsMaxQuery.xl} {
        &.ab-table-mobileBreakpoint-xl {
            ${mobileTableStyle}
        }
    }
`;

const mobileHeaderStyle = css`
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0.5rem;
    position: absolute;
    width: 1px;
    background: red;
`;

export const StyledTableHeader = styled.thead`
    text-align: left;
    white-space: nowrap;

    .ab-table-header-row {
        background: ${Colors.white};
    }

    @media ${BreakpointsMaxQuery.xs} {
        &.ab-table-header-mobileBreakpoint-xs {
            ${mobileHeaderStyle}
        }
    }
    @media ${BreakpointsMaxQuery.sm} {
        &.ab-table-header-mobileBreakpoint-sm {
            ${mobileHeaderStyle}
        }
    }
    @media ${BreakpointsMaxQuery.md} {
        &.ab-table-header-mobileBreakpoint-md {
            ${mobileHeaderStyle}
        }
    }
    @media ${BreakpointsMaxQuery.lg} {
        &.ab-table-header-mobileBreakpoint-lg {
            ${mobileHeaderStyle}
        }
    }
    @media ${BreakpointsMaxQuery.xl} {
        &.ab-table-header-mobileBreakpoint-xl {
            ${mobileHeaderStyle}
        }
    }
`;

export const StyledTableHeaderRow = styled.tr`
    position: sticky;
    top: 0;
    background: ${Colors.white};
    z-index: ${Z_INDEX.BASE};
    width: 100%;
`;

export const StyledTableBody = styled.tbody`
    overflow: auto;
`;
