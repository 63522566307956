import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const ScalePriceFilled: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.0927734" width="20" height="20" rx="10" fill={color} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.32465 4.97583C4.97947 4.97583 4.69965 5.25565 4.69965 5.60083V14.3508C4.69965 14.696 4.97947 14.9758 5.32465 14.9758C5.66982 14.9758 5.94965 14.696 5.94965 14.3508V5.60083C5.94965 5.25565 5.66982 4.97583 5.32465 4.97583ZM8.44153 7.05377C8.09635 7.05377 7.81653 7.33359 7.81653 7.67877V14.4288C7.81653 14.7739 8.09635 15.0538 8.44153 15.0538C8.78671 15.0538 9.06653 14.7739 9.06653 14.4288V7.67877C9.06653 7.33359 8.78671 7.05377 8.44153 7.05377ZM10.9334 9.75669C10.9334 9.41152 11.2132 9.13169 11.5584 9.13169C11.9036 9.13169 12.1834 9.41152 12.1834 9.75669V14.5067C12.1834 14.8519 11.9036 15.1317 11.5584 15.1317C11.2132 15.1317 10.9334 14.8519 10.9334 14.5067V9.75669ZM14.6753 11.2096C14.3301 11.2096 14.0503 11.4894 14.0503 11.8346V14.5846C14.0503 14.9298 14.3301 15.2096 14.6753 15.2096C15.0205 15.2096 15.3003 14.9298 15.3003 14.5846V11.8346C15.3003 11.4894 15.0205 11.2096 14.6753 11.2096Z"
                fill="white"
            />
        </SVG>
    );
};
