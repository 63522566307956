import { BreakpointsMinQuery } from '@ab-core/breakpoints';
import { Colors } from '@ab-core/colors';
import { textAlignmentStyle } from '@ab-core/functions/styles/textAlignment';
import styled from 'styled-components';
import HeadlineTag from './headline';
import type { HeadlineProps } from './index';

export const StyledHeadline = styled(HeadlineTag)<HeadlineProps>`
    font-style: normal;
    color: ${({ color }) =>
        color && Colors[color as keyof typeof Colors] ? Colors[color as keyof typeof Colors] : Colors.black};
    font-weight: 700;

    &.ab-headline-size-1 {
        font-size: 2rem;
        line-height: 2rem;
        @media ${BreakpointsMinQuery.sm} {
            font-size: 2.5rem;
            line-height: 2.5rem;
        }
        @media ${BreakpointsMinQuery.lg} {
            font-size: 3.75rem;
            line-height: 3.75rem;
        }
    }

    &.ab-headline-size-2 {
        font-size: 1.75rem;
        line-height: 1.75rem;
        @media ${BreakpointsMinQuery.sm} {
            font-size: 2rem;
            line-height: 2rem;
        }
        @media ${BreakpointsMinQuery.lg} {
            font-size: 2.5rem;
            line-height: 2.5rem;
        }
    }

    &.ab-headline-size-3 {
        font-size: 1.5rem;
        line-height: 1.5rem;
        @media ${BreakpointsMinQuery.lg} {
            font-size: 2rem;
            line-height: 2rem;
        }
    }

    &.ab-headline-size-4 {
        font-size: 1.25rem;
        line-height: 1.25rem;

        @media ${BreakpointsMinQuery.lg} {
            font-size: 1.5rem;
            line-height: 1.5rem;
        }
    }

    &.ab-headline-size-5 {
        font-size: 1.125rem;
        line-height: 1.125rem;
        font-weight: 500;

        @media ${BreakpointsMinQuery.lg} {
            font-size: 1.25rem;
            line-height: 1.25rem;
        }
    }

    &.ab-headline-size-6 {
        font-size: 1.125rem;
        line-height: 1.125rem;
        font-weight: 500;
        @media ${BreakpointsMinQuery.lg} {
            font-size: 1.25rem;
            line-height: 1.25rem;
        }
    }
    ${textAlignmentStyle}
`;
