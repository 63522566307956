import type { Mutation, MutationUpdateKanbanLabelArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { GET_KANBAN_LABEL_SET } from './gql/getKanbanLabelSet';
import { UPDATE_KANBAN_LABEL } from './gql/updateKanbanLabel';

type MutationDataType = Pick<Mutation, 'updateKanbanLabel'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('updateKanbanLabel');

const refetchQueries = [GET_KANBAN_LABEL_SET];

export const useUpdateKanbanLabel = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [updateKanbanLabel, { loading, error, data: updateKanbanLabelData }] = useMutation<
        MutationDataType,
        MutationUpdateKanbanLabelArgs
    >(UPDATE_KANBAN_LABEL, {
        refetchQueries,
        onError
    });

    const outgoingUpdateKanbanLabel = useCallback(
        (data: MutationUpdateKanbanLabelArgs['data']) => {
            updateKanbanLabel({ variables: { data } });
        },
        [updateKanbanLabel]
    );

    useEffect(() => {
        if (updateKanbanLabelData) {
            setResponse(updateKanbanLabelData);
        }
    }, [updateKanbanLabelData]);

    return {
        success: !!updateKanbanLabelData,
        error: !!error,
        updateKanbanLabel: outgoingUpdateKanbanLabel,
        loading,
        response
    };
};
