import type { Contact } from '@ab-core/graphql/dist';
import { gql } from '@apollo/client';

export type UpsertUserFeatureResponseType = {
    upsertUserFeature: Contact;
};
const UPSERT_USER_FEATURE = gql`
    mutation upsertUserFeature($featureAccessInput: FeatureAccessInput!, $contactId: String!) {
        upsertUserFeature(featureAccessInput: $featureAccessInput, contactId: $contactId) {
            id
            salutation
            userName
            firstName
            lastName
            email
            fullName
            fax
            featureAccess {
                id
                feature {
                    id
                    name
                    value
                    valueText
                }
            }
        }
    }
`;

export default UPSERT_USER_FEATURE;
