import Headline from '@ab-core/headline';
import { ListItem } from '@ab-core/list';
import { TableDataItem, TableRow } from '@ab-core/table';
import { StyledTableHeaderItem } from '@ab-core/table/components/tableHeaderItem/styled';
import { StyledTableBody, StyledTableHeader } from '@ab-core/table/styled';
import type { WithTestId } from '@ab-core/testing';
import Text, { FontTypes } from '@ab-core/text';
import ReactMarkdown from 'markdown-to-jsx';
import type { FC } from 'react';
import React from 'react';
import Bold from './bold';
import Cursive from './cursive';
import Markdownlink from './link';
import { MarkdownTable } from './table';

export type MarkdownProps = WithTestId & {
    markDown: string;
    className?: string;
    color?: string;
    type?: FontTypes;
    id?: string;
};

const Markdown: FC<MarkdownProps> = ({ markDown, color, type = FontTypes.Body, ...rest }: MarkdownProps) => (
    <ReactMarkdown
        {...rest}
        options={{
            overrides: {
                h1: {
                    component: Headline,
                    props: {
                        size: 1
                    }
                },
                h2: {
                    component: Headline,
                    props: {
                        size: 2
                    }
                },
                h3: {
                    component: Headline,
                    props: {
                        size: 3
                    }
                },
                h4: {
                    component: Headline,
                    props: {
                        size: 4
                    }
                },
                h5: {
                    component: Headline,
                    props: {
                        size: 5
                    }
                },
                h6: {
                    component: Headline,
                    props: {
                        size: 6
                    }
                },
                li: {
                    component: ListItem,
                    props: {
                        type
                    }
                },
                table: {
                    component: MarkdownTable
                },

                th: {
                    component: StyledTableHeaderItem
                },

                td: {
                    component: TableDataItem
                },

                tr: {
                    component: TableRow
                },

                thead: {
                    component: StyledTableHeader
                },

                tbody: {
                    component: StyledTableBody
                },

                p: {
                    component: Text,
                    props: {
                        type,
                        color,
                        asElement: 'p'
                    }
                },
                span: {
                    component: Text,
                    props: {
                        type,
                        color,
                        asElement: 'span'
                    }
                },
                strong: {
                    component: Bold,
                    props: {
                        type,
                        color
                    }
                },
                em: {
                    component: Cursive,
                    props: {
                        type,
                        color
                    }
                },
                a: {
                    component: Markdownlink
                }
            }
        }}
    >
        {markDown}
    </ReactMarkdown>
);

export default Markdown;
