import { Colors, Shadows } from '@ab-core//colors';
import { BreakpointsMinQuery } from '@ab-core/breakpoints';
import { RADIUS } from '@ab-core/radius';
import { SPACING } from '@ab-core/spacing';
import { Z_INDEX } from '@ab-core/z-index';
import styled from 'styled-components';

type WrapperType = {
    topMargin?: number;
    width: string;
    offsetLeft: string;
};

export const Wrapper = styled.div<WrapperType>`
    position: fixed;
    z-index: ${Z_INDEX.MODAL_BACKDROP};
    top: ${({ topMargin }) => (topMargin ? `${topMargin}px` : '56px')};
    padding: ${SPACING.SMALL_3} ${SPACING.SMALL};
    @media ${BreakpointsMinQuery.lg} {
        top: ${({ topMargin }) => (topMargin ? `${topMargin}px` : '100px')};
        padding: ${SPACING.SMALL_4} ${SPACING.SMALL_2};
    }
    border-top: 1px solid ${Colors.gray30};

    background: ${Colors.white};
    box-shadow: ${Shadows.navigationShadow};
    border-radius: 0px 0px ${RADIUS.FULL} ${RADIUS.FULL};
    width: ${({ width }) => width};
    left: ${({ offsetLeft }) => offsetLeft};
`;
