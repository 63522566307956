import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const RotatingArrow: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <svg>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.4187 1.85556C10.6883 1.33558 8.83644 1.38709 7.13763 2.00245C5.43882 2.6178 3.98354 3.76425 2.98758 5.27179C2.80217 5.55243 2.87937 5.93024 3.16002 6.11565C3.44066 6.30106 3.81846 6.22385 4.00387 5.94321C4.85549 4.65415 6.09986 3.67386 7.55247 3.14768C9.00508 2.62151 10.5886 2.57746 12.0682 3.02208C13.5478 3.4667 14.8447 4.37631 15.7667 5.61604C16.6197 6.76302 17.1097 8.13607 17.1782 9.55861L15.8385 9.37565C15.6338 9.3477 15.4894 9.57117 15.599 9.74627L17.2508 12.3853C17.3314 12.5142 17.5092 12.5385 17.6215 12.4359L19.9204 10.3364C20.0729 10.1971 19.9937 9.94311 19.7891 9.91516L18.4027 9.72583C18.3656 7.98199 17.7867 6.29117 16.7441 4.88917C15.6659 3.43932 14.1491 2.37554 12.4187 1.85556ZM12.8624 17.8067C11.1636 18.4221 9.31166 18.4736 7.58127 17.9536C5.85088 17.4336 4.33412 16.3698 3.2559 14.92C2.21327 13.518 1.63438 11.8272 1.59731 10.0833L0.21092 9.894C0.00625129 9.86605 -0.0729297 9.61205 0.0795999 9.47274L2.37846 7.37323C2.49076 7.27067 2.66855 7.29495 2.74924 7.42387L4.40099 10.0629C4.51058 10.238 4.36619 10.4615 4.16152 10.4335L2.82179 10.2505C2.89033 11.6731 3.38033 13.0461 4.23331 14.1931C5.15526 15.4328 6.4522 16.3425 7.93181 16.7871C9.41142 17.2317 10.9949 17.1877 12.4475 16.6615C13.9001 16.1353 15.1445 15.155 15.9961 13.8659C16.1815 13.5853 16.5593 13.5081 16.84 13.6935C17.1206 13.8789 17.1978 14.2567 17.0124 14.5374C16.0165 16.0449 14.5612 17.1914 12.8624 17.8067Z"
                    fill={color}
                />
            </svg>
        </SVG>
    );
};
