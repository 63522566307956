import { getClassNames } from '@ab-core/functions/styles/classNameFormatter';
import type { WithChildren } from '@ab-core/utils/types';
import type { FC } from 'react';
import React from 'react';
import type { TABS_STATE } from '../../tabState';
import { TabPanelItemContainer } from './styled';

type TabPanelItemProps = WithChildren &
    React.ComponentPropsWithoutRef<'div'> & {
        styleType: TABS_STATE;
    };

export const TabPanelItem: FC<TabPanelItemProps> = (props) => {
    const { children, styleType, ...rest } = props;
    const newClassNames = getClassNames({ prefix: 'tab-panel-item', modifier: { 'style-type': styleType } });

    return (
        <TabPanelItemContainer {...rest} className={newClassNames}>
            {children}
        </TabPanelItemContainer>
    );
};
