import type { Query, QuerySearchArgs } from '@ab-core/graphql/dist';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { GET_CABLE_SNAPS } from './gql/getCableSnaps';
import type { SearchDataMapperType } from './productSearchMapper';
import { searchDataMapper } from './productSearchMapper';

type QueryDataType = Pick<Query, 'search'>;
type QueryArgs = QuerySearchArgs;
type OutgoingDataType = SearchDataMapperType;

type IncomingDataType = {
    search?: string;
    page?: number;
    skip?: boolean;
};

const onError = getErrorLoggerFunction('search');

export const useCableSnaps = (props: IncomingDataType) => {
    const { search, page = 1, skip = false } = props;
    const [outgoingData, setOutgoingData] = useState<OutgoingDataType>(undefined);

    const query = `?q=${search}&f.hasCableSnap=true&page=${page}`;

    const { data, loading, refetch, error } = useQuery<QueryDataType, QueryArgs>(GET_CABLE_SNAPS, {
        variables: {
            query
        },
        skip,
        onError
    });

    useEffect(() => {
        if (data?.search) {
            const mappedData = searchDataMapper(data?.search);
            setOutgoingData(mappedData);
        }
    }, [data]);

    return {
        cableSnaps: outgoingData,
        error: !!error,
        success: !!data,
        loading,
        refetch
    };
};
