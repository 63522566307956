export const formatTestId = (value: string): string => {
    //Normalisieren
    value = value.normalize('NFC');

    //Leerzeichen ersetzen
    value = value.replaceAll(' ', '-');

    //Punkte ersetzen
    value = value.replaceAll('.', '-');

    //Camelcase in Kebabase auflösen
    value = value.replace(/([a-z])([A-Z])/g, '$1-$2');

    //Alles in Kleinbuchstaben
    value = value.toLowerCase();

    return value;
};
