import { Colors } from '@ab-core/colors';
import { RADIUS } from '@ab-core/radius';
import { SPACING } from '@ab-core/spacing';
import Text from '@ab-core/text';
import styled from 'styled-components';

export const ItemContainer = styled.div`
    height: 100%;
    padding: ${SPACING.SMALL} ${SPACING.SMALL_2};
    display: flex;
    flex-direction: column;
    border-radius: ${RADIUS.FULL};
    border: 1px solid transparent;
    cursor: pointer;
    &:hover {
        border-color: ${Colors.gray15};
    }
`;

export const QuadratImage = styled.div`
    position: relative;
    height: 0;
    padding-bottom: 100%;
`;

export const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
`;

export const ItemText = styled(Text)`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;
