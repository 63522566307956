import { gql } from '@apollo/client';
import { AVAILABILITY_FRAGMENT } from '../../fragments/availabilityFragment';

export const GET_PRODUCTS_AVAILABILITIES_WITH_CHANNEL = gql`
    query ProductsAvailabilitiesWithChannel($skus: [String!]!, $cartId: String, $externalId: String) {
        productsAvailabilitiesWithChannel(skus: $skus, cartId: $cartId, externalId: $externalId) {
            sku
            aggregatedMainChannelQuantity
            procuredProduct
            deliveryFromFactory
            deliveryTimeInfoIdentifier
            mainChannelAvailability {
                ...availabilityFields
            }
            cartChannelAvailability {
                ...availabilityFields
            }
            pickupChannelsAvailability {
                ...availabilityFields
            }
            zajadaczChannelAvailability {
                ...availabilityFields
            }
            phillipsburgChannelAvailability {
                ...availabilityFields
            }
        }
    }
    ${AVAILABILITY_FRAGMENT}
`;
