import { css } from 'styled-components';

export type TextAlignmentType = { textAlignment?: TextAlignment };

export enum TextAlignment {
    LEFT = 'left',
    RIGHT = 'right',
    CENTER = 'center',
    JUSTIFY = 'justify'
}

export const textAlignmentStyle = css<TextAlignmentType>`
    ${({ textAlignment }) => textAlignment && `text-align: ${textAlignment}`};
`;
