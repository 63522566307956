import type { CartShippingInput, ShippingAddressInput } from '@ab-core/graphql/dist/index';
import { useCartOffer } from '@ab-core/hooks';
import { useMyCarts } from '@ab-core/hooks/cartNew/getCarts';
import type { CartShippingMethodInput } from '@ab-core/shippingaddress/types';
import { DeliveryMethod } from '@ab-core/shippingaddress/types';
import { toast } from '@ab-core/toast';
import { useMutation } from '@apollo/client';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import { UPDATE_CART_INFO } from '../cartNew/updateCart/updateCartInfoMutation';
import { UPDATE_CART_OFFER } from './updateCartOfferMutation';

type UseUpdateCartInfo = {
    offerNumber?: string;
};

export type HandleUpdateCartInfoType = {
    customFields?: CustomFields;
    addressFields?: AddressFields;
    cartId: string;
    offerId?: string;
    shippingMethod?: string;
};

export type AddressFields = {
    shippingAddress: ShippingAddressInput;
    shippingMethod: CartShippingMethodInput;
};

export type CustomFields = {
    orderText?: string;
    costCenter?: string;
    singleInv?: boolean;
    priceDN?: boolean;
    cartId?: string;
    email?: string;
    deliveryDate?: string;
    shippingInstructions?: string;
    completeDelivery?: boolean;
    cartType?: string;
    cartGhostBlocked?: string;
    orderGhostOrderType?: string;
    projectNr?: string;
    displayShippingMethod?: string;
    createAddress?: string;
};

type CartCustomFieldInput = {
    value: string;
    name: string;
};

type CartInformationInput = {
    fields: CartCustomFieldInput[];
};

export type CartInfoArgs = {
    cartId?: string;
    offerId?: string;
    shippingInput?: CartShippingInput;
    attributes?: string[];
    cartInformationInput?: CartInformationInput;
    offerInformationInput?: CartInformationInput;
};

type UseUpdateCartInfoReturnType = {
    handleUpdateCartInfo: (info: HandleUpdateCartInfoType) => void;
    modalOpen: boolean;
    setModalOpen: Dispatch<SetStateAction<boolean>>;
    loading: boolean;
};

const useUpdateCartInfo = ({ offerNumber }: UseUpdateCartInfo | undefined = {}): UseUpdateCartInfoReturnType => {
    const [modalOpen, setModalOpen] = useState(() => false);
    const { myCartsLoading } = useMyCarts(true);
    const { cartOfferLoading } = useCartOffer(offerNumber);
    const cartOfferLoadingUnion = myCartsLoading || cartOfferLoading;

    const [loading, setLoading] = useState(true);

    const [updateCartInfo, { loading: updateCartInfoLoading }] = useMutation(UPDATE_CART_INFO, {
        onCompleted: () => {
            toast.success('Warenkorb aktualisiert');
            setModalOpen(() => false);
        }
    });
    const [updateCartOffer, { loading: updateCartOfferLoading }] = useMutation(UPDATE_CART_OFFER, {
        onCompleted: () => {
            toast.success('Warenkorb aktualisiert');
            setModalOpen(() => false);
        }
    });

    const createCartInformationInput = (customFields: CustomFields) => {
        const customFieldMap = {
            orderText: 'cart-auftragstext',
            costCenter: 'cart-kostenstelle',
            singleInv: 'cart-einzelrechnung',
            email: 'cart-orderConfirmationEmail',
            priceDN: 'cart-preis-auf-lieferschein',
            deliveryDate: 'cart-wunschlieferdatum',
            completeDelivery: 'cart-komplettlieferung',
            shippingInstructions: 'cart-versandhinweis',
            cartType: 'cart-bestellart',
            cartGhostBlocked: 'cart-ghost-blocked',
            orderGhostOrderType: 'order-ghost-orderType',
            projectNr: 'cart-projectNr',
            displayShippingMethod: 'cart-anzeigeLieferMethode',
            createAddress: 'cart-adresseAnlegen'
        };

        return Object.entries(customFields).map((customField) => {
            if (customField[0] === 'deliveryDate') {
                const date = customField[1];

                return {
                    name: customFieldMap[customField[0] as keyof typeof customFieldMap],
                    value: date || ''
                };
            }

            if (customField[0] === 'priceDN' || customField[0] === 'singleInv') {
                return {
                    name: customFieldMap[customField[0] as keyof typeof customFieldMap],
                    value: `${customField[1] || 'false'}`
                };
            }

            return {
                name: customFieldMap[customField[0] as keyof typeof customFieldMap],
                value: `${customField[1] || ''}`
            };
        });
    };

    const handleUpdateCartInfo = async (cartInfo: HandleUpdateCartInfoType) => {
        const variables: CartInfoArgs = {};

        if (cartInfo.customFields) {
            if (offerNumber) {
                variables.offerInformationInput = {
                    fields: createCartInformationInput(cartInfo.customFields)
                };
            } else {
                variables.cartInformationInput = {
                    fields: createCartInformationInput(cartInfo.customFields)
                };
            }
        }

        if (cartInfo.addressFields) {
            variables.shippingInput = {
                address: cartInfo.addressFields.shippingAddress,
                shippingMethod: cartInfo.addressFields.shippingMethod
            } as CartShippingInput;
        }

        if (cartInfo.offerId) {
            variables.offerId = cartInfo.offerId;

            if (cartInfo.shippingMethod !== DeliveryMethod.OFFER && cartInfo.offerId) {
                await updateCartOffer({ variables });
            }
        } else if (cartInfo.cartId) {
            variables.cartId = cartInfo.cartId;

            await updateCartInfo({ variables });
        }
    };

    useEffect(() => {
        if (cartOfferLoadingUnion || updateCartInfoLoading || updateCartOfferLoading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [cartOfferLoadingUnion, updateCartInfoLoading, updateCartOfferLoading]);

    return {
        handleUpdateCartInfo,
        modalOpen,
        setModalOpen,
        loading
    };
};

export default useUpdateCartInfo;
