import { gql } from '@apollo/client';

const CREATE_UPDATE_SPECIAL_ARTICLE = gql`
    mutation upsertCustomLineItemToCarts($customLineItemInput: CustomLineItemInput!, $cartIds: [String!]!) {
        upsertCustomLineItemToCarts(customLineItemInput: $customLineItemInput, cartIds: $cartIds) {
            id
        }
    }
`;
export default CREATE_UPDATE_SPECIAL_ARTICLE;
