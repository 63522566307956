import { gql } from '@apollo/client';

import { CART_OBJECT } from '../../fragments/cartFragment';

export const CART = gql`
    query getMyCart($cartId: String!) {
        getMyCart(cartId: $cartId) {
            ...ABCartFields
        }
    }
    ${CART_OBJECT}
`;
