import { gql } from '@apollo/client';

import PRODUCT_SEARCH_FRAGMENT from '../fragments/productSearchFragment';

const PRODUCT_SEARCH_BY_CATEGORY_QUERY = gql`
    query ProductsByCategory($category: String!, $page: Float) {
        searchByCategory(category: $category, page: $page) {
            results {
                document {
                    ...productSearchFields
                }
            }
            pageCount
            currentPage
            count
            total
        }
    }
    ${PRODUCT_SEARCH_FRAGMENT}
`;

export default PRODUCT_SEARCH_BY_CATEGORY_QUERY;
