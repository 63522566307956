import { createGlobalStyle } from 'styled-components';
// eslint-disable-next-line
import blackEot from './TextaAlt-Black/font.eot';
// eslint-disable-next-line
import blackTtf from './TextaAlt-Black/font.ttf';
// eslint-disable-next-line
import blackWoff from './TextaAlt-Black/font.woff';
// eslint-disable-next-line
import blackWoff2 from './TextaAlt-Black/font.woff2';
// eslint-disable-next-line
import boldEot from './TextaAlt-Bold/font.eot';
// eslint-disable-next-line
import boldTtf from './TextaAlt-Bold/font.ttf';
// eslint-disable-next-line
import boldWoff from './TextaAlt-Bold/font.woff';
// eslint-disable-next-line
import boldWoff2 from './TextaAlt-Bold/font.woff2';
// eslint-disable-next-line
import lightEot from './TextaAlt-Light/font.eot';
// eslint-disable-next-line
import lightTtf from './TextaAlt-Light/font.ttf';
// eslint-disable-next-line
import lightWoff from './TextaAlt-Light/font.woff';
// eslint-disable-next-line
import lightWoff2 from './TextaAlt-Light/font.woff2';
// eslint-disable-next-line
import mediumEot from './TextaAlt-Medium/font.eot';
// eslint-disable-next-line
import mediumTtf from './TextaAlt-Medium/font.ttf';
// eslint-disable-next-line
import mediumWoff from './TextaAlt-Medium/font.woff';
// eslint-disable-next-line
import mediumWoff2 from './TextaAlt-Medium/font.woff2';
// eslint-disable-next-line
import regularEot from './TextaAlt-Regular/font.eot';
// eslint-disable-next-line
import regularTtf from './TextaAlt-Regular/font.ttf';
// eslint-disable-next-line
import regularWoff from './TextaAlt-Regular/font.woff';
// eslint-disable-next-line
import regularWoff2 from './TextaAlt-Regular/font.woff2';

const ABFonts = createGlobalStyle`

  @font-face {
    font-family: 'TextaAlt';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(${lightEot});
    src: local('textaAlt'), local('textaAlt'), /* Old IE */
        url(${lightWoff2}) format('woff2'), /* Super Modern Browsers */
        url(${lightWoff}) format('woff'), /* Modern Browsers */
        url(${lightTtf}) format('truetype') /* Safari, Android, iOS */
  };

  @font-face {
    font-family: 'TextaAlt';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: url(${regularEot});
    src: local('textaAlt'), local('textaAlt'), /* Old IE */
        url(${regularWoff2}) format('woff2'), /* Super Modern Browsers */
        url(${regularWoff}) format('woff'), /* Modern Browsers */
        url(${regularTtf}) format('truetype') /* Safari, Android, iOS */
  };


  @font-face {
    font-family: 'TextaAlt';
    font-weight: 500;
    font-display: swap;
    src: url(${mediumEot});
    src: local('textaAlt'), local('textaAlt'), /* Old IE */
        url(${mediumWoff2}) format('woff2'), /* Super Modern Browsers */
        url(${mediumWoff}) format('woff'), /* Modern Browsers */
        url(${mediumTtf}) format('truetype') /* Safari, Android, iOS */
  };

  @font-face {
    font-family: 'TextaAlt';
    font-style: normal;
    font-weight: bold;
    font-display: swap;
    src: url(${boldEot});
    src: local('textaAlt'), local('textaAlt'), /* Old IE */
        url(${boldWoff2}) format('woff2'), /* Super Modern Browsers */
        url(${boldWoff}) format('woff'), /* Modern Browsers */
        url(${boldTtf}) format('truetype') /* Safari, Android, iOS */
  };

  @font-face {
    font-family: 'TextaAlt';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(${blackEot});
    src: local('textaAlt'), local('textaAlt'), /* Old IE */
        url(${blackWoff2}) format('woff2'), /* Super Modern Browsers */
        url(${blackWoff}) format('woff'), /* Modern Browsers */
        url(${blackTtf}) format('truetype') /* Safari, Android, iOS */
  };

`;

export default ABFonts;
