import axios from 'axios';
import baseUrlService from '../baseUrlService';

type Token = {
    access_token: string;
    id: string;
    id_token: string;
    instance_url: string;
    issued_at: string;
    refresh_token: string;
    scope: string;
    signature: string;
    token_type: string;
};

type LoginParams = {
    response_type: string;
    client_id: string;
    scope: string;
    redirect_uri: string;
    state: string;
};

export const LOCALSTORAGE_KEYS = [
    'isLoggedIn',
    'ociSession',
    'idsSession',
    'elbridgeData',
    'cxmlSession',
    'ociSessionId'
];

const getRedirectUri = () => {
    if (typeof window === 'undefined') {
        return '';
    }
    const url = new URL(baseUrlService());
    if (url?.hostname?.includes('gatsbyjs.io')) {
        url.hostname = 'staging.alexander-buerkle.com';
    }
    if (url?.hostname?.includes('vercel.app')) {
        url.hostname = 'staging.alexander-buerkle.com';
    }
    if (url?.hostname?.includes('release.')) {
        url.hostname = 'alexander-buerkle.com';
    }
    if (url?.hostname === 'localhost') {
        url.pathname = '/callback';
    } else {
        url.pathname = '/de-de/callback';
    }
    return url.toString();
};

export const abcloudToken = async (code?: string, logout = false): Promise<Token | undefined> => {
    let formData = '';

    if (code) {
        formData += `&code=${code}&redirect_uri=${getRedirectUri()}`;
    }
    if (logout) {
        formData += '&revoke=true';
    }
    if (formData.length === 1) {
        return;
    }

    try {
        const response = await axios({
            withCredentials: true,
            url: `${process.env.GATSBY_ABCLOUD_OAUTH_URL}/oauth/token`,
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: formData
        });
        return response?.data;
    } catch (e) {
        return undefined;
    }
};

export const logIn = (redirectAfterLogin = false): void => {
    if (typeof window === 'undefined') {
        return;
    }

    const loginParams = new URLSearchParams({
        response_type: 'code',
        client_id: process.env.GATSBY_SALESFORCE_APP_CLIENT_ID,
        scope: 'openid api refresh_token',
        redirect_uri: getRedirectUri(),
        state: redirectAfterLogin ? window.location.href : `${baseUrlService()}/mein-shop`
    } as LoginParams).toString();

    window.location.href = `${process.env.GATSBY_SALESFORCE_COMMUNITY_URL}/services/oauth2/authorize?${loginParams}`;
};

export const logOut = async (): Promise<void> => {
    await abcloudToken(undefined, true);
    for (const key of LOCALSTORAGE_KEYS) {
        localStorage.removeItem(key);
    }
    localStorage.removeItem('ociSessionId');

    window.location.href = `${process.env.GATSBY_SALESFORCE_COMMUNITY_URL}/secur/logout.jsp`;
};
