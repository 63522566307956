import { Colors } from '@ab-core/colors';
import { Z_INDEX } from '@ab-core/z-index';
import styled from 'styled-components';

export const FooterWrapper = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    height: 60px;
    background-color: ${Colors.white};
    display: flex;
    align-items: center;
    width: 100vw;
    z-index: ${Z_INDEX.FIXED};
`;
