import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Table: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 19H19.5" stroke={color} strokeMiterlimit="10" />
            <path d="M19 19V1" stroke={color} strokeMiterlimit="10" />
            <path d="M0.5 7H18.5" stroke={color} strokeMiterlimit="10" />
            <path d="M0.5 13H18.5" stroke={color} strokeMiterlimit="10" />
            <path d="M10 19L10 1" stroke={color} strokeMiterlimit="10" />
            <path d="M0.5 1.5H18.5" stroke={color} strokeMiterlimit="10" />
            <path d="M1 19L1 2" stroke={color} strokeMiterlimit="10" />
        </SVG>
    );
};
