import { gql } from '@apollo/client';
import { FRAGMENTS } from '../../_fragments';

export const POSITION_PRICE_OBJECTS = gql`
    query PositionPriceObjects($positions: [CartProduct!], $hidePrice: Boolean, $projectId: Int) {
        positionPriceObjects(positions: $positions, hidePrice: $hidePrice, projectId: $projectId) {
            ...PositionPriceObjectFields
        }
    }
    ${FRAGMENTS.POSITION_PRICE_OBJECT}
`;
