import { gql } from '@apollo/client';
import { OFFER_DETAIL_FRAGMENT } from '../fragments/offerDetailFragment';

export const UPDATE_CART_OFFER = gql`
    mutation setOfferInformation(
        $offerId: String!
        $shippingInput: CartShippingInput
        $offerInformationInput: CartInformationInput
    ) {
        setOfferInformation(
            offerId: $offerId
            shippingInput: $shippingInput
            offerInformationInput: $offerInformationInput
        ) {
            ...OfferDetailFragment
        }
    }
    ${OFFER_DETAIL_FRAGMENT}
`;
