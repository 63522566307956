import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Plus: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.75 1C9.33579 1 9 1.33579 9 1.75V9.00001H1.75C1.33579 9.00001 1 9.33579 1 9.75001C1 10.1642 1.33579 10.5 1.75 10.5H9V18.25C9 18.6642 9.33579 19 9.75 19C10.1642 19 10.5 18.6642 10.5 18.25V10.5H18.25C18.6642 10.5 19 10.1642 19 9.75001C19 9.33579 18.6642 9.00001 18.25 9.00001H10.5V1.75C10.5 1.33579 10.1642 1 9.75 1Z"
                fill={color}
            />
        </SVG>
    );
};
