import type { FC } from 'react';
import React from 'react';
import { SkeletonWrapper } from './styled';

export type SkeletonCardProps = {
    small?: boolean;
    medium?: boolean;
    mediumSmall?: boolean;
    large?: boolean;
    extraLarge?: boolean;
    noMa?: boolean;
    short?: boolean;
    width?: number;
    height?: number;
    productCard?: boolean;
    productListCard?: boolean;
    card?: boolean;
};

const SkeletonCard: FC<SkeletonCardProps> = ({
    small,
    mediumSmall,
    medium,
    large,
    extraLarge,
    productCard,
    productListCard,
    noMa,
    short,
    width,
    height,
    card
}) => (
    <SkeletonWrapper
        small={small}
        mediumSmall={mediumSmall}
        medium={medium}
        large={large}
        extraLarge={extraLarge}
        noMa={noMa}
        short={short}
        width={width}
        height={height}
        productCard={productCard}
        productListCard={productListCard}
        card={card}
    />
);

export default SkeletonCard;
