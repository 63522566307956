import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const ReturnArrow: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.15195 2C3.51201 2 3.8039 2.29189 3.8039 2.65195V12.5471H15.1386L12.1025 9.51107C11.8479 9.25647 11.8479 8.84368 12.1025 8.58907C12.3571 8.33447 12.7699 8.33447 13.0245 8.58907L17.1735 12.7381C17.4281 12.9927 17.4281 13.4055 17.1735 13.6601L13.0245 17.809C12.7699 18.0637 12.3571 18.0637 12.1025 17.809C11.8479 17.5544 11.8479 17.1417 12.1025 16.8871L15.1386 13.851H3.15195C2.79189 13.851 2.5 13.5591 2.5 13.1991V2.65195C2.5 2.29189 2.79189 2 3.15195 2Z"
                fill={color}
            />
        </SVG>
    );
};
