import { gql } from '@apollo/client';
import ACCOUNT_DATA from '@ab-core/hooks/fragments/accountFragment';

export const USER_QUERY = gql`
    query GetCustomerDataWithoutRightsAndActiveAccount {
        getCustomerDataWithoutRightsAndActiveAccount {
            contact {
                id
                salutation
                userName
                firstName
                lastName
                email
                onlineShopAccess
                fax
                title
                createdAt
                academicTitle
                function
                mobilePhone
            }
            activeAccount {
                ...accountFields
            }
        }
    }
    ${ACCOUNT_DATA}
`;
