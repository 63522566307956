import type { FC } from 'react';
import React from 'react';

export type MetaDataProps = {
    metaTitle?: string;
    metaDescription?: string;
    metaRobots?: string;
};

type HeadType = {
    metaData?: MetaDataProps;
    slug: string;
};

const Head: FC<HeadType> = ({ metaData, slug }) => (
    <>
        <meta httpEquiv="language" content="de" />
        <meta httpEquiv="cache-control" content="no-cache" />
        {metaData?.metaDescription && <meta name="description" content={metaData.metaDescription} />}
        <title>{metaData?.metaTitle || 'Alexander Bürkle'}</title>
        <meta name="robots" content={metaData?.metaRobots || 'index, follow'} />
        <script type="application/javascript">
            {`
          var UC_UI_SUPPRESS_CMP_DISPLAY=${slug === 'impressum' || slug === 'datenschutz'};
          `}
        </script>
    </>
);

export default Head;
