import { ROUTES } from '@ab-core/functions';
import { useAuth } from '@ab-core/hooks';
import getActiveCart from '@ab-core/hooks/cartNew/activeCart/useActiveCart';
import { Icon } from '@ab-core/icon';
import { LINK_VARIANT, Link } from '@ab-core/link';
import { getTestIdProp } from '@ab-core/testing';
import type { FC } from 'react';
import React from 'react';
import { CartCounter, CartCounterWhite } from './styled';

export const Cart: FC = () => {
    const { user } = useAuth();

    const skip = user ? !user : true;
    const { cartCount } = getActiveCart(skip);

    return (
        <>
            {user ? (
                <Link variant={LINK_VARIANT.LINK_ONLY} to={ROUTES.CART}>
                    <div className="flex-direction-column flex-align-items">
                        <div className="flex mx-small5 mt-small4 gap-small2" {...getTestIdProp('go-to-cart-button')}>
                            <Icon name="Cart" color="black" />
                            {cartCount !== 0 && (
                                <CartCounterWhite>
                                    <CartCounter>{cartCount}</CartCounter>
                                </CartCounterWhite>
                            )}
                        </div>
                    </div>
                </Link>
            ) : (
                <div className="flex-direction-column flex-align-items px-small4 mt-small2 py-small2">
                    <Icon name="Cart" color="gray50" />
                </div>
            )}
        </>
    );
};
