import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Package: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 8.36907L6 3.19922" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M21 14.9436V6.97466C20.9996 6.6253 20.8967 6.28217 20.7017 5.9797C20.5067 5.67723 20.2264 5.42606 19.8889 5.25138L12.1111 1.26691C11.7733 1.09205 11.3901 1 11 1C10.6099 1 10.2267 1.09205 9.88889 1.26691L2.11111 5.25138C1.77363 5.42606 1.49331 5.67723 1.29829 5.9797C1.10327 6.28217 1.0004 6.6253 1 6.97466V14.9436C1.0004 15.293 1.10327 15.6361 1.29829 15.9386C1.49331 16.241 1.77363 16.4922 2.11111 16.6669L9.88889 20.6513C10.2267 20.8262 10.6099 20.9183 11 20.9183C11.3901 20.9183 11.7733 20.8262 12.1111 20.6513L19.8889 16.6669C20.2264 16.4922 20.5067 16.241 20.7017 15.9386C20.8967 15.6361 20.9996 15.293 21 14.9436Z"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.29993 5.93848L10.9999 10.9689L20.6999 5.93848"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M11 20.9998V10.959" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        </SVG>
    );
};
