import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Download: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.4509 12.3078C13.6899 12.0588 13.6818 11.6632 13.4328 11.4241C13.1838 11.1851 12.7882 11.1932 12.5491 11.4422L13.4509 12.3078ZM10 15L9.54913 15.4328C9.66699 15.5556 9.82982 15.625 10 15.625C10.1702 15.625 10.333 15.5556 10.4509 15.4328L10 15ZM7.45087 11.4422C7.21182 11.1932 6.81617 11.1851 6.56717 11.4241C6.31816 11.6632 6.31009 12.0588 6.54913 12.3078L7.45087 11.4422ZM10.625 5C10.625 4.65482 10.3452 4.375 10 4.375C9.65482 4.375 9.375 4.65482 9.375 5H10.625ZM12.5491 11.4422L9.54913 14.5672L10.4509 15.4328L13.4509 12.3078L12.5491 11.4422ZM10.4509 14.5672L7.45087 11.4422L6.54913 12.3078L9.54913 15.4328L10.4509 14.5672ZM9.375 5V15H10.625V5H9.375Z"
                fill={color}
            />
            <rect x="0.625" y="0.625" width="18.75" height="18.75" rx="1.375" stroke={color} strokeWidth="1.25" />
        </SVG>
    );
};
