import { SetActiveCartButtonState } from '@ab-core/gatsby-theme-core/src/scopes/shopping-carts/state/setActiveCartButtonState';
import { cartActiveToast } from '@ab-core/hooks/cart/cartActiveToast';
import useText from '@ab-core/localization/src/useText';
import { DefaultMutationReturnType } from '@ab-core/types/src';
import { FetchResult, MutationFunctionOptions, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { CARTS } from '../getCarts/getMyCartsQuery';
import { RECEIVED_CARTS } from '../getCarts/getReceivedCartsQuery';
import { SHARED_CARTS } from '../getCarts/getSharedCartsQuery';
import { ACTIVECART } from './activeCart';
import type { SetActiveCartResponseType } from './setActiveCartMutation';
import SET_ACTIVE_CART from './setActiveCartMutation';

type SetActiveCartMutationResponseType<T> = DefaultMutationReturnType<T> & {
    setActiveCart: (options?: MutationFunctionOptions) => Promise<FetchResult<SetActiveCartResponseType>>;
};

type SetActiveCartReturnType = SetActiveCartMutationResponseType<SetActiveCartResponseType>;

const SetActiveCart = (cartId?: string): SetActiveCartReturnType => {
    const setActiveCartNotification = useText('cartList.setActiveCartNotification');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [response, setResponse] = useState<SetActiveCartResponseType | undefined>(undefined);
    const [pending, setPending] = useState(false);
    const [setActiveCart, { loading }] = useMutation<SetActiveCartResponseType>(SET_ACTIVE_CART, {
        variables: {
            cartInformationInput: {
                fields: [{ name: 'cart-is-active', value: 'true' }]
            },
            cartId
        },
        update: () => {
            setSuccess(false);
            setErrorMessage('');
            setError(false);
        },
        onCompleted: (res) => {
            if (res && res.setCartInformation.name !== 'Neuer Warenkorb (Standard)') {
                cartActiveToast(res?.setCartInformation?.id, res?.setCartInformation?.name, setActiveCartNotification);
            }

            SetActiveCartButtonState(false);
            setResponse(res);
            setSuccess(true);
            setPending(false);
        },
        onError: (err) => {
            setErrorMessage(err.message);
            setError(true);
            setPending(false);
            setSuccess(false);
        },
        refetchQueries: [
            {
                query: CARTS
            },
            {
                query: RECEIVED_CARTS
            },
            {
                query: SHARED_CARTS
            },
            {
                query: ACTIVECART
            }
        ]
    });
    useEffect(() => {
        if (loading === true) {
            setPending(true);
        }
    }, [loading]);

    return {
        setActiveCart,
        success,
        error,
        response,
        errorMessage,
        pending
    };
};
export default SetActiveCart;
