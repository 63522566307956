import { gql } from '@apollo/client';

export const GET_COST_CENTERS = gql`
    query GetCostCenters {
        getCostCenters {
            id
            accountId
            name
            description
        }
    }
`;
