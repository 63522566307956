import { gql } from '@apollo/client';
import USER_DATA from '../fragments/userFragment';

export const UPDATE_ACCOUNT_CONTACT_RELATION = gql`
    mutation updateAccountContactRelation($accountContactRelationInput: AccountContactRelationInput!) {
        updateAccountContactRelation(accountContactRelationInput: $accountContactRelationInput) {
            ...userFields
        }
    }
    ${USER_DATA}
`;
