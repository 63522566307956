import { gql } from '@apollo/client';

export type SendMyCartResponseType = {
    data: {
        sendMyCart: boolean;
    };
};
const SEND_MY_CART = gql`
    mutation SendMyCart($cartId: String!, $contactId: String!) {
        sendMyCart(cartId: $cartId, contactId: $contactId)
    }
`;
export default SEND_MY_CART;
