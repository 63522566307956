import { formatTestId } from '@ab-core/testing/formatTestIds';

export type WithTutorialId = { tutorialId?: string };

export const getTutorialIdProp = (tutorialId?: string): Record<string, string> => {
    if (!tutorialId) {
        return {};
    }

    return { 'data-tutorial-id': formatTestId(tutorialId) };
};

export const tutorialDataAttr = 'data-tutorial-id';

export const getTutorialActionIdProp = (tutorialActionId?: string): Record<string, string> => {
    if (!tutorialActionId) {
        return {};
    }

    return { 'data-tutorial-action-id': formatTestId(tutorialActionId) };
};

export const tutorialActionDataAttr = 'data-tutorial-action-id';
