import type { Mutation, MutationSearchTrackingFeedbackArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { SEARCH_TRACKING_FEEDBACK } from './gql/searchTrackingFeedback';

type MutationDataType = Pick<Mutation, 'searchTrackingFeedback'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('searchTrackingFeedback');

export const useSearchTrackingFeedback = () => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [searchTrackingFeedback, { loading, error, data }] = useMutation<
        MutationDataType,
        MutationSearchTrackingFeedbackArgs
    >(SEARCH_TRACKING_FEEDBACK, {
        onError
    });

    const outgoingSearchTrackingFeedback = useCallback(
        (trackingFeedback: MutationSearchTrackingFeedbackArgs['trackingFeedback']) => {
            searchTrackingFeedback({ variables: { trackingFeedback } });
        },
        [searchTrackingFeedback]
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        searchTrackingFeedback: outgoingSearchTrackingFeedback,
        loading,
        response
    };
};
