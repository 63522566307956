import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import {
    Backdrop,
    BottomSheet,
    ChildrenContent,
    CloseHeader,
    CloseHeaderContent,
    Container,
    Content,
    Footer,
    Spacer
} from './index.styled';

type BottomSheetType = {
    children?: React.ReactNode;
    active: boolean;
    header?: React.ReactNode;
    footer?: React.ReactNode;
    id?: string;
};
const TIMEOUT = 900;
const NewBottomSheet: FC<BottomSheetType> = ({ children, active, header, footer, id }) => {
    const [show, setShow] = useState<boolean | undefined>();
    const [display, setDisplay] = useState<string | undefined>('none');

    useEffect(() => {
        setShow(active);

        if (active) {
            setDisplay('flex');
        } else {
            setTimeout(() => {
                setDisplay('none');
            }, TIMEOUT);
        }
    }, [active]);

    return (
        <>
            <Backdrop active={show} />
            <Container active={show} display={display} id={id}>
                <BottomSheet active={show}>
                    <Content>
                        <CloseHeader>
                            <CloseHeaderContent>{header}</CloseHeaderContent>
                        </CloseHeader>
                        <Spacer />
                        <ChildrenContent footer={Boolean(footer)}> {children}</ChildrenContent>

                        {typeof footer === 'object' ? (
                            <>
                                <Spacer />
                                <Footer footer={Boolean(footer)}>{footer}</Footer>
                            </>
                        ) : undefined}
                    </Content>
                </BottomSheet>
            </Container>
        </>
    );
};

export default NewBottomSheet;
