import { BREAKPOINT } from '@ab-core/breakpoints';
import { useIsMobile, useOnClickOutside } from '@ab-core/functions';
import type { WithChildren } from '@ab-core/utils/types';
import type { FC } from 'react';
import React, { useRef } from 'react';
import { Wrapper } from './styled';

export type DropdownOverlayType = WithChildren & {
    parentRef: React.RefObject<HTMLDivElement>;
    open: boolean;
    onClose: () => void;
    topMargin?: number;
};

const MAX_WIDTH = 9999;

export const DropdownOverlay: FC<DropdownOverlayType> = (props) => {
    const { open, parentRef, children, topMargin, onClose } = props;
    const isMobile = useIsMobile(BREAKPOINT.SM);

    const ref = useRef<HTMLDivElement>(null);

    useOnClickOutside([ref, parentRef], onClose);

    if (typeof window === 'undefined') {
        return;
    }

    const getWrapperWidth = (): string => (isMobile ? '100%' : `${parentRef.current?.clientWidth}px`);

    const getWrapperLeftOffset = (): string => {
        if (isMobile) {
            return `0px`;
        }

        if ((window.visualViewport?.width || 0) > MAX_WIDTH) {
            return `${(parentRef.current?.offsetLeft || 0) + ((window.visualViewport?.width || 0) - MAX_WIDTH) / 2}px`;
        }

        return `${parentRef.current?.offsetLeft}px`;
    };

    if (!open) {
        return <></>;
    }

    return (
        <Wrapper offsetLeft={getWrapperLeftOffset()} width={getWrapperWidth()} ref={ref} topMargin={topMargin}>
            {children}
        </Wrapper>
    );
};
