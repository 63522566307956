import type { FC } from 'react';
import React from 'react';
import { BackdropWrapper } from './collection.styled';

const MAXWIDTH = 9999;

export const Backdrop: FC = () => {
    if (typeof window === 'undefined') {
        return;
    }

    const getLeftProp = (): string => {
        if ((window.visualViewport?.width || 0) > MAXWIDTH) {
            return `${((window.visualViewport?.width || 0) - MAXWIDTH) / 2}px`;
        }

        return '0px';
    };

    return <BackdropWrapper left={getLeftProp()} />;
};
