import { gql } from '@apollo/client';

export const KANBAN_LABEL = gql`
    fragment KanbanLabelFields on KanbanLabel {
        id
        createdAt
        updatedAt
        accountId
        labelSetId
        qrContent
        name
        description
        sku
        quantity
        quantityUnit
        salesUnit
        supplier
        customerArticleNumber
        manufacturerShort
        storageLocation
        qrId
        packagingSize
        hideSku
    }
`;
