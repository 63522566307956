import { gql } from '@apollo/client';
import { KANBAN_LABEL } from './kanbanLabelFragment';

export const KANBAN_LABEL_SET = gql`
    fragment KanbanLabelSetFields on KanbanLabelSet {
        id
        createdAt
        updatedAt
        accountId
        name
        kanbanLabels {
            ...KanbanLabelFields
        }
        count
    }
    ${KANBAN_LABEL}
`;
