import Button, { BUTTON_COLOR, BUTTON_VARIANT } from '@ab-core/button';
import { Col, Container, Row } from '@ab-core/grid';
import type { User } from '@ab-core/hooks/user/useFullUser';
import { useFullUser } from '@ab-core/hooks/user/useFullUser';
import useText from '@ab-core/localization/src/useText';
import Modal from '@ab-core/modal';
import Spacer from '@ab-core/spacer';
import Text from '@ab-core/text';
import { toast } from '@ab-core/toast';
import React, { useState } from 'react';
import { logger } from './..';

type LoggingType<T> = { user?: User } & T;

export const DebugModal = <T,>(props: T) => {
    const { user } = useFullUser();
    const [open, setOpen] = useState(false);

    const sendDebugInformation = () => {
        logger.debug<LoggingType<T>>({
            title: `Debug-Informationen - "${location.href}" -  ${user?.activeAccount?.accountNumber}: ${user?.activeAccount?.accountName}`,
            infos: { ...props, user }
        });

        toast.success('Debug Informationen wurden gesendet');
        setOpen(false);
    };

    return (
        <>
            <Text
                className="pointer text-underline"
                content="Debug Informationen senden"
                onClick={() => setOpen(true)}
            />
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                headerText="Debug Informationen senden"
                footer={
                    <Row justifyContent="between">
                        <Col sm={6} smOrder={2} className="pb-small5">
                            <Button onClick={sendDebugInformation} fluid>
                                Daten senden
                            </Button>
                        </Col>
                        <Col sm={6} smOrder={1}>
                            <Button
                                color={BUTTON_COLOR.GRAY}
                                fluid
                                variant={BUTTON_VARIANT.OUTLINE}
                                onClick={() => setOpen(false)}
                            >
                                {useText('cartList.addCartCancelButtonLabel')}
                            </Button>
                        </Col>
                    </Row>
                }
            >
                <Spacer size="xs" />
                <Container>
                    <Row>
                        <Col>
                            <Text content="Bitte senden Sie nur Ihre Daten, wenn Sie von einem Alexander-Bürkle Mitarbeiter dazu aufgefordert werden. Möglicherweise müssen Sie hierfür Ihren Ad-blocker deaktivieren." />
                        </Col>
                    </Row>
                </Container>
            </Modal>
        </>
    );
};
