import { gql } from '@apollo/client';

export const FAST_CAPTURE_SEARCH_LIST = gql`
    query FastCaptureSearchList($input: [String!]!) {
        fastCaptureSearchList(input: $input) {
            results {
                document {
                    ean
                    name
                    quantityUnit
                    salesUnit
                    sku
                }
            }
            notFound
        }
    }
`;
