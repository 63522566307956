import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Minus: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="19" y="9.47058" width="1.5" height="18" rx="0.75" transform="rotate(90 19 9.47058)" fill={color} />
        </SVG>
    );
};
