import type { Mutation, MutationDeleteKanbanLabelArgs } from '@ab-core/graphql/dist';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getErrorLoggerFunction } from '../utils';
import { DELETE_KANBAN_LABEL } from './gql/deleteKanbanLabel';
import { GET_KANBAN_LABEL_SET } from './gql/getKanbanLabelSet';

type MutationDataType = Pick<Mutation, 'deleteKanbanLabel'>;
type ResponseDataType = MutationDataType | undefined;

const onError = getErrorLoggerFunction('deleteKanbanLabel');

const refetchQueries = [GET_KANBAN_LABEL_SET];

export const useDeleteKanbanLabel = (id: string) => {
    const [response, setResponse] = useState<ResponseDataType>(undefined);

    const [deleteKanbanLabel, { loading, error, data }] = useMutation<MutationDataType, MutationDeleteKanbanLabelArgs>(
        DELETE_KANBAN_LABEL,
        {
            variables: { id },
            refetchQueries,
            onError
        }
    );

    useEffect(() => {
        if (data) {
            setResponse(data);
        }
    }, [data]);

    return {
        success: !!data,
        error: !!error,
        deleteKanbanLabel,
        loading,
        response
    };
};
