import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const List: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} version="1.1" x="0px" y="0px" viewBox="0 0 20 20">
            <path
                d="M19.4,0.1h-14C5,0.1,4.7,0.4,4.7,0.7S5,1.4,5.4,1.4h14c0.3,0,0.6-0.3,0.6-0.6S19.7,0.1,19.4,0.1z"
                fill={color}
            />
            <path d="M0.8,0C0.3,0,0,0.3,0,0.7s0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7S1.2,0,0.8,0z" fill={color} />
            <path
                d="M19.4,18.6h-14c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h14c0.3,0,0.6-0.3,0.6-0.6S19.7,18.6,19.4,18.6z"
                fill={color}
            />
            <path
                d="M0.8,18.5c-0.4,0-0.7,0.3-0.7,0.7S0.3,20,0.8,20c0.4,0,0.7-0.3,0.7-0.7S1.2,18.5,0.8,18.5z"
                fill={color}
            />
            <path
                d="M19.4,9.4h-14C5,9.4,4.7,9.7,4.7,10s0.3,0.6,0.6,0.6h14c0.3,0,0.6-0.3,0.6-0.6S19.7,9.4,19.4,9.4z"
                fill={color}
            />
            <path d="M0.8,9.3C0.3,9.3,0,9.6,0,10s0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7S1.2,9.3,0.8,9.3z" fill={color} />
        </SVG>
    );
};
