import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Euro: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.1536 12.3408V10.7504H7.808C7.7856 10.504 7.7856 10.2576 7.7856 9.9888C7.7856 9.6976 7.7856 9.4288 7.808 9.1376H12.1536V7.5472H8.1216C8.6144 6.0688 9.6896 5.128 11.4592 5.128C13.1392 5.128 14.08 6.3152 14.08 6.3152L15.2672 4.6128C15.2672 4.6128 14.0576 3 11.4368 3C8.3008 3 6.464 4.9712 5.7696 7.5472H4V9.1376H5.5008C5.4784 9.4288 5.4784 9.72 5.4784 9.9888C5.4784 10.2576 5.4784 10.504 5.5008 10.7504H4V12.3408H5.7472C6.4192 14.984 8.2784 17 11.4368 17C14.0576 17 15.2672 15.3872 15.2672 15.3872L14.08 13.7296C14.08 13.7296 13.1392 14.9168 11.4592 14.9168C9.6448 14.9168 8.5696 13.9088 8.0992 12.3408H12.1536Z"
                fill={color}
            />
        </SVG>
    );
};
