import type { FC } from 'react';
import React from 'react';
import { SVG } from '../styled';
import type { IconTagProps } from '../types';

export const Linkedin: FC<IconTagProps> = (props) => {
    const { color } = props;

    return (
        <SVG {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.625001 10C0.625001 4.82233 4.82233 0.624999 10 0.624999C15.1777 0.625 19.375 4.82233 19.375 10C19.375 15.1777 15.1777 19.375 10 19.375C4.82233 19.375 0.625 15.1777 0.625001 10Z"
                stroke={color}
                strokeWidth="1.25"
                strokeLinecap="round"
            />
            <path
                d="M15 5.7375V14.2611C15 14.6694 14.6694 15 14.2611 15H5.73889C5.33056 15 5 14.6694 5 14.2611V5.7375C5 5.33056 5.33056 5 5.73889 5H14.2625C14.6708 5 15.0014 5.33056 15.0014 5.7375H15ZM7.98056 8.74306H6.47778V13.5194H7.98056V8.74306ZM8.09583 7.21806C8.09583 6.71944 7.70833 6.35417 7.22917 6.35417C6.75 6.35417 6.3625 6.71944 6.3625 7.21806C6.3625 7.71667 6.75 8.08056 7.22917 8.08056C7.70833 8.08056 8.09583 7.71667 8.09583 7.21806ZM13.5236 10.5681C13.5236 9.10972 12.6167 8.61111 11.7778 8.61111C11.0028 8.61111 10.4944 9.1125 10.3486 9.40694H10.3292V8.74583H8.88333V13.5222H10.3861V10.9333C10.3861 10.2431 10.8236 9.90694 11.2694 9.90694C11.6917 9.90694 12.0181 10.1444 12.0181 10.9139V13.5222H13.5208V10.5681H13.5236Z"
                fill={color}
            />
        </SVG>
    );
};
