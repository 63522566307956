import { ROUTES } from '@ab-core/functions';
import { getChangeLog } from '@ab-core/gatsby-theme-core/src/scopes/changeLog/components/getChangeLog';
import { Icon } from '@ab-core/icon';
import { navigationStore } from '@ab-core/store';
import Text from '@ab-core/text';
import { navigate } from 'gatsby';
import type { FC } from 'react';
import React from 'react';

export const ChangeLogNotifications: FC = () => {
    const allContentfulChangeLog = getChangeLog();

    const goToChangeLog = () => {
        navigate(ROUTES.CHANGELOG);

        navigationStore.setters.setUserDropdownNavigation(false);
        navigationStore.setters.setUserNavigation(false);
    };

    const changeLogRead = localStorage.getItem('changeLogRead')
        ? // eslint-disable-next-line
          new Date(localStorage.getItem('changeLogRead') || '')
        : new Date('2022-12-12');
    const latestNotification = new Date(allContentfulChangeLog?.nodes[0].createdAt);

    const readMessages = (): boolean => latestNotification.getTime() >= changeLogRead.getTime();

    return (
        <div className=" flex pointer gap-small2 " onClick={() => goToChangeLog()}>
            <div className="flex">
                <Icon name={readMessages() ? 'NotificationNews' : 'Notification'} />
            </div>

            <Text>Neuigkeiten</Text>
        </div>
    );
};
